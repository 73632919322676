import { useCurrentScene } from "@/modes/mode-data-context";
import {
  CadModelObject,
  PanoObject,
  PointCloudObject,
  useCached3DObjectIfReady,
} from "@/object-cache";
import { isPanoObject, isPointCloudObject } from "@/object-cache-type-guard";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectModeName } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { useTypedEvent } from "@faro-lotv/app-component-toolbox";
import { FaroText, TextField } from "@faro-lotv/flat-ui";
import {
  CadModel,
  LodCachingStrategyMaxChunks,
  StreamCadModel,
} from "@faro-lotv/lotv";
import { Checkbox, Stack } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { Color } from "three";
import { useThreeContext } from "../common/three-context/three-context";

/** @returns a panel with dev functions enabled only using the dev server */
export function DevToolsPanel(): JSX.Element | null {
  const showDevTools =
    useAppSelector(selectHasFeature(Features.DevTools)) && import.meta.env.DEV;

  const activeMode = useAppSelector(selectModeName);
  const isInvalidMode = activeMode === "createArea" || activeMode === "start";

  if (!showDevTools || isInvalidMode) {
    return null;
  }

  return <DevToolsPanelImplementation />;
}

function DevToolsPanelImplementation(): JSX.Element {
  const { main } = useCurrentScene();
  const mainObject = useCached3DObjectIfReady(main);

  const safeMainObject =
    mainObject && !(mainObject instanceof Error) ? mainObject : undefined;

  const activeCad = useAppSelector(selectActiveCadModel);
  const cadModelObject = useCached3DObjectIfReady(activeCad);

  const [isOpen, toggle] = useReducer((value) => !value, false);

  return (
    <Stack
      direction="column"
      alignContent="center"
      justifyItems="center"
      component="fieldset"
    >
      <legend onClick={toggle}>
        Dev Panel <button>{isOpen ? "close" : "open"}</button>
      </legend>
      {isOpen && (
        <>
          <DepthButton mainObject={safeMainObject} />
          <PcCacheEdit mainObject={safeMainObject} />
          {cadModelObject && !(cadModelObject instanceof Error) && (
            <CadModelStatus cadModel={cadModelObject} />
          )}
          <BackgroundSelector />
        </>
      )}
    </Stack>
  );
}

type ToolProps = {
  mainObject?: PanoObject | PointCloudObject;
};

function DepthButton({ mainObject }: ToolProps): JSX.Element {
  const [showDepthImage, toggle] = useReducer((val) => !val, false);

  const isEnabled = mainObject && isPanoObject(mainObject);

  useEffect(() => {
    if (mainObject && isPanoObject(mainObject)) {
      mainObject.showDepthImage = showDepthImage;
      return () => {
        mainObject.showDepthImage = false;
      };
    }
  }, [mainObject, showDepthImage]);

  return (
    <Stack
      direction="row"
      sx={{ width: "100%" }}
      alignItems="center"
      justifyContent="space-between"
      onClick={isEnabled ? toggle : undefined}
    >
      <FaroText variant="bodyM">Show Depths</FaroText>
      <Checkbox disabled={!isEnabled} value={showDepthImage} />
    </Stack>
  );
}

function PcCacheEdit({ mainObject }: ToolProps): JSX.Element {
  const [pcNodeCacheSize, setPcNodeCacheSize] = useState(0);
  const [pcParallelFetches, setPcParallelFetches] = useState(4);
  const [nodesInCache, setNodesInCache] = useState(0);
  const [nodesInGpu, setNodesInGpu] = useState(0);

  useEffect(() => {
    if (!mainObject || !isPointCloudObject(mainObject)) return;
    const cacheSystem = mainObject.cacheCleanComputer;
    if (cacheSystem instanceof LodCachingStrategyMaxChunks) {
      setPcNodeCacheSize(cacheSystem.maxChunks);
    }
    setPcParallelFetches(mainObject.lodTreeFetcher.maxNodesToDownloadAtOnce);
    const updated = (): void => {
      setNodesInCache(mainObject.nodesInMemoryCount);
      setNodesInGpu(mainObject.nodesInGPU.size);
    };
    const handle = setInterval(updated, 1000);
    return () => {
      clearInterval(handle);
    };
  }, [mainObject]);

  useEffect(() => {
    if (mainObject && isPointCloudObject(mainObject)) {
      const cacheSystem = mainObject.cacheCleanComputer;
      if (cacheSystem instanceof LodCachingStrategyMaxChunks) {
        const oldSize = cacheSystem.maxChunks;
        cacheSystem.maxChunks = pcNodeCacheSize;
        return () => {
          cacheSystem.maxChunks = oldSize;
        };
      }
    }
  }, [mainObject, pcNodeCacheSize]);

  useEffect(() => {
    if (mainObject && isPointCloudObject(mainObject)) {
      const oldPar = mainObject.lodTreeFetcher.maxNodesToDownloadAtOnce;
      mainObject.lodTreeFetcher.maxNodesToDownloadAtOnce = pcParallelFetches;
      return () => {
        mainObject.lodTreeFetcher.maxNodesToDownloadAtOnce = oldPar;
      };
    }
  }, [mainObject, pcParallelFetches]);

  return (
    <>
      <Stack
        direction="row"
        sx={{ width: "100%" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <FaroText variant="bodyM">Pc Cached Nodes</FaroText>
        <TextField
          text={pcNodeCacheSize.toString()}
          onTextChanged={(value) => setPcNodeCacheSize(parseInt(value, 10))}
        />
      </Stack>
      <Stack
        direction="row"
        sx={{ width: "100%" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <FaroText variant="bodyM">Parallel Fetches</FaroText>
        <TextField
          text={pcParallelFetches.toString()}
          onTextChanged={(value) => setPcParallelFetches(parseInt(value, 10))}
        />
      </Stack>
      <FaroText variant="bodyM">Nodes in cache {nodesInCache}</FaroText>
      <FaroText variant="bodyM">Nodes in gpu {nodesInGpu}</FaroText>
    </>
  );
}

function BackgroundSelector(): JSX.Element {
  const { scene } = useThreeContext();
  const [color, setColor] = useState(scene?.background);

  useEffect(() => {
    if (!color || !scene) return;
    scene.background = color;
  }, [color, scene]);

  return (
    <Stack
      direction="row"
      sx={{ width: "100%" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <FaroText variant="bodyM">Background</FaroText>
      <input
        type="color"
        onChange={(e) => setColor(new Color(e.target.value))}
      />
    </Stack>
  );
}

type CadModelStatusProps = {
  /** The active CAD model */
  cadModel: CadModelObject;
};

/** @returns Component display the status of the active CAD model */
function CadModelStatus({ cadModel }: CadModelStatusProps): JSX.Element {
  const [completed, setCompleted] = useState(false);
  const [chunkState, setChunkState] = useState("");
  const [bufferState, setBufferState] = useState("");

  useEffect(() => {
    if (cadModel instanceof StreamCadModel) {
      setCompleted(cadModel.isCompleted);
      setChunkState(cadModel.stats.chunks);
      setBufferState(cadModel.stats.bufferSize);
    }
  }, [cadModel]);

  useTypedEvent<void>(
    cadModel instanceof StreamCadModel ? cadModel.chunkLoaded : undefined,
    () => {
      if (!(cadModel instanceof StreamCadModel)) return;
      setChunkState(cadModel.stats.chunks);
      setBufferState(cadModel.stats.bufferSize);
    },
  );
  useTypedEvent<void>(
    cadModel instanceof StreamCadModel ? cadModel.streamCompleted : undefined,
    () => {
      if (!(cadModel instanceof StreamCadModel)) return;
      setCompleted(cadModel.isCompleted);
    },
  );
  return (
    <>
      {cadModel instanceof CadModel && (
        <FaroText variant="bodyM">
          CadModel Nodes {cadModel.nodesCount()}
        </FaroText>
      )}
      {cadModel instanceof StreamCadModel && (
        <>
          <FaroText variant="bodyM">
            CadModel Stream {completed ? " completed" : " in progress"}
          </FaroText>
          <FaroText variant="bodyM">CadModel Chunks {chunkState} </FaroText>
          <FaroText variant="bodyM">
            CadModel BufferSize {bufferState}{" "}
          </FaroText>
        </>
      )}
    </>
  );
}
