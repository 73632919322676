import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { ProjectProvider } from "@/components/common/project-provider/project-provider";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { EnsureProjectAccess } from "@/permissions/ensure-project-access";
import { runtimeConfig } from "@/runtime-config";
import { selectActiveElement } from "@/store/selections-selectors";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { appId } from "@/utils/appid";
import { redirectToViewer } from "@/utils/redirects";
import {
  FaroDialog,
  LoadingScreen,
  selectAncestor,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { useAnalyticsInitialization } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  IElementGenericImgSheet,
  IElementPointCloudStream,
  isIElementGenericPointCloudStream,
  isIElementSection,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { ApiClientContextProvider } from "@faro-lotv/service-wires";
import { PropsWithChildren, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../store/store-hooks";
import { AlignmentToolUi } from "./alignment-tool-ui";
import { useAlignmentToolSubTree } from "./hooks/use-alignment-tool-sub-tree";
import { useHandleWindowClose } from "./hooks/use-handle-window-close";
import { createPointCloudAlignmentMutations } from "./project-alignment-mutations";
import { selectIsAlignmentCompleted } from "./store/alignment-selectors";
import {
  AlignmentTransform,
  initAlignment,
  setAlignmentArea,
  setCloudToAlign,
  setIsAlignmentCompleted,
  setIsAlignmentToolBusy,
} from "./store/alignment-slice";
import { useQueryParams } from "./use-query-params";
import { alignmentTransformToMatrix4 } from "./utils/alignment-transform";

/**
 * @returns Entry point of the Alignment Tool
 */
export function ProjectAlignmentTool(): JSX.Element | null {
  const { projectId } = useParams();
  const currentUser = useAppSelector(selectCurrentUser);

  // Hook to handle the dialog on window close during alignment
  useHandleWindowClose();

  if (!projectId) {
    throw Error("No project ID provided");
  }

  useAnalyticsInitialization(
    runtimeConfig.analytics.amplitudeApiKey,
    currentUser?.email,
  );

  const [isReady, setIsReady] = useState(false);
  const dispatch = useAppDispatch();

  const { alignAreaId, alignCloudId } = useQueryParams();
  assert(
    alignAreaId && alignCloudId,
    "Area and cloud must be provided to perform cloud-to-area alignment",
  );

  const onReady = useCallback(
    (ready: boolean) => {
      if (!ready) {
        return;
      }
      // If we don't have it start in Load and Align mode

      dispatch(initAlignment());
      dispatch(setCloudToAlign(alignCloudId));
      dispatch(setAlignmentArea(alignAreaId));
      setIsReady(ready);
    },
    [alignAreaId, dispatch, alignCloudId],
  );

  const activeElement = useAppSelector(selectActiveElement);

  const showLoadingScreen = !isReady || !activeElement;

  return (
    <ApiClientContextProvider
      projectId={projectId}
      userId={currentUser?.id}
      clientId={appId()}
      apiBaseUrls={runtimeConfig.backendEndpoints}
    >
      <EnsureProjectAccess projectId={projectId}>
        <ProjectProvider
          projectId={projectId}
          onReady={onReady}
          requiredItem={alignAreaId}
        >
          <SubTreeFetcherWrapper
            alignAreaId={alignAreaId}
            alignCloudId={alignCloudId}
          >
            {!showLoadingScreen && <ProjectAlignmentToolUi />}
          </SubTreeFetcherWrapper>
        </ProjectProvider>
        {showLoadingScreen && <LoadingScreen />}
      </EnsureProjectAccess>
    </ApiClientContextProvider>
  );
}

/**
 * @returns A wrapper for the AlignmentToolUi to be used in a project context
 */
function ProjectAlignmentToolUi(): JSX.Element {
  const client = useCurrentProjectApiClient();

  const dispatch = useAppDispatch();
  const store = useAppStore();

  const isAlignmentCompleted = useAppSelector(selectIsAlignmentCompleted);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const { handleErrorWithDialog } = useErrorHandlers();

  const handleAlignmentFinished = useCallback(
    async (
      sheet: IElementGenericImgSheet,
      cloudStream: IElementPointCloudStream,
      elementTransform: AlignmentTransform,
    ) => {
      const elementTransformMatrix4 =
        alignmentTransformToMatrix4(elementTransform);

      try {
        dispatch(setIsAlignmentToolBusy(true));

        const pointCloudSection = selectAncestor(
          cloudStream,
          isIElementSectionDataSession,
        )(store.getState());

        assert(
          pointCloudSection,
          "The cloud to align is not part of the project, unable to find point cloud",
        );

        await client.applyMutations(
          createPointCloudAlignmentMutations(
            sheet,
            pointCloudSection,
            cloudStream,
            elementTransformMatrix4,
            store.getState(),
          ),
        );

        dispatch(setIsAlignmentCompleted(true));
      } catch (error) {
        handleErrorWithDialog({
          title: "Could not finish the alignment.",
          error,
        });
      } finally {
        dispatch(setIsAlignmentToolBusy(false));
      }
    },
    [client, dispatch, handleErrorWithDialog, store],
  );

  return (
    <>
      <FaroDialog
        open={isAlignmentCompleted}
        title="Alignment Completed"
        onConfirm={() => {
          dispatch(setIsAlignmentCompleted(false));
          redirectToViewer(client.projectId);
        }}
      >
        The alignment between the Point Cloud and floor plan was successful, you
        will be now redirected to the Sphere Viewer.
      </FaroDialog>

      <FaroDialog
        open={isCancelDialogOpen}
        title="Leave the Alignment Tool?"
        onConfirm={() => {
          setIsCancelDialogOpen(false);
          redirectToViewer(client.projectId);
        }}
        onCancel={() => setIsCancelDialogOpen(false)}
      >
        Any changes made will be lost.
      </FaroDialog>

      <AlignmentToolUi
        onFinishAlignment={handleAlignmentFinished}
        onRedirectToViewerClicked={() => setIsCancelDialogOpen(true)}
      />
    </>
  );
}

type SubTreeFetcherWrapperProps = {
  /** Id of the current area */
  alignAreaId: GUID;
  /** Id of the cloud to align in the tool */
  alignCloudId: GUID;
};

/**
 * @returns a wrapper that waits for the sub tree to be fetched before rendering its children
 */
function SubTreeFetcherWrapper({
  alignAreaId,
  alignCloudId,
  children,
}: PropsWithChildren<SubTreeFetcherWrapperProps>): JSX.Element | null {
  const alignCloud = useAppSelector(selectIElement(alignCloudId));
  const alignArea = useAppSelector(selectIElement(alignAreaId));

  // Fetch the sub tree needed for the element to align.
  // The sub tree will be used to get other models from the same floor to get an initial transform.
  // If the alignAreaId is not available, the sub tree will be fetched starting from the root.
  const fetchInProgress = useAlignmentToolSubTree(
    alignArea && isIElementSection(alignArea) ? alignArea : undefined,
    alignCloud && isIElementGenericPointCloudStream(alignCloud)
      ? alignCloud
      : undefined,
  );

  if (fetchInProgress) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
