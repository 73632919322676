import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { Mode, ModeTransitionProps } from "@/modes/mode";
import { useCached3DObject } from "@/object-cache";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import { selectCloudForCadAlignment } from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  CloudToCadAlignmentStep,
  resetCloudToCadAlignment,
  setCloudElevationForCloudAlignment,
  setModelElevationForCloudAlignment,
  setStepForCloudAlignment,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import { isIElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useEffect } from "react";
import { Vector3 } from "three";
import { CloudToCadAlignmentModeOverlay } from "./cloud-to-cad-alignment-mode-overlay";
import { CloudToCadAlignmentModeScene } from "./cloud-to-cad-alignment-mode-scene";

export const cloudToCadAlignmentMode: Mode = {
  name: "cloudToCadAlignment",
  ModeScene: CloudToCadAlignmentModeScene,
  ModeOverlay: CloudToCadAlignmentModeOverlay,
  ModeTransition: CloudToCadAlignmentModeTransition,
  exclusive: {
    title: "Align cloud to 3D Model",
    // eslint-disable-next-line require-await -- FIXME
    async onBack({ dispatch }) {
      dispatch(resetCloudToCadAlignment());

      dispatch(changeMode("overview"));
    },
  },
};

function CloudToCadAlignmentModeTransition({
  onCompleted,
}: ModeTransitionProps): null {
  const activeCloudID = useAppSelector(selectCloudForCadAlignment);
  const activeCloud = useAppSelector(selectIElement(activeCloudID));
  const activeCad = useAppSelector(selectActiveCadModel);
  assert(
    activeCad && activeCloud && isIElementGenericPointCloudStream(activeCloud),
    "Cloud to CAd Alignment Mode requires cad and cloud",
  );

  const cadModel = useCached3DObject(activeCad);
  const cadBox = useObjectBoundingBox(cadModel, cadModel.iElement.id);
  assert(cadBox, "Expected CAD model has bounding box computed on loading");

  const pointCloud = useCached3DObject(activeCloud);
  const cloudBox = useObjectBoundingBox(pointCloud, activeCloud.id);
  assert(cloudBox, "Expected cloud has bounding box computed on loading");

  // Get the elevation value saved from the previous cloud-to-cad alignment
  // The value is same for both cad and the aligned cloud.
  const elevation =
    activeCloud.metaDataMap?.cloudToCadElevation &&
    typeof activeCloud.metaDataMap.cloudToCadElevation === "number"
      ? activeCloud.metaDataMap.cloudToCadElevation
      : undefined;

  const dispatch = useAppDispatch();

  useEffect(() => {
    // make sure to start with the first step (set elevations)
    dispatch(setStepForCloudAlignment(CloudToCadAlignmentStep.setElevations));

    const cadCenter = cadBox.getCenter(new Vector3());
    dispatch(setModelElevationForCloudAlignment(elevation ?? cadCenter.y));

    const cloudCenter = cloudBox.getCenter(new Vector3());
    dispatch(setCloudElevationForCloudAlignment(elevation ?? cloudCenter.y));

    onCompleted();
  }, [cadBox, cloudBox, dispatch, elevation, onCompleted]);

  return null;
}
