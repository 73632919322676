import { selectClippingBoxEnabledForCadAlignment } from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import { setClippingBoxEnabled } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  ClippingBoxIcon,
  ToolButton,
  ToolGroup,
  neutral,
} from "@faro-lotv/flat-ui";
import { Stack, Toolbar, Tooltip } from "@mui/material";
import { SheetToCadAlignmentSetElevationPanel } from "./sheet-to-cad-alignment-set-elevation-panel";

/** @returns The sheet to cad alignment elevation control */
export function SheetToCadAlignmentElevation(): JSX.Element {
  const dispatch = useAppDispatch();

  const isClippingBoxEnabled = useAppSelector(
    selectClippingBoxEnabledForCadAlignment,
  );

  return (
    <Stack direction="row">
      <Toolbar
        sx={{
          height: "max-content",
          position: "absolute",
          right: 5,
          top: "40%",
        }}
      >
        <ToolGroup>
          <Tooltip title="Clip 3D Model" placement="left">
            <ToolButton
              sx={{ backgroundColor: neutral[999] }}
              selected={isClippingBoxEnabled}
              onClick={() => {
                dispatch(setClippingBoxEnabled(!isClippingBoxEnabled));
              }}
            >
              <ClippingBoxIcon sx={{ width: "24px", height: "24px" }} />
            </ToolButton>
          </Tooltip>
        </ToolGroup>
      </Toolbar>
      <SheetToCadAlignmentSetElevationPanel />
    </Stack>
  );
}
