import { Plane } from "three";
import { create } from "zustand";

export type ClippingBoxContext = {
  /** Function called when the reset clipping box button is clicked */
  onBoxReset?(): void;

  /** Setter for the reset callback function */
  setOnBoxReset(onBoxReset: () => void): void;

  /** The six planes defining the clipping box if a valid set is available */
  clippingPlanes?: Plane[];

  /**
   * Set the six planes defining the clipping box or undefined if there are no valid planes available
   *
   * The planes are valid if all the sizes of the selection box are > 0.01 and
   * the user defined box intersects the point cloud bounding box
   *
   * They selection box may still contain no points
   */
  setClippingPlanes(planes: Plane[] | undefined): void;
};

/** Context containing useful data for the Clipping Box mode */
export const useClippingBoxContext = create<ClippingBoxContext>((set) => ({
  onBoxReset: undefined,
  setOnBoxReset: (onBoxReset: () => void) => set({ onBoxReset }),
  clippingPlanes: undefined,
  setClippingPlanes: (clippingPlanes: Plane[]) => set({ clippingPlanes }),
}));
