import { getPickedPoint } from "@faro-lotv/app-component-toolbox";
import { TypedEvent } from "@faro-lotv/lotv";
import {
  DomEvent,
  ThreeEvent,
} from "@react-three/fiber/dist/declarations/src/core/events";
import { EventDispatcher, MOUSE, Vector2, Vector3 } from "three";
import { ToolControlsLogic } from "../tool-controls-interface";

/**
 * Provides functionality for taking a polygon measurement in 3D.
 * During the measurement process, events are emitted to indicate when a measurement has started,
 * when the picked points have changed, and when a measurement has been completed.
 *
 * TODO: add tests https://faro01.atlassian.net/browse/SWEB-3911
 */
export class MultiPointMeasureControlsLogic
  extends EventDispatcher
  implements ToolControlsLogic
{
  #points: Vector3[] | undefined = [];
  #currentPoint = new Vector3();
  #endPointInPixels = new Vector2();
  #currentElement: string | undefined;

  onMeasurementStarted = new TypedEvent<void>();
  onMeasurementCompleted = new TypedEvent<{ isClosed: boolean; id: string }>();
  onMeasurementCanceled = new TypedEvent<void>();
  onCurrentPointChanged = new TypedEvent<Vector3 | undefined>();
  onPointsChanged = new TypedEvent<void>();

  /**
   * Called when the user moves the mouse above the current active model
   *
   * @param ev The mouse event that triggered this callback
   * @param iElementId The iElement that triggered this event
   */
  pointHovered(ev: ThreeEvent<DomEvent>, iElementId: string): void {
    if (this.#currentElement && this.#currentElement !== iElementId) return;

    getPickedPoint(ev, this.#currentPoint);
    this.#endPointInPixels.set(ev.clientX, ev.clientY);
    this.onCurrentPointChanged.emit(this.#currentPoint);
  }

  /**
   * Called when the user clicks/taps on the current active model
   *
   * @param ev The mouse event that triggered this callback
   * @param iElementId The iElement that triggered this event
   */
  pointClicked(ev: ThreeEvent<DomEvent>, iElementId: string): void {
    if (this.#currentElement && this.#currentElement !== iElementId) return;
    if (ev.button !== MOUSE.LEFT) return;
    if (!this.#points) this.#points = [];

    getPickedPoint(ev, this.#currentPoint);
    if (
      this.#points.length > 0 &&
      this.#currentPoint.distanceTo(this.#points[this.#points.length - 1]) <
        Number.EPSILON
    ) {
      return;
    }

    this.#points.push(this.#currentPoint.clone());
    this.onPointsChanged.emit();

    if (this.#points.length === 1) {
      this.onMeasurementStarted.emit();
      this.#currentElement = iElementId;
    }
  }

  /**
   * Complete the interaction
   *
   * @param isClosed True if the measurement is a closed polygon
   */
  complete(isClosed: boolean): void {
    if (!this.#currentElement) return;
    this.onMeasurementCompleted.emit({ isClosed, id: this.#currentElement });
    this.#points = undefined;
    this.onPointsChanged.emit();
    this.onCurrentPointChanged.emit(undefined);
    this.#currentElement = undefined;
  }

  /**
   * Remove one point from the list of picked points
   *
   * @param index The index of the point to remove
   */
  deletePoint(index: number): void {
    if (!this.#points) return;
    if (index < 0 || index >= this.#points.length) return;

    this.#points.splice(index, 1);
    this.onPointsChanged.emit();

    if (this.#points.length === 0) {
      this.cancel();
    }
  }

  /**
   * Cancels the measurement
   */
  cancel(): void {
    this.#points = undefined;
    this.onMeasurementCanceled.emit();
    this.onPointsChanged.emit();
    this.onCurrentPointChanged.emit(undefined);
    this.#currentElement = undefined;
  }

  /**
   * @returns The list of picked points. Undefined if the measurement has never started
   */
  get points(): Vector3[] | undefined {
    return this.#points;
  }

  /**
   * @returns true if the measurement is started or live
   */
  isLiveMeasure(): boolean {
    return !!this.#points;
  }

  /**
   * @returns The 2D coordinates in pixels of the point on which the mouse hovering
   */
  get pointCoordinates(): Vector2 {
    return this.#endPointInPixels;
  }
}
