import { EventType } from "@/analytics/analytics-events";
import {
  selectBackgroundTasks,
  selectBackgroundTasksThatShouldPreventWindowClose,
} from "@/store/background-tasks/background-tasks-selector";
import { useAppSelector } from "@/store/store-hooks";
import { Analytics } from "@faro-lotv/foreign-observers";
import { isBackgroundTaskActive } from "@faro-lotv/service-wires";
import { useBeforeUnload } from "react-router-dom";

/**
 * @returns the logic to prevent the browser tab from closing if there are any blocking background tasks.
 */
export function HandleWindowClose(): null {
  const blockingTasks = useAppSelector(
    selectBackgroundTasksThatShouldPreventWindowClose(),
  );
  const runningTasks = useAppSelector(
    selectBackgroundTasks((task) => isBackgroundTaskActive(task.state)),
  );

  useBeforeUnload((ev) => {
    if (blockingTasks.length > 0) {
      Analytics.track(EventType.closePageWhileUploading);
      ev.preventDefault();
      // All browsers prevent custom messages, so we just have to pass an empty string here to get the dialog to appear.
      ev.returnValue = "";
    } else if (runningTasks.length > 0) {
      Analytics.track(EventType.closePageWhileBackendProcessing);
    }
  });

  return null;
}
