import {
  LabelAnchored,
  LabelsScreenPositionsComputer,
  TOOLBAR_HEIGHT,
  TOOLBAR_WIDTH,
} from "@/components/r3f/utils/labels-screen-positions-computer";
import {
  humanReadableArea,
  humanReadableDistance,
  parseVector3,
} from "@faro-lotv/app-component-toolbox";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import {
  CameraMonitor,
  areaOfClosedPolygon,
  polygonPerimeter,
} from "@faro-lotv/lotv";
import { Vector3 as Vector3Prop, useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { Vector2, Vector3 } from "three";
import { BIG_HANDLER_SIZE } from "./measure-constants";
import { Measurement } from "./measure-types";

// Cached value used for internal computations of the computeMeasurement function
// to not re-allocate them at every call as this function can be used in the useFrame hot loop
const P1 = new Vector3();
const P2 = new Vector3();
const REFERENCE = new Vector3();

/**
 * Compute the Measurement structure with all the components from a pair of points and a reference position
 *
 * @param from starting point of the measurement
 * @param to ending point of the measurement
 * @param reference reference position for this measurement
 * @returns the computed Measurement object
 */
export function computeMeasurement(
  from: Vector3Prop,
  to: Vector3Prop,
  reference: Vector3Prop,
): Measurement {
  const p1 = parseVector3(from, P1);
  const p2 = parseVector3(to, P2);
  const ref = parseVector3(reference, REFERENCE);

  // Compute the Measurement segments data
  return {
    referencePosition: ref.clone(),
    main: {
      start: new Vector3(p1.x, p1.y, p1.z).sub(ref),
      end: new Vector3(p2.x, p2.y, p2.z).sub(ref),
      length: p1.distanceTo(p2),
      visible: true,
      labelPosition: new Vector3(),
    },
    components: {
      horizontal: {
        prefix: "Horizontal",
        start: new Vector3(p1.x, p2.y, p1.z).sub(ref),
        end: new Vector3(p2.x, p2.y, p2.z).sub(ref),
        length: Math.sqrt(
          (p1.x - p2.x) * (p1.x - p2.x) + (p1.z - p2.z) * (p1.z - p2.z),
        ),
        visible: true,
        labelPosition: new Vector3(ref.x, p2.y, ref.z).sub(ref),
      },
      vertical: {
        prefix: "Vertical",
        start: new Vector3(p1.x, p1.y, p1.z).sub(ref),
        end: new Vector3(p1.x, p2.y, p1.z).sub(ref),
        length: Math.abs(p1.y - p2.y),
        visible: true,
        labelPosition: new Vector3(p1.x, ref.y, p1.z).sub(ref),
      },
    },
  };
}

/**
 * This coefficient has been agreed with the designers and has the purpose
 * of showing the distance components only when their length is significant,
 * i.e. greater than 4 cm by default. The main distance is shown when is longer than zero.
 */
const MIN_SNAP_THRESHOLD = 0.04;
/** Components shorter than this fraction of the measurement length, will be hidden. */
const MIN_SNAP_THRESHOLD_FRACTION = 0.05;
/** Max value that the snap threshold may have. */
const MAX_SNAP_THRESHOLD = 0.1;

/**
 * Update the visibility of a measurement components based on a snap threshold
 *
 * @param measurement the measurement to update
 */
export function updateComponentsVisibility(measurement: Measurement): void {
  const snapDistance = Math.max(
    MIN_SNAP_THRESHOLD,
    Math.min(
      measurement.main.length * MIN_SNAP_THRESHOLD_FRACTION,
      MAX_SNAP_THRESHOLD,
    ),
  );
  measurement.main.visible = measurement.main.length > 0;
  Object.values(measurement.components).forEach(
    (component) => (component.visible = component.length > snapDistance),
  );

  // If only one component is visible, then hide all three
  // because it means that the distance is almost aligned with one of the three axes.
  const componentsVisible = Object.values(measurement.components).filter(
    (component) => component.visible,
  ).length;
  if (componentsVisible < 2) {
    Object.values(measurement.components).forEach(
      (component) => (component.visible = false),
    );
  }
}

/**
 * Compute the top most measurement point based on the camera position
 *
 * @param measurement the measurement to analyze
 * @returns the top most point based on the camera position
 */
export function useCameraSpaceTopMostPoint(
  measurement?: Measurement,
): Vector3 | undefined {
  const [topMostPoint, setTopMostPoint] = useState<Vector3>();

  // Private state used only to optimize the useFrame memory management
  // to not re-allocate objects each frame
  const [topPoint] = useState(new Vector3());
  const [testPoint] = useState(new Vector3());

  // Minimum squared distance required for two points to be considered different
  const minSquaredDistance = 1e-6;

  // Compute top most measurement line end based on current camera position
  useFrame(({ camera }) => {
    if (!measurement) return;
    topPoint
      .copy(measurement.main.start)
      .add(measurement.referencePosition)
      .project(camera);

    // List of other candidate points
    const others = [
      measurement.main.end,
      measurement.components.horizontal.end,
    ];

    // Find highest point in camera space
    for (const point of others) {
      testPoint.copy(point).add(measurement.referencePosition).project(camera);
      if (testPoint.y > topPoint.y) {
        topPoint.copy(testPoint);
      }
    }
    topPoint.unproject(camera).sub(measurement.referencePosition);

    if (
      !topMostPoint ||
      topPoint.distanceToSquared(topMostPoint) > minSquaredDistance
    ) {
      setTopMostPoint(topPoint.clone());
    }
  }, 0);
  return topMostPoint;
}

/**
 * @returns an array of the visibility of the 4 segments of a measurement
 * @param measurement to query for the visibility information
 */
function getSegmentsVisibility(
  measurement: Measurement,
): [boolean, boolean, boolean] {
  return [
    measurement.main.visible,
    measurement.components.horizontal.visible,
    measurement.components.vertical.visible,
  ];
}

/**
 * @returns an array of the desired positions for the segments label
 * @param measurement to query for the visibility information
 * @param array of pre-allocated vectors to not recreate them every frame
 */
function getSegmentsLabelPositions(
  measurement: Measurement,
  array: [Vector3, Vector3, Vector3],
): [Vector3, Vector3, Vector3] {
  array[0]
    .copy(measurement.main.labelPosition)
    .add(measurement.referencePosition);
  array[1]
    .copy(measurement.components.horizontal.labelPosition)
    .add(measurement.referencePosition);
  array[2]
    .copy(measurement.components.vertical.labelPosition)
    .add(measurement.referencePosition);
  return array;
}

/** Pre allocated Vector3 array to optimize segments label computation each frame */
const TEMP_ARRAY: [Vector3, Vector3, Vector3] = [
  new Vector3(),
  new Vector3(),
  new Vector3(),
];

/** Pre allocated Vector3 for real time checks of the measurement end point world position */
const TEMP_END_POINT = new Vector3();

/**
 * Initialize and connect a LabelsScreenPositionsComputer for a measurement
 *
 * @param measurement to position the labels for
 * @param labelContainer HTMLElement that will contain the labels
 * @param isLiveEditing true if the user is live editing this measurement
 * @param toolbarPosition position of the toolbar if visible
 * @returns the LabelsScreenPositionsComputer instance to use to position the labels
 */
export function useLabelScreenPositionComputer(
  measurement: Measurement | undefined,
  labelContainer: HTMLElement,
  isLiveEditing: boolean,
  toolbarPosition?: Vector3,
): LabelsScreenPositionsComputer {
  // This object is responsible to guarantee that measurement labels never overlap the mouse position
  // and never overlap each other.
  const labelsScreenPositionsComputer = useMemo(
    // There are only three segments to account for: distance, horizontal, and vertical component.
    () => new LabelsScreenPositionsComputer(3),
    [],
  );

  const screenSize = useRef(
    new Vector2(labelContainer.clientWidth, labelContainer.clientHeight),
  );

  useEffect(() => {
    if (!measurement) return;
    labelsScreenPositionsComputer.setDirty();
    labelsScreenPositionsComputer.setSegmentsVisible(
      getSegmentsVisibility(measurement),
    );
  }, [labelsScreenPositionsComputer, measurement]);

  const cameraMonitor = useMemo(() => new CameraMonitor(), []);

  // At each frame, the component checks whether the label screen positions must be
  // recomputed, either because the 3D position changed or because the camera changed.
  useFrame(({ camera }, delta) => {
    cameraMonitor.checkCameraMovement(camera, delta);
    const screenResized =
      labelContainer.clientWidth !== screenSize.current.x ||
      labelContainer.clientHeight !== screenSize.current.y;
    screenSize.current.set(
      labelContainer.clientWidth,
      labelContainer.clientHeight,
    );
    // At each frame: if the camera moved, or the screen resized, or
    // the distance start and end points changed, the labels' screen
    // positions are recomputed.
    if (
      measurement &&
      (cameraMonitor.cameraMoving ||
        labelsScreenPositionsComputer.dirty ||
        screenResized)
    ) {
      if (isLiveEditing) {
        labelsScreenPositionsComputer.setCollider(
          TEMP_END_POINT.copy(measurement.main.end).add(
            measurement.referencePosition,
          ),
          BIG_HANDLER_SIZE * 2,
          BIG_HANDLER_SIZE * 2,
          LabelAnchored.Center,
        );
      } else if (toolbarPosition) {
        labelsScreenPositionsComputer.setCollider(
          toolbarPosition,
          TOOLBAR_WIDTH,
          TOOLBAR_HEIGHT,
          LabelAnchored.BottomRight,
        );
      } else {
        labelsScreenPositionsComputer.resetCollider();
      }
      labelsScreenPositionsComputer.compute(
        getSegmentsLabelPositions(measurement, TEMP_ARRAY),
        camera,
        {
          width: labelContainer.clientWidth,
          height: labelContainer.clientHeight,
        },
      );
    }
    // here the useFrame priority is deliberately set to 0, otherwise
    // the measure labels flicker a lot while rotating the camera.
  }, 0);

  return labelsScreenPositionsComputer;
}

/**
 * Update the clipboard with a text representation of a measurement
 *
 * @param measurement to copy to the clipboard
 * @param unitOfMeasure the unit of measure to use for the measurement
 */
export function copyMeasurementToClipboard(
  measurement: Measurement,
  unitOfMeasure: SupportedUnitsOfMeasure,
): void {
  // Text with the correct format and lines to copy to the clipboard.
  const textToCopy = `Distance: ${humanReadableDistance(
    measurement.main.length,
    unitOfMeasure,
  )}

Horizontal component: ${humanReadableDistance(
    measurement.components.horizontal.length,
    unitOfMeasure,
  )}

Vertical component: ${humanReadableDistance(
    measurement.components.vertical.length,
    unitOfMeasure,
  )}`;

  // Copy the text to the clipboard.
  navigator.clipboard.writeText(textToCopy);
}

/**
 * @param points of the measurement
 * @param isClosed true if the measurement is a closed loop
 * @param unitOfMeasure to use to format the numbers
 * @returns a string description of the measurement
 */
export function computeMultiPointMeasurementDescription(
  points: Vector3[],
  isClosed: boolean,
  unitOfMeasure: SupportedUnitsOfMeasure,
): string {
  const prettyDistance = (value: number): string =>
    humanReadableDistance(value, unitOfMeasure);
  const prettyArea = (value: number): string =>
    humanReadableArea(value, unitOfMeasure);

  const numSegments = isClosed ? points.length : points.length - 1;

  // Compute the header line (Eg: "3 points polygon" or "4 points poly-line")
  const header = `${numSegments} segments ${
    isClosed ? "polygon" : "poly-line"
  }`;

  // Compute the length line (Eg: "Perimeter: 4 m" or "Full Length: 4m")
  const perimeter = `${
    isClosed ? "Perimeter" : "Full Length"
  }: ${prettyDistance(polygonPerimeter(points, isClosed))}`;

  // If the measure is a closed polygon compute a area line (Eg: "Area: 10m^2")
  const area = isClosed
    ? `Area: ${prettyArea(areaOfClosedPolygon(points).area)}`
    : undefined;

  // Compute a list of line, one for each segment, with a first line saying Segments
  // Eg:
  // Segments
  // 1: 5 m
  // 2: 3.4 m
  const segments = points.reduce((segmentString, current, index, points) => {
    // Skip closing segment if the measure is not closed
    if (index === points.length - 1 && !isClosed) {
      return segmentString;
    }

    const nextIndex = (index + 1) % points.length;
    const next = points[nextIndex];
    const segmentLength = current.distanceTo(next);
    return `${segmentString}\n${index + 1}: ${prettyDistance(segmentLength)}`;
  }, "Segments");

  return [header, perimeter, area, segments].filter(Boolean).join("\n");
}
