import {
  ChangeObjectVisibilityProperties,
  EventType,
  ToggleWaypointColoringProperties,
  ToggleWaypointHeightProperties,
  ToggleWaypointsVisibilityProperties,
} from "@/analytics/analytics-events";
import {
  useCurrentScene,
  useWaypointAltitudeRange,
} from "@/modes/mode-data-context";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectObjectsVisibility,
  selectShouldColorWaypoints,
  selectShouldShowWaypointsOnFloors,
  selectVisibilityDistance,
} from "@/store/view-options/view-options-selectors";
import {
  ViewObjectTypes,
  VisibilityDistance,
  setObjectVisibility,
  setShouldColorWaypoints,
  setShouldShowWaypointsOnFloors,
  setVisibilityDistance,
} from "@/store/view-options/view-options-slice";
import {
  FaroMenu,
  FaroMenuProps,
  FaroRadio,
  FaroRadioGroup,
  FaroSwitch,
  FaroText,
  neutral,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Box, Stack, ThemeOptions } from "@mui/material";
import { isEqual } from "lodash";
import { MouseEvent, useCallback, useMemo } from "react";
import {
  HelpPopover,
  ShowWaypointsOnFloorsHelp,
  WaypointsElevationHelp,
} from "./view-options-popovers";

type ViewOptionsMenuProps = Pick<
  FaroMenuProps,
  "open" | "anchorEl" | "onClose"
>;

/** @returns a PopOver to open from a menu to control the app view options */
export function ViewOptionsMenu({
  open,
  anchorEl,
  onClose,
}: ViewOptionsMenuProps): JSX.Element {
  const dispatch = useAppDispatch();

  const objectsVisibility = useAppSelector(selectObjectsVisibility, isEqual);

  const shouldColorWaypoints = useAppSelector(selectShouldColorWaypoints);
  const shouldShowWaypointsOnFloors = useAppSelector(
    selectShouldShowWaypointsOnFloors,
  );

  const updateObjectVisibility = useCallback(
    (visible: boolean, type: ViewObjectTypes, eventToTrack: EventType) => {
      Analytics.track<ToggleWaypointsVisibilityProperties>(eventToTrack, {
        visible,
      });

      dispatch(setObjectVisibility({ type, visibility: visible }));
    },
    [dispatch],
  );

  const toggleWaypointColoring = useCallback(
    (enabling: boolean) => {
      Analytics.track<ToggleWaypointColoringProperties>(
        EventType.toggleWaypointColoring,
        {
          enabling,
        },
      );

      dispatch(setShouldColorWaypoints(enabling));
    },
    [dispatch],
  );

  const toggleShouldShowWaypointsOnFloors = useCallback(
    (enabling: boolean) => {
      Analytics.track<ToggleWaypointHeightProperties>(
        EventType.toggleWaypointHeight,
        {
          enabling,
        },
      );

      dispatch(setShouldShowWaypointsOnFloors(enabling));
    },
    [dispatch],
  );

  const waypointsAltitudeRange = useWaypointAltitudeRange();

  const { panos } = useCurrentScene();

  const hasPanos = useMemo(() => panos.length > 0, [panos.length]);

  const shouldShowWaypointsOnFloorsOption = useAppSelector(
    selectHasFeature(Features.WaypointsPosition),
  );

  const shouldShowWaypointsSection = useMemo(
    () =>
      waypointsAltitudeRange ?? (shouldShowWaypointsOnFloorsOption && hasPanos),
    [waypointsAltitudeRange, shouldShowWaypointsOnFloorsOption, hasPanos],
  );

  return (
    <FaroMenu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: -2,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      dark
    >
      <Stack gap={1} p={1} sx={{ minWidth: "291px" }}>
        <FaroText
          variant="heading12"
          color={neutral[100]}
          sx={{ textTransform: "uppercase", fontSize: "10px" }}
        >
          General View Setting
        </FaroText>
        <ViewOptionSwitch
          label="Waypoints"
          isChecked={objectsVisibility[ViewObjectTypes.waypoints]}
          onToggled={(visible) => {
            updateObjectVisibility(
              visible,
              ViewObjectTypes.waypoints,
              EventType.toggleWaypointsVisibility,
            );
          }}
        />
        <ViewOptionSwitch
          label="Annotations"
          isChecked={objectsVisibility[ViewObjectTypes.annotations]}
          onToggled={(visible) => {
            updateObjectVisibility(
              visible,
              ViewObjectTypes.annotations,
              EventType.toggleAnnotationsVisibility,
            );
          }}
        />
        <ViewOptionSwitch
          label="Measurements"
          isChecked={objectsVisibility[ViewObjectTypes.measurements]}
          onToggled={(visible) => {
            updateObjectVisibility(
              visible,
              ViewObjectTypes.measurements,
              EventType.toggleMeasurementsVisibility,
            );
          }}
        />
        <ViewOptionSwitch
          label="Trajectories"
          isChecked={objectsVisibility[ViewObjectTypes.trajectories]}
          onToggled={(visible) => {
            updateObjectVisibility(
              visible,
              ViewObjectTypes.trajectories,
              EventType.toggleTrajectoriesVisibility,
            );
          }}
        />
        {shouldShowWaypointsSection && (
          <>
            <FaroText variant="heading12" color={neutral[100]}>
              Waypoints elevation
            </FaroText>
            {waypointsAltitudeRange && (
              <ViewOptionSwitch
                label="Color waypoints by elevation"
                isChecked={shouldColorWaypoints}
                onToggled={toggleWaypointColoring}
                helpButton={
                  <WaypointsElevationHelp range={waypointsAltitudeRange} />
                }
              />
            )}
            {shouldShowWaypointsOnFloorsOption && hasPanos && (
              <ViewOptionSwitch
                label="Show waypoints on floors"
                isChecked={shouldShowWaypointsOnFloors}
                onToggled={toggleShouldShowWaypointsOnFloors}
                helpButton={<ShowWaypointsOnFloorsHelp />}
              />
            )}
          </>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FaroText variant="heading12" color={neutral[100]}>
            Object visibility
          </FaroText>

          <HelpPopover
            title="Object Visibility"
            description="Configure the distance at which objects become visible in the current 3D scene"
            onClick={() => {
              Analytics.track(EventType.openObjectVisibilityHelp);
            }}
          />
        </Stack>
        <VisibilityDistanceRadio />
      </Stack>
    </FaroMenu>
  );
}

type ViewOptionSwitchProps = {
  /** Label to show next to the switch */
  label: string;

  /** Value of the switch */
  isChecked: boolean;

  /** Button to show next to the switch */
  helpButton?: JSX.Element;

  /** Callback after the option has been toggled */
  onToggled?(visible: boolean): void;
};

function ViewOptionSwitch({
  label,
  isChecked,
  onToggled,
  helpButton,
}: ViewOptionSwitchProps): JSX.Element {
  return (
    <FaroSwitch
      // Put the text and the help button next to each others and before the actual switch
      label={
        <Stack direction="row" justifyContent="space-between">
          {label}
          {/** This box is used to stop the click from propagating and changing the switch value */}
          <Box
            component="div"
            sx={{
              // The zIndex is set so that it's always on top of the switch selection area
              zIndex: (theme: ThemeOptions) => theme.zIndex?.tooltip,
            }}
            onClick={(e: MouseEvent) => e.stopPropagation()}
          >
            {helpButton}
          </Box>
        </Stack>
      }
      dark
      fullWidth={true}
      checked={isChecked}
      onToggle={() => onToggled?.(!isChecked)}
    />
  );
}

function isVisibilityDistance(value: string): value is VisibilityDistance {
  return Object.keys(VisibilityDistance).includes(value);
}

function VisibilityDistanceRadio(): JSX.Element {
  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  const dispatch = useAppDispatch();

  return (
    <FaroRadioGroup
      value={visibilityDistance}
      onChange={(_, value) => {
        if (isVisibilityDistance(value)) {
          Analytics.track<ChangeObjectVisibilityProperties>(
            EventType.changeObjectVisibility,
            { value },
          );

          dispatch(setVisibilityDistance(value));
        }
      }}
    >
      <FaroRadio
        dark
        value={VisibilityDistance.nearby}
        label="Show nearby objects only"
      />
      <FaroRadio dark value={VisibilityDistance.all} label="Show all objects" />
    </FaroRadioGroup>
  );
}
