import { GUID, assert } from "@faro-lotv/foundation";
import {
  CachedWorldTransform,
  computeCachedWorldTransform,
} from "@faro-lotv/project-source";
import { CaptureTreeEntity } from "@faro-lotv/service-wires";
import { Matrix4, Quaternion, Vector3 } from "three";
import { EntityMap } from "./revision-slice";

/** Matrix to convert from a Z-up to a Y-up coordinate system. */
const Z_TO_Y_UP: Readonly<Matrix4> = Object.freeze(
  new Matrix4().makeRotationFromQuaternion(
    new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2),
  ),
);

export type RevisionTransformCache = Record<
  GUID,
  CachedWorldTransform | undefined
>;

type ChildrenMap = Record<GUID, GUID[] | undefined>;

/**
 * @param entityMap All available revision entities.
 * @returns A cache containing the world transforms for the revision entities.
 */
export function generateRevisionTransformCache(
  entityMap: EntityMap,
): RevisionTransformCache {
  const cache: RevisionTransformCache = {};

  let root;

  for (const entity of Object.values(entityMap)) {
    if (!entity?.parentId) {
      root = entity;
      break;
    }
  }

  assert(root, "No root entity found in the revision");

  generateCacheRecursively(
    entityMap,
    generateChildrenMap(entityMap),
    cache,
    // At the start, we convert from Z-up to Y-up as we use Y-up in the viewer
    Z_TO_Y_UP,
    root,
  );

  return cache;
}

/**
 * @param entityMap All available revision entities.
 * @param childrenMap A map from an entity ID to its children IDs.
 * @param cache The transform cache to modify.
 * @param parentTransform The world transform of the parent entity (should not be modified).
 * @param curEntity The entity to process.
 */
function generateCacheRecursively(
  entityMap: EntityMap,
  childrenMap: ChildrenMap,
  cache: RevisionTransformCache,
  parentTransform: Matrix4,
  curEntity: CaptureTreeEntity,
): void {
  const { pos, rot, scale } = curEntity.pose;

  const localPos = new Vector3(pos?.x ?? 0, pos?.y ?? 0, pos?.z ?? 0);
  const localRot = new Quaternion(
    rot?.x ?? 0,
    rot?.y ?? 0,
    rot?.z ?? 0,
    rot?.w ?? 1,
  );
  const localScale = new Vector3(scale?.x ?? 1, scale?.y ?? 1, scale?.z ?? 1);

  const localTransform = new Matrix4().compose(localPos, localRot, localScale);
  const globalTransform = localTransform.premultiply(parentTransform);

  cache[curEntity.id] = computeCachedWorldTransform(globalTransform);

  for (const childId of childrenMap[curEntity.id] ?? []) {
    const child = entityMap[childId];

    if (child) {
      generateCacheRecursively(
        entityMap,
        childrenMap,
        cache,
        globalTransform,
        child,
      );
    }
  }
}

/**
 * The Capture Tree API does not provide the childrenIds directly, so it needs to be computed on the fly.
 *
 * @param entityMap The entities in the revision.
 * @returns A map from an entity ID to its children IDs.
 */
function generateChildrenMap(entityMap: EntityMap): ChildrenMap {
  const childrenMap: ChildrenMap = {};

  for (const entity of Object.values(entityMap)) {
    if (entity?.parentId) {
      const childrenList = childrenMap[entity.parentId] ?? [];
      childrenList.push(entity.id);
      childrenMap[entity.parentId] = childrenList;
    }
  }

  return childrenMap;
}
