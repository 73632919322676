import {
  ToggleIconButton,
  Update360TransformationIcon,
} from "@faro-lotv/app-component-toolbox";
import { Tooltip } from "@mui/material";

interface IProps {
  /** Indicate whether the button is enabled (can be clicked) */
  isEnabled: boolean;

  /** Handler to call when the button is clicked */
  onClick(): void;

  /** Tooltip to display on top of the button (whether it is enabled or disabled) */
  tooltip: string;
}

/**
 * @returns Button with a link icon
 */
export function Update360TransformationButton({
  isEnabled,
  onClick,
  tooltip,
}: IProps): JSX.Element {
  return (
    <Tooltip title={tooltip}>
      {/** A span is needed to allow the tooltip to work on disabled buttons */}
      <span>
        <ToggleIconButton
          value="update-360-transformation-button"
          disabled={!isEnabled}
          onClick={onClick}
        >
          <Update360TransformationIcon sx={{ color: "white" }} />
        </ToggleIconButton>
      </span>
    </Tooltip>
  );
}
