import { AlignmentProgress } from "@/alignment-tool/alignment-progress-bar/alignment-progress";
import { FaroButton, neutral } from "@faro-lotv/flat-ui";
import Stack from "@mui/material/Stack";

interface AlignToCadProgressBarProps {
  /** A callback that is called when the user clicks "Next" or "Confirm Alignment" button in the progressbar */
  goToAlignmentNextStep(): void;

  /** A callback that is called when the user clicks "Back" button in the progressbar */
  goToAlignmentPreviousStep(): void;

  /** The current alignment step index */
  activeStepIndex: number;

  /** true to enable Next button, false to disable it */
  enableNextButton: boolean;

  /** if the current step is the last alignment step */
  isLastStep: boolean;
}

/**
 * @returns The progress bar that is shown at the top of an alignment (sheet-to-cad or cloud-to-cad) mode
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function AlignToCadProgressBar({
  goToAlignmentNextStep,
  goToAlignmentPreviousStep,
  activeStepIndex,
  enableNextButton,
  isLastStep,
}: AlignToCadProgressBarProps): JSX.Element {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        mt: -2,
        ml: -2,
        mr: -2,
        p: 1,
        backgroundColor: neutral[100],
        zIndex: 1,
      }}
    >
      <Stack
        sx={{
          height: "100%",
          width: "20%",
          ml: 2,
        }}
      />

      <AlignmentProgress
        activeStep={activeStepIndex}
        sx={{
          height: "100%",
          width: "25%",
        }}
        firstStepLabel="Elevation"
        secondStepLabel="Align"
      />

      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        sx={{
          width: "20%",
          mr: "12px",
        }}
      >
        {activeStepIndex > 0 && (
          <FaroButton
            variant="secondary"
            onClick={goToAlignmentPreviousStep}
            aria-label="back"
          >
            Back
          </FaroButton>
        )}
        <FaroButton
          disabled={!enableNextButton}
          onClick={goToAlignmentNextStep}
          aria-label="next"
        >
          {isLastStep ? "Confirm Alignment" : "Next"}
        </FaroButton>
      </Stack>
    </Stack>
  );
}
