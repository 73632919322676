import { UnrecoverableError } from "@/errors/unrecoverable-error";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { EnsureProjectAccess } from "@/permissions/ensure-project-access";
import { runtimeConfig } from "@/runtime-config";
import { selectCurrentUser } from "@/store/user-selectors";
import { appId } from "@/utils/appid";
import { ProgressApiTracker } from "@/utils/progress-api-tracker";
import { redirectToViewer } from "@/utils/redirects";
import { LoadingScreen } from "@faro-lotv/flat-ui";
import { useAnalyticsInitialization } from "@faro-lotv/foreign-observers";
import { GUID, assert } from "@faro-lotv/foundation";
import {
  IElementType,
  isIElementSectionDataSession,
  isIElementTimeseriesDataSession,
} from "@faro-lotv/ielement-types";
import { selectIElement, selectProjectName } from "@faro-lotv/project-source";
import { ApiClientContextProvider } from "@faro-lotv/service-wires";
import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../store/store-hooks";
import {
  AlignmentOverlayContext,
  useAlignmentOverlayInitialState,
} from "../common/alignment-overlay-context";
import { useLoadIElements } from "../common/use-load-ielements";
import {
  PairwiseRegistrationUi,
  PairwiseRegistrationUiProps,
} from "./pairwise-registration-ui";

/**
 * @returns Entry point of the pairwise registration workflow.
 * This tool allows the user to visually & automatically align two point clouds.
 *
 * Shows an error page when being used on a small screen.
 */
export function PairwiseRegistrationPage(): JSX.Element | null {
  // Extract all needed URL parameters
  const { projectId } = useParams();
  assert(projectId, "No project ID provided");

  const [searchParams] = useSearchParams();
  const refCloudId = searchParams.get("refCloudId");
  const modelCloudId = searchParams.get("modelCloudId");
  const rawDashboardRedirect = searchParams.get("dashboardRedirect");
  assert(refCloudId, "No reference cloud ID provided");
  assert(modelCloudId, "No model cloud ID provided");
  const dashboardRedirect = rawDashboardRedirect
    ? decodeURIComponent(rawDashboardRedirect)
    : undefined;

  const currentUser = useAppSelector(selectCurrentUser);
  const theme = useTheme();

  useAnalyticsInitialization(
    runtimeConfig.analytics.amplitudeApiKey,
    currentUser?.email,
  );

  // The tool doesn't work on small screens
  // Show an error page instead and ask the user to use a tablet or desktop PC
  useEffect(() => {
    if (window.screen.width < theme.breakpoints.values.md) {
      throw new UnrecoverableError(null, {
        errorStatement: "Optimized for Larger Screens",
        helpText:
          "This workflow contains features that are not supported on smaller devices. Please use a tablet or desktop for the best experience.",
        actions: [
          {
            action: () => redirectToViewer(projectId),
            label: "Return to project",
            primary: true,
          },
        ],
      });
    }
  }, [projectId, theme]);

  const manualAlignmentOverlay = useAlignmentOverlayInitialState();

  const projectName = useAppSelector(selectProjectName);
  const title = projectName
    ? `Pairwise Registration - ${projectName}`
    : "Pairwise Registration";
  useDocumentTitle(title);

  return (
    <ApiClientContextProvider
      projectId={projectId}
      userId={currentUser?.id}
      clientId={appId()}
      apiBaseUrls={runtimeConfig.backendEndpoints}
    >
      <EnsureProjectAccess projectId={projectId}>
        <AlignmentOverlayContext.Provider value={manualAlignmentOverlay}>
          <PairwiseRegistrationLoading
            modelCloudId={modelCloudId}
            refCloudId={refCloudId}
            dashboardRedirect={dashboardRedirect}
            currentUser={currentUser}
          />
        </AlignmentOverlayContext.Provider>
      </EnsureProjectAccess>
    </ApiClientContextProvider>
  );
}

type PairwiseRegistrationLoadingProps = {
  /** The ID of the Section.DataSession used as a reference in the registration. */
  refCloudId: GUID;

  /** The ID of the Section.DataSession which is being adjusted during the registration. */
  modelCloudId: GUID;

  /** The dashboard path to redirect to. */
  dashboardRedirect: PairwiseRegistrationUiProps["dashboardRedirect"];

  /** The currently logged-in user. */
  currentUser?: PairwiseRegistrationUiProps["currentUser"];
};

/** @returns Wrapper for the pairwise registration view, ensuring that all needed elements are loaded. */
function PairwiseRegistrationLoading({
  refCloudId,
  modelCloudId,
  dashboardRedirect,
  currentUser,
}: PairwiseRegistrationLoadingProps): JSX.Element {
  // Load the elements required for the view
  const isLoading = useLoadIElements([
    // Load ancestors (needed for world transforms and sheet)
    {
      descendantIds: [refCloudId, modelCloudId],
    },
    // Load descendants (needed for point cloud streams)
    {
      ancestorIds: [refCloudId, modelCloudId],
      types: [IElementType.section, IElementType.pointCloudStream],
    },
  ]);
  const refCloudDataSession = useAppSelector(selectIElement(refCloudId));
  const modelCloudDataSession = useAppSelector(selectIElement(modelCloudId));
  const timeSeries = useAppSelector(
    selectIElement(refCloudDataSession?.parentId),
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Validate the elements
  assert(refCloudDataSession, "Reference cloud not found");
  assert(modelCloudDataSession, "Model cloud not found");
  assert(
    isIElementSectionDataSession(modelCloudDataSession),
    "Model point cloud id does not reference a data session.",
  );
  assert(
    isIElementSectionDataSession(refCloudDataSession),
    "Reference point cloud id does not reference a data session.",
  );

  assert(timeSeries, "Time series not found");
  assert(
    isIElementTimeseriesDataSession(timeSeries),
    "Parent is not a valid time series",
  );

  assert(currentUser, "No current user available");

  return (
    <>
      <PairwiseRegistrationUi
        refCloudDataSession={refCloudDataSession}
        modelCloudDataSession={modelCloudDataSession}
        dashboardRedirect={dashboardRedirect}
        currentUser={currentUser}
      />
      <ProgressApiTracker />
    </>
  );
}
