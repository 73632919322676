import { RootState } from "@/store/store";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  parseVector3,
  selectAncestor,
  selectIElementWorldPosition,
} from "@faro-lotv/app-component-toolbox";
import {
  IElement,
  IElementTypeHint,
  isIElementSectionGeoslam,
} from "@faro-lotv/ielement-types";
import { useEffect, useState } from "react";
import { Vector3 } from "three";
import { DEFAULT_PLACEHOLDER_FLOOR_OFFSET } from "./use-map-placeholder-positions";

/**
 * Calculates the placeholder positions for a given set of IElements in walk mode
 *
 * @param placeholders we want to render
 * @param floor the floor which the placeholders belong to
 * @param shouldUseOriginalPosition if true, the original position of the placeholder will be used
 * @returns the computed list of positions for the placeholders
 */
export function useWalkPlaceholderPositions(
  placeholders: IElement[],
  floor: IElement,
  shouldUseOriginalPosition?: boolean,
): Vector3[] {
  const floorPosition = useAppSelector(selectIElementWorldPosition(floor.id));

  const [points, setPoints] = useState<Vector3[]>([]);

  const store = useAppStore();

  /**
   * Compute the adjusted placeholders positions to use in walk mode for a set of scans
   * accounting for special heuristics depending on the type of scan
   */
  useEffect(() => {
    const state = store.getState();
    setPoints(
      placeholders.map((pano) => {
        const position = selectIElementWorldPosition(pano.id)(state);

        // If the flag is true, then use the original position
        if (shouldUseOriginalPosition) {
          return parseVector3(position);
        }

        const altitude = selectScanAltitude(pano)(state);
        if (altitude) {
          return parseVector3(position).setY(position[1] - altitude);
        }

        // Default: floor height plus offset
        return parseVector3(position).setY(
          floorPosition[1] + DEFAULT_PLACEHOLDER_FLOOR_OFFSET,
        );
      }),
    );
  }, [placeholders, floorPosition, store, shouldUseOriginalPosition]);

  return points;
}

/**
 * @param scan to check the altitude for
 * @returns the altitude from the floor of the passed in scan
 *
 * TODO: generalize the follow up early returns once altitude the info is provided by the backend https://faro01.atlassian.net/browse/SWEB-2533
 */
function selectScanAltitude(scan: IElement) {
  return (state: RootState): number | undefined => {
    const ORBIS_ALTITUDE_OFFSET = 0.5;
    const FOCUS_ALTITUDE_OFFSET = 1.6;
    if (selectAncestor(scan, isIElementSectionGeoslam)(state)) {
      return ORBIS_ALTITUDE_OFFSET;
    }
    if (
      selectAncestor(
        scan,
        (el) =>
          el.typeHint === IElementTypeHint.dataSetWs ||
          el.typeHint === IElementTypeHint.focusScan ||
          el.typeHint === IElementTypeHint.structuredE57,
      )(state)
    ) {
      return FOCUS_ALTITUDE_OFFSET;
    }
  };
}
