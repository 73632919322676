import { TypedEvent } from "@faro-lotv/foundation";
import { PropsWithChildren, createContext, useContext, useState } from "react";

export type ClipboxEvents = {
  /** Signal emitted when the user presses the 'auto clip box' button */
  autoClipBox: TypedEvent<void>;
};

/** A context for the CAD 3D scene events */
const ClipboxEventsContext = createContext<ClipboxEvents | undefined>(
  undefined,
);

/**
 * @returns The clipbox events context
 */
export function useClipboxEvents(): ClipboxEvents | undefined {
  return useContext(ClipboxEventsContext);
}

class ClipboxEventsContextImpl implements ClipboxEvents {
  autoClipBox = new TypedEvent<void>();
}

/**
 * @returns A component to register the invalidate 3D scene event context
 */
export function ClipboxEventsContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [contextData] = useState(() => new ClipboxEventsContextImpl());

  return (
    <ClipboxEventsContext.Provider value={contextData}>
      {children}
    </ClipboxEventsContext.Provider>
  );
}
