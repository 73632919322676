import { loadRuntimeConfig } from "./utils/runtime-config-loader";

export type AuthenticationConfig = {
  /** URL of the Unified Login Mask (ULM) to use for authentication */
  ulmUrl: string;
};

/** Link to external pages referenced by the sphere viewer app */
export type ExternalLinks = {
  /** Base url to the HoloBuilder home page for this environment (Eg. https://www.holobuilder.com) */
  holoBuilderHome: string;

  /** Base url to the AlignmentTool for Sphere environment (Eg. https://viewer.holobuilder.com/alignment) */
  alignmentTool: string;

  /** Base url to the Web Editor for this environment */
  webEditor: string;

  /** URL of the main script of the cookie manager */
  cookieManagerLatestUrl?: string;

  /**
   * URL of the prefly script of the cookie manager.
   * The prefly script is smaller than the main script and provides some basic cookie API,
   * allowing apps to access the cookies as fast as possible.
   */
  cookieManagerPreflyUrl?: string;

  /** Base url to the Dashboard for this environment */
  dashboardUrl: string;

  /** Url link to the faro terms of service page (Eg. https://www.farosphere.com/home/legal/terms-of-service) */
  termsOfServiceUrl: string;

  /** Url link to the faro contact information page (Eg. https://www.faro.com/About-Us/Contact-Us) */
  contactUrl: string;

  /** Url link to the faro imprint page (Eg. https://www.faro.com/de-DE/Imprint) */
  imprintUrl: string;

  /** Url link to the faro privacy policy page (Eg. https://www.farosphere.com/home/legal/privacy-policy) */
  privacyPolicyUrl: string;

  /** Url link to the faro cookie policy page (Eg. https://www.farosphere.com/home/legal/cookie-policy) */
  cookiePolicyUrl: string;

  /** Url link to the faro data processing agreement page (Eg. https://www.farosphere.com/home/legal/data-processing-agreement) */
  dataProcessingAgreementUrl: string;

  /** Url to fetch the static files stored in the cloud (Eg. https://uploads.holobuilder.com/static/media/viewer/) */
  staticResourcesBaseUrl: string;

  /** Url for the Viewer feedback form (Eg: https://forms.office.com/xxxx) */
  feedbackForm?: string;

  /** Url for the jira connector integration for the error page */
  jiraCollector?: string;

  /** Url for the form for unsupported projects */
  unsupportedProjectsForm?: string;

  /** Url to get the information on user without Faro scanner seeking early access to other workflows */
  getEarlyAccessForm?: string;

  /** Url to the troubleshooting page */
  troubleShooting?: string;
};

/** Urls of backend services used by the sphere viewer app */
export type BackendEndpoints = {
  /** Base url for the core api */
  coreApiUrl: string;

  /** Base url for the project api */
  projectApiUrl: string;

  /** Base url of the progress api */
  progressApiUrl: string;

  /** Base url of the point cloud api */
  pointCloudApiUrl: string;

  /** Base url of the registration */
  registrationApiUrl?: string;

  /** Base url of the pre-alignment api */
  preAlignmentApiUrl?: string;

  /** Url used to send event to sentry */
  sentryDsn?: string;
};

/** Any analytics related configuration */
export type Analytics = {
  /** API key to use when sending events to Amplitude */
  amplitudeApiKey?: string;
};

/** Configuration related for external/third-party services */
export type ExternalServices = {
  /**
   * Configurations necessary for Intercom.
   * If this object is not present, Intercom shouldn't be setup.
   */
  intercom?: {
    appId: string;
  };

  /**
   * Configurations necessary for Localize.
   * If this object is not present, Localize shouldn't be setup.
   */
  localize: {
    projectKey: string;
    saveNewTranslations: boolean;
  };
};

/** Expected type of the runtime config */
export type RuntimeConfig = {
  /** Info about the current deployment */
  deployment: {
    /** Top level domain of the running app (Eg https://viewer.dev.holobuilder.com) */
    topLevelDomain: string;

    /** Description string of the current environment (eg: hb-dev-com)*/
    environment: string;
  };

  /** Data required for the authentication process */
  authentication: AuthenticationConfig;

  /** Link to external webpages referenced by the sphere viewer (Eg. dashboard, imprint, cookie policy) */
  externalLinks: ExternalLinks;

  /** Configuration related for external/third-party services */
  externalServices: ExternalServices;

  /** Urls of the backends used by the sphere viewer */
  backendEndpoints: BackendEndpoints;

  /** Analytics configuration */
  analytics: Analytics;

  /** True if this deployment should auto enable preview features */
  enablePreviewFeatures: boolean;
};

export const runtimeConfig = loadRuntimeConfig();
