import {
  AlignPointCloudEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectAreaFor } from "@/store/selections-selectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { redirectToAlignmentTool } from "@/utils/redirects";
import {
  selectIsPointCloudAligned,
  selectNumberOfPointCloudsOnFloor,
  selectProjectId,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { IElement } from "@faro-lotv/ielement-types";
import { useCallback } from "react";
import { ProjectTreeActionButton } from "./project-tree-action-button";
import {
  compatibilityMessage,
  useDisableCaptureTreeAlignment,
} from "./tree/cad-model-tree/use-disable-capture-tree-alignment";

type AlignPcToAreaButtonProps = {
  /** DataSession/DataSet element for the point cloud to align */
  pointCloud: IElement;

  /** optional tooltip for the button */
  tooltip?: string;

  /** true if the button should be disabled (visible but user cannot click on it) */
  disabled?: boolean;
};

/**
 * @returns A component that renders a button in the project tree item,
 * redirecting to alignment tool with the point cloud selected
 * if the conditions to display that button are met.
 */
export function AlignCloudButton({
  pointCloud,
  tooltip,
  disabled,
}: AlignPcToAreaButtonProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const hasAlignWizardFeatureEnabled = useAppSelector(
    selectHasFeature(Features.AlignWizard),
  );

  const projectId = useAppSelector(selectProjectId);
  const targetArea = useAppSelector(selectAreaFor(pointCloud));

  const isPointCloudAligned = useAppSelector(
    selectIsPointCloudAligned(pointCloud),
  );
  const numberOfAlignedPointClouds = useAppSelector(
    selectNumberOfPointCloudsOnFloor(targetArea, true),
  );

  const disableAlignment = useDisableCaptureTreeAlignment();

  const startAlignmentWizard = useCallback(() => {
    openAlignmentWizard({
      elementIdToAlign: pointCloud.id,
      dispatch,
    });
  }, [dispatch, pointCloud.id]);

  const startAlignment = useCallback(() => {
    assert(
      !!projectId,
      "Unable to start alignment tool without a valid projectId",
    );
    assert(
      !!targetArea,
      "Unable to start alignment tool without a target area to align to",
    );

    Analytics.track<AlignPointCloudEventProperties>(EventType.alignPointCloud, {
      via: "align button",
      alreadyAligned: isPointCloudAligned,
      numberOfAlignedPCs: numberOfAlignedPointClouds,
    });

    redirectToAlignmentTool({
      projectId,
      elementId: pointCloud.id,
      floorId: targetArea.id,
      state: store.getState(),
      dispatch,
    });
  }, [
    dispatch,
    isPointCloudAligned,
    numberOfAlignedPointClouds,
    pointCloud.id,
    projectId,
    store,
    targetArea,
  ]);

  return (
    <ProjectTreeActionButton
      name="Align"
      tooltip={disableAlignment ? compatibilityMessage : tooltip}
      onClick={
        hasAlignWizardFeatureEnabled ? startAlignmentWizard : startAlignment
      }
      disabled={disableAlignment || disabled}
    />
  );
}
