import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  redirectToDashboardProjectPage,
  redirectToViewer,
} from "@/utils/redirects";
import {
  FaroButton,
  FaroText,
  LayoutSheetSmallIcon,
  PointCloudIcon,
  dataComparisonColorsSorted,
  neutral,
  useToast,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  IElementSectionDataSession,
  IPose,
  isIElementGenericDataset,
  isIElementSectionGeoslam,
  isIElementTimeseries,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import {
  createMutationSetElementPose,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import Stack from "@mui/material/Stack";
import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { MergeAndPublishDialog } from "../common/merge-and-publish-dialog";
import { UpdatedLocalPose } from "../utils/multi-registration-report";

/** Info displayed in the alignment bar of the inspect & publish tool */
type InspectAndPublishProps = {
  /** Name of the current sheet */
  areaName?: string;
  /** The data sessions the user is viewing. */
  dataSessions: IElementSectionDataSession[];

  // TODO: Actual redirect will be implemented with https://faro01.atlassian.net/browse/NRT-1007
  /**
   * Optional, enables redirect to the dashboard after the workflow finishes if not undefined.
   * If not undefined, the string is used to call redirectToDashboardProjectPage which allows
   * to define the target tab of the project details in the dashboard.
   * If undefined the Viewer will be opened after the registration is saved or the merge is started.
   */
  dashboardRedirect?: string;

  /**
   * When true, shows a button to trigger a multi-cloud registration that will then redirect to either viewer or dashboard
   */
  enableMultiCloudRegistration?: boolean;

  /** The local poses which have been calculated during registration. */
  updatedLocalIElementPoses?: UpdatedLocalPose[] | null;
};

/**
 * @returns Utility bar that provides buttons for registration refinement and confirming the result
 */
export function InspectAndPublishBar({
  areaName,
  dataSessions,
  dashboardRedirect,
  enableMultiCloudRegistration,
  updatedLocalIElementPoses,
}: InspectAndPublishProps): JSX.Element {
  const store = useAppStore();
  const { projectApiClient, registrationApiClient, preAlignmentApiClient } =
    useApiClientContext();
  const { handleErrorWithToast } = useErrorHandlers();
  const { openToast } = useToast();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isStartingMultiRegistrationTask, setIsStartingMultiRegistrationTask] =
    useState(false);
  const [isStartingPreAlignmentTask, setIsStartingPreAlignmentTask] =
    useState(false);

  const hasVisualRegistrationFeature = useAppSelector(
    selectHasFeature(Features.VisualRegistration),
  );

  const hasPreAlignmentFeature = useAppSelector(
    selectHasFeature(Features.PreAlignment),
  );

  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);

  const pointCloudDatasets = useAppSelector(
    (state) =>
      dataSessions.map((dataSession) => {
        const dataSet = selectChildDepthFirst(
          dataSession,
          isIElementGenericDataset,
          1,
        )(state);
        assert(dataSet, "No dataset found for data session");
        return dataSet;
      }),
    isEqual,
  );

  const orbisDataSessions = useMemo(() => {
    return dataSessions.filter((session) => {
      return (
        selectChildrenDepthFirst(
          session,
          isIElementSectionGeoslam,
          1,
        )(store.getState()).length !== 0
      );
    });
  }, [store, dataSessions]);
  const timeSeriesId = useAppSelector((state) => {
    const timeSeries = selectAncestor(
      dataSessions[0],
      isIElementTimeseries,
    )(state);
    assert(timeSeries, "No timeseries found for data session");
    return timeSeries.id;
  });

  const primaryActionButtonTitle = useMemo(() => {
    return updatedLocalIElementPoses
      ? "Approve and save the registration result"
      : "Continue to merge and publish";
  }, [updatedLocalIElementPoses]);

  const primaryActionButtonLabel = useMemo(() => {
    return updatedLocalIElementPoses ? "Approve" : "Continue";
  }, [updatedLocalIElementPoses]);

  /** Callback to confirm the registration result and persist it to the Project API. */
  const onConfirm = useCallback(async () => {
    if (updatedLocalIElementPoses) {
      // Apply registration result, then show merge & publish dialog
      setIsProcessing(true);

      const mutations = updatedLocalIElementPoses.map((updatedPose) => {
        const oldPose = selectIElement(updatedPose.id)(store.getState())?.pose;
        const newPose: IPose = {
          ...updatedPose.pose,
          // For now, we want to take the world flags from the old position
          gps: oldPose?.gps ?? null,
          isWorldPose: oldPose?.isWorldPose,
          isWorldRot: oldPose?.isWorldRot ?? false,
          isWorldScale: oldPose?.isWorldScale ?? true,
        };

        return createMutationSetElementPose(updatedPose.id, newPose);
      });

      try {
        await projectApiClient.applyMutations(mutations);
        setIsMergeDialogOpen(true);
      } catch (error) {
        handleErrorWithToast({
          title: "Failed to apply registration result",
          error,
        });
      }

      setIsProcessing(false);
    } else {
      // No result to apply, just show merge & publish dialog
      setIsMergeDialogOpen(true);
    }
  }, [
    updatedLocalIElementPoses,
    store,
    projectApiClient,
    handleErrorWithToast,
  ]);

  /**
   * Redirects the user to the viewer or the dashboard project page
   *
   * @param dashboardUrl optional url that is forwarded to the
   *  redirectToDashboardProjectPage function, if dashboardRedirect is active
   */
  const redirectToViewerOrDashboard = useCallback(
    (dashboardUrl?: string): void => {
      if (dashboardRedirect === undefined) {
        redirectToViewer(projectApiClient.projectId);
      } else {
        redirectToDashboardProjectPage(
          projectApiClient.projectId,
          dashboardRedirect,
          dashboardUrl,
        );
      }
    },
    [dashboardRedirect, projectApiClient.projectId],
  );

  /** Callback to trigger the multi-cloud registration and redirect to either viewer and dashboard after job was started */
  const startMultiCloudRegistration = useCallback(async () => {
    assert(
      registrationApiClient,
      "Registration API client is not defined, cannot start multi cloud registration",
    );
    // Apply registration result, then show merge & publish dialog
    setIsStartingMultiRegistrationTask(true);

    try {
      await registrationApiClient.startMultiCloudRegistration({
        dataSetId: timeSeriesId,
        referencePointclouds: [dataSessions[0].id],
        dataPointclouds: dataSessions
          .slice(1)
          .map((dataSession) => dataSession.id),
        applyRegistrationResults: false,
      });
      redirectToViewerOrDashboard();
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to start multi cloud registration",
        error,
      });
    }

    setIsStartingMultiRegistrationTask(false);
  }, [
    registrationApiClient,
    timeSeriesId,
    dataSessions,
    redirectToViewerOrDashboard,
    handleErrorWithToast,
  ]);

  /** Callback to trigger the orbis pre-alignment */
  const startPreAlignment = useCallback(async () => {
    assert(
      preAlignmentApiClient,
      "Pre-Alignment API client is not defined, cannot start pre-alignment",
    );

    if (orbisDataSessions.length < 2) {
      handleErrorWithToast({
        title: "Failed to start automatic pre-alignment",
        error:
          "At least two Orbis data sessions are required for automatic pre-alignment",
      });
      return;
    }
    setIsStartingPreAlignmentTask(true);

    try {
      await preAlignmentApiClient.startPreAlignment({
        sheetIds: orbisDataSessions.map((session) => session.id),
      });
      openToast({
        title: "Automatic Pre-Alignment started.",
        message: "Task started successfully.",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to start pre-alignment task",
        error,
      });
    }

    setIsStartingPreAlignmentTask(false);
  }, [
    preAlignmentApiClient,
    orbisDataSessions,
    handleErrorWithToast,
    openToast,
  ]);

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{
          py: 1,
          px: 4,
          backgroundColor: neutral[100],
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="left"
          gap={1}
          sx={{ overflow: "hidden" }}
        >
          {areaName ? (
            <>
              <LayoutSheetSmallIcon />
              {/* Will change this once we switch from sheet to area in all user-facing strings */}
              <FaroText variant="bodyS">Sheet: {areaName}</FaroText>
            </>
          ) : null}
          {/* With visual registration, the point clouds are shown in the side bar instead */}
          {!hasVisualRegistrationFeature && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="left"
              gap={2}
              sx={{ overflowX: "auto" }}
            >
              {dataSessions.map((dataSession, index) => {
                return (
                  <Stack
                    key={dataSession.id}
                    direction="row"
                    alignItems="center"
                    gap={1}
                  >
                    <PointCloudIcon
                      sx={{
                        color:
                          dataComparisonColorsSorted[
                            index % dataComparisonColorsSorted.length
                          ],
                      }}
                    />
                    <FaroText variant="bodyS">{dataSession.name}</FaroText>
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="left"
          gap={2}
          sx={{ overflow: "hidden" }}
        >
          {hasPreAlignmentFeature && preAlignmentApiClient && (
            <FaroButton
              variant="secondary"
              title="Run automatic pre-alignment"
              onClick={startPreAlignment}
              isLoading={isStartingPreAlignmentTask}
            >
              Run automatic pre-alignment
            </FaroButton>
          )}
          {enableMultiCloudRegistration ? (
            <FaroButton
              title="Run automatic registration"
              onClick={startMultiCloudRegistration}
              isLoading={isStartingMultiRegistrationTask}
            >
              Run automatic registration
            </FaroButton>
          ) : (
            <FaroButton
              title={primaryActionButtonTitle}
              onClick={onConfirm}
              isLoading={isProcessing}
            >
              {primaryActionButtonLabel}
            </FaroButton>
          )}
        </Stack>
      </Stack>
      <MergeAndPublishDialog
        isOpen={isMergeDialogOpen}
        onClose={() => setIsMergeDialogOpen(false)}
        onPublish={() => redirectToViewerOrDashboard()}
        pointCloudDatasets={pointCloudDatasets}
      />
    </Stack>
  );
}
