import {
  createContext,
  Dispatch,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

/** All the overlay element we need to react from the mode scenes */
export type OverlayElements = {
  /** The actual canvas on which the minimap will be rendered */
  minimapCanvas: HTMLCanvasElement | null;

  /** Change the canvas on which the minimap will be rendered */
  setMiniMapCanvas(div: HTMLCanvasElement | null): void;

  /** Div used for the mini map overlay */
  miniMap: HTMLDivElement | null;

  /** Change the div used for the minimap */
  setMiniMap(div: HTMLDivElement | null): void;

  /** First screen in split screen (left or top screen) */
  firstScreen: HTMLDivElement | null;

  /** Change the div used for the first split screen */
  setFirstScreen(div: HTMLDivElement | null): void;

  /** Second  screen in split screen (right or bottom screen) */
  secondScreen: HTMLDivElement | null;

  /** Change the div used for the second split screen */
  setSecondScreen(div: HTMLDivElement | null): void;
};

/** A context to pass to R3F the overlay elements we need in our scenes */
export const OverlayElementsContext = createContext<
  OverlayElements | undefined
>(undefined);

/**
 * @returns The registered overlay elements to be used in mode scenes
 */
export function useOverlayElements(): OverlayElements {
  const ctx = useContext(OverlayElementsContext);
  if (!ctx) {
    throw Error("useOverlayElements called outside an OverlayElementContext");
  }
  return ctx;
}

/**
 * @returns The initial state for the OverlayElementsContext
 */
export function useOverlayElementsInitialState(): OverlayElements {
  const [minimapCanvas, setMiniMapCanvas] = useState<HTMLCanvasElement | null>(
    null,
  );
  const [miniMap, setMiniMap] = useState<HTMLDivElement | null>(null);
  const [firstScreen, setFirstScreen] = useState<HTMLDivElement | null>(null);
  const [secondScreen, setSecondScreen] = useState<HTMLDivElement | null>(null);

  return {
    minimapCanvas,
    setMiniMapCanvas,
    miniMap,
    setMiniMap,
    firstScreen,
    setFirstScreen,
    secondScreen,
    setSecondScreen,
  };
}

/**
 * Create a div that will forward to an OverlayElements to be used in a mode Scene
 *
 * @param setter The setter used to register this div state to the OverlayElements context
 * @returns A ref that can be used in the Overlay JSX
 */
export function useOverlayRef<T extends Element>(
  setter: Dispatch<T | null>,
): RefObject<T> {
  const ref = useRef<T>(null);
  useEffect(() => {
    setter(ref.current);
    return () => setter(null);
  }, [ref, setter]);
  return ref;
}
