import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { CaptureTreeEntityRevision } from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  RevisionTransformCache,
  generateRevisionTransformCache,
} from "./revision-transform-cache";

export type EntityMap = Record<GUID, CaptureTreeEntityRevision | undefined>;

type RevisionState = {
  /** A map from IDs to the corresponding entity in the revision. */
  entityMap: EntityMap;

  /** The cached world transforms of the entities. */
  transformCache: RevisionTransformCache;
};

export const initialState: Readonly<RevisionState> = Object.freeze({
  entityMap: {},
  transformCache: {},
});

export const revisionSlice = createSlice({
  initialState,
  name: "revision",

  reducers: {
    /**
     * @param state The current application state
     * @param action The revision entities to add to the store.
     */
    addEntities(state, action: PayloadAction<CaptureTreeEntityRevision[]>) {
      // Create a new map, as the store should not be mutated directly
      const newMap: EntityMap = {
        // Add old entries
        ...state.entityMap,
      };

      // Add new entries
      action.payload.forEach((entity) => {
        newMap[entity.id] = entity;
      });

      // Re-generate the world transform cache
      const transformCache = generateRevisionTransformCache(newMap);

      state.entityMap = newMap;
      state.transformCache = transformCache;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const { addEntities } = revisionSlice.actions;

export const revisionReducer = revisionSlice.reducer;
