import { SuspenseLoadNotifier } from "@/components/r3f/utils/suspense-load-notifier";
import {
  useEnvironmentMap,
  useReproportionCamera,
} from "@faro-lotv/app-component-toolbox";
import { Camera, RootState, useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { OrthographicCamera } from "three";
import { StoreApi } from "zustand";
import { CameraRestorer, Step, StepNames } from "./steps";

type StepTransitionManagerProps = {
  /** The current alignment step */
  step: Step;

  /** Signal out when a transition start and stop */
  onTransitionEnd?(): void;
};

function changeActiveCamera(
  curr: Camera,
  next: Camera,
  set: StoreApi<RootState>["setState"],
): void {
  if (curr !== next) {
    set({ camera: next });
  }
}

/**
 * It starts rendering a 3d scene but allow animated transition between 3d scenes
 *
 * It injects a TransitionContext with two functions
 * startTransition(JSX) - to allow the scene to start a transition scene that will be rendered instead
 * endTransition() - to allow the transition scene to notify it has finished
 *
 * @returns A component to manage the lifecycle of 3d scenes and transition between scenes
 */
export function StepTransitionManager({
  step,
  onTransitionEnd,
}: StepTransitionManagerProps): JSX.Element {
  // Local state to store the previous rendered scene (mode or transition)
  const [stepName, setStepName] = useState<StepNames>(step.name);
  const [transition, setTransition] = useState<JSX.Element | null>(null);
  const [modeScene, setModeScene] = useState<JSX.Element | null>(null);
  const [restoreCamera, setRestoreCamera] = useState<CameraRestorer>();
  const { envMap, updateEnvMap } = useEnvironmentMap();

  const { camera, set } = useThree();
  const [defaultCamera] = useState<OrthographicCamera>(() => {
    const o = new OrthographicCamera();
    Object.assign(o, { manual: true });
    return o;
  });

  // As we are manually adjusting the ortho projection we need to update the
  // camera every time the aspect ratio changes
  useReproportionCamera(defaultCamera);

  useEffect(() => {
    // Determine the camera to use for the next mode
    const nextCamera = step.customCamera?.camera() ?? defaultCamera;
    // Function to apply all changes needed when the transition and and the new mode is ready
    const endTransition = (): void => {
      setTransition(null);
      onTransitionEnd?.();
      // Update the camera once the transition is done
      changeActiveCamera(camera, nextCamera, set);
      // Here we we pass a function and not a value, because if we pass
      // directly the 'restoreGlobalCamera' function, React will call it!
      setRestoreCamera(() => step.customCamera?.restoreGlobalCamera);
    };
    restoreCamera?.(camera, defaultCamera);
    // a transition may begin, and it will go with the default camera,
    // so let's update it.
    changeActiveCamera(camera, defaultCamera, set);

    if (step.Transition) {
      updateEnvMap(camera.position);
      setTransition(
        <SuspenseLoadNotifier>
          <step.Transition
            previousMode={stepName}
            previousEnvMap={envMap}
            defaultCamera={defaultCamera}
            modeCamera={nextCamera}
            onCompleted={() => {
              endTransition();
            }}
          />
        </SuspenseLoadNotifier>,
      );
    } else {
      // Update the camera directly
      endTransition();
    }

    setModeScene(<step.Scene />);

    setStepName(step.name);
    // We want to update only when mode changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  // Render a transition if one is running, if no one is running then render the current mode
  const sceneToRender = transition ?? modeScene;

  return <SuspenseLoadNotifier>{sceneToRender}</SuspenseLoadNotifier>;
}
