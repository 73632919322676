import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { areVector3ArraysEqual } from "@/utils/equality";
import {
  selectAltitudeDifferenceToFloor,
  selectIElementWorldPosition,
} from "@faro-lotv/app-component-toolbox";
import { IElement } from "@faro-lotv/ielement-types";
import { Vector3 } from "three";

/** Default distance to keep between the floor and the placeholders so they don't z-fight */
export const DEFAULT_PLACEHOLDER_FLOOR_OFFSET = 0.012;

/**
 * Calculates the placeholder positions for a given set of IElements on a sheet
 *
 * @param placeholders we want to render
 * @param floor where to place the placeholders
 * @param heightOffset added to the positions to avoid z-fighting with other ui elements
 * @returns the computed list of positions for the placeholders
 */
export function useMapPlaceholderPositions(
  placeholders: IElement[],
  floor: IElement,
  heightOffset: number = DEFAULT_PLACEHOLDER_FLOOR_OFFSET,
): Vector3[] {
  return useAppSelector(
    selectMapPlaceholdersPositions(placeholders, floor, heightOffset),
    areVector3ArraysEqual,
  );
}

/**
 * Special purpose store selector to compute the adjusted position for all the placeholders relative to a floor
 *
 * Need to be used with a specific comparator as the selector will generate new objects every call
 *
 * @param placeholders we want to render
 * @param floor where to place the placeholders
 * @param heightOffset offset added to the positions to avoid z-fighting with other ui elements
 * @returns the computed list of positions for the placeholders
 */
function selectMapPlaceholdersPositions(
  placeholders: IElement[],
  floor: IElement,
  heightOffset: number,
) {
  return (state: RootState) => {
    const positions: Vector3[] = [];
    for (const placeholder of placeholders) {
      const heightFix =
        selectAltitudeDifferenceToFloor(placeholder.id, floor.id)(state) -
        heightOffset;
      const worldPos = selectIElementWorldPosition(placeholder.id)(state);
      positions.push(
        new Vector3(worldPos[0], worldPos[1] - heightFix, worldPos[2]),
      );
    }
    return positions;
  };
}
