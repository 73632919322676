import {
  humanReadableArea,
  humanReadableDistance,
  neutral,
} from "@faro-lotv/app-component-toolbox";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import {
  VectorArea,
  areaOfClosedPolygon,
  polygonPerimeter,
  polygonPlanarity,
} from "@faro-lotv/lotv";
import { Paper, Typography } from "@mui/material";
import { HtmlProps } from "@react-three/drei/web/Html";
import {
  MouseEventHandler,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Matrix4, Vector3 } from "three";
import {
  INACTIVE_MEASURES_OPACITY,
  MEASURE_ANIMATION_LENGTH,
} from "./measure-constants";
import { PositionedHtml } from "./positioned-html";

export type ShapeLabelActions = {
  /** A callback to force the update of the rendering elements of this component */
  updateRendering(): void;
};

type ShapeLabelProps = {
  /** The list of points describing the polyline */
  points: Vector3[];
  /** A flag specifying if the measurement is a closed polygon or not */
  isClosed: boolean;
  /** A ref to the parent HTML element to which all labels will be attached */
  parentRef: MutableRefObject<HTMLElement>;
  /** The HTML property for the pointer events on the HTML labels */
  pointerEvents: HtmlProps["pointerEvents"];
  /** A flag to make the label transparent */
  transparent: boolean;
  /** The unit of measure used to display the measurement */
  unitOfMeasure: SupportedUnitsOfMeasure;
  /** The world matrix of the whole measurement */
  worldMatrix: Matrix4;
  /** The actions that can be manually triggered from outside  */
  actions?: RefObject<ShapeLabelActions>;
  /** Callback when this label is clicked */
  onClick?: MouseEventHandler<HTMLDivElement>;
};

/** Temporary data to avoid re-allocations */
const VECTOR_AREA: VectorArea = {
  area: 0,
  normal: new Vector3(),
};

/** @returns a label to be placed at the centre of a closed polygon */
export function ShapeLabel({
  isClosed,
  parentRef,
  points,
  unitOfMeasure,
  worldMatrix,
  transparent,
  pointerEvents,
  actions,
  onClick,
}: ShapeLabelProps): JSX.Element | null {
  const [planarity, setPlanarity] = useState(false);
  const [area, setArea] = useState<number | undefined>();
  const [perimeter, setPerimeter] = useState<number | undefined>();
  const [mean] = useState(() => new Vector3());

  const label = useMemo(() => {
    let text = "";
    if (area) {
      // Convert from meters
      text += planarity
        ? `\u2206: ${humanReadableArea(area, unitOfMeasure)};`
        : "not planar";
    }
    if (perimeter) {
      text += `\u2211: ${humanReadableDistance(perimeter, unitOfMeasure)}`;
    }
    return text;
  }, [area, perimeter, planarity, unitOfMeasure]);

  // For computing the area, we need to take into account the 3D pose of the object, because it could contain
  // scaling values
  const adjustedPositions = useMemo(
    () => points.map((p) => p.clone().applyMatrix4(worldMatrix)),
    [points, worldMatrix],
  );

  const updateRendering = useCallback(() => {
    if (isClosed) {
      // Computing the area of the closed polygon and the normal of the plane
      // on which it makes sense.
      const result = areaOfClosedPolygon(adjustedPositions, VECTOR_AREA).area;
      // Computing to what extent the polygon is really described by the found plane
      const planarity = polygonPlanarity(adjustedPositions, VECTOR_AREA.normal);
      setArea(result);
      setPlanarity(planarity < 0.1);
    } else {
      setArea(undefined);
    }

    if (adjustedPositions.length < 3) {
      setPerimeter(undefined);
    } else {
      const result = polygonPerimeter(adjustedPositions, isClosed);
      setPerimeter(result);
    }

    mean.set(0, 0, 0);
    if (points.length === 0) {
      return;
    }
    points.reduce((prev, next) => {
      prev.add(next);
      return prev;
    }, mean);
    mean.divideScalar(points.length);
  }, [isClosed, mean, points, adjustedPositions]);

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (transparent) setOpacity(INACTIVE_MEASURES_OPACITY);
    else setOpacity(1);
  }, [transparent]);

  useEffect(() => {
    updateRendering();
  }, [updateRendering]);

  useImperativeHandle(actions, () => ({
    updateRendering,
  }));

  if (!area && !perimeter) {
    return null;
  }

  return (
    <PositionedHtml
      position={mean}
      portal={parentRef}
      style={{
        pointerEvents,
        display: "block",
      }}
      zIndexRange={[0, 0]}
    >
      {/* Main Body */}
      <Paper
        elevation={0}
        onClick={onClick}
        sx={{
          padding: "5px",
          userSelect: "text",
          cursor: "text",
          opacity,
          transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
          transform: "translate(-50%, -50%)",
          pointerEvents,
          backgroundColor: neutral[999],
          outline: `${neutral[0]}33 solid 1px`,
        }}
      >
        <Typography
          noWrap
          sx={{ fontSize: "0.75em", fontWeight: "inherit", color: neutral[0] }}
        >
          {label}
        </Typography>
      </Paper>
    </PositionedHtml>
  );
}
