import { useToast } from "@faro-lotv/flat-ui";
import { useCallback } from "react";
import { AlignWizardProgressBar } from "./align-wizard-progress-bar";
import { AlignWizardSplitScreen } from "./align-wizard-split-screen";

/** @returns The overlay for the cloud to CAD alignment mode */
export function AlignWizardModeOverlay(): JSX.Element {
  const { openToast } = useToast();
  const selectAndRunAlignment = useCallback(() => {
    openToast({
      title: "Alignment Wizard",
      message: "Alignment Wizard functionality is not fully supported yet",
      variant: "error",
    });
  }, [openToast]);

  return (
    <>
      <AlignWizardProgressBar goToNextStep={selectAndRunAlignment} />
      <AlignWizardSplitScreen />
    </>
  );
}
