import { useDeepLink } from "@/components/common/deep-link/deep-link-context";
import {
  ValidDeepLink,
  isValidDeepLink,
} from "@/components/common/deep-link/deep-link-encoding";
import {
  SceneConversionState,
  useSceneConversionState,
} from "@/hooks/use-scene-conversion-state";
import { setActiveCad } from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import {
  setCompareShouldUseIntensityData,
  setShouldUseIntensityData,
} from "@/store/modes/walk-mode-slice";
import { selectIsProjectEmpty } from "@/store/selections-selectors";
import { setActiveElement } from "@/store/selections-slice";
import { AppDispatch, RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { setShowSpinner } from "@/store/ui/ui-slice";
import { isIElementImg360GrayScale } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useEffect } from "react";
import { Mode } from "./mode";

/**
 * Mode to handle the start of the application
 */
export const startMode: Mode = {
  name: "start",

  ModeScene() {
    const dispatch = useAppDispatch();

    const isProjectEmpty = useAppSelector(selectIsProjectEmpty);
    const deepLink = useDeepLink();

    const sceneConversionState = useSceneConversionState();

    const { getState } = useAppStore();

    // When app starts
    useEffect(() => {
      if (!sceneConversionState) {
        dispatch(setShowSpinner(true));
        return;
      }

      dispatch(setShowSpinner(false));
      if (sceneConversionState !== SceneConversionState.Ok) {
        dispatch(changeMode("projectConversion"));
      } else if (isProjectEmpty) {
        dispatch(changeMode("createArea"));
      } else if (isValidDeepLink(deepLink)) {
        prepareAppStateForDeepLink(deepLink, dispatch, getState());
      } else {
        dispatch(changeMode("sheet"));
      }
    }, [deepLink, dispatch, isProjectEmpty, getState, sceneConversionState]);

    return null;
  },
  ModeOverlay() {
    // TODO: Better loading screen
    return <p>Loading.....</p>;
  },
};

/**
 * Prepares the store's state to open a deep link in its expected configuration
 *
 * @param deepLink the deep link to load
 * @param dispatch store dispatch function
 * @param state current app state
 */
function prepareAppStateForDeepLink(
  deepLink: ValidDeepLink,
  dispatch: AppDispatch,
  state: RootState,
): void {
  dispatch(setActiveElement(deepLink.id));
  dispatch(changeMode(deepLink.mode));
  if (deepLink.cadId) {
    dispatch(setActiveCad(deepLink.cadId));
  }

  // Infer the state of whether to use intensity data from the deep link.
  // Otherwise the modes default to color images on their own.
  const mainElement = selectIElement(deepLink.id)(state);
  if (mainElement && isIElementImg360GrayScale(mainElement)) {
    dispatch(setShouldUseIntensityData(true));
  }
  const compareElement = selectIElement(deepLink.rightId)(state);
  if (compareElement && isIElementImg360GrayScale(compareElement)) {
    dispatch(setCompareShouldUseIntensityData(true));
  }
}
