import {
  selectCloudToCadAlignmentActiveStepIndex,
  selectCloudToCadAlignmentStepIsLastStep,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  goToCloudToCadAlignmentNextStep,
  goToCloudToCadAlignmentPreviousStep,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { useCallback } from "react";
import { AlignToCadProgressBar } from "../align-to-cad-commons/align-to-cad-progress-bar";

interface CloudToCadAlignmentProgressBarProps {
  /** A callback that is called when the user wants to apply the alignment */
  apply(): void;
}

/**
 * @returns The bar that is shown in the top of cloud-to-cad alignment mode
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function CloudToCadAlignmentProgressBar({
  apply,
}: CloudToCadAlignmentProgressBarProps): JSX.Element {
  const dispatch = useAppDispatch();
  const activeStepIndex = useAppSelector(
    selectCloudToCadAlignmentActiveStepIndex,
  );
  const isLastStep = useAppSelector(selectCloudToCadAlignmentStepIsLastStep);

  const goToAlignmentPreviousStep = useCallback(() => {
    dispatch(goToCloudToCadAlignmentPreviousStep());
  }, [dispatch]);

  const goToNextStepOrApplyAlignment = useCallback(() => {
    if (isLastStep) {
      apply();
    } else {
      dispatch(goToCloudToCadAlignmentNextStep());
    }
  }, [apply, dispatch, isLastStep]);

  return (
    <AlignToCadProgressBar
      goToAlignmentNextStep={goToNextStepOrApplyAlignment}
      goToAlignmentPreviousStep={goToAlignmentPreviousStep}
      activeStepIndex={activeStepIndex}
      isLastStep={isLastStep}
      enableNextButton
    />
  );
}
