import { useDashboardAddOnUrl } from "@/utils/redirects";
import { Link, Typography } from "@mui/material";

/** @returns A short message with a link to the Add On Gallery */
export function PointCloudAddOnRequiredMessage(): JSX.Element {
  const addOnUrl = useDashboardAddOnUrl();

  return (
    <Typography>
      <Link href={addOnUrl}>Point Cloud Management add-on</Link> required
    </Typography>
  );
}
