import { ValidDeepLink } from "@/components/common/deep-link/deep-link-encoding";
import { ModeNames } from "@/modes/mode";
import { clearStore } from "@faro-lotv/app-component-toolbox";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Shared state of the main 3d view
 */
export type ModeState = {
  /** Name of the current active mode */
  name: ModeNames;

  /** True if the mode is suspending to load new data */
  isLoadingData: boolean;

  /** True if the mode is transitioning to another mode */
  isTransitioning: boolean;

  /** The deep link used to initialize the current session */
  deepLink: ValidDeepLink | undefined;
};

const initialState: ModeState = {
  name: "start",
  isLoadingData: false,
  isTransitioning: false,
  deepLink: undefined,
};

/**
 * Slice to access global information about the current state of the main 3d view
 */
const modeSlice = createSlice({
  name: "mode",
  initialState,
  reducers: {
    /**
     * Change the current active camera the user is interacting with
     *
     * @param state Current state
     * @param action Camera to set
     */
    changeMode(state, action: PayloadAction<ModeNames>) {
      state.name = action.payload;
    },
    /**
     * Change the current state of the flag isLoadingData
     *
     * @param state Current state
     * @param action True if the mode is loading data
     */
    setModeIsLoadingData(state, action: PayloadAction<boolean>) {
      state.isLoadingData = action.payload;
    },
    /**
     * Change the current state of the flag isTransitioning
     *
     * @param state Current state
     * @param action True if the mode is loading data
     */
    setModeIsTransitioning(state, action: PayloadAction<boolean>) {
      state.isTransitioning = action.payload;
    },
    /**
     * Store the parsed valid deep link in the store
     *
     * @param state Current state
     * @param action The parsed valid deep link
     */
    setDeepLink(state, action: PayloadAction<ValidDeepLink>) {
      state.deepLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const modeReducer = modeSlice.reducer;

export const {
  changeMode,
  setModeIsLoadingData,
  setModeIsTransitioning,
  setDeepLink,
} = modeSlice.actions;
