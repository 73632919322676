import { WayPointTarget } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { selectPanoCameraTransform } from "@/utils/camera-transform";
import { selectAncestor } from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementGenericStream,
  IElementImg360,
  isIElementSectionGeoslam,
} from "@faro-lotv/ielement-types";
import { Camera, Vector3 as Vector3Prop, useThree } from "@react-three/fiber";
import { isEqual } from "lodash";
import { useLayoutEffect } from "react";
import { Vector3 } from "three";

/**
 * @returns a good camera position to use when moving to model rendering from a 360
 * @param pano the active 360 before we move to model rendering
 * @param sheet to project 360s without a valid height
 */
export function selectBestModelCameraFor360(
  pano: IElementImg360,
  sheet: IElementGenericImgSheet,
) {
  return (state: RootState): Vector3 => {
    const { position } = selectPanoCameraTransform(pano, sheet)(state);

    // If it's a geoslam pano, just pick the pano position and adds 50cm vertically
    // TODO: generalize this code once the info is provided by the backend https://faro01.atlassian.net/browse/SWEB-2533
    const extraHeight = selectAncestor(pano, isIElementSectionGeoslam)(state)
      ? 0.5
      : 0;

    return new Vector3(position[0], position[1] + extraHeight, position[2]);
  };
}

/**
 * @param el reference element to find best waypoint
 * @param camera curren camera
 * @param sheet current sheet
 * @param shouldSyncCamerasOnWaypoint if true, both cameras in split view should be in sync
 * @returns target waypoint
 */
export function selectBestWayPointForElement(
  el: IElementImg360,
  camera: Camera,
  sheet: IElementGenericImgSheet,
  shouldSyncCamerasOnWaypoint: boolean,
) {
  return (state: RootState): WayPointTarget => {
    const position = selectBestModelCameraFor360(el, sheet)(state);

    return {
      targetElement: el,
      position,
      quaternion: shouldSyncCamerasOnWaypoint ? camera.quaternion : undefined,
    };
  };
}

type PanoToModelProp = {
  /** The pano element from which the animation starts */
  currentElement: IElementImg360;

  /** The target model element of the animation */
  targetElement: IElementGenericStream;

  /** The current sheet  */
  sheet: IElementGenericImgSheet;

  /** The callback executed when the animation finishes */
  onAnimationFinished(target: IElementGenericStream): void;

  /** The callback executed when the camera changes position */
  onCameraMoved?(position: Vector3Prop): void;
};

/** @returns The pano to model (fake) animation for the walk scene */
export function PanoToModel({
  currentElement,
  targetElement,
  sheet,
  onAnimationFinished,
  onCameraMoved,
}: PanoToModelProp): null {
  const position = useAppSelector(
    selectBestModelCameraFor360(currentElement, sheet),
    isEqual,
  );

  const camera = useThree((s) => s.camera);

  useLayoutEffect(() => {
    onAnimationFinished(targetElement);
    camera.position.copy(position);
    onCameraMoved?.(position);
  }, [
    camera.position,
    onAnimationFinished,
    onCameraMoved,
    position,
    targetElement,
  ]);

  return null;
}
