import {
  EventType,
  ToggleUnitOfMeasureEventProperties,
} from "@/analytics/analytics-events";
import { useProjectUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import { convertIElementToThreePositionParsed } from "@/utils/transform-conversion-parsed";
import { Img2dRenderer } from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  IElement,
  IElementMarkupPolygon,
  IElementMeasurePolygon,
  isIElementImg2d,
  isIElementMarkupPolygon,
  isIElementMeasurePolygon,
  isIElementModel3d,
} from "@faro-lotv/ielement-types";
import { convertIElementToThreePosition } from "@faro-lotv/project-source";
import { RefObject, forwardRef, useCallback, useMemo } from "react";
import { Group, Material, Mesh, Object3D } from "three";
import { MultiPointMeasureRenderer } from "../measurements/multi-point-measure-renderer";
import { ZipModel3dRenderer } from "../zip-model-3d-renderer";
import { AnnotationVisibility } from "./annotation-utils";

interface AnnotationObjectProps {
  /** The Annotation's element to render */
  annotation: IElement;

  /** Whether a collapsed variant of the annotation should be shown */
  isCollapsed?: boolean;

  /** Whether depth testing should be used to render the annotation */
  depthTest?: Material["depthTest"];
}

/**
 * @returns the visual representation of an annotation (without any behavior)
 */
export const AnnotationObject = forwardRef<Object3D, AnnotationObjectProps>(
  function AnnotationObject(
    { annotation, isCollapsed, depthTest }: AnnotationObjectProps,
    ref,
  ): JSX.Element | null {
    if (isIElementMarkupPolygon(annotation)) {
      return (
        <MarkupPolygonRenderer
          // The ref is also a valid Object3D, but TS doesn't support the automatic cast here
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ref={ref as RefObject<Group>}
          annotation={annotation}
        />
      );
    } else if (isIElementImg2d(annotation)) {
      return (
        <Img2dRenderer
          // The ref is also a valid Object3D, but TS doesn't support the automatic cast here
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ref={ref as RefObject<Mesh>}
          iElement={annotation}
        />
      );
    } else if (isIElementModel3d(annotation)) {
      return (
        <ZipModel3dRenderer
          // The ref is also a valid Object3D, but TS doesn't support the automatic cast here
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ref={ref as RefObject<Group>}
          iElement={annotation}
        />
      );
    } else if (isIElementMeasurePolygon(annotation)) {
      return (
        <MeasurePolygonAnnotationRenderer
          // The ref is also a valid Object3D, but TS doesn't support the automatic cast here
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ref={ref as RefObject<Group>}
          measurement={annotation}
          isCollapsed={isCollapsed}
          depthTest={depthTest}
        />
      );
    }

    return null;
  },
);

type MarkupPolygonRendererProps = {
  /** IElement of the annotation */
  annotation: IElementMarkupPolygon;
};

/** @returns a point annotation object of type MarkupPolygon */
const MarkupPolygonRenderer = forwardRef<Group, MarkupPolygonRendererProps>(
  function MarkupPolygonRenderer(
    { annotation }: MarkupPolygonRendererProps,
    ref,
  ): JSX.Element {
    const markupPosition = useMemo(
      () => convertIElementToThreePosition(annotation.points[0]),
      [annotation.points],
    );

    return <group ref={ref} position={markupPosition} />;
  },
);

type MeasurePolygonAnnotationRendererProps = {
  /** The iElement containing the measurement*/
  measurement: IElementMeasurePolygon;

  /** Whether a collapsed variant of the annotation should be shown */
  isCollapsed?: boolean;

  /** Whether depth testing should be used to render the measurement */
  depthTest?: Material["depthTest"];
};

/** @returns A component to render measure polygon IElement*/
const MeasurePolygonAnnotationRenderer = forwardRef<
  Group,
  MeasurePolygonAnnotationRendererProps
>(function MeasurePolygonAnnotationRenderer(
  {
    measurement,
    isCollapsed,
    depthTest,
  }: MeasurePolygonAnnotationRendererProps,
  ref,
): JSX.Element | null {
  const points = useMemo(
    () => measurement.points.map(convertIElementToThreePositionParsed),
    [measurement.points],
  );

  const { unitOfMeasure, setUnitOfMeasure } = useProjectUnitOfMeasure();

  const onToggleUnitOfMeasure = useCallback(() => {
    const newUnitOfMeasure = unitOfMeasure === "metric" ? "us" : "metric";

    Analytics.track<ToggleUnitOfMeasureEventProperties>(
      EventType.toggleUnitOfMeasure,
      {
        newValue: newUnitOfMeasure === "metric" ? newUnitOfMeasure : "imperial",
      },
    );

    setUnitOfMeasure(newUnitOfMeasure);
  }, [setUnitOfMeasure, unitOfMeasure]);

  return (
    <MultiPointMeasureRenderer
      ref={ref}
      points={points}
      isClosed={measurement.isClosed}
      unitOfMeasure={unitOfMeasure}
      onToggleUnitOfMeasure={onToggleUnitOfMeasure}
      visibility={
        isCollapsed
          ? AnnotationVisibility.NotVisible
          : AnnotationVisibility.Visible
      }
      depthTest={depthTest}
      showActionBar={false}
    />
  );
});
