import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { selectObjectVisibility } from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { selectPanoCameraTransform } from "@/utils/camera-transform";
import {
  findClosestIndex,
  selectChildrenDepthFirst,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { Quaternion, useThree } from "@react-three/fiber";
import { useCallback } from "react";
import { Vector3 } from "three";
import { WalkPathRenderer } from "./walk-path-renderer";

// used to specify properties of the WayPoint target clicked by user in walk mode (on 360 images, cad and cloud)
export type WayPointTarget = {
  // target element
  targetElement: WalkSceneActiveElement;

  // position of the target (can be used to center "other" camera in split view)
  position?: Vector3;

  // rotation of the camera (can be used to set rotation of "other" camera in split view)
  quaternion?: Quaternion;
};

type WalkPathsRendererProps = {
  /** Whether to fade off the path in the distance. */
  fadeOff?: boolean;

  /** Active sheet to render trajectories for */
  activeSheet: IElementGenericImgSheet;

  /** Paths to render */
  paths: IElementSection[];

  /** Current active path */
  activePath?: IElementSection;

  /** The currently active pano, if present. */
  activePano?: IElementImg360;

  /**
   * Check if the pano should be aligned in order to make the component visible
   *
   * @default false
   */
  alignmentRequired?: boolean;

  /**
   * A callback for when the active element is being changed.
   *
   * @param el The new active element.
   */
  setActiveElement?(el: IElementImg360 | undefined): void;

  /** Callback called when the active waypoint target element inside the walk scene has changed. */
  onWayPointChanged?(target: WayPointTarget): void;
};

/**
 * @returns A single odometric path for usage in 3D environments.
 */
export function WalkPathsRenderer({
  fadeOff,
  paths,
  activePano,
  activePath,
  activeSheet,
  alignmentRequired = false,
  setActiveElement,
  onWayPointChanged,
}: WalkPathsRendererProps): JSX.Element | null {
  const isAligned = !!activePano?.isRotationAccurate;

  const camera = useThree((s) => s.camera);

  const store = useAppStore();

  const notifyNewWayPoint = useCallback(
    (el: IElementImg360) => {
      if (onWayPointChanged) {
        const { position } = selectPanoCameraTransform(
          el,
          activeSheet,
        )(store.getState());
        onWayPointChanged({
          targetElement: el,
          position: new Vector3(...position),
          quaternion: camera.quaternion,
        });
      }
    },
    [activeSheet, camera.quaternion, onWayPointChanged, store],
  );

  // Select the closest pano in the path to the point clicked on the path
  // TODO: consolidate duplication with 2d-paths (https://faro01.atlassian.net/browse/SWEB-1882)
  const changeActivePath = useCallback(
    (
      odometricPath: IElementSection,
      position: Vector3,
      placeholderPoints: Vector3[],
    ) => {
      const state = store.getState();
      const panos = selectChildrenDepthFirst(
        odometricPath,
        isIElementImg360,
      )(state);
      const closest = findClosestIndex(position, placeholderPoints);
      if (closest) {
        setActiveElement?.(panos[closest]);
        notifyNewWayPoint(panos[closest]);
      }
    },
    [notifyNewWayPoint, setActiveElement, store],
  );

  const changeActivePano = useCallback(
    (el: IElementImg360) => {
      setActiveElement?.(el);
      notifyNewWayPoint(el);
    },
    [notifyNewWayPoint, setActiveElement],
  );

  const shouldTrajectoriesBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.trajectories),
  );

  if ((!isAligned && alignmentRequired) || !shouldTrajectoriesBeVisible) {
    return null;
  }

  return (
    <>
      {paths.map((path) => (
        <WalkPathRenderer
          activePano={activePano}
          sheet={activeSheet}
          odometricPath={path}
          key={path.id}
          isActive={path.id === activePath?.id}
          onPathClicked={changeActivePath}
          onPlaceholderClicked={changeActivePano}
          fadeOff={fadeOff}
        />
      ))}
    </>
  );
}
