import {
  CloseIcon,
  SplitscreenStartIcon,
} from "@faro-lotv/app-component-toolbox";
import { SxProps, ToggleButton, Tooltip, Typography } from "@mui/material";

type SplitScreenButtonProps = {
  /** True if this button should be enabled */
  enabled: boolean;

  /** True if this button is checked (split screen enabled) */
  checked: boolean;

  /** Callback called when the button is clicked */
  onClick(): void;

  /** Style for the button */
  sx?: SxProps;
};

/**
 * @returns A button to enable/disable split screen
 */
export function SplitScreenButton({
  enabled,
  checked,
  onClick,
  sx,
}: SplitScreenButtonProps): JSX.Element {
  return (
    <Tooltip title={enabled ? "Split screen" : "Split screen is unavailable"}>
      {/* The span is required to enable tooltip even when the ToggleButton is disabled */}
      <span>
        <ToggleButton
          selected={checked}
          value="splitscreen"
          aria-label="split screen"
          onChange={onClick}
          disabled={!enabled}
          sx={{
            ...sx,
            textTransform: "none",
          }}
        >
          {checked ? (
            <CloseIcon sx={{ width: "18px", marginRight: "14px" }} />
          ) : (
            <SplitscreenStartIcon
              sx={{
                width: "18px",
                marginRight: "14px",
                ["&:hover"]: {
                  color: "white",
                },
              }}
            />
          )}

          <Typography
            sx={{ fontSize: "12px", fontWeight: 600, whiteSpace: "nowrap" }}
          >
            Split Screen
          </Typography>
        </ToggleButton>
      </span>
    </Tooltip>
  );
}
