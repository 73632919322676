import {
  AlignPointCloudEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { ElementIconType } from "@/components/ui/icons";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import {
  selectActiveCadId,
  selectCadAlignmentErrorMessage,
} from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import { setCloudForCloudAlignment } from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { selectActiveAreaOrThrow } from "@/store/selections-selectors";
import { redirectToAlignmentTool } from "@/utils/redirects";
import {
  selectChildDepthFirst,
  selectIElement,
  selectIsPointCloudAligned,
  selectNumberOfPointCloudsOnFloor,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAreaSection,
  isIElementGenericPointCloudStream,
  isIElementGenericStream,
  isIElementModel3dStream,
} from "@faro-lotv/ielement-types";
import {
  compatibilityMessage,
  selectDisableCaptureTreeAlignment,
} from "../../cad-model-tree/use-disable-capture-tree-alignment";
import {
  ContextMenuAction,
  ContextMenuActionHandlerArgs,
  ContextMenuActionType,
} from "../action-types";

export const ALIGN_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.align,
  label: "Align to Sheet",
  icon: ElementIconType.AlignToSheetIcon,
  handler: startAlignmentCloudToArea,
  disabledMessageForNode(iElement, state) {
    if (selectDisableCaptureTreeAlignment(state)) {
      return compatibilityMessage;
    }
  },
};

export const ALIGN_CLOUD_TO_CAD_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.alignCloud,
  label: "Align to 3D Model",
  icon: ElementIconType.AlignToModelIcon,
  handler: startAlignmentCloudToCad,
  disabledMessageForNode: (_, state) => {
    if (selectDisableCaptureTreeAlignment(state)) {
      return compatibilityMessage;
    }
    return selectCadAlignmentErrorMessage(state);
  },
};

export const ALIGN_AREA_TO_CAD_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.alignArea,
  label: "Align to 3D Model",
  icon: ElementIconType.AlignToModelIcon,
  handler: startAlignmentAreaToCad,
  disabledMessageForNode: (_, state) => {
    if (selectDisableCaptureTreeAlignment(state)) {
      return compatibilityMessage;
    }
    return selectCadAlignmentErrorMessage(state);
  },
};

export const OPEN_ALIGN_WIZARD_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.openAlignmentWizard,
  label: "Align",
  icon: ElementIconType.AlignToSheetIcon,
  handler: ({ elementID, dispatch }) =>
    openAlignmentWizard({ elementIdToAlign: elementID, dispatch }),
  disabledMessageForNode: (element, state) => {
    if (selectDisableCaptureTreeAlignment(state)) {
      return compatibilityMessage;
    }
    if (isIElementModel3dStream(element)) {
      return selectCadAlignmentErrorMessage(state);
    }
  },
};

/**
 * Start the alignment tool passing the element as input. The elementID here is ID of point cloud
 */
function startAlignmentCloudToArea({
  elementID,
  state,
  apiClients,
  dispatch,
}: ContextMenuActionHandlerArgs): void {
  const elementSection = selectIElement(elementID)(state);
  const floor = selectActiveAreaOrThrow(elementSection)(state);
  const isAreaSection = elementSection && isIElementAreaSection(elementSection);

  const alignedElement = selectChildDepthFirst(
    elementSection,
    isAreaSection ? isIElementModel3dStream : isIElementGenericStream,
  )(state);

  if (!isAreaSection) {
    if (alignedElement && isIElementGenericPointCloudStream(alignedElement)) {
      const isAligned = selectIsPointCloudAligned(alignedElement)(state);
      const numberOfAlignedPointClouds = selectNumberOfPointCloudsOnFloor(
        floor,
        true,
      )(state);
      Analytics.track<AlignPointCloudEventProperties>(
        EventType.alignPointCloud,
        {
          via: "context menu",
          alreadyAligned: isAligned,
          numberOfAlignedPCs: numberOfAlignedPointClouds,
        },
      );
    }
  }

  assert(
    alignedElement && floor,
    "should not be called when there is no cloud or area",
  );

  redirectToAlignmentTool({
    projectId: apiClients.projectApiClient.projectId,
    elementId: alignedElement.id,
    floorId: floor.id,
    state,
    dispatch,
  });
}

/**
 * Start the alignment tool passing the element as input. The elementID here is ID of the Area
 */
function startAlignmentAreaToCad({
  elementID,
  state,
  apiClients,
  dispatch,
}: ContextMenuActionHandlerArgs): void {
  const elementSection = selectIElement(elementID)(state);
  const floor = selectActiveAreaOrThrow(elementSection)(state);

  const cad = selectActiveCadId(state);
  assert(cad && floor, "should not be called when there is no CAD or area");

  redirectToAlignmentTool({
    projectId: apiClients.projectApiClient.projectId,
    elementId: cad,
    floorId: floor.id,
    state,
    dispatch,
  });
}

/**
 * Start the alignment tool passing the element as input. The elementID here is ID of point cloud
 */
function startAlignmentCloudToCad({
  elementID,
  state,
  dispatch,
}: ContextMenuActionHandlerArgs): void {
  const elementSection = selectIElement(elementID)(state);

  const cloud = selectChildDepthFirst(
    elementSection,
    isIElementGenericPointCloudStream,
  )(state);

  assert(
    cloud,
    "Cloud-To-Cad should not be called when there is no point cloud selected",
  );

  dispatch(setCloudForCloudAlignment(cloud.id));

  dispatch(changeMode("cloudToCadAlignment"));
}
