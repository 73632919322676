import {
  CadModelSetVisibilityEvent,
  useSceneEvents,
} from "@/components/common/scene-events-context";
import { selectActiveCadId } from "@/store/cad/cad-slice";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { useTypedEvent } from "@faro-lotv/app-component-toolbox";
import { useCallback, useEffect, useRef, useState } from "react";
import { NodeApi, Tree, TreeApi } from "react-arborist";
import { TREE_NODE_HEIGHT } from "../tree-node";
import { CadModelTreeNodeData } from "./cad-model-tree-data";
import { CadModelTreeNode } from "./cad-model-tree-node";
import { CadModelTreeRow } from "./cad-model-tree-row";
import {
  selectModelTreeNode,
  setNodeVisibility,
  unselectAllNodes,
} from "./cad-model-visibility";

/** Parameters for the CadModelTree component */
type CadModelTreeProps = {
  /** Cad model tree to display */
  modelTreeData: CadModelTreeNodeData[];

  /** Height to apply to the Tree component */
  height?: number;
};

/**
 * Display the CAD model tree UI component.
 *
 * @returns The CAD model tree.
 */
export function CadModelTree({
  modelTreeData,
  height,
}: CadModelTreeProps): JSX.Element {
  const treeRef = useRef<TreeApi<CadModelTreeNodeData>>();
  const [selectedTreeNode, setSelectedTreeNode] = useState<string | undefined>(
    undefined,
  );
  const selectHistory = useRef<string[]>([]);
  const activeTool = useAppSelector(selectActiveTool);

  const cadModelData = modelTreeData.length
    ? modelTreeData[0].cadModelData
    : null;

  const updateSelectedTreeNode = useCallback(
    (id: string): void => {
      if (!treeRef.current) return;
      const treeNode = treeRef.current.get(id);
      if (treeNode) {
        treeNode.select();
        treeRef.current.scrollTo(id);
      } else {
        setSelectedTreeNode(id);
      }
    },
    [treeRef],
  );

  const cadSceneEvents = useSceneEvents();

  const unselectAllTreeNodes = useCallback((): void => {
    if (!treeRef.current) return;
    if (treeRef.current.selectedNodes.length > 0) {
      treeRef.current.deselectAll();
    }
    if (
      cadModelData?.selectedObjects &&
      cadModelData.selectedObjects.length > 0
    ) {
      unselectAllNodes(cadModelData);
      cadSceneEvents?.invalidate.emit();
    }
    selectHistory.current = [];
  }, [cadModelData, cadSceneEvents?.invalidate]);

  useTypedEvent<CadModelSetVisibilityEvent>(
    cadSceneEvents?.setCadModelVisibility,
    (e) => {
      if (!treeRef.current || !cadModelData) return;

      const data = cadModelData.treeNodeData.get(e.objectId);
      if (!data) {
        console.warn(`CAD object ${e.objectId} not found in the model tree.`);
        return;
      }

      setNodeVisibility(data, e.visibility);
      cadSceneEvents?.invalidate.emit();
      updateSelectedTreeNode(data.id);
    },
  );

  useTypedEvent<number>(
    cadSceneEvents?.selectParentCadObjectInTree,
    (objectId) => {
      if (!cadModelData) return;

      const data = cadModelData.treeNodeData.get(objectId);
      if (!data) {
        console.warn(`CAD object ${objectId} not found in the model tree.`);
        return;
      }

      updateSelectedTreeNode(data.id);
      if (treeRef.current) {
        const selected = treeRef.current.selectedNodes;
        if (selected.length > 0) {
          selected[0].parent?.select();
        }
      }

      // add child node to history, so "Shift-tab" will go back down to the clicked element
      selectHistory.current = [data.id];
    },
  );

  useTypedEvent<number | undefined>(
    cadSceneEvents?.locateCadObjectInTree,
    (objectId) => {
      if (objectId === undefined) {
        unselectAllTreeNodes();
        return;
      }
      const data = cadModelData?.treeNodeData.get(objectId);
      if (!data) {
        console.warn(`CAD object ${objectId} not found in the model tree.`);
        return;
      }

      updateSelectedTreeNode(data.id);
      // need to update the selected state of the node, the `onSelect` event seems not received the node
      selectModelTreeNode(data);
      cadSceneEvents?.invalidate.emit();
      // clear selected node history
      selectHistory.current = [];
    },
  );

  // reset selection of cad nodes at cad switch
  const activeCad = useAppSelector(selectActiveCadId);
  useEffect(() => {
    unselectAllTreeNodes();
  }, [activeCad, unselectAllTreeNodes]);

  // This overrides the deselectAll method of TreeApi to handle properly the deselection
  // when user click in the empty space under the CAD tree.
  useEffect(() => {
    if (!treeRef.current) return;
    const originalDeselectAll = treeRef.current.deselectAll.bind(
      treeRef.current,
    );
    treeRef.current.deselectAll = () => {
      if (cadModelData?.selectedObjects.length) {
        unselectAllNodes(cadModelData);
        cadSceneEvents?.invalidate.emit();
      }
      originalDeselectAll();
    };
  }, [
    cadModelData,
    cadModelData?.selectedObjects.length,
    cadSceneEvents?.invalidate,
    treeRef,
    unselectAllTreeNodes,
  ]);

  // Setup keyboard shortcuts
  // - "Tab" should select one parent level above until reaching root
  // - "Shift+Tab" should go down the tree from where we came up
  // - "Esc" should deselect the current selected node if there is no active tool
  useEffect(() => {
    function keyDown(ev: KeyboardEvent): void {
      if (!treeRef.current) return;

      switch (ev.key) {
        case "Tab": {
          ev.stopPropagation();
          const selected = treeRef.current.selectedNodes;
          if (selected.length !== 1) return;

          if (!ev.shiftKey) {
            if (selected[0].id !== modelTreeData[0]?.id) {
              // backup current selected node history, change selected will clear the history
              const history = selectHistory.current.slice();
              selected[0].parent?.select();
              history.push(selected[0].id);
              selectHistory.current = history;
            }
          } else if (selectHistory.current.length) {
            // backup current selected node history, change selected will clear the history
            const history = selectHistory.current.slice();
            const lastSelected = history.pop();
            if (lastSelected) treeRef.current.select(lastSelected);
            selectHistory.current = history;
          }
          break;
        }
        case "Escape": {
          if (activeTool === null) {
            ev.stopPropagation();
            unselectAllTreeNodes();
            treeRef.current.hideCursor();
          }
          break;
        }
      }
    }

    window.addEventListener("keydown", keyDown);

    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  }, [
    modelTreeData,
    treeRef,
    cadModelData,
    cadSceneEvents,
    selectHistory,
    unselectAllTreeNodes,
    activeTool,
  ]);

  const handleNodeSelect = useCallback(
    (nodes: Array<NodeApi<CadModelTreeNodeData>>): void => {
      if (nodes.length === 0) return;
      selectModelTreeNode(nodes[0].data);
      cadSceneEvents?.invalidate.emit();
      // clear selected node history
      selectHistory.current = [];
    },
    [cadSceneEvents?.invalidate],
  );

  return (
    <Tree
      data={modelTreeData}
      renderRow={CadModelTreeRow}
      openByDefault={false}
      width="100%"
      initialOpenState={{ [modelTreeData[0]?.id]: true }}
      indent={12}
      height={height}
      rowHeight={TREE_NODE_HEIGHT}
      ref={treeRef}
      selection={selectedTreeNode}
      onSelect={handleNodeSelect}
      disableMultiSelection={true}
    >
      {CadModelTreeNode}
    </Tree>
  );
}
