import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { StepIcon } from "../../components/ui/step-icon";

interface Props {
  /** Id of the active step */
  activeStep: number;

  /** Style for this component */
  sx: SxProps<Theme>;

  /** The text to display for the first step */
  firstStepLabel: string;

  /** The text to display for the second step*/
  secondStepLabel: string;
}

/**
 * @returns The progress of the alignment process
 */
export function AlignmentProgress({
  activeStep,
  sx,
  firstStepLabel,
  secondStepLabel,
}: Props): JSX.Element {
  return (
    <Stepper sx={sx} activeStep={activeStep}>
      <Step>
        <StepLabel StepIconComponent={StepIcon}>
          <Box component="span" sx={{ fontWeight: "bold" }}>
            {firstStepLabel}
          </Box>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={StepIcon}>
          <Box component="span" sx={{ fontWeight: "bold" }}>
            {secondStepLabel}
          </Box>
        </StepLabel>
      </Step>
    </Stepper>
  );
}
