import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { UnrecoverableError } from "@/errors/unrecoverable-error";
import {
  SceneConversionState,
  useSceneConversionState,
} from "@/hooks/use-scene-conversion-state";
import { runtimeConfig } from "@/runtime-config";
import { redirectToDashboard } from "@/utils/redirects";
import {
  CloudConnectionIcon,
  CloudProcessingGraphicIcon,
  FaroText,
  neutral,
} from "@faro-lotv/flat-ui";
import { Link, Stack } from "@mui/material";
import { CSSProperties, useEffect, useMemo } from "react";

/** @returns The overlay elements of this mode */
export function ProjectConversionModeOverlay(): JSX.Element | null {
  /** Style used to fit the entire page */
  const entirePageStyle = useMemo<CSSProperties>(
    () => ({
      position: "relative",
      // Set the size of the element to the entire page, minus the header bar
      width: "100%",
      height: "100%",
      p: "60px",
      backgroundColor: neutral[50],
    }),
    [],
  );

  const conversionState = useSceneConversionState();

  const { handleErrorWithPage } = useErrorHandlers();
  useEffect(() => {
    if (conversionState === SceneConversionState.Failed) {
      const error = new UnrecoverableError(
        "The system wasn’t able to process your data",
        {
          errorStatement: "The processing of your data failed",
          actions: [
            {
              label: "Go to dashboard",
              action: redirectToDashboard,
              primary: true,
            },
          ],
        },
      );
      handleErrorWithPage(error);
    } else if (conversionState === SceneConversionState.Ok) {
      window.location.reload();
    }
  }, [conversionState, handleErrorWithPage]);

  return (
    <Stack sx={entirePageStyle}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={5}
        sx={{ width: "100%", height: "100%" }}
      >
        <CloudProcessingGraphicIcon sx={{ fontSize: 200 }} />
        <FaroText variant="heading24" color={neutral[800]} textAlign="center">
          Your data is being processed, the project will be available soon
        </FaroText>
        <FaroText
          variant="bodyM"
          color={neutral[600]}
          sx={{ maxWidth: "600px", whiteSpace: "pre-line" }}
          textAlign="center"
        >
          This page will refresh automatically, when the project you have
          uploaded is ready for use. Click the Cloud Activity.
          <CloudConnectionIcon
            sx={{ color: neutral[600], transform: "translate(0%, 20%)" }}
          />{" "}
          in the top right corner to check the status.
        </FaroText>
        <FaroText
          variant="bodyM"
          color={neutral[600]}
          sx={{ maxWidth: "600px", whiteSpace: "pre-line" }}
          textAlign="center"
        >
          Have a question? Visit our{" "}
          <Link
            sx={{ display: "contents" }}
            href={runtimeConfig.externalLinks.troubleShooting}
          >
            Help Center
          </Link>
        </FaroText>
      </Stack>
    </Stack>
  );
}
