import {
  AnimationManager,
  LineMaterial,
  ObjectPropertyAnimation,
} from "@faro-lotv/lotv";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef } from "react";
import { ColorRepresentation, Material, Vector2, Vector3 } from "three";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";
import { MEASURE_ANIMATION_LENGTH } from "./measure-constants";

/** Properties for the SegmentRenderer */
export type SegmentRendererProps = {
  visible: boolean;

  /** Start point of the segment */
  start: Vector3;

  /** End point of the segment */
  end: Vector3;

  /** Segment width */
  width: number;

  /** Color of the segment */
  color: ColorRepresentation;

  /**
   * Extra z-offset in clip space [-1, 1] to apply to the segment when rendering
   *
   * @default 0
   */
  zOffset?: number;

  /** Opacity of the segment */
  opacity?: number;

  /** Make the line dashed */
  dashed?: boolean;

  /** Whether depth testing should be used to render the segment */
  depthTest?: Material["depthTest"];
};

/** Length of the dash expressed as a percentage of the segment length */
const DASH_PERCENTAGE = 0.05;

/** @returns the rendering logic for a single segment */
export function SegmentRenderer({
  visible,
  start,
  end,
  width,
  color,
  zOffset = 0,
  opacity = 1,
  dashed,
  depthTest,
}: SegmentRendererProps): JSX.Element | null {
  const isDirty = useRef(true);

  useEffect(() => {
    isDirty.current = true;
  }, [dashed]);

  const geometry = useMemo(() => {
    const g = new LineGeometry();
    g.setPositions([0, 0, 0, ...end.clone().sub(start).toArray()]);
    isDirty.current = true;
    return g;
  }, [start, end]);

  const segment = useMemo(() => new LineSegments2(), []);
  const size = useThree((state) => state.size);
  const resolution = useMemo(
    () => new Vector2(size.width, size.height),
    [size.height, size.width],
  );

  const material = useRef<LineMaterial>(null);
  const animationManager = useMemo(() => new AnimationManager(), []);
  useFrame((_, delta) => {
    animationManager.update(delta);
    if (isDirty.current) {
      segment.computeLineDistances();
    }
    if (material.current) {
      const segmentLength = Math.min(
        start.distanceTo(end) * DASH_PERCENTAGE,
        0.5,
      );
      material.current.dashSize = segmentLength;
      material.current.gapSize = segmentLength;
      material.current.uniformsNeedUpdate = true;
    }
    isDirty.current = false;
  }, 0);

  useEffect(() => {
    if (!material.current) return;
    const target = visible ? opacity : 0;
    const animation = new ObjectPropertyAnimation(
      material.current,
      "opacity",
      material.current.opacity,
      target,
      {
        duration: MEASURE_ANIMATION_LENGTH,
      },
    );
    animationManager.run(animation);
    return () => {
      animation.cancel();
    };
  }, [animationManager, opacity, visible]);

  return (
    <primitive position={start} object={segment}>
      <primitive object={geometry} attach="geometry" />
      <lineMaterial
        ref={material}
        color={color}
        transparent={true}
        opacity={opacity}
        depthTest={depthTest}
        /* eslint-disable react/no-unknown-property */
        worldUnits={false}
        linewidth={width}
        zOffset={zOffset}
        resolution={resolution}
        alphaToCoverage={false}
        dashed={dashed}
        /* eslint-enable react/no-unknown-property */
      />
    </primitive>
  );
}
