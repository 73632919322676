import { FaroStep, FaroStepper, neutral } from "@faro-lotv/flat-ui";
import Stack from "@mui/material/Stack";
import { useCallback, useState } from "react";

interface AlignWizardProgressBarProps {
  /** A callback that is called when the user clicks "Next" button in the progressbar */
  goToNextStep(): void;
}

/** defines steps for Alignment Wizard progressbar */
const steps: FaroStep[] = [
  {
    key: "1",
    label: "Select reference",
  },
  {
    key: "2",
    label: "Align",
  },
];

/**
 * @returns The progress bar that is shown at the top of an alignment Wizard mode
 *  Allows user to see where there are currently in the alignment process
 */
export function AlignWizardProgressBar({
  goToNextStep,
}: AlignWizardProgressBarProps): JSX.Element {
  const [activeStepKey, setActiveStepKey] = useState(steps[0].key);

  const handleNextStep = useCallback(
    (step: FaroStep) => {
      goToNextStep();
      setActiveStepKey(step.key);
    },
    [goToNextStep],
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        mt: -2,
        ml: -2,
        mr: -2,
        p: 1,
        backgroundColor: neutral[100],
        zIndex: 1,
      }}
    >
      <FaroStepper
        steps={steps}
        activeStepKey={activeStepKey}
        onStepChange={handleNextStep}
      />
    </Stack>
  );
}
