import { raycastOnPlaceholdersGroup } from "@/components/r3f/renderers/odometry-paths/odometry-path-placeholders";
import { PathRenderer } from "@/components/r3f/renderers/odometry-paths/odometry-path-renderer";
import { useSubSamplePlaceholders } from "@/components/r3f/renderers/odometry-paths/use-subsample-placeholders";
import {
  MapWaypointsRenderer,
  computePointerNdcCoordinates,
  useCustomCanvasCursor,
  useSvg,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import { CursorStyle, neutral } from "@faro-lotv/flat-ui";
import { Line } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CatmullRomCurve3,
  GreaterStencilFunc,
  Group,
  KeepStencilOp,
  Matrix4,
  ReplaceStencilOp,
  Vector2,
  Vector3,
} from "three";
import PointSvg from "../../components/r3f/renderers/odometry-paths/VM_WaypointDisabled.svg?url";
import { usePathAlignmentContext } from "./path-alignment-mode-context";

const POINTS_SEGMENT_FACTOR = 50;

/** The click event is called for mouse movements smaller than 5 pixels */
const MAX_PIXEL_DISTANCE_SQUARED = 25;

/** The minimum distance to snap the point to the starting position */
const MIN_PIXEL_SNAPPING_SQUARED = 36;

/** The stencil value used by the stationary path */
const STENCIL_REF = 1;

/** Size of the placeholders in the stationary path in pixels */
const BASE_SIZE = 15;

/** Temporary data to avoid mermory garbage */
const TEMP_VEC2 = new Vector2();
const TEMP_VEC3 = new Vector3();

/**
 * Compute the new positions of the points in the path after translating one of them
 *
 * @param waypoints The list of points belonging to the path
 * @param index The index of the point that changed position
 * @param correctedEndpoint The new position of the point at the specified index
 * @returns The computed new positions
 */
function correctWaypointPositions(
  waypoints: Vector3[],
  index: number,
  correctedEndpoint: Vector3,
): Vector3[] {
  // Compute the picked point displacement
  const startingPos = waypoints[index];
  const displacement = new Vector3(
    correctedEndpoint.x - startingPos.x,
    correctedEndpoint.y - startingPos.y,
    correctedEndpoint.z - startingPos.z,
  );

  // The final corrected points
  const correctedPoints: Vector3[] = [];

  // Backward correct
  for (let i = 0; i < index; ++i) {
    const percentage = 1 - (index - i) / index;
    const correctedPoint = new Vector3(
      waypoints[i].x + percentage * displacement.x,
      waypoints[i].y + percentage * displacement.y,
      waypoints[i].z + percentage * displacement.z,
    );
    correctedPoints.push(correctedPoint);
  }

  // Forward correct
  for (let i = index; i < waypoints.length; i++) {
    const den = waypoints.length - 1 - index;
    const percentage = den > 0 ? 1 - (i - index) / den : 1;
    const correctedPoint = new Vector3(
      waypoints[i].x + percentage * displacement.x,
      waypoints[i].y + percentage * displacement.y,
      waypoints[i].z + percentage * displacement.z,
    );
    correctedPoints.push(correctedPoint);
  }

  return correctedPoints;
}

type StationaryPathProps = {
  /** The list of points describing the line to render */
  positions: Vector3[];
};

/** @returns A grayed path that the user cannot interact with */
function StationaryPath({
  positions: positions3D,
}: StationaryPathProps): JSX.Element {
  const defaultTexture = useSvg(PointSvg, 256, 256);

  const positions = useMemo(() => {
    return positions3D.map((p) => p.clone().setY(0));
  }, [positions3D]);

  const [segments] = useState(() => {
    return new CatmullRomCurve3(positions).getPoints(
      positions.length * POINTS_SEGMENT_FACTOR,
    );
  });

  // Computes the hidden placeholders based on their screen-space distance
  // Prioritizes showing the reference positions (start- and end-panos)
  const references = useMemo(
    () => [segments[0], segments[segments.length - 1]],
    [segments],
  );

  const { groupRef, computeHiddenPlaceholders } = useSubSamplePlaceholders(
    references,
    BASE_SIZE,
  );

  return (
    <group ref={groupRef}>
      <Line
        forceSinglePass={false}
        points={segments}
        lineWidth={8}
        transparent={true}
        opacity={0.6}
        color="white"
        depthTest={false}
        stencilRef={STENCIL_REF}
        stencilFunc={GreaterStencilFunc}
        stencilFail={KeepStencilOp}
        stencilZPass={ReplaceStencilOp}
        stencilWrite={true}
      />
      <group position-y={0.1}>
        <Line
          forceSinglePass={false}
          points={segments}
          lineWidth={4}
          transparent={true}
          opacity={0.4}
          color={neutral[500]}
          stencilRef={STENCIL_REF + 1}
          stencilFunc={GreaterStencilFunc}
          stencilFail={KeepStencilOp}
          stencilZPass={ReplaceStencilOp}
          stencilWrite={true}
        />
        <MapWaypointsRenderer
          baseSize={BASE_SIZE}
          waypoints={positions}
          computeHidden={computeHiddenPlaceholders}
          textures={{
            defaultTexture,
          }}
        />
      </group>
    </group>
  );
}

type PathAlignmentSinglePointProp = {
  /** True to enable Path editing trough this tool */
  enabled: boolean;

  /** Callback to signal when the user is dragging a point */
  onDragging(d: boolean): void;

  /** Callback called when the user click one of the placeholders */
  onPlaceholderClick(position: Vector3): void;

  /** Callback when the placeholder is hovered */
  onPlaceholderHovered?(index?: number): void;
};

/**
 * @returns An interaction that allows to adjust a path by dragging points
 */
export function PathAlignmentSinglePoint({
  enabled,
  onDragging,
  onPlaceholderClick,
  onPlaceholderHovered,
}: PathAlignmentSinglePointProp): JSX.Element {
  const camera = useThree((s) => s.camera);
  const raycaster = useThree((s) => s.raycaster);

  const {
    pose,
    positions: globalPositions,
    updatePositions: setGlobalPositions,
    locked,
    setLocked,
  } = usePathAlignmentContext();

  const poseInverse = useMemo(
    () => new Matrix4().fromArray(pose).invert(),
    [pose],
  );

  // We need to store locally the positions we modify at each frame, because going through zustand is slower
  // Moreover, when we will implement undo/redo, we don't want to store all the changes at each frame
  const [positions, setPositions] = useState(globalPositions);
  // Position of the placeholders at the start of the interaction
  const [startingPositions, setStartingPositions] = useState(positions);

  useEffect(() => {
    setPositions(globalPositions);
  }, [globalPositions]);

  const eventTarget = useThreeEventTarget();

  const groupRef = useRef<Group>(null);
  const pathRef = useRef<Group>(null);
  const hoveredId = useRef<number>();
  const [draggingId, setDraggingId] = useState<number>();
  const startPos = useRef(new Vector2());
  const endPos = useRef(new Vector2());
  const maxDelta = useRef(0);

  /** Returns the hovered placeholder index for a given event */
  const getPlaceholderIdForEvent = useCallback(
    (e: PointerEvent | MouseEvent) => {
      if (pathRef.current) {
        raycaster.setFromCamera(computePointerNdcCoordinates(e), camera);
        return raycastOnPlaceholdersGroup(raycaster, pathRef.current);
      }
    },
    [camera, raycaster],
  );

  // Set up the initial state of the interaction (mouse position,
  // position of the placeholders)
  const onPointerDown = useCallback(
    (e: PointerEvent) => {
      if (!enabled) return;
      e.stopPropagation();
      // Track the pointer to get the "up" event when leaving the placeholder
      if (e.target instanceof HTMLElement) {
        e.target.setPointerCapture(e.pointerId);
      }
      // Initialize the interaction
      startPos.current.set(e.clientX, e.clientY);
      maxDelta.current = 0;

      let newDraggingId;
      if (hoveredId.current) {
        newDraggingId = hoveredId.current;
      } else if (pathRef.current) {
        newDraggingId = getPlaceholderIdForEvent(e);
      }

      setStartingPositions(positions);

      setDraggingId(newDraggingId);
      onDragging(newDraggingId !== undefined);
    },
    [enabled, getPlaceholderIdForEvent, onDragging, positions],
  );

  /** Finalize the interaction by synchronizing the new positions with the store */
  const onPointerUp = useCallback(
    (e: PointerEvent) => {
      if (!enabled) return;
      endPos.current.set(e.clientX, e.clientY);
      // If we were dragging something, flag the placeholder as "locked"
      if (draggingId !== undefined) {
        e.stopPropagation();

        const newLocked = locked.slice();
        newLocked[draggingId] = true;

        // Synchronize the new positions in the store
        setGlobalPositions(positions, newLocked);
      }
      setDraggingId(undefined);
      onDragging(false);
    },
    [draggingId, enabled, locked, onDragging, positions, setGlobalPositions],
  );

  /** Update the positions of the placeholders if the user is dragging one of them */
  const onPointerMove = useCallback(
    (e: PointerEvent) => {
      if (!enabled) return;
      if (draggingId !== undefined) {
        e.stopPropagation();

        // If the mouse didn't move enough from the intial position, we don't do anything
        // This is necessary to correctly behave on click events
        const delta = startPos.current.distanceToSquared(
          TEMP_VEC2.set(e.clientX, e.clientY),
        );
        if (
          delta <= MAX_PIXEL_DISTANCE_SQUARED &&
          maxDelta.current <= MAX_PIXEL_DISTANCE_SQUARED
        ) {
          return;
        }
        maxDelta.current = Math.max(delta, maxDelta.current);

        // If delta is less than a predefined threshold, snap the placeholder to
        // its own starting position
        if (delta <= MIN_PIXEL_SNAPPING_SQUARED) {
          TEMP_VEC3.copy(startingPositions[draggingId]);
        } else {
          const rect = eventTarget.getBoundingClientRect();
          TEMP_VEC3.set(
            2 * ((e.clientX - rect.x) / rect.width) - 1,
            1 - 2 * ((e.clientY - rect.y) / rect.height),
            0,
          );
          TEMP_VEC3.unproject(camera);
          // Compute points coordinate relative to the global pose of the path
          TEMP_VEC3.applyMatrix4(poseInverse);
        }

        // Find the start and end index by searching from the dragged point for the next locked point
        // or the end of the array.
        let startIndex = Math.max(draggingId - 1, 0);
        for (; startIndex > 0; startIndex--) {
          if (locked[startIndex]) {
            break;
          }
        }
        let endIndex = Math.min(draggingId + 1, locked.length - 1);
        for (; endIndex < locked.length - 1; endIndex++) {
          if (locked[endIndex]) {
            break;
          }
        }

        TEMP_VEC3.y = positions[draggingId].y;
        const correctPoints = correctWaypointPositions(
          positions.slice(startIndex, endIndex + 1),
          draggingId - startIndex,
          TEMP_VEC3,
        );
        const newPoints = positions.slice();
        for (let i = startIndex; i < endIndex + 1; ++i) {
          newPoints[i] = correctPoints[i - startIndex];
        }
        setPositions(newPoints);

        // Lock the waypoint as soon as a proper drag is detected
        if (
          !locked[draggingId] &&
          maxDelta.current > MAX_PIXEL_DISTANCE_SQUARED
        ) {
          const newLocked = locked.slice();
          newLocked[draggingId] = true;
          setLocked(newLocked);
        }
      }
    },
    [
      enabled,
      locked,
      positions,
      startingPositions,
      eventTarget,
      poseInverse,
      camera,
      setLocked,
      draggingId,
    ],
  );

  /** Toggle the stationary state for one placholder */
  const onClick = useCallback(
    (e: MouseEvent) => {
      if (!enabled) return;
      if (maxDelta.current > MAX_PIXEL_DISTANCE_SQUARED) return;

      const clickedId = hoveredId.current ?? getPlaceholderIdForEvent(e);
      if (clickedId !== undefined) {
        const newLocked = locked.slice();
        newLocked[clickedId] = !newLocked[clickedId];
        setLocked(newLocked);
      }
    },
    [enabled, getPlaceholderIdForEvent, locked, setLocked],
  );

  useEffect(() => {
    if (enabled) {
      eventTarget.addEventListener("pointerdown", onPointerDown);
      eventTarget.addEventListener("pointerup", onPointerUp);
      eventTarget.addEventListener("pointermove", onPointerMove);
      eventTarget.addEventListener("click", onClick);
    }
    return () => {
      eventTarget.removeEventListener("pointerdown", onPointerDown);
      eventTarget.removeEventListener("pointerup", onPointerUp);
      eventTarget.removeEventListener("pointermove", onPointerMove);
      eventTarget.removeEventListener("click", onClick);
    };
  }, [
    enabled,
    eventTarget,
    onClick,
    onPointerDown,
    onPointerMove,
    onPointerUp,
  ]);

  useCustomCanvasCursor({
    enable: enabled && !!draggingId,
    cursorToShow: CursorStyle.translation,
  });

  return (
    <group ref={groupRef}>
      {positions.length > 0 && (
        <PathRenderer
          ref={pathRef}
          segmentFactor={draggingId === undefined ? POINTS_SEGMENT_FACTOR : 5}
          positions={positions}
          stencilRef={STENCIL_REF + 2}
          isActive={true}
          onHoveredPlaceholderChanged={(index) => {
            hoveredId.current = index;
            onPlaceholderHovered?.(index);
          }}
          locked={locked}
          onPlaceholderClick={(index) => {
            if (groupRef.current) {
              onPlaceholderClick(
                groupRef.current.localToWorld(positions[index].clone()),
              );
            }
          }}
        />
      )}
      {draggingId !== undefined && (
        <StationaryPath positions={startingPositions} />
      )}
    </group>
  );
}
