import {
  selectCloudCrossSectionEnabled,
  selectCloudToCadAlignmentCloudElevation,
  selectCloudToCadAlignmentModelElevation,
  selectCloudToCadAlignmentSplitDirection,
  selectCloudToCadAlignmentStep,
  selectModelCrossSectionEnabled,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  CloudToCadAlignmentStep,
  setCloudCrossSectionEnabled,
  setCloudToCadAlignmentSplitDirection,
  setModelCrossSectionEnabled,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { SplitDirection } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { neutral, ViewDiv } from "@faro-lotv/app-component-toolbox";
import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useSplitDirection } from "../align-to-cad-commons/align-to-cad-utils";
import { CrossSectionToggleButton } from "../align-to-cad-commons/cross-section-toggle-button";
import { ScreenSplitDirectionToggle } from "../align-to-cad-commons/screen-split-direction-toggle";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { ModelElevationOverlay } from "./model-elevation-overlay";

/**
 * @returns Renderer of split screen for aligning cloud data with CAD models.
 *
 * This component sets up a side-by-side view with two `ViewDiv` components, each managed by `useOverlayRef`
 * to handle references for interactions. It occupies the full viewport using MUI's `Stack` component.
 *
 * For developers:
 * - `useOverlayElements` retrieves the context to manage screen overlays.
 * - `useOverlayRef` creates and registers the references for the two screens.
 * - Use `ViewDiv` to utilize full-screen space for content.
 *
 * For end users:
 * - Provides a seamless side-by-side comparison of point cloud data and CAD models.
 * - Facilitates accurate alignment with full viewport visibility and interaction.
 * - Users can manipulate and view both data sources simultaneously for effective comparison.
 */
export function CloudToCadAlignmentSplitScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { setFirstScreen, setSecondScreen } = useOverlayElements();
  const firstScreenRef = useOverlayRef(setFirstScreen);
  const secondScreenRef = useOverlayRef(setSecondScreen);

  const alignmentStep = useAppSelector(selectCloudToCadAlignmentStep);
  const alignmentSplitDirection = useAppSelector(
    selectCloudToCadAlignmentSplitDirection,
  );
  // in the first step, always split into left and right
  const splitDirection = useMemo(() => {
    return alignmentStep === CloudToCadAlignmentStep.setElevations
      ? SplitDirection.horizontalSplit
      : alignmentSplitDirection;
  }, [alignmentSplitDirection, alignmentStep]);

  const isModelSectionEnabled = useAppSelector(selectModelCrossSectionEnabled);
  const isCloudSectionEnabled = useAppSelector(selectCloudCrossSectionEnabled);

  const modelElevation = useAppSelector(
    selectCloudToCadAlignmentModelElevation,
  );
  const cloudElevation = useAppSelector(
    selectCloudToCadAlignmentCloudElevation,
  );

  // callback function for changing split-screen direction in the second step
  const changeSplitDirection = useCallback(() => {
    const newDirection =
      splitDirection === SplitDirection.horizontalSplit
        ? SplitDirection.verticalSplit
        : SplitDirection.horizontalSplit;
    dispatch(setCloudToCadAlignmentSplitDirection(newDirection));
  }, [dispatch, splitDirection]);

  const { width, height } = useSplitDirection(splitDirection);

  return (
    <Stack
      direction={
        splitDirection === SplitDirection.horizontalSplit ? "row" : "column"
      }
      justifyItems="stretch"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <ViewDiv
        eventDivRef={firstScreenRef}
        sx={{
          height: { height },
          width: { width },
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {alignmentStep === CloudToCadAlignmentStep.setElevations && (
          <ModelElevationOverlay />
        )}
        {alignmentStep === CloudToCadAlignmentStep.alignIn2d && (
          <CrossSectionToggleButton
            isEnabled={isModelSectionEnabled}
            elevation={modelElevation}
            onToggleCrossSections={() => {
              dispatch(setModelCrossSectionEnabled(!isModelSectionEnabled));
            }}
          />
        )}
      </ViewDiv>

      <ViewDiv
        eventDivRef={secondScreenRef}
        sx={{
          height: { height },
          width: { width },
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {alignmentStep === CloudToCadAlignmentStep.alignIn2d && (
          <CrossSectionToggleButton
            isEnabled={isCloudSectionEnabled}
            elevation={cloudElevation}
            onToggleCrossSections={() => {
              dispatch(setCloudCrossSectionEnabled(!isCloudSectionEnabled));
            }}
          />
        )}
      </ViewDiv>
      {alignmentStep === CloudToCadAlignmentStep.alignIn2d && (
        <ScreenSplitDirectionToggle
          splitDirection={splitDirection}
          onClick={changeSplitDirection}
        />
      )}
    </Stack>
  );
}
