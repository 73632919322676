import { RootState } from "@/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/** State specific for the clipping box mode */
type ClippingBoxModeState = {
  /** True if the help banner should be visible */
  hasUserInteractedWithBoxControls: boolean;
};

const initialState: ClippingBoxModeState = {
  hasUserInteractedWithBoxControls: false,
};

export const clippingBoxModeSlice = createSlice({
  initialState,
  name: "clippingBoxMode",
  reducers: {
    setHasUserInteractedWithBoxControls(state, action: PayloadAction<boolean>) {
      state.hasUserInteractedWithBoxControls = action.payload;
    },
  },
});

export const { setHasUserInteractedWithBoxControls } =
  clippingBoxModeSlice.actions;

export const clippingBoxModeReducer = clippingBoxModeSlice.reducer;

/**
 * @returns if the help banner should be visible
 */
export function selectHasUserInteractedWithBoxControls({
  clippingBoxMode,
}: RootState): boolean {
  return clippingBoxMode.hasUserInteractedWithBoxControls;
}
