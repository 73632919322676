import {
  TREE_NODE_HEIGHT,
  TreeNode,
  TreeNodeProps,
} from "@/components/ui/tree/tree-node";
import { TreeWrapper } from "@/components/ui/tree/tree-wrapper";
import { ScanIcon, TruncatedFaroText } from "@faro-lotv/flat-ui";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { Stack } from "@mui/system";
import { Tree } from "react-arborist";

type ScanListProps = {
  /** The scans to show in the list. */
  scanEntities: RevisionScanEntity[];
};

/** @returns A list of scans. */
export function ScanList({ scanEntities }: ScanListProps): JSX.Element {
  // TODO: This tree view is only used to display a simple list for now with the correct styles.
  // Soon more layers in the tree will be implemented.
  // see https://faro01.atlassian.net/browse/NRT-1233
  return (
    <TreeWrapper>
      <Tree
        data={scanEntities}
        width="100%"
        disableDrag
        disableDrop
        rowHeight={TREE_NODE_HEIGHT}
        indent={2}
        disableMultiSelection
      >
        {ScanListNode}
      </Tree>
    </TreeWrapper>
  );
}

function ScanListNode({
  node,
}: TreeNodeProps<RevisionScanEntity>): JSX.Element {
  return (
    <TreeNode<RevisionScanEntity>
      node={node}
      isSelectable={false}
      shouldExpandNodeOnClick={false}
    >
      <Stack direction="row" alignItems="center" gap={1} minWidth={0}>
        <ScanIcon sx={{ fontSize: "1.125rem" }} />
        <TruncatedFaroText variant="bodyM">{node.data.name}</TruncatedFaroText>
      </Stack>
    </TreeNode>
  );
}
