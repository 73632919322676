import { useAppSelector } from "@/store/store-hooks";
import {
  selectRootIElement,
  useLocalStorage,
} from "@faro-lotv/app-component-toolbox";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";

export type UseUnitOfMeasureReturn = {
  /** Current selected unit of measure to use */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Function to change the unit of measure to use */
  setUnitOfMeasure(unit: SupportedUnitsOfMeasure): void;
};

/**
 * Access the session configured unit of measure to use for labels
 *
 * @param defaultUnit the unit to use if nothing is in the session store
 * @returns The unit of measure to use an a support function to update the session store
 */
export function useUnitOfMeasure(
  defaultUnit: SupportedUnitsOfMeasure,
): UseUnitOfMeasureReturn {
  const [unitOfMeasure, setUnitOfMeasure] = useLocalStorage(
    "unitOfMeasure",
    defaultUnit,
  );

  return {
    unitOfMeasure,
    setUnitOfMeasure,
  };
}

/**
 * @returns The current unit of measure of the project, if defined in the project root
 */
export function useProjectUnitOfMeasure(): UseUnitOfMeasureReturn {
  const root = useAppSelector(selectRootIElement);
  return useUnitOfMeasure(
    root?.metaDataMap?.projectSettings?.unitSystem ?? "metric",
  );
}
