import { CameraAnimationProps } from "@/components/r3f/animations/camera-animation";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { CadModelObject, PointCloudObject, SheetObject } from "@/object-cache";
import {
  selectOverviewIsCadSelected,
  selectOverviewIsPointCloudSelected,
} from "@/store/modes/overview-mode-slice";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { selectPanoCameraTransform } from "@/utils/camera-transform";
import {
  getSheetCenter,
  getSheetDiagonal,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { clamp } from "@faro-lotv/lotv";
import {
  CachedWorldTransform,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { useCallback, useMemo, useRef, useState } from "react";
import { Box3, Frustum, Matrix4, Object3D, Quaternion, Vector3 } from "three";
import { ModeWithSceneFilterInitialState } from "../mode";

/** Half-size of the framing box if there's only one pano or all panos are really close together*/
const BOUNDING_BOX_SIZE = 30;

/** lower bound of the camera far plane setting (100m)*/
const FAR_PLANE_LOWER_BOUND = 100;

/** upper bound of the camera far plane setting (10km)*/
const FAR_PLANE_UPPER_BOUND = 10000;

type UseUpdateOverviewCameraProps = {
  /** Current rendered sheet image */
  sheet: SheetObject;

  /** Current rendered point cloud if available */
  pointCloud: PointCloudObject | null;

  /** Current rendered cad model if available */
  cad: CadModelObject | null;

  /** The list of scans in the current area */
  panos: IElementImg360[];

  /** The initial state from the deep link if available */
  initialState?: ModeWithSceneFilterInitialState;
};

type UseUpdateOverviewCameraReturn = {
  /** The camera target to use for the controls pivot */
  target?: Vector3;

  /** A camera animation to apply to optimize the camera position for the current data*/
  cameraAnimation?: CameraAnimationProps;

  /** A callback to notify the camera animation finished and it can be cleaned up */
  resetCameraAnimation(): void;

  /** The model bounding box */
  modelBox?: Box3;
};

/**
 * Defines the desired camera position, rotation and pivot depending on how the current scene changes in 3d overview
 *
 * If the camera position is outside of the scene, place the camera in ad advantage position and the pivot at the center of the scene
 * If the camera is inside the current scene, leave it without changes
 *
 * @returns the target and camera animation to keep the camera and controls updated to the current scene
 */
export function useUpdateOverviewCamera({
  sheet,
  pointCloud,
  cad,
  panos,
  initialState,
}: UseUpdateOverviewCameraProps): UseUpdateOverviewCameraReturn {
  const camera = useThree((s) => s.camera);

  // Compute the current scene volume information and the target pivot and camera
  const sheetTransform = useAppSelector(
    selectIElementWorldTransform(sheet.iElement.id),
  );
  const model3dBox = useCurrentModelBoundingBox(pointCloud, cad);

  const store = useAppStore();
  const panoBox = useMemo(() => {
    const state = store.getState();
    const box = new Box3();
    const TEMP_VEC3 = new Vector3();
    panos.forEach((pano) => {
      const position = TEMP_VEC3.fromArray(
        selectPanoCameraTransform(pano, sheet.iElement)(state).position,
      );
      box.expandByPoint(position);
    });
    if (box.isEmpty()) return;

    const size = box.getSize(TEMP_VEC3);
    if (size.x < Number.EPSILON || size.z < Number.EPSILON) {
      const center = box.getCenter(TEMP_VEC3);
      box.min.copy(center).subScalar(BOUNDING_BOX_SIZE);
      box.max.copy(center).addScalar(BOUNDING_BOX_SIZE);
    }
    return box;
  }, [panos, sheet.iElement, store]);

  const modelBox = panoBox ?? model3dBox;
  const targetPivot = useOverviewTarget(
    modelBox,
    sheet.iElement,
    sheetTransform,
    initialState,
  );
  const targetCamera = useOverviewCameraPosition(
    targetPivot,
    modelBox,
    sheet.iElement,
    sheetTransform,
    initialState,
  );
  const [cameraAnimation, setCameraAnimation] = useState<
    CameraAnimationProps | undefined
  >(targetCamera);
  const [target, setTarget] = useState<Vector3 | undefined>(targetPivot);

  // adjust camera far plane in case that the model box is too large (up to 10km)
  if (modelBox) {
    camera.far = clamp(
      Math.max(camera.far, 2 * modelBox.max.distanceTo(modelBox.min)),
      FAR_PLANE_LOWER_BOUND,
      FAR_PLANE_UPPER_BOUND,
    );
  }

  // Update the pivot and camera if the new scene is not in the camera frustum
  useNonExhaustiveEffect(() => {
    if (!modelBox) return;
    const frustum = new Frustum();
    frustum.setFromProjectionMatrix(
      new Matrix4().multiplyMatrices(
        camera.projectionMatrix,
        camera.matrixWorldInverse,
      ),
    );
    if (frustum.intersectsBox(modelBox)) return;
    setCameraAnimation(targetCamera);
    setTarget(targetPivot);
  }, [targetCamera, targetPivot]);

  const resetCameraAnimation = useCallback(() => {
    setCameraAnimation(undefined);
  }, []);

  return {
    target,
    cameraAnimation,
    resetCameraAnimation,
    modelBox,
  };
}

/**
 * @param pointCloud in the scene
 * @param cad in the scene
 * @returns the bounding box containing the pointCloud and or cad depending on the current user selected scene
 */
function useCurrentModelBoundingBox(
  pointCloud: PointCloudObject | null,
  cad: CadModelObject | null | Error,
): Box3 | undefined {
  const cloudSelected = useAppSelector(selectOverviewIsPointCloudSelected);
  const cadSelected = useAppSelector(selectOverviewIsCadSelected);

  const cloudBox = useObjectBoundingBox(pointCloud, pointCloud?.iElement.id);
  const validCad = cad instanceof Error ? null : cad;
  const cadBox = useObjectBoundingBox(validCad, validCad?.iElement.id);

  return useMemo(() => {
    let box: Box3 | undefined = undefined;
    if (cloudSelected && cloudBox) {
      box = cloudBox.clone();
    }
    if (cadSelected && cadBox) {
      if (box) {
        box.union(cadBox);
      } else {
        box = cadBox.clone();
      }
    }
    return box;
  }, [cadBox, cadSelected, cloudBox, cloudSelected]);
}

/**
 * @param modelBox bounding box of the current active scene models
 * @param sheet rendered in the current scene
 * @param sheetTransform from the data model
 * @param initialState defined by the deep link
 * @returns the best pivot to use to look at the scene
 */
function useOverviewTarget(
  modelBox: Box3 | undefined,
  sheet: IElementGenericImgSheet,
  sheetTransform: CachedWorldTransform,
  initialState?: ModeWithSceneFilterInitialState,
): Vector3 {
  // Extract the target from the initialState if defined
  const initialTarget = useRef<Vector3 | undefined>(
    initialState && initialState.camera
      ? new Vector3().fromArray(initialState.camera.target)
      : undefined,
  );

  return useMemo(() => {
    // If we have a target from the initialState that overrides the first target
    if (initialTarget.current) {
      const target = initialTarget.current;
      initialTarget.current = undefined;
      return target;
    }
    // If we have a valid model use the center of the bounding box of the model
    if (modelBox) {
      return modelBox.getCenter(new Vector3());
    }
    // Fallback to the center of the sheet
    return getSheetCenter(
      sheet,
      new Matrix4().fromArray(sheetTransform.worldMatrix),
    );
  }, [modelBox, sheet, sheetTransform.worldMatrix]);
}

/**
 * @param target to center in front of the camera
 * @param modelBox bounding box of the current active scene models
 * @param sheet rendered in the current scene
 * @param sheetTransform from the data model
 * @param initialState defined by the deep link
 * @returns the best position and rotation for the camera to get an overview on the scene
 */
function useOverviewCameraPosition(
  target: Vector3 | undefined,
  modelBox: Box3 | undefined,
  sheet: IElementGenericImgSheet,
  sheetTransform: CachedWorldTransform,
  initialState?: ModeWithSceneFilterInitialState,
): CameraAnimationProps | undefined {
  // Extract the desired initial camera position from the initialState
  const initialPosition = useRef(initialState?.camera?.pos);

  // Use the model box half diagonal if available if not fallback to the sheet half diagonal
  const distance =
    (modelBox?.max.distanceTo(modelBox.min) ??
      getSheetDiagonal(sheet, sheetTransform.scale)) / 2;

  return useMemo(() => {
    // A position from the initialState overrides any scene logic the first time
    if (initialPosition.current) {
      const position = new Vector3().fromArray(initialPosition.current);
      initialPosition.current = undefined;
      return { position };
    }

    // Don't move the camera without a valid target to center
    if (!target) return;

    // Move the camera a little up so we can see the scene from an angle
    const dir = new Vector3(target.x, 0, target.z)
      .normalize()
      .multiplyScalar(distance);

    const position = new Vector3(
      target.x - dir.x,
      target.y - dir.y + distance,
      target.z - dir.z,
    );
    return {
      position,
      quaternion: computeLookAtQuaternion(position, target),
    };
  }, [distance, target]);
}

/**
 * @param position of the camera
 * @param target to center
 * @returns the quaternion to rotate the camera toward the target
 */
function computeLookAtQuaternion(
  position: Vector3,
  target: Vector3,
): Quaternion {
  return new Quaternion().setFromRotationMatrix(
    new Matrix4().lookAt(position, target, Object3D.DEFAULT_UP),
  );
}
