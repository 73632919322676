import { ModeNames } from "@/modes/mode";
import { RootState } from "./store";

/**
 * @returns the name of the current active mode
 * @param root The root state
 */
export function selectModeName(root: RootState): ModeNames {
  return root.mode.name;
}

/**
 * @returns true if the mode is suspended to load new data
 * @param root The root state
 */
export function selectModeIsLoadingData(root: RootState): boolean {
  return root.mode.isLoadingData;
}

/**
 * @returns true if the mode is suspended to load new data
 * @param root The root state
 */
export function selectModeIsTransitioning(root: RootState): boolean {
  return root.mode.isTransitioning;
}
