import { useObjectView } from "@/hooks/use-object-view";
import { PanoObject, useCached3DObject } from "@/object-cache";
import {
  CameraAnimationTimeOptions,
  getCameraAnimationTime,
} from "@/utils/camera-animation-time";
import { usePanoCameraTransform } from "@/utils/camera-transform";
import {
  DollhouseAnimation,
  UniformLights,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { Easing } from "@faro-lotv/foundation";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { Quaternion, useThree } from "@react-three/fiber";
import { useReducer, useState } from "react";
import { PanoramaRenderer } from "../renderers/panorama-renderer";
import { CameraAnimation } from "./camera-animation";

export type PanoPanoAnimation = {
  /** The panorama to render */
  pano: PanoObject;

  /** if provided quaternion should be applied while performing pano animation */
  targetQuaternion?: Quaternion;

  /** The current active img sheet, used to compute pano eye position for panos without a valid height */
  sheet: IElementGenericImgSheet;

  /** The panorama to transition to */
  targetElement: IElementImg360;

  /**
   * Define to true to register this as a secondary view for split screen rendering
   *
   * @default false
   */
  isSecondaryView?: boolean;

  /** Callback to signal the animation was completed */
  onCompleted(targetElement: PanoObject): void;
};

/** Timings to use for 2d pano pano animation without a dollhouse */
const ANIMATION_TIME_2D: CameraAnimationTimeOptions = {
  min: 0.5,
  max: 1,
  scale: 10,
};

/**
 * @returns A scene to animate between two panorama images in PanoramaMode
 */
export function PanoPanoAnimation({
  pano,
  targetQuaternion,
  sheet,
  targetElement,
  isSecondaryView = false,
  onCompleted,
}: PanoPanoAnimation): JSX.Element {
  const camera = useThree((s) => s.camera);
  const targetPosition = usePanoCameraTransform(targetElement, sheet);

  const target = useCached3DObject(targetElement);

  // Prepare views for source and target panos so we don't steal them from a different
  // view if they're in use
  const sourceView = useObjectView(pano);
  const targetView = useObjectView(target);

  // Use the original pano as the source for the animation only if this is the main view
  // this remove one black flash at the end of the animation to re-adjust the original pano
  // opacity
  const sourceToUse = isSecondaryView ? sourceView : pano;

  // Duration should not be revaluated, because it could change
  // when the camera position changes
  const [duration] = useState(() =>
    getCameraAnimationTime(camera, targetPosition.position, ANIMATION_TIME_2D),
  );

  // In this case, useReducer is preferred over useState because returns a function
  // that can be used directly in the callbacks of the animations,
  // without the need to call useCallback and wrap them.
  const [isPanoAnimationFinished, onPanoAnimationFinished] = useReducer(
    () => true,
    false,
  );
  const [isCameraAnimationFinished, onCameraAnimationFinished] = useReducer(
    () => true,
    false,
  );

  // use non-exhaustive effect to make sure the callback only runs once
  useNonExhaustiveEffect(() => {
    // Once both animations finished, call the onCompleted callback
    if (isPanoAnimationFinished && isCameraAnimationFinished) {
      onCompleted(target);
    }
  }, [isCameraAnimationFinished, isPanoAnimationFinished]);

  return (
    <>
      {/* Make sure the pano and doolhouse position are computed correctly during the animation */}
      <group visible={false}>
        <PanoramaRenderer pano={sourceToUse} sheet={sheet} />
        <PanoramaRenderer pano={targetView} sheet={sheet} />
      </group>
      <DollhouseAnimation
        from={sourceToUse}
        to={targetView}
        dollhouse={undefined}
        camera={camera}
        duration={duration}
        easing={Easing.linear}
        onCompleted={onPanoAnimationFinished}
      />
      <UniformLights />
      <CameraAnimation
        position={targetPosition.position}
        quaternion={targetQuaternion ?? camera.quaternion}
        duration={duration}
        onAnimationFinished={onCameraAnimationFinished}
      />
    </>
  );
}
