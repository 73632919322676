import { useAppSelector } from "@/store/store-hooks";
import { selectIElementFloorPosition } from "@/utils/camera-transform";
import {
  roundedCalculatePosition,
  selectAncestor,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { FontWeights } from "@faro-lotv/flat-ui";
import { convertToDateString } from "@faro-lotv/foundation";
import {
  IElement,
  IElementImg360,
  IElementTypeHint,
  isIElementSection,
  isIElementTimeseries,
} from "@faro-lotv/ielement-types";
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Fade,
  Popper,
  Skeleton,
  useTheme,
} from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Vector3, Vector3Tuple } from "three";
import { AppAwareHtml } from "../renderers/app-aware-html";

/** The height of the preview image in px */
const PREVIEW_IMAGE_HEIGHT = 180;

/** The offset that needs to be applies for popper from its anchor element in px */
const POPPER_OFFSET = 24;

type Props = {
  /** The placeholder Img360 which is currently being hovered */
  placeholder?: IElementImg360;

  /** Position at which the placeholder preview should appear */
  position?: Vector3;
};

/**
 * @param placeholder - waypoint placeholder
 * @returns element to identify waypoint name for different types of scans (E57, Focus scan, Orbis)
 */
function useWaypointReference(
  placeholder: IElementImg360 | undefined,
): IElement | undefined {
  let referenceElement: IElement | undefined = placeholder;
  const refElementParent = useAppSelector(
    selectIElement(referenceElement?.parentId),
  );

  // name to be displayed should be name of room section, which is parent of time series
  const placeholderTimeSeries = useAppSelector(
    selectAncestor(placeholder, isIElementTimeseries),
  );

  // Use the parent section for name/date if the 360s is not part of a path
  const placeholderSection = useAppSelector(
    selectAncestor(placeholderTimeSeries, isIElementSection),
  );

  if (
    refElementParent &&
    isIElementSection(refElementParent) &&
    (refElementParent.typeHint === IElementTypeHint.structuredE57 ||
      refElementParent.typeHint === IElementTypeHint.focusScan)
  ) {
    referenceElement = refElementParent;
  } else {
    referenceElement =
      placeholder?.typeHint === IElementTypeHint.odometryPath
        ? placeholder
        : placeholderSection;
  }

  return referenceElement;
}

/**
 * @returns  A dialog that displays a preview of an Img360 placeholder on hover,
 * while handling warm-up and cool-down transitions. The warm-up transition
 * introduces a delay before showing the preview window upon hovering,
 * ensuring a smooth and deliberate interaction. The cool-down transition
 * introduces a delay before hiding the preview window after moving the
 * pointer away, preventing flickering or abrupt disappearance.
 *
 * These transitions contribute to a better user experience by providing a
 * subtle delay for previewing and dismissing the content, reducing visual
 * noise and creating a more polished interaction.
 */
export function PlaceholderPreview({
  placeholder,
  position: customPosition,
}: Props): JSX.Element | null {
  const theme = useTheme();

  /**
   * The duration used for both fade in/out of window as well as the warm up and cool down time to make the window visible/invisible
   * i.e handles the delay between pointer hover and the popup visibility both while hovering in (warm-up) and hovering out (cool-down)
   */
  const transitionDuration = theme.transitions.duration.shorter;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [isPreviewLoading, setIsPreviewImageLoading] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [cachedPlaceholder, setCachedPlaceholder] = useState<IElementImg360>();
  const [cachedPosition, setCachedPosition] = useState<
    Vector3 | Vector3Tuple
  >();

  const referenceElement = useWaypointReference(cachedPlaceholder);

  const position = useAppSelector(
    (state) =>
      // use the floor position to avoid the placeholder being placed out of view in (video mode) paths with large vertical components
      cachedPlaceholder
        ? selectIElementFloorPosition(cachedPlaceholder)(state)
        : undefined,
    isEqual,
  );

  useEffect(() => {
    setCachedPosition(customPosition ?? position);
  }, [customPosition, position]);

  const onPointerInDebounced = useRef<ReturnType<typeof setTimeout>>();
  const onPointerIn = useCallback(() => {
    clearTimeout(onPointerInDebounced.current);

    onPointerInDebounced.current = setTimeout(() => {
      setIsHovering(true);
      setCachedPlaceholder(placeholder);
    }, transitionDuration);
  }, [placeholder, transitionDuration]);

  const onPointerOutDebounced = useRef<ReturnType<typeof setTimeout>>();
  const onPointerOut = useCallback(() => {
    clearTimeout(onPointerOutDebounced.current);

    // isHovering is made false so that the fade out animation can start
    setIsHovering(false);

    onPointerOutDebounced.current = setTimeout(() => {
      setIsPreviewImageLoading(true);
      setCachedPlaceholder(undefined);
      setCachedPosition(undefined);
    }, transitionDuration);
  }, [transitionDuration]);

  useEffect(() => {
    if (placeholder) {
      onPointerIn();
    } else if (cachedPlaceholder) {
      onPointerOut();
    } else {
      // The user has hovered over the placeholder too quickly, so cancel the onPointerInDebounced
      clearTimeout(onPointerInDebounced.current);
    }
  }, [cachedPlaceholder, onPointerIn, onPointerOut, placeholder]);

  if (!cachedPlaceholder || !cachedPosition || !referenceElement) return null;

  const imageUri =
    cachedPlaceholder.thumbnailUri ??
    JSON.parse(cachedPlaceholder.json1x1)?.sources[0] ??
    cachedPlaceholder.uri;

  return (
    <AppAwareHtml
      position={cachedPosition}
      calculatePosition={roundedCalculatePosition}
      style={{
        backdropFilter: "blur(4px) brightness(40%)",
        borderRadius: 6,
      }}
    >
      {/* anchor element where the popper attaches to */}
      <Box component="div" ref={setAnchorEl} />
      {anchorEl && (
        <Fade in={isHovering} timeout={transitionDuration}>
          <Popper
            open
            placement="bottom"
            disablePortal={true}
            anchorEl={anchorEl}
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, POPPER_OFFSET],
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
              {
                name: "arrow",
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
              {
                name: "applyArrowHide",
                enabled: true,
                phase: "write",
                fn({ state }) {
                  if (arrowRef) {
                    if (state.placement === "bottom") {
                      arrowRef.style.visibility = "visible";
                    } else {
                      // Hide the arrow if the popper can't be displayed below the anchor
                      arrowRef.style.visibility = "hidden";
                    }
                  }
                },
              },
            ]}
            sx={{ pointerEvents: "none" }}
          >
            {/* arrow element */}
            <Box
              component="span"
              ref={setArrowRef}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                "&::after": {
                  backgroundColor: theme.palette.gray950,
                  borderTopLeftRadius: 4,
                  content: '""',
                  position: "absolute",
                  width: 12,
                  height: 12,
                  top: -6,
                  transform: "rotate(45deg)",
                },
              }}
            />
            <Card sx={{ minWidth: 400, background: theme.palette.gray950 }}>
              <CardHeader
                title={
                  referenceElement.typeHint === IElementTypeHint.odometryPath
                    ? ""
                    : referenceElement.name
                }
                subheader={convertToDateString(referenceElement.createdAt)}
                sx={{
                  ".MuiCardHeader": {
                    "&-content": {
                      fontSize: "1rem",
                      display: "flex",
                      fontWeight: 600,
                      color: "white",
                      justifyContent: "space-between",
                      alignItems: "center",
                    },
                    "&-title": {
                      fontSize: "1rem",
                    },
                    "&-subheader": {
                      fontSize: "0.75rem",
                      color: theme.palette.gray200,
                      fontWeight: FontWeights.Bold,
                      textTransform: "uppercase",
                    },
                  },
                }}
              />

              {isPreviewLoading && (
                <Box component="div" height={PREVIEW_IMAGE_HEIGHT}>
                  <Skeleton
                    variant="rectangular"
                    height="100%"
                    sx={{ background: theme.palette.gray800 }}
                  />
                </Box>
              )}

              <CardMedia
                component="img"
                height={isPreviewLoading ? 0 : PREVIEW_IMAGE_HEIGHT}
                width="100%"
                onLoad={() => setIsPreviewImageLoading(false)}
                onError={() => setIsPreviewImageLoading(false)}
                image={imageUri}
                sx={{
                  objectFit: "fill",
                }}
              />
            </Card>
          </Popper>
        </Fade>
      )}
    </AppAwareHtml>
  );
}
