import { degToRad } from "@faro-lotv/lotv";
import { OrthographicCamera, PerspectiveCamera, Vector3 } from "three";

/**
 * Modifies the position and rotation of a perspective camera to match an orthographic view.
 * The camera is placed, such that the perspective plane at the reference point remains at the same size.
 *
 * @param perspCamera the target perspective camera
 * @param orthoCamera the source orthographic camera
 * @param referencePoint a point that lies on the plane that should be in focus
 * @returns the reference point projected into the camera center. Use e.g. to set the pivot of orbit controls.
 */
export function framePerspectiveCameraFromOrthoView(
  perspCamera: PerspectiveCamera,
  orthoCamera: OrthographicCamera,
  referencePoint: Vector3,
): Vector3 {
  const targetAtCameraCenter = referencePoint
    .clone()
    .project(orthoCamera)
    .setX(0)
    .setY(0)
    .unproject(orthoCamera);

  const orthoHeight = (orthoCamera.top - orthoCamera.bottom) / orthoCamera.zoom;

  const cameraDistance = calculateCameraDistanceToFrameSize(
    perspCamera.getEffectiveFOV(),
    orthoHeight,
  );

  perspCamera.quaternion.copy(orthoCamera.quaternion);

  // Place camera cameraDistance away from pivot
  perspCamera.position.copy(
    targetAtCameraCenter
      .clone()
      .add(
        perspCamera
          .getWorldDirection(new Vector3())
          .multiplyScalar(-cameraDistance),
      ),
  );

  return targetAtCameraCenter;
}

/**
 * Modifies an orthographic camera to match a perspective view.
 * The orthographic camera is placed and scaled, such that the perspective plane at the reference point remains at the same size.
 *
 * @param orthoCamera the target orthographic camera
 * @param perspCamera the source perspective camera
 * @param referencePoint a point that lies on the plane that should be in focus
 */
export function frameOrthoCameraFromPerspectiveView(
  orthoCamera: OrthographicCamera,
  perspCamera: PerspectiveCamera,
  referencePoint: Vector3,
): void {
  const referencePointInCameraPlane = referencePoint
    .clone()
    .project(orthoCamera)
    .setZ(-1)
    .unproject(orthoCamera);

  orthoCamera.position.copy(referencePointInCameraPlane);

  const desiredHeight = calculateOrthoHeightToFramePerspectiveDistance(
    perspCamera.getEffectiveFOV(),
    referencePoint.distanceTo(perspCamera.position),
  );

  scaleOrthoCameraToHeight(orthoCamera, desiredHeight);
}

/**
 * @returns The distance a perspective camera has to be placed to frame an orthogonal plane with maximum width/height of size.
 * @param fov the vertical fov of the camera
 * @param orthoHeight the vertical size of the orthogonal frame to place
 */
function calculateCameraDistanceToFrameSize(
  fov: number,
  orthoHeight: number,
): number {
  const halfSize = orthoHeight / 2;
  const halfAngle = degToRad(fov / 2);

  return halfSize / Math.tan(halfAngle);
}

/**
 * @returns The height of an orthogonal plane of a perspective camera at a specific distance
 * @param fov the fov of the camera
 * @param distance the size distance of the camera plane
 */
function calculateOrthoHeightToFramePerspectiveDistance(
  fov: number,
  distance: number,
): number {
  const halfAngle = degToRad(fov / 2);
  const halfSize = Math.tan(halfAngle) * distance;

  return halfSize * 2;
}

/**
 * Adjusts an orthogonal's camera's frustum to fit a desired orthogonal size
 *
 * @param camera the camera to adjust
 * @param orthoHeight the desired vertical height of the camera
 */
function scaleOrthoCameraToHeight(
  camera: OrthographicCamera,
  orthoHeight: number,
): void {
  const currentSize = (camera.top - camera.bottom) / camera.zoom;

  const factor = orthoHeight / currentSize;

  camera.left *= factor;
  camera.right *= factor;
  camera.top *= factor;
  camera.bottom *= factor;
}
