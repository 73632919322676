import { GUID } from "@faro-lotv/ielement-types";
import { PropsWithChildren, useEffect } from "react";
import { ProjectLoadingContextProvider } from "./project-loading-context";
import { useProjectInitialData } from "./use-project-initial-data";
import { useUpdateProjectMetadata } from "./use-project-metadata";

export type ProjectProviderProps = {
  /** Id of the project to provide */
  projectId: GUID;

  /** If defined make sure the sub-tree containing this item is loaded */
  requiredItem?: GUID | null;

  /** Callback to signal if the project is ready to be used or still undefined or loading  */
  onReady?(isReady: boolean): void;
};

/**
 * Wrap a page using a project from the ProjectAPI ensuring the initial data is loaded
 * and allowing efficient fetching on additional parts of the project
 *
 * Need to be used inside an already authenticated context and inside an `ApiClientContextProvider`
 * to have the proper token data to talk to the ProjectApi
 *
 * @returns a context provider with everything needed to access and fetch more parts of the project
 */
export function ProjectProvider({
  projectId,
  requiredItem,
  onReady,
  children,
}: PropsWithChildren<ProjectProviderProps>): JSX.Element | null {
  const isMetadataReady = useUpdateProjectMetadata(projectId);
  const isProjectReady = useProjectInitialData(projectId, requiredItem);

  useEffect(
    () => onReady?.(isProjectReady && isMetadataReady),
    [isProjectReady, isMetadataReady, onReady],
  );

  // Make sure the active element is ready in the store before continuing
  if (!isProjectReady || !isMetadataReady) return null;

  return (
    <ProjectLoadingContextProvider>{children}</ProjectLoadingContextProvider>
  );
}
