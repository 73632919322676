import { RootState } from "../store";
import { ViewObjectTypes, VisibilityDistance } from "./view-options-slice";

/**
 * @param type of object to check the visibility for
 * @returns if that type of objects should be visible
 */
export function selectObjectVisibility(type: ViewObjectTypes) {
  return ({ viewOptions }: RootState): boolean => viewOptions.visibility[type];
}

/** @returns the map of the objects visibilities */
export function selectObjectsVisibility({
  viewOptions,
}: RootState): Record<ViewObjectTypes, boolean> {
  return viewOptions.visibility;
}

/**
 * @returns the visibility distance of the project
 */
export function selectVisibilityDistance({
  viewOptions,
}: RootState): VisibilityDistance {
  return viewOptions.visibilityDistance;
}

/**
 * @returns if the waypoints should be colored based on their altitude
 */
export function selectShouldColorWaypoints({
  viewOptions,
}: RootState): boolean {
  return viewOptions.shouldColorWaypoints;
}

/**
 * @returns if the waypoints should be rendered on the floor or at the scan height
 */
export function selectShouldShowWaypointsOnFloors({
  viewOptions,
}: RootState): boolean {
  return viewOptions.shouldShowWaypointsOnFloors;
}
