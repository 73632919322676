import { PointCloudDefaultEffects } from "@/components/r3f/renderers/pointcloud-renderer";
import { useLodCloudDynamicRenderer } from "@/hooks/use-lod-cloud-dynamic-renderer";
import { CadSubscene } from "@/modes/overview-mode/cad-subscene";
import { RenderDispatch } from "@/modes/overview-mode/render-dispatch";
import { CadModelObject, PointCloudObject } from "@/object-cache";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  SubScene,
} from "@faro-lotv/app-component-toolbox";
import { Object3D, Points } from "three";

export type SplitscreenModePipelineProps = {
  /** The point cloud to render */
  pointCloud: PointCloudObject | null;

  /** The optional CAD model to render or to overlay */
  cadModel: CadModelObject | null;

  /** True to enable this effect pipeline */
  enabled: boolean;
};

/**
 * @returns A simple effect pipeline to render the point cloud in a separate subscene with gap-filling
 * and everything else on top.
 */
export function SplitscreenModePipeline({
  pointCloud,
  cadModel,
  enabled,
}: SplitscreenModePipelineProps): JSX.Element {
  const dynamicRenderer = useLodCloudDynamicRenderer(pointCloud);

  return (
    <EffectPipelineWithSubScenes enabled={enabled}>
      <SubScene
        renderingPolicy={dynamicRenderer}
        filter={(o: Object3D) => o instanceof Points}
      >
        <PointCloudDefaultEffects pointCloud={pointCloud} />
      </SubScene>
      <CadSubscene cadModel={cadModel} enabled={!!cadModel} />
      <FilteredRenderPass
        filter={(o: Object3D) =>
          !(o instanceof Points) && o.userData.type !== RenderDispatch.CadModel
        }
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipelineWithSubScenes>
  );
}
