import { changeMode } from "@/store/mode-slice";
import { AppDispatch } from "@/store/store";
import { GUID } from "@faro-lotv/ielement-types";

export type AlignmentWizardArgs = {
  /** Id of the element in the project tree. */
  elementIdToAlign: GUID;

  /** Function to update the state. */
  dispatch: AppDispatch;
};

/**
 * Start Alignment Wizard
 */
export function openAlignmentWizard({ dispatch }: AlignmentWizardArgs): void {
  // TODO:
  // in https://faro01.atlassian.net/browse/CADBIM-664 here will be call dispatch(changeMode("openAlignmentWizard"));

  dispatch(changeMode("alignWizard"));
}
