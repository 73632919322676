import { PointCloudObject } from "@/object-cache";
import { useMultiCloudPointBudgetManager } from "@/registration-tools/common/rendering/use-multi-cloud-point-budget-manager";
import { usePointCloudMaterials } from "@/registration-tools/common/rendering/use-point-cloud-materials";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { RevisionScanRenderer } from "./revision-scan-renderer";

/**
 * The maximum number of points for all point clouds combined.
 *
 * Value determined experimentally.
 */
const MAX_POINTS = 1_000_000;

/**
 * The maximum number of points to download at the same time for all point clouds combined.
 *
 * Value determined experimentally.
 */
const MAX_NODES_TO_DOWNLOAD_AT_ONCE = 6;

export type CaptureTreeScansProps = {
  scanEntities: RevisionScanEntity[];

  pointCloudObjects: PointCloudObject[];

  /** Callback to execute when the scans are placed at their initial position. */
  onInitialPositioning(): void;
};

/** @returns Render the scans from the capture tree at the position of their revisions */
export function RevisionScansRenderer({
  scanEntities,
  pointCloudObjects,
  onInitialPositioning,
}: CaptureTreeScansProps): JSX.Element {
  usePointCloudMaterials(pointCloudObjects);

  useMultiCloudPointBudgetManager(
    pointCloudObjects,
    MAX_POINTS,
    MAX_NODES_TO_DOWNLOAD_AT_ONCE,
  );

  return (
    <>
      {scanEntities.map((scanEntity) => (
        <RevisionScanRenderer
          key={scanEntity.id}
          scanEntity={scanEntity}
          onInitialPositioning={onInitialPositioning}
        />
      ))}
    </>
  );
}
