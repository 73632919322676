import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/alignment-tool/utils/compute-split-screen-alignment";
import { RootState } from "@/store/store";
import { GUID } from "@faro-lotv/foundation";
import { CloudToCadAlignmentStep } from "./cloud-to-cad-alignment-mode-slice";
import {
  AlignmentViewLayout,
  SplitDirection,
} from "./sheet-to-cad-alignment-mode-slice";

/**
 * @returns the current cloud-to-CAD alignment step
 */
export function selectCloudToCadAlignmentStep({
  cloudToCadAlignmentMode,
}: RootState): CloudToCadAlignmentStep {
  return cloudToCadAlignmentMode.step;
}

/**
 * @param state  Current state
 * @returns ID of cloud used in current session of cloud-to-cad alignment
 */
export function selectCloudForCadAlignment(state: RootState): GUID | undefined {
  return state.cloudToCadAlignmentMode.cloudIdToAlignWithCad;
}

/**
 * @returns the current cloud-to-CAD alignment model elevation
 */
export function selectCloudToCadAlignmentModelElevation({
  cloudToCadAlignmentMode,
}: RootState): number {
  return cloudToCadAlignmentMode.modelElevation;
}

/**
 * @returns the current cloud-to-CAD alignment cloud elevation
 */
export function selectCloudToCadAlignmentCloudElevation({
  cloudToCadAlignmentMode,
}: RootState): number {
  return cloudToCadAlignmentMode.cloudElevation;
}

/**
 * @returns the current cloud-to-CAD alignment isModelClippingBoxEnabled
 */
export function selectModelClippingBoxEnabled({
  cloudToCadAlignmentMode,
}: RootState): boolean {
  return cloudToCadAlignmentMode.isModelClippingBoxEnabled;
}

/**
 * @returns the current flag isModelCrossSectionEnabled for cloud-to-cad alignment
 */
export function selectModelCrossSectionEnabled({
  cloudToCadAlignmentMode,
}: RootState): boolean {
  return cloudToCadAlignmentMode.isModelCrossSectionEnabled;
}

/**
 * @returns the current flag isCloudCrossSectionEnabled for cloud-to-cad alignment
 */
export function selectCloudCrossSectionEnabled({
  cloudToCadAlignmentMode,
}: RootState): boolean {
  return cloudToCadAlignmentMode.isCloudCrossSectionEnabled;
}

/**
 * @param state Current state
 * @returns The index of the current active cloud-to-cad alignment step
 */
export function selectCloudToCadAlignmentActiveStepIndex(
  state: RootState,
): number {
  return Object.values(CloudToCadAlignmentStep).indexOf(
    state.cloudToCadAlignmentMode.step,
  );
}

/**
 * @param state Current state
 * @returns true if the current active cloud-to-cad alignment step is the last step for alignment process
 */
export function selectCloudToCadAlignmentStepIsLastStep(
  state: RootState,
): boolean {
  const allCloudToCadAlignmentSteps = Object.values(CloudToCadAlignmentStep);
  return (
    allCloudToCadAlignmentSteps.indexOf(state.cloudToCadAlignmentMode.step) ===
    allCloudToCadAlignmentSteps.length - 1
  );
}

/**
 * @param state  Current state
 * @returns  anchor positions in split screen for cloud-to-cad alignment
 */
export function selectAnchorPositionsForCloudAlignment(
  state: RootState,
): TwoPointsPairsAlignmentAnchorPositions {
  return state.cloudToCadAlignmentMode.alignmentAnchorPositions;
}

/**
 * @param state  Current state
 * @returns incremental cloud-to-cad transform in CS of previous pose or undefined if position/scale was not modified
 */
export function selectIncrementalCloudTransform(
  state: RootState,
): AlignmentTransform | undefined {
  return state.cloudToCadAlignmentMode.incrementalTransform;
}

/**
 * @returns the current cloud to CAD alignment screen layout
 */
export function selectCloudToCadAlignmentLayout({
  cloudToCadAlignmentMode,
}: RootState): AlignmentViewLayout {
  return cloudToCadAlignmentMode.alignmentLayout;
}

/**
 * @returns the current alignment screen split direction
 */
export function selectCloudToCadAlignmentSplitDirection({
  cloudToCadAlignmentMode,
}: RootState): SplitDirection {
  return cloudToCadAlignmentMode.splitDirection;
}
