import { selectAllCadModels } from "@/store/cad/cad-selectors";
import {
  selectActiveCadId,
  selectActiveCadLoadingError,
  setActiveCad,
} from "@/store/cad/cad-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectCanReadCAD } from "@/store/subscriptions/subscriptions-selectors";
import { Dropdown, useBreakpointMdUp } from "@faro-lotv/app-component-toolbox";
import { Alert } from "@faro-lotv/flat-ui";
import { TabContext, TabList } from "@mui/lab";
import { Box, Stack, SxProps, Tab, Theme, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { CadModelTree } from "./tree/cad-model-tree/cad-model-tree";
import { useCadModelTreeData } from "./tree/cad-model-tree/cad-model-tree-data";
import { CaptureTree } from "./tree/capture-tree/capture-tree";
import { TreeWrapper } from "./tree/tree-wrapper";

/**
 * UI component including the data sessions tree either alone, or with the CAD model tree in a separate tab.
 *
 * @returns JSX.Element UI component displaying the tab "Capture" and the tab "Model" and their contents.
 */
export function ProjectView(): JSX.Element {
  const newModelTreeTab = useAppSelector(selectCanReadCAD);
  if (newModelTreeTab) {
    return <TabbedProjectView />;
  }

  // Transitional: still display the legacy UI with no tabs
  return (
    <TreeWrapper>
      <CaptureTree />
    </TreeWrapper>
  );
}

/**
 * Display the tabs with both the "Capture" (list of data sessions) and "Model" (list of CADs) tree views.
 *
 * @returns UI component including 2 tabs and their contents
 */
function TabbedProjectView(): JSX.Element {
  // List of tabs:
  // - "Capture" for the legacy HB tree (list of sheets/sections with their clouds + 360 measurements)
  // - "Model" for the list of nominal CAD models
  enum TabType {
    "Capture" = 0,
    "Model" = 1,
  }

  // Active tab
  const [activeTab, setActiveTab] = useState<string>("Capture");

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: TabType,
  ): void => {
    setActiveTab(newValue.toString());
  };

  const dispatch = useAppDispatch();

  const currentCadId = useAppSelector(selectActiveCadId);
  const cadModels = useAppSelector(selectAllCadModels);

  // sort models in alphabetical order of names
  const sortedCadModels = useMemo(
    () => cadModels.sort((a, b) => a.name.localeCompare(b.name)),
    [cadModels],
  );

  const cadSelectorOptions = sortedCadModels.map((cad) => ({
    key: cad.id,
    value: cad.id,
    label: cad.name,
  }));

  // if there is no active CAD yet, take first one in the lost and set it as default active CAD
  useEffect(() => {
    if (!currentCadId && sortedCadModels.length) {
      dispatch(setActiveCad(sortedCadModels[0].id));
    }
  }, [currentCadId, dispatch, sortedCadModels]);

  const modelTreeData = useCadModelTreeData();
  const activeCadLoadingError = useAppSelector(selectActiveCadLoadingError);

  const isMdUp = useBreakpointMdUp();

  const sharedStyles: SxProps<Theme> = {
    overflow: "auto",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    ...(isMdUp ? { mt: 2.5, ml: 2.5 } : { mt: 1 }),
  };

  return (
    <Stack component="div" flexGrow="1" sx={{ overflow: "auto" }}>
      <TabContext value={activeTab.toString()}>
        <Box component="div">
          <TabList onChange={handleTabChange}>
            <Tab label="Capture" value="Capture" />

            <Tab label="3D Model" value="Model" />
          </TabList>
        </Box>
      </TabContext>

      <Box
        component="div"
        sx={{
          ...sharedStyles,
          display: activeTab === "Capture" ? undefined : "none",
        }}
      >
        <TreeWrapper>
          <CaptureTree />
        </TreeWrapper>
      </Box>

      <Stack
        sx={{
          ...sharedStyles,
          display: activeTab === "Model" ? undefined : "none",
        }}
      >
        {activeCadLoadingError && (
          <Alert
            title={activeCadLoadingError}
            variant="warning"
            sx={{ mb: 3 }}
          />
        )}

        <Dropdown
          label="Active Model"
          options={cadSelectorOptions}
          sx={{ height: 35 }}
          value={currentCadId ?? ""}
          onChange={(e) => {
            const selCad = cadSelectorOptions.find(
              (el) => el.value === e.target.value,
            );
            if (selCad) {
              dispatch(setActiveCad(selCad.key));
            }
          }}
        />

        {typeof modelTreeData === "string" ? (
          <Typography>{modelTreeData}</Typography>
        ) : (
          <TreeWrapper>
            <CadModelTree modelTreeData={modelTreeData} />
          </TreeWrapper>
        )}
      </Stack>
    </Stack>
  );
}
