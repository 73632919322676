import { ExportIcon, FaroButton, FaroText, GridIcon } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";

interface Props {
  /** Callback when the user confirms the upload */
  onConfirm(): void;

  /** Callback when the user wants to select a different file */
  onCancel(): void;

  /**
   * Indicates if the selected image is the grid
   *
   * @default false
   */
  isUsingGrid?: boolean;
}

/** @returns buttons to confirm or cancel an image selection */
export function ConfirmAreaUpload({
  onConfirm,
  onCancel,
  isUsingGrid = false,
}: Props): JSX.Element {
  return (
    <Stack alignItems="center" justifyContent="center" flexGrow={1} padding={1}>
      <Stack
        alignItems="center"
        justifyContent="center"
        width="3.75rem"
        height="3.75rem"
        borderRadius="50%"
        sx={{ backgroundColor: "gray100" }}
      >
        {isUsingGrid ? (
          <GridIcon sx={{ fontSize: "2rem" }} />
        ) : (
          <ExportIcon sx={{ fontSize: "2rem" }} />
        )}
      </Stack>

      <FaroText variant="heading16" sx={{ mt: 2 }}>
        {isUsingGrid ? "Continue with the grid?" : "Is this the right image?"}
      </FaroText>

      <FaroButton aria-label="upload" sx={{ mt: 6 }} onClick={onConfirm}>
        Upload {isUsingGrid ? "Grid" : "Sheet"}
      </FaroButton>

      <FaroButton
        aria-label="select a different image"
        variant="ghost"
        sx={{ mt: 2.5 }}
        onClick={onCancel}
      >
        Select a Different Image
      </FaroButton>
    </Stack>
  );
}
