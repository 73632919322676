import { RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { FaroText } from "@faro-lotv/flat-ui";
import { assert, GUID } from "@faro-lotv/foundation";
import {
  IElementBase,
  IElementTimeSeries,
  IElementType,
  IElementTypeHint,
  isIElementSection,
  isIElementTimeseries,
  isIElementWithTypeAndHint,
  WithHint,
} from "@faro-lotv/ielement-types";
import {
  selectChildrenDepthFirst,
  selectIElement,
  selectRootIElement,
  TreeData,
} from "@faro-lotv/project-source";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import { SvgIconProps } from "@mui/material";
import { Stack, SxProps } from "@mui/system";
import { PropsWithChildren, useMemo, useState } from "react";
import { Tree } from "react-arborist";
import { TreeNodeProps } from "../tree/tree-node";
import { AreaContentProps } from "./area-navigation-types";
import { selectNode } from "./area-navigation-utils";
import { ElementNameWithIcon } from "./element-with-icon";

type TimeSeriesRoom = WithHint<IElementTimeSeries, IElementTypeHint.room>;

/**
 * @returns A list of all pano captures in the active area (all if there is no active area)
 */
export function PanoCaptures({
  activeAreaId,
}: AreaContentProps): JSX.Element | null {
  const root = useAppSelector(selectRootIElement);
  const activeArea = useAppSelector(selectIElement(activeAreaId));
  const timeseriesRooms = useAppSelector(
    selectChildrenDepthFirst(activeArea ?? root, (el): el is TimeSeriesRoom =>
      isIElementWithTypeAndHint(
        el,
        IElementType.timeSeries,
        IElementTypeHint.room,
      ),
    ),
  ).map((timeseriesRoom) => timeseriesRoom.id);

  const store = useAppStore();

  const panoTree = useMemo(
    () => generatePanoTree(timeseriesRooms, store.getState()),
    [timeseriesRooms, store],
  );

  if (!timeseriesRooms.length) return null;

  return (
    <Stack sx={{ py: 1 }}>
      <FaroText variant="heading14" sx={{ py: 1.5 }}>
        Pano captures
      </FaroText>
      <Stack>
        <Tree<PanoTreeData>
          data={panoTree ?? []}
          indent={24}
          rowHeight={33}
          disableDrag
          disableDrop
          width="100%"
          openByDefault={false}
        >
          {TreeNode}
        </Tree>
      </Stack>
    </Stack>
  );
}

type PanoTreeData = Omit<TreeData, "element"> & {
  element?: IElementBase;
};

function generatePanoTree(
  elementIds: GUID[],
  store: RootState,
): PanoTreeData[] | null {
  const elements = elementIds
    .map((elementId) => selectIElement(elementId)(store))
    .filter(
      (element) =>
        !!element &&
        (isIElementSection(element) || isIElementTimeseries(element)),
    );

  if (!elementIds.length) return null;

  const treeRootNodes: PanoTreeData[] = elements.map((element) => {
    let children = element.childrenIds
      ? generatePanoTree(element.childrenIds, store)
      : null;
    if (!children?.length) children = null;

    return {
      id: element.id,
      label: element.name,
      children,
      element,
    };
  });

  return treeRootNodes;
}

function TreeNode({
  node,
  style,
}: PropsWithChildren<TreeNodeProps<PanoTreeData>>): JSX.Element | null {
  const store = useAppStore();
  const dispatch = useAppDispatch();
  assert(node.data.element, "");

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Stack style={{ ...style, height: "100%" }} direction="row">
      <ExpandNodeIcon
        sx={{
          display: "flex",
          mr: 0.5,
          visibility: node.isLeaf ? "hidden" : "unset",
          fontSize: "1.125em",
        }}
        isExpanded={isExpanded}
        onClick={() => {
          if (isExpanded) node.close();
          else node.open();

          setIsExpanded(!isExpanded);
        }}
      />
      <ElementNameWithIcon
        elementName={node.data.element.name}
        element={node.data.element}
        key={node.data.element.id}
        onClick={() => {
          assert(node.data.element, "");
          selectNode(node.data.element, store.getState(), dispatch);
        }}
      />
    </Stack>
  );
}

type ExpandNodeIconProps = {
  /** Boolean to know if the node is expanded or closed */
  isExpanded: boolean;

  /** Optional style to apply to the image */
  sx?: SxProps;

  /** Callback called when the icon is clicked */
  onClick?: SvgIconProps["onClick"];
};

/**
 * This component is required, because by simply rotating, with css, the image will become blurry.
 *
 * @returns an arrow icon. The arrow points to below if the node is expanded, otherwise it points to the right.
 */
function ExpandNodeIcon({
  isExpanded,
  sx,
  onClick,
}: ExpandNodeIconProps): JSX.Element {
  return isExpanded ? (
    <ExpandMoreSharpIcon sx={sx} onClick={onClick} />
  ) : (
    <ChevronRightSharpIcon sx={sx} onClick={onClick} />
  );
}
