import { useDocumentTitle } from "@/hooks/use-document-title";
import { EnsureProjectAccess } from "@/permissions/ensure-project-access";
import { runtimeConfig } from "@/runtime-config";
import { selectBackgroundTask } from "@/store/background-tasks/background-tasks-selector";
import { selectCurrentUser } from "@/store/user-selectors";
import { appId } from "@/utils/appid";
import {
  RegisterMultiCloudDataSetTask,
  SuccessfullyCompleted,
  isRegisterMultiCloudDataSetTask,
  isSuccessfullyCompletedWithResult,
} from "@/utils/background-tasks";
import { LoadingScreen } from "@faro-lotv/flat-ui";
import { GUID, assert } from "@faro-lotv/foundation";
import {
  IElementType,
  isIElementSectionDataSession,
  isIElementTimeseriesDataSession,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  selectChildrenDepthFirst,
  selectIElement,
  selectProjectName,
} from "@faro-lotv/project-source";
import { ApiClientContextProvider } from "@faro-lotv/service-wires";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store-hooks";
import { ProgressApiTracker } from "../../utils/progress-api-tracker";
import {
  AlignmentOverlayContext,
  useAlignmentOverlayInitialState,
} from "../common/alignment-overlay-context";
import { isMergedDataSession } from "../common/select-main-point-clouds";
import { setLastRegistrationPoseUsed } from "../common/store/registration-slice";
import { useLoadIElements } from "../common/use-load-ielements";
import {
  InspectAndPublishUi,
  InspectAndPublishUiProps,
} from "./inspect-and-publish-ui";

/**
 * @returns Entry point of the inspect & publish workflow.
 * This tool allows the user to inspect the result of a registration and merge it into a single point cloud.
 */
export function InspectAndPublishPage(): JSX.Element | null {
  const { projectId } = useParams();
  assert(projectId, "No project ID provided");

  const currentUser = useAppSelector(selectCurrentUser);

  const [searchParams] = useSearchParams();
  // TODO: Fix name to dataSessionId https://faro01.atlassian.net/browse/NRT-706
  const datasetId = searchParams.get("datasetId") ?? undefined;
  const dataSessionIds =
    searchParams.get("dataSessionIds")?.split(",") ?? undefined;
  const taskId = searchParams.get("taskId") ?? undefined;
  const rawDashboardRedirect =
    searchParams.get("dashboardRedirect") ?? undefined;
  const enableMultiCloudRegistration = searchParams.has(
    "enablemulticloudregistration",
  );

  const dashboardRedirect = rawDashboardRedirect
    ? decodeURIComponent(rawDashboardRedirect)
    : undefined;

  const manualAlignmentOverlay = useAlignmentOverlayInitialState();

  const projectName = useAppSelector(selectProjectName);
  const title = projectName
    ? `Inspect & Publish - ${projectName}`
    : "Inspect & Publish";
  useDocumentTitle(title);

  return (
    <ApiClientContextProvider
      projectId={projectId}
      userId={currentUser?.id}
      clientId={appId()}
      apiBaseUrls={runtimeConfig.backendEndpoints}
    >
      <EnsureProjectAccess projectId={projectId}>
        <AlignmentOverlayContext.Provider value={manualAlignmentOverlay}>
          <InspectAndPublishLoading
            dataSetId={datasetId}
            dataSessionIds={dataSessionIds}
            taskId={taskId}
            dashboardRedirect={dashboardRedirect}
            enableMultiCloudRegistration={enableMultiCloudRegistration}
            currentUser={currentUser}
          />
        </AlignmentOverlayContext.Provider>
        <ProgressApiTracker />
      </EnsureProjectAccess>
    </ApiClientContextProvider>
  );
}

type InspectAndPublishLoadingProps = {
  /** The IDs of the Section.DataSession elements to display in the multi cloud view. */
  dataSessionIds?: GUID[];

  /**
   * The ID of the TimeSeries.DataSession element to display all datasets of.
   * This is an old parameter and only used for backwards-compatibility.
   * Use `dataSessionIds` instead where possible.
   */
  dataSetId?: GUID;

  /** The dashboard path to redirect to. */
  dashboardRedirect: InspectAndPublishUiProps["dashboardRedirect"];

  /**
   * When true, shows a button to trigger a multi-cloud registration that will then redirect to either viewer or dashboard
   */
  enableMultiCloudRegistration?: boolean;

  /** The ID of the registration task to display the results of. */
  taskId?: GUID;

  /** The currently logged-in user. */
  currentUser: InspectAndPublishUiProps["currentUser"];
};

/** @returns Wrapper component which ensures that all needed data is loaded and validated. */
function InspectAndPublishLoading({
  dataSessionIds,
  dataSetId,
  dashboardRedirect,
  enableMultiCloudRegistration,
  taskId,
  currentUser,
}: InspectAndPublishLoadingProps): JSX.Element {
  assert(
    !!dataSessionIds || !!dataSetId,
    "Either `dataSessionIds` or the deprecated `dataSetId` must be provided",
  );

  const dispatch = useAppDispatch();
  const registrationTask = useRegistrationTask(taskId);

  // If the registration report exists, the positions of that registration are used
  useEffect(() => {
    if (registrationTask?.result) {
      dispatch(setLastRegistrationPoseUsed(true));
    }
  }, [registrationTask?.result, dispatch]);

  // Load all data
  const loadingReferenceIds = dataSessionIds ?? (dataSetId ? [dataSetId] : []);
  const isLoading = useLoadIElements([
    // Load ancestors (needed for world transforms and sheet)
    {
      descendantIds: loadingReferenceIds,
    },
    // Load descendants (needed for point cloud streams)
    {
      ancestorIds: loadingReferenceIds,
      types: [
        IElementType.section,
        IElementType.pointCloudStream,
        // Needed for merge dialog
        IElementType.pointCloudLaz,
      ],
    },
  ]);

  // Get elements from the store, depending on how they were provided in the URL
  const dataSessionsDirect = useAppSelector(
    (state) => dataSessionIds?.map((id) => selectIElement(id)(state)),
    isEqual,
  );
  const timeSeriesId = dataSessionsDirect?.at(0)?.parentId ?? dataSetId;
  const timeSeries = useAppSelector(selectIElement(timeSeriesId));
  const dataSessionsIndirect = useAppSelector(
    selectChildrenDepthFirst(
      timeSeries,
      // Automatically exclude merged data sessions
      (element) =>
        isIElementSectionDataSession(element) && !isMergedDataSession(element),
      1,
    ),
    isEqual,
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  const dataSessions = dataSessionsDirect ?? dataSessionsIndirect;

  // Validate data
  assert(dataSessions, "No data sessions found");
  assert(dataSessions.every(isValid), "All data sessions must be loaded");
  assert(
    dataSessions.every(isIElementSectionDataSession),
    "All data sessions must be of valid type",
  );

  assert(timeSeries, "No time series found");
  assert(
    isIElementTimeseriesDataSession(timeSeries),
    "Time series must be of valid type",
  );

  return (
    <InspectAndPublishUi
      currentUser={currentUser}
      dataSessions={dataSessions}
      dashboardRedirect={dashboardRedirect}
      enableMultiCloudRegistration={enableMultiCloudRegistration}
      registrationTask={registrationTask}
    />
  );
}

/**
 * @param taskId The ID of the task to retrieve and validate.
 * @returns The validated task, or the latest task if no ID was given.
 */
function useRegistrationTask(
  taskId?: GUID,
): SuccessfullyCompleted<RegisterMultiCloudDataSetTask> | undefined {
  const selectedTask = useAppSelector(selectBackgroundTask(taskId ?? ""));
  if (!selectedTask) return undefined;

  assert(
    isRegisterMultiCloudDataSetTask(selectedTask),
    "The task must be a multi-cloud registration task",
  );
  assert(
    isSuccessfullyCompletedWithResult(selectedTask),
    "The task must be completed successfully",
  );

  return selectedTask;
}
