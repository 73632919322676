import { validateMultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { selectBackgroundTasks } from "@/store/background-tasks/background-tasks-selector";
import {
  addBackgroundTask,
  updateBackgroundTask,
} from "@/store/background-tasks/background-tasks-slice";
import { AppDispatch } from "@/store/store";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import {
  BackgroundTask,
  BackgroundTaskTypes,
  C2CRegistrationTask,
  PointCloudExportTask,
  RegisterMultiCloudDataSetTask,
  validateC2CRegistrationResult,
} from "@/utils/background-tasks";
import {
  fetchProjectIElements,
  selectAncestor,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementBase,
  isIElementBimModelGroup,
  isIElementSection,
  isIElementSectionDataSession,
  isIElementVideoRecording,
} from "@faro-lotv/ielement-types";
import {
  BackgroundTaskDetails,
  BackgroundTaskState,
  ProgressApiSupportedTaskTypes,
  isBackgroundTaskActive,
  isBackgroundTaskInfoProgress,
  isBackgroundTaskInfoState,
  isProgressApiSupportedTaskType,
  useApiClientContext,
  validateDownloadResult,
} from "@faro-lotv/service-wires";
import { useCallback, useEffect, useState } from "react";

/** Default interval at which the polling is executed */
const DEFAULT_INTERVAL = 3000;

/**
 * Create a BackgroundTask to track a backend task only if it's needed
 *
 * Do not track already completed or failed tasks
 *
 * @param task Task data received from the ProgressApi
 * @param dispatch Function to update the application store
 */
function createTaskIfNeeded(
  task: BackgroundTaskDetails,
  dispatch: AppDispatch,
): void {
  // Ignore un-supported tasks
  if (!task.taskType || !isProgressApiSupportedTaskType(task.taskType)) {
    return;
  }
  if (
    isBackgroundTaskInfoState(task.status) &&
    isBackgroundTaskActive(task.status.state)
  ) {
    // Init a new BackgroundTask object as the backend process is new but is active
    dispatch(
      addBackgroundTask({
        id: task.id,
        createdAt: task.createdAt,
        changedAt: task.status.changedAt,
        iElementId: task.context.elementId ?? task.context.correlationId,
        type: task.taskType,
        devMessage: task.status.devMessage,
        errorCode: task.status.errorCode ?? undefined,
        state: task.status.state,
        progress: 0,
        metadata: { jobId: task.context.jobId },
        tags: task.tags,
      }),
    );
  } else if (isBackgroundTaskInfoProgress(task.status)) {
    // Init a new BackgroundTask object as the backend process is already running
    dispatch(
      addBackgroundTask({
        id: task.id,
        createdAt: task.createdAt,
        changedAt: task.status.changedAt,
        iElementId: task.context.elementId ?? task.context.correlationId,
        type: task.taskType,
        devMessage: task.status.devMessage,
        errorCode: task.status.errorCode ?? undefined,
        state: BackgroundTaskState.started,
        progress:
          (task.status.progress.current / task.status.progress.total) * 100,
        metadata: { jobId: task.context.jobId },
        tags: task.tags,
      }),
    );
  } else if (
    task.status.state === BackgroundTaskState.succeeded &&
    task.taskType === ProgressApiSupportedTaskTypes.registerMultiCloudDataSet
  ) {
    // Track completed registration tasks to display metric reports
    // Maybe we can track this in a different way in the future https://faro01.atlassian.net/browse/NRT-688
    const { result } = task.status;

    dispatch(
      addBackgroundTask({
        id: task.id,
        createdAt: task.createdAt,
        changedAt: task.status.changedAt,
        iElementId: task.context.elementId ?? task.context.correlationId,
        type: task.taskType,
        devMessage: task.status.devMessage,
        errorCode: task.status.errorCode ?? undefined,
        state: BackgroundTaskState.succeeded,
        progress: 100,
        metadata: { jobId: task.context.jobId },
        tags: task.tags,
        result: validateMultiRegistrationReport(result) ? result : undefined,
      }),
    );
  }
}

function computeTaskWithMetadata(
  task: BackgroundTaskDetails,
  backgroundTask: BackgroundTask,
): BackgroundTask {
  switch (backgroundTask.type) {
    case ProgressApiSupportedTaskTypes.pointCloudExport: {
      const downloadUrl = validateDownloadResult(task.status.result)
        ? task.status.result.downloadUrl
        : undefined;
      return {
        ...backgroundTask,
        shouldPreventWindowClose: true,
        metadata: {
          ...backgroundTask.metadata,
          downloadUrl,
        },
      } satisfies PointCloudExportTask;
    }
    case ProgressApiSupportedTaskTypes.c2cRegistration: {
      const result = validateC2CRegistrationResult(task.status.result)
        ? task.status.result
        : undefined;
      return {
        ...backgroundTask,
        result,
      } satisfies C2CRegistrationTask;
    }
    case ProgressApiSupportedTaskTypes.registerMultiCloudDataSet: {
      const result = validateMultiRegistrationReport(task.status.result)
        ? task.status.result
        : undefined;
      return {
        ...backgroundTask,
        result,
      } satisfies RegisterMultiCloudDataSetTask;
    }
  }
  return { ...backgroundTask };
}

/**
 * Update a background task with the new information received from the backend
 *
 * @param task information received from the ProgressApi
 * @param backgroundTask application stored information about this task
 * @param dispatch function to update the application state
 * @param onTaskCompleted callback called when a task move to the succeeded state
 */
function updateTask(
  task: BackgroundTaskDetails,
  backgroundTask: BackgroundTask,
  dispatch: AppDispatch,
  onTaskCompleted: (task: BackgroundTask) => void,
): void {
  // We received an update for a task that's in the store
  const taskWithMetadata = computeTaskWithMetadata(task, backgroundTask);
  if (
    isBackgroundTaskInfoState(task.status) &&
    backgroundTask.state !== task.status.state
  ) {
    // The task changed state
    const updatedTask: BackgroundTask = {
      ...taskWithMetadata,
      state: task.status.state,
      changedAt: task.status.changedAt,
      devMessage: task.status.devMessage,
      errorCode: task.status.errorCode ?? undefined,
      tags: task.tags,
    };
    dispatch(updateBackgroundTask(updatedTask));
    if (updatedTask.state === BackgroundTaskState.succeeded) {
      onTaskCompleted(updatedTask);
    }
  } else if (isBackgroundTaskInfoProgress(task.status)) {
    // The progress of this task changed
    const updatedTask: BackgroundTask = {
      ...taskWithMetadata,
      changedAt: task.status.changedAt,
      progress:
        (task.status.progress.current / task.status.progress.total) * 100,
    };
    dispatch(updateBackgroundTask(updatedTask));
  }
}

/**
 * A map from a background task type to a type guard.
 *
 * The type guard determines which ancestor is selected
 * for refreshing the IElement tree.
 */
const TASK_TO_ANCESTOR_TYPE_GUARD_MAP: Record<
  BackgroundTaskTypes,
  ((iElement: IElementBase) => boolean) | undefined
> = {
  FileUpload: isIElementSection,
  [ProgressApiSupportedTaskTypes.pointCloudExport]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.pointCloudToPotree]:
    isIElementSectionDataSession,
  // TODO: Remove after backend update: https://faro01.atlassian.net/browse/SWEB-1980
  [ProgressApiSupportedTaskTypes.pointCloudLazToPotree]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.pointCloudE57ToLaz]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.pointCloudGSToLaz]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.c2cRegistration]: isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.registerMultiCloudDataSet]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.mergePointClouds]:
    isIElementSectionDataSession,
  [ProgressApiSupportedTaskTypes.bimModelImport]: isIElementBimModelGroup,
  [ProgressApiSupportedTaskTypes.videoMode]: isIElementVideoRecording,
  [ProgressApiSupportedTaskTypes.sceneConversion]: undefined,
};

/**
 * @returns a component to track the ProgressApi and keep the store in sync
 * Must be used within an `ApiClientContextProvider`.
 */
export function ProgressApiTracker(): null {
  // For now we poll with a fixed delta, we should evaluate in the future to use a different polling
  // if there's an active task in the backend
  const [polling] = useState<number>(DEFAULT_INTERVAL);

  const { progressApiClient: progressApi } = useApiClientContext();
  const appStore = useAppStore();
  const dispatch = useAppDispatch();

  const { projectApiClient: projectApi } = useApiClientContext();

  const onTaskCompleted = useCallback(
    (task: BackgroundTask) => {
      // If the task is a SceneConversion, instead of fetching a subtree,
      // refresh the entire page so that the app can be correctly initialized
      if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
        window.location.reload();
        return;
      }

      // The element of which the subtree needs to be fetched once the tasked is completed
      const ancestorToFetch = TASK_TO_ANCESTOR_TYPE_GUARD_MAP[task.type];

      if (ancestorToFetch) {
        if (!task.iElementId) return;

        const element = selectIElement(task.iElementId)(appStore.getState());
        // A background task updated an element that is not currently loaded, nothing to do
        if (!element) return;

        // A background task changed an element that is loaded, we need to refresh that element sub-tree
        // find the section containing this IElement to update
        const elementSection = selectAncestor(
          element,
          ancestorToFetch,
        )(appStore.getState());

        dispatch(
          fetchProjectIElements({
            // eslint-disable-next-line require-await -- FIXME
            fetcher: async () =>
              projectApi.getAllIElements({
                ancestorIds: [elementSection?.id ?? element.id],
              }),
          }),
        );
      }
    },
    [appStore, dispatch, projectApi],
  );

  useEffect(() => {
    async function updateBackgroundTasks(): Promise<void> {
      const bgTasks = selectBackgroundTasks()(appStore.getState());
      // If the connection with the ProgressAPI fails do not error out but just
      // act like we got no updates as we don't want the app to fail if the ProgressAPI
      // does not respond
      const { data } = await progressApi
        .requestProgress()
        .catch(() => ({ data: [] }));

      for (const task of data) {
        const backgroundTask = bgTasks.find((bgTask) => bgTask.id === task.id);
        if (backgroundTask) {
          updateTask(task, backgroundTask, dispatch, onTaskCompleted);
        } else {
          createTaskIfNeeded(task, dispatch);
        }
      }
    }
    const id = setInterval(() => updateBackgroundTasks(), polling);

    return () => clearInterval(id);
  }, [polling, dispatch, appStore, progressApi, onTaskCompleted]);

  return null;
}
