import { GUID, assert, generateGUID } from "@faro-lotv/foundation";
import {
  IElementDateTimeMarkupField,
  IElementDropDownMarkupField,
  IElementMarkup,
  IElementType,
  IElementTypeHint,
  IElementUserDirectoryMarkupField,
} from "@faro-lotv/ielement-types";
import {
  Mutation,
  createMutationAddMarkupField,
  createMutationDateTimeMarkupField,
  createMutationDeleteElement,
  createMutationDropDownMarkupField,
  createMutationUserDirectoryMarkupField,
  isMarkupStatus,
} from "@faro-lotv/service-wires";

/**
 * @param markupElement to edit
 * @param newAssignee to apply
 * @param previousValueId if a value was already present
 * @param assigneeTemplateId to reference in the new UserDirectoryMarkupField node
 * @returns the proper mutation to update the Markup to the new value
 */
export function createEditAssigneeMutation(
  markupElement: IElementMarkup,
  newAssignee: string | undefined,
  previousValueId: GUID | undefined,
  assigneeTemplateId: GUID,
): Mutation[] {
  if (newAssignee) {
    // Edit previous value if it exists
    if (previousValueId) {
      return [
        createMutationUserDirectoryMarkupField(previousValueId, [newAssignee]),
      ];
    }

    // Create a new node if it did not exists
    return [
      createMutationAddMarkupField<IElementUserDirectoryMarkupField>(
        markupElement.id,
        {
          id: generateGUID(),
          childrenIds: null,
          name: "Assignee",
          parentId: markupElement.id,
          rootId: markupElement.rootId,
          templateId: assigneeTemplateId,
          type: IElementType.userDirectoryMarkupField,
          typeHint: IElementTypeHint.markupAssigneeId,
          values: [newAssignee],
        },
      ),
    ];
  } else if (previousValueId) {
    // Remove the value node if not needed anymore
    return [createMutationDeleteElement(previousValueId)];
  }
  return [];
}

/**
 * @param markupElement to edit
 * @param newStatus to apply
 * @param previousValueId if a value was already present
 * @param statusTemplateId to reference in the new UserDirectoryMarkupField node
 * @returns the proper mutation to update the Markup to the new value
 */
export function createEditStatusMutation(
  markupElement: IElementMarkup,
  newStatus: string | undefined,
  previousValueId: GUID | undefined,
  statusTemplateId: GUID,
): Mutation[] {
  if (newStatus) {
    assert(
      isMarkupStatus(newStatus),
      "The new status should be one of the valid statuses",
    );
    // Edit previous value if it exists
    if (previousValueId) {
      return [createMutationDropDownMarkupField(previousValueId, newStatus)];
    }

    // Create a new node if it did not exists
    return [
      createMutationAddMarkupField<IElementDropDownMarkupField>(
        markupElement.id,
        {
          id: generateGUID(),
          childrenIds: null,
          name: "Status",
          parentId: markupElement.id,
          rootId: markupElement.rootId,
          templateId: statusTemplateId,
          type: IElementType.dropDownMarkupField,
          typeHint: IElementTypeHint.markupIssueStatus,
          value: newStatus,
        },
      ),
    ];
  } else if (previousValueId) {
    // Remove the value node if not needed anymore
    return [createMutationDeleteElement(previousValueId)];
  }
  return [];
}

/**
 * @param markupElement to edit
 * @param newDueDate to apply
 * @param previousValueId if a value was already present
 * @param dueDateTemplateId to reference in the new UserDirectoryMarkupField node
 * @returns the proper mutation to update the Markup to the new value
 */
export function createEditDueDateMutation(
  markupElement: IElementMarkup,
  newDueDate: Date | undefined,
  previousValueId: GUID | undefined,
  dueDateTemplateId: GUID,
): Mutation[] {
  if (newDueDate) {
    // Edit previous value if it exists
    if (previousValueId) {
      return [createMutationDateTimeMarkupField(previousValueId, newDueDate)];
    }

    // Create a new node if it did not exists
    return [
      createMutationAddMarkupField<IElementDateTimeMarkupField>(
        markupElement.id,
        {
          id: generateGUID(),
          childrenIds: null,
          name: "Due Date",
          parentId: markupElement.id,
          rootId: markupElement.rootId,
          templateId: dueDateTemplateId,
          type: IElementType.dateTimeMarkupField,
          typeHint: IElementTypeHint.markupIssueDueDate,
          value: newDueDate.toISOString(),
        },
      ),
    ];
  } else if (previousValueId) {
    // Remove the value node if not needed anymore
    return [createMutationDeleteElement(previousValueId)];
  }
  return [];
}
