import { PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { reproportionCamera } from "@faro-lotv/lotv";
import {
  CachedWorldTransform,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { useLayoutEffect, useMemo } from "react";
import {
  Box3,
  Camera,
  Matrix4,
  OrthographicCamera,
  Quaternion,
  Vector3,
} from "three";

const BOX_DIAG_MULTIPLIER = 0.6;

type CenterOnCloudResult = {
  /** Real transform used to render the point cloud */
  cloudTransform: CachedWorldTransform;
  /** The cloud bounding box */
  box: Box3;
  /** Point the camera is looking at */
  target: Vector3;
  /** Target camera position to look at the cloud */
  targetCameraPos: Vector3;
};

/**
 * Hook used to center the camera on a pointCloud.
 *
 * @param camera The camera that renders the scene
 * @param pointCloudObject The point cloud
 * @returns the target for the camera
 */
export function useCenterCameraOnPointCloud(
  camera: Camera,
  pointCloudObject: PointCloudObject,
): CenterOnCloudResult {
  const modelTransform = useAppSelector(
    selectIElementWorldTransform(pointCloudObject.iElement.id),
  );

  const cloudTransform = useMemo<CachedWorldTransform>(() => {
    const p = new Vector3().fromArray(modelTransform.position);
    const q = new Quaternion().fromArray(modelTransform.quaternion);
    const m = new Matrix4().compose(p, q, new Vector3(1, 1, 1));
    return {
      position: p.toArray(),
      quaternion: [q.x, q.y, q.z, q.w],
      scale: [1, 1, 1],
      worldMatrix: m.toArray(),
      worldMatrixRhd: m.clone().toArray(),
    };
  }, [modelTransform]);

  const box = useMemo(() => {
    const b = pointCloudObject.tree.boundingBox.clone();
    b.applyMatrix4(new Matrix4().fromArray(cloudTransform.worldMatrix));
    return b;
  }, [pointCloudObject, cloudTransform]);

  // Position of where the camera needs to look at
  const target = useMemo(() => {
    return box.getCenter(new Vector3());
  }, [box]);

  const size = useThree((s) => s.size);

  const targetCameraPos = useMemo(() => {
    const delta = new Vector3().subVectors(box.max, box.min);
    const t = delta.x;
    delta.x = delta.z;
    delta.z = t;
    return new Vector3().addVectors(target, delta);
  }, [target, box]);

  // In case of ortho camera, resize the camera frustum to fit the bbox
  useLayoutEffect(() => {
    const d = box.min.distanceTo(box.max);
    if (camera instanceof OrthographicCamera) {
      Object.assign(camera, { manual: true });
      camera.top = d * BOX_DIAG_MULTIPLIER;
      camera.bottom = -camera.top;
      camera.right = d * BOX_DIAG_MULTIPLIER;
      camera.left = -camera.right;
      camera.near = 0.01;
      camera.far = 4 * d;
      camera.updateProjectionMatrix();
    }

    // Adjust the aspect ratio of the camera.
    reproportionCamera(camera, size.width / size.height);
  }, [pointCloudObject, camera, target, size.width, size.height, box]);

  return {
    cloudTransform,
    box,
    target,
    targetCameraPos,
  };
}
