import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import {
  BackgroundTask,
  isFileUploadTask,
  sortActiveBackgroundTasks,
} from "@/utils/background-tasks";
import {
  selectAncestor,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import {
  BackgroundTaskState,
  isBackgroundTaskActive,
} from "@faro-lotv/service-wires";
import { RootState } from "../store";

/**
 * @returns a list of background tasks, filtered by the type guard.
 * @param filterFn a type guard to filter the tasks with.
 */
export function selectBackgroundTasks<T extends BackgroundTask>(
  filterFn: (task: BackgroundTask, state: RootState) => task is T,
): (state: RootState) => T[];

export function selectBackgroundTasks(
  filterFn?: (task: BackgroundTask, state: RootState) => boolean,
): (state: RootState) => BackgroundTask[];

/**
 *  @returns a list of background tasks
 *  @param filterFn optional filter function applied to the task list
 */
export function selectBackgroundTasks(
  filterFn: (task: BackgroundTask, state: RootState) => boolean = () => true,
): (state: RootState) => BackgroundTask[] {
  return (state: RootState) => {
    return state.backgroundTasks.backgroundTasks.filter((task) =>
      filterFn(task, state),
    );
  };
}

/**
 *  @returns a background task
 *  @param id ID of the required background task
 */
export function selectBackgroundTask(id?: GUID) {
  return ({ backgroundTasks }: RootState): BackgroundTask | undefined =>
    backgroundTasks.backgroundTasks.filter(
      (backgroundTask) => backgroundTask.id === id,
    )[0];
}

/**
 *  @returns a background task for this element
 *  @param id ID of the iElement to whose task is required
 */
export function selectBackgroundTaskForIElement(id?: GUID) {
  return ({ backgroundTasks }: RootState): BackgroundTask | undefined => {
    if (!id) return;

    const tasks = backgroundTasks.backgroundTasks.filter((backgroundTask) => {
      // At the moment we don't need anywhere to report CAD upload tasks. Exclude them from selector
      const cadUpload =
        isFileUploadTask(backgroundTask) &&
        backgroundTask.metadata.uploadElementType === UploadElementType.cad;

      return (
        !cadUpload &&
        isBackgroundTaskActive(backgroundTask.state) &&
        backgroundTask.iElementId === id
      );
    });

    // Sort so the running tasks are first, then the scheduled, then the created
    sortActiveBackgroundTasks(tasks);

    return tasks[0];
  };
}

/**
 * Return the task relative to a floor that we can consider the most important.
 *
 * The most important task is a non finished/failed task and if there are more then one
 * we prefer one that is running compared to one that is only scheduled or created
 *
 * @param floorId id of the floor we want the task of
 * @returns the most important task if one is available
 */
export function selectMostImportantBackgroundTaskForFloor(floorId?: GUID) {
  return (state: RootState): BackgroundTask | undefined => {
    if (!floorId) return;
    // Select all tasks that are active and reference an IElement in the required floor
    const tasks = selectActiveBackgroundTasksInChildren(floorId)(state);

    // Sort so the running tasks are first, then the scheduled, then the created
    sortActiveBackgroundTasks(tasks);

    return tasks[0];
  };
}

/**
 * @param id - ID of the element whose children needs to be checked for having an active background task
 * @returns list of active background tasks in its children
 */
export function selectActiveBackgroundTasksInChildren(id: GUID) {
  return (state: RootState): BackgroundTask[] => {
    if (!id) return [];
    // Select all tasks that are active and whose ancestor is the given ID
    const tasks = selectBackgroundTasks((task, state) => {
      if (!task.iElementId) return false;
      const targetElement = selectIElement(task.iElementId)(state);
      if (!targetElement) return false;

      return (
        isBackgroundTaskActive(task.state) &&
        !!selectAncestor(targetElement, (ancestor) => ancestor.id === id)(state)
      );
    })(state);
    return tasks;
  };
}

/**
 *  @returns a list of background tasks based on the requested state
 *  @param backgroundTaskState The state of the required list of background tasks should be
 */
export function selectBackgroundTasksBasedOnState(
  backgroundTaskState: BackgroundTaskState,
) {
  return ({ backgroundTasks }: RootState) =>
    backgroundTasks.backgroundTasks.filter(
      (backgroundTask) => backgroundTask.state === backgroundTaskState,
    );
}

/**
 *  @returns a list of background tasks that should block the closing of the window
 *  That is, they have the shouldPreventWindowClose property true, and have a status of InProgress
 */
export function selectBackgroundTasksThatShouldPreventWindowClose() {
  return ({ backgroundTasks }: RootState) =>
    backgroundTasks.backgroundTasks.filter(
      (backgroundTask) =>
        backgroundTask.shouldPreventWindowClose &&
        (backgroundTask.state === BackgroundTaskState.created ||
          backgroundTask.state === BackgroundTaskState.scheduled ||
          backgroundTask.state === BackgroundTaskState.started),
    );
}
