import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { HtmlProps } from "@react-three/drei/web/Html";
import { MutableRefObject, useMemo, useState } from "react";
import { Material, Matrix3, Matrix4, Vector3 } from "three";
import { TwoPointMeasureSegment } from "./two-point-segment-renderer";

type MultiSegmentRendererProps = {
  /** The list of points describing the polyline */
  points: Vector3[];
  /** A flag specifying if the polyline is closed */
  isClosed: boolean;
  /** A flag specifying if the measurement is the current active one */
  isActive: boolean;
  /** A ref to the parent HTML element to which all labels will be attached */
  labelContainer: MutableRefObject<HTMLElement>;
  /** The HTML property for the pointer events on the HTML labels */
  labelsPointerEvents: HtmlProps["pointerEvents"];
  /** The unit of measure used to display the measurement */
  unitOfMeasure: SupportedUnitsOfMeasure;
  /** The world matrix of the whole measurement */
  worldMatrix: Matrix4;
  /** A flag for making dashed lines */
  dashed?: boolean;
  /** Whether depth testing should be used to render the segment */
  depthTest?: Material["depthTest"];
  /** Callback executed when a label is clicked */
  onClick?(): void;
};

/** @returns A component to render a polyline that, optionally, could be closed, where each segment have a label showing the length */
export function MultiSegmentRenderer({
  points,
  isClosed,
  isActive,
  labelContainer,
  labelsPointerEvents,
  unitOfMeasure,
  worldMatrix,
  dashed,
  depthTest,
  onClick,
}: MultiSegmentRendererProps): JSX.Element {
  const matrix = useMemo(
    () => new Matrix3().setFromMatrix4(worldMatrix),
    [worldMatrix],
  );

  const [TEMP_VEC3] = useState(() => new Vector3());

  return (
    <>
      {points.map((p1, i) => {
        if (!isClosed && i === points.length - 1) return null;
        const nextIndex = (i + 1) % points.length;
        const p2 = points[nextIndex];
        return (
          <TwoPointMeasureSegment
            key={i}
            main={true}
            start={p1}
            end={p2}
            length={TEMP_VEC3.subVectors(p2, p1).applyMatrix3(matrix).length()}
            index={0}
            live={false}
            visible={true}
            isMeasurementActive={isActive}
            isLabelActive={isActive}
            labelContainer={labelContainer}
            labelsPointerEvents={labelsPointerEvents}
            unitOfMeasure={unitOfMeasure}
            onClick={() => onClick?.()}
            dashed={dashed}
            depthTest={depthTest}
            labelPosition={new Vector3().addVectors(p1, p2).multiplyScalar(0.5)}
          />
        );
      })}
    </>
  );
}
