import { useSceneEvents } from "@/components/common/scene-events-context";
import { ElementIcon, ElementIconType } from "@/components/ui/icons";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { selectActiveAreaOrThrow } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { FaroIconButton, useCheckForOverflow } from "@faro-lotv/flat-ui";
import { isAreaAligned } from "@faro-lotv/ielement-types";
import { Box, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { AlignAreaButton } from "../../align-area-button";
import { TreeNode, TreeNodeProps } from "../tree-node";
import { TreePopUp } from "../tree-popup";
import { ContextMenuCadModelTree } from "./cad-model-tree-context-menu";
import { CadModelTreeNodeData } from "./cad-model-tree-data";
import {
  highlightModelTreeNode,
  isNodeVisible,
  setNodeVisibility,
  unhighlightCadObjects,
} from "./cad-model-visibility";
import {
  compatibilityMessage,
  useDisableCaptureTreeAlignment,
} from "./use-disable-capture-tree-alignment";

/**
 * @returns A node in the CAD model tree
 */
export function CadModelTreeNode({
  node,
  style,
}: TreeNodeProps<CadModelTreeNodeData>): JSX.Element {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();

  const boxRef = useRef<HTMLDivElement>();

  const toggleContextMenu = useCallback(
    (open: boolean) => {
      if (isPopupOpen && open) {
        setIsPopupOpen(false);
      }
    },
    [isPopupOpen],
  );
  const highlightedCadObjects = useRef<number[]>([]);
  const cadSceneEvents = useSceneEvents();
  const visibilityIconActive = useRef(false);
  const activeCad = useAppSelector(selectActiveCadModel);
  const activeArea = useAppSelector(selectActiveAreaOrThrow());

  const shouldDisableAlignment = useDisableCaptureTreeAlignment();

  const highlightNodeIn3DScene = useCallback(() => {
    setIsPopupOpen(true);
    // After clicking on the eye icon, the entry event is triggered again,
    // and we don't want to highlight the node at the moment.
    if (visibilityIconActive.current) {
      return;
    }
    unhighlightCadObjects(
      node.data.cadModelData,
      highlightedCadObjects.current,
    );
    highlightedCadObjects.current.length = 0;
    highlightModelTreeNode(node.data, highlightedCadObjects.current);
    cadSceneEvents?.invalidate.emit();
  }, [node, cadSceneEvents]);

  const unhighlightNodeIn3DScene = useCallback(() => {
    setIsPopupOpen(false);
    if (highlightedCadObjects.current.length > 0) {
      unhighlightCadObjects(
        node.data.cadModelData,
        highlightedCadObjects.current,
      );
      highlightedCadObjects.current.length = 0;
      cadSceneEvents?.invalidate.emit();
    }
    // reset the flag after move out of the node
    visibilityIconActive.current = false;
  }, [node.data, cadSceneEvents]);

  const changeNodeVisibility = useCallback(() => {
    setNodeVisibility(node.data, !node.data.visibility);
    if (highlightedCadObjects.current.length > 0) {
      unhighlightCadObjects(
        node.data.cadModelData,
        highlightedCadObjects.current,
      );
      highlightedCadObjects.current.length = 0;
    }
    cadSceneEvents?.invalidate.emit();
    // Mark the flag to prevent the entry event from highlighting the CAD again
    visibilityIconActive.current = true;
  }, [node.data, cadSceneEvents?.invalidate]);

  return (
    <Box
      ref={boxRef}
      component="div"
      style={{ ...style, height: "100%" }}
      onMouseEnter={highlightNodeIn3DScene}
      onMouseLeave={unhighlightNodeIn3DScene}
    >
      <TreePopUp
        parentRef={boxRef}
        open={isPopupOpen}
        title={node.data.name}
        isTooltipEnabled={hasOverflown}
      >
        <TreeNode<CadModelTreeNodeData>
          node={node}
          shouldExpandNodeOnClick={false}
        >
          <Stack
            direction="row"
            flexGrow={1}
            alignItems="center"
            overflow="hidden"
          >
            <Typography
              ml={1}
              flexGrow={1}
              fontSize="0.875em"
              noWrap
              onMouseEnter={(e) => checkForOverflow(e.currentTarget)}
            >
              {node.data.name}
            </Typography>
          </Stack>
          {node.data.idIElementModel3dStream &&
            activeCad &&
            !isAreaAligned(activeArea) && (
              <AlignAreaButton
                disabled={shouldDisableAlignment}
                area={activeArea}
                tooltip={
                  shouldDisableAlignment
                    ? compatibilityMessage
                    : "Align active Sheet to 3D Model"
                }
              />
            )}
          <FaroIconButton
            onClick={changeNodeVisibility}
            margin={0.5}
            size="s"
            aria-label="toggle model visibility"
            // Get both the image color and its hover color from the container
            color="inherit"
            hoverColor="inherit"
            // Change the button color to forward the container's color to the image
            sx={{ color: "inherit" }}
          >
            <ElementIcon
              icon={
                isNodeVisible(node.data)
                  ? ElementIconType.Visible
                  : ElementIconType.NonVisible
              }
            />
          </FaroIconButton>
          <ContextMenuCadModelTree
            onToggle={toggleContextMenu}
            idIElementModel3dStream={node.data.idIElementModel3dStream}
            modelMessages={node.data.modelMessages}
          />
        </TreeNode>
      </TreePopUp>
    </Box>
  );
}
