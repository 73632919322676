import { PropsWithChildren } from "react";

/** @returns a component that will stop the propagation of mouse,pointer and keyboard events */
export function EventBarrier({ children }: PropsWithChildren): JSX.Element {
  return (
    <div
      onPointerDown={stopPropagation}
      onPointerUp={stopPropagation}
      onPointerMove={stopPropagation}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      onMouseMove={stopPropagation}
      onWheel={stopPropagation}
      onClick={stopPropagation}
      onDoubleClick={stopPropagation}
      onContextMenu={stopPropagation}
      onKeyDown={stopPropagation}
      onKeyUp={stopPropagation}
      onKeyPress={stopPropagation}
    >
      {children}
    </div>
  );
}

/**
 * A generic event handler to stop the propagation of that event
 *
 * @param event to stop the propagation of
 */
function stopPropagation(event: Pick<MouseEvent, "stopPropagation">): void {
  event.stopPropagation();
}
