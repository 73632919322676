import { matrix4ToAlignmentTransform } from "@/alignment-tool/utils/alignment-transform";
import {
  selectCrossSectionEnabledForTomographicView,
  selectSheetElevation,
} from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import { setCrossSectionEnabled } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { CrossSectionToggleButton } from "../align-to-cad-commons/cross-section-toggle-button";
import { useSheetSelectedForCadAlignment } from "../mode-data-context";

/**
 * @returns cad tomographic view toggle cross-section vs full CAD
 */
export function SheetToCadCrossSectionToggle(): JSX.Element {
  const dispatch = useAppDispatch();

  const isCrossSectionEnabled = useAppSelector(
    selectCrossSectionEnabledForTomographicView,
  );

  const sheetElevation = useAppSelector(selectSheetElevation);
  const sheet = useSheetSelectedForCadAlignment();
  const sheetMatrix = useAppSelector(selectIElementWorldMatrix4(sheet.id));
  const sheetTransform = matrix4ToAlignmentTransform(sheetMatrix);
  const elevation = sheetElevation ?? sheetTransform.position[1];

  return (
    <CrossSectionToggleButton
      isEnabled={isCrossSectionEnabled}
      elevation={elevation}
      onToggleCrossSections={() => {
        dispatch(setCrossSectionEnabled(!isCrossSectionEnabled));
      }}
    />
  );
}
