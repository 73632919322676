import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { selectObjectVisibility } from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { GUID, IElementGenericAnnotation } from "@faro-lotv/ielement-types";
import { selectChildDepthFirst } from "@faro-lotv/project-source";
import { Material, Matrix4, Object3D } from "three";
import { GeneralLinkAnnotationRenderer } from "./annotation-renderers/general-link-annotation-renderer";
import { ImageAnnotationRenderer } from "./annotation-renderers/image-annotation-renderer";
import { InfoAnnotationRenderer } from "./annotation-renderers/info-annotation-renderer";
import { MarkupAnnotationRenderer } from "./annotation-renderers/markup-annotation-renderer";
import { AnnotationSorterProvider } from "./annotation-sorter";
import {
  AnnotationProps,
  AnnotationTypes,
  selectAnnotationType,
} from "./annotations-types";

const IDENTITY = new Matrix4();

export type AnnotationsRendererProps = {
  /** The list of annotations to render */
  annotations: IElementGenericAnnotation[];
  /** The world offset applied to the pano associated to these annotations */
  worldTransform?: Matrix4;
  /**  if true, the annotation is not collapsed based on the view */
  preventCollapse?: boolean;
  /** The distance threshold after which annotations start fading (in meters) */
  fadeThreshold?: number;
  /** The distance threshold over which annotations are hidden (in meters) */
  hideThreshold?: number;
  /** Optional ID of the annotation to look at */
  lookAtId?: GUID;
  /** Callback executed when the active element should be changed */
  onTargetElementChanged?(element: WalkSceneActiveElement): void;
  /** Whether depth testing should be used to render the annotations */
  depthTest?: Material["depthTest"];
  /** The render order to use for the annotations */
  renderOrder?: Object3D["renderOrder"];
};

/** @returns A renderer for the annotations of a panorama image */
export function AnnotationsRenderer({
  annotations,
  worldTransform = IDENTITY,
  preventCollapse,
  fadeThreshold,
  hideThreshold,
  lookAtId,
  renderOrder,
  depthTest,
  onTargetElementChanged,
}: AnnotationsRendererProps): JSX.Element | null {
  const store = useAppStore();

  const { canReadAnnotations } = useAnnotationPermissions();
  const shouldAnnotationsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.annotations),
  );
  if (!canReadAnnotations || !shouldAnnotationsBeVisible) return null;

  return (
    <AnnotationSorterProvider
      annotations={annotations}
      preventCollapse={preventCollapse}
      fadeThreshold={fadeThreshold}
      hideThreshold={hideThreshold}
    >
      {annotations.map((a) => (
        <AnnotationRenderer
          key={a.id}
          iElement={a}
          worldTransform={worldTransform}
          onTargetElementChanged={onTargetElementChanged}
          depthTest={depthTest}
          renderOrder={renderOrder}
          expandOnce={
            selectChildDepthFirst(
              a,
              (el) => el.id === lookAtId,
            )(store.getState()) !== undefined
          }
        />
      ))}
    </AnnotationSorterProvider>
  );
}

/**
 * @returns a specific component depending on the type of the annotation. If the annotation is not supported it is not rendered.
 */
function AnnotationRenderer({
  iElement,
  onTargetElementChanged,
  worldTransform,
  depthTest,
  renderOrder,
  expandOnce = false,
}: AnnotationProps): JSX.Element | null {
  const annotationType = useAppSelector(selectAnnotationType(iElement));

  switch (annotationType) {
    case AnnotationTypes.info:
      return (
        <InfoAnnotationRenderer
          iElement={iElement}
          worldTransform={worldTransform}
        />
      );
    case AnnotationTypes.image:
      return (
        <ImageAnnotationRenderer
          iElement={iElement}
          worldTransform={worldTransform}
        />
      );
    case AnnotationTypes.generalLink:
      return (
        <GeneralLinkAnnotationRenderer
          iElement={iElement}
          worldTransform={worldTransform}
          onTargetElementChanged={onTargetElementChanged}
        />
      );
    case AnnotationTypes.markup:
      return (
        <MarkupAnnotationRenderer
          annotation={iElement}
          worldTransform={worldTransform}
          expandOnce={expandOnce}
          renderOrder={renderOrder}
          depthTest={depthTest}
        />
      );
    default:
      return null;
  }
}
