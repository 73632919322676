import { AnnotationsRenderer } from "@/components/r3f/renderers/annotations/annotations-renderer";
import { MeasurementsRenderer } from "@/components/r3f/renderers/measurements/measurements-renderer";
import { WayPointTarget } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { PanoramaRenderer } from "@/components/r3f/renderers/panorama-renderer";
import { useSceneContextMenuEventHandlers } from "@/hooks/use-scene-context-menu-event-handlers";
import { PanoObject } from "@/object-cache";
import { Measurement } from "@/store/measurement-tool-slice";
import { selectPanoAnnotationSection } from "@/store/selections-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectFilteredElementsWithTags } from "@/store/tags/tags-selectors";
import { PickingToolsRef } from "@/tools/picking-tools";
import { usePanoAnnotationsAdjustedPose } from "@/utils/camera-transform";
import {
  Img360PanoRef,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
} from "@faro-lotv/app-component-toolbox";
import {
  GUID,
  IElementDepthMap,
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
  isIElementGenericAnnotation,
  isIElementMarkup,
} from "@faro-lotv/ielement-types";
import {
  EquirectangularDepthImage,
  LodPano,
  decodeCdiBuffer,
} from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { isEqual } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { WalkPlaceholders } from "./walk-placeholders";
import { selectDepthMapForImg360 } from "./walk-scene-selectors";
import { WalkSceneActiveElement } from "./walk-types";

export type WalkPanoramaProps = PickingToolsRef & {
  /* The panorama object to render */
  pano: PanoObject;

  /** List of all the placeholders to show */
  placeholders: IElementImg360[];

  /** If true other panorama's placeholders will be rendered  */
  shouldShowPlaceholders: boolean;

  /** If true, the panorama's annotations will be rendered  */
  shouldShowAnnotations: boolean;

  /** List of all the annotations for this area */
  annotations: IElementGenericAnnotation[];

  /** True if there's an active tool */
  isToolActive: boolean;

  /** List of placeholder IDs to hide from the placeholders list */
  hiddenPlaceholders: GUID[];

  /** List of all the measurements in the store for the current selection */
  measurements: Measurement[];

  /** The floor image where the placeholders are placed */
  sheet: IElementGenericImgSheet;

  /* True to show, will animate opacity */
  visible: boolean;

  /** Define to true to register this as a secondary view for split screen rendering */
  isSecondaryView?: boolean;

  /** Optional ID of the annotation to look at */
  lookAtId?: GUID;

  /** Callback executed when the active element should be changed */
  onTargetElementChanged(element: WalkSceneActiveElement): void;

  /** Callback called when the active waypoint target element inside the walk scene has changed. */
  onWayPointChanged(target: WayPointTarget): void;
};

async function loadDepths(
  pano: LodPano,
  depthMap: IElementDepthMap,
  signal: AbortSignal,
): Promise<void> {
  const ret = await fetch(depthMap.uri, { signal });
  if (!ret.ok) return;
  const b = await ret.blob();
  const data = await b.arrayBuffer();
  const depthData = depthMap.name.endsWith("cdi.br")
    ? decodeCdiBuffer(new DataView(data))
    : new Float32Array(data);
  const equirectImage = new EquirectangularDepthImage(
    depthMap.pixelWidth,
    depthData,
  );
  if (!signal.aborted) {
    pano.tiledPano.setDepths(equirectImage);
  }
}

type PanoWidthDepthProps = PickingToolsRef &
  Pick<WalkPanoramaProps, "pano" | "sheet" | "isSecondaryView">;

/** @returns a component that renders a LOD panorama image and loads and manages its depth information if present.  */
function WalkPanoWithDepth({
  pano,
  sheet,
  isSecondaryView,
  onModelClicked,
  onModelHovered,
  onModelZoomed,
}: PanoWidthDepthProps): JSX.Element {
  const ref = useRef<Img360PanoRef>();
  const depthMap = useAppSelector(selectDepthMapForImg360(pano.iElement));

  useEffect(() => {
    if (!ref.current) return;
    if (!depthMap) return;

    const controller = new AbortController();
    const connection =
      ref.current === pano
        ? undefined
        : ref.current.depthsChanged.pipe(pano.depthsChanged);
    if (ref.current.depths === undefined) {
      loadDepths(ref.current, depthMap, controller.signal).catch((error) => {
        console.warn(error);
      });
    }
    return () => {
      controller.abort();
      connection?.dispose();
    };
  }, [ref, depthMap, pano]);

  return (
    <PanoramaRenderer
      pano={pano}
      sheet={sheet}
      ref={ref}
      isSecondaryView={isSecondaryView}
      onClick={(ev) => onModelClicked(ev, pano.iElement.id)}
      onPointerMove={(ev) => onModelHovered(ev, pano.iElement.id)}
      onWheel={(ev) => onModelZoomed(ev, pano.iElement.id)}
    />
  );
}

/**
 * @returns the rendering logic for panorama images inside a WalkScene
 */
export function WalkPanorama({
  pano,
  placeholders,
  measurements,
  shouldShowPlaceholders,
  shouldShowAnnotations,
  annotations,
  isToolActive,
  hiddenPlaceholders,
  sheet,
  visible,
  isSecondaryView,
  lookAtId,
  onTargetElementChanged,
  onWayPointChanged,
  ...toolEvents
}: WalkPanoramaProps): JSX.Element | null {
  // Compute annotations section and adjusted pose
  const panoAdjustedPose = usePanoAnnotationsAdjustedPose(pano.iElement);
  const panoSection = useAppSelector(
    selectPanoAnnotationSection(pano.iElement),
  );

  useSceneContextMenuEventHandlers({ panoObject: pano });

  const camera = useThree((s) => s.camera);

  // Collect annotations of the current panorama
  const generalAnnotations = useAppSelector(
    selectChildrenDepthFirst(panoSection, isIElementGenericAnnotation),
    isEqual,
  );
  const filteredGeneralAnnotations = useAppSelector(
    selectFilteredElementsWithTags(generalAnnotations, (el) =>
      selectChildDepthFirst(el, isIElementMarkup),
    ),
    isEqual,
  );

  // Remove general annotations from area annotations, so that there wont be duplicate rendering of same annotations
  const areaAnnotations = useMemo(
    () =>
      annotations.filter(
        (annotation) =>
          !filteredGeneralAnnotations.some((a) => a.id === annotation.id),
      ),
    [annotations, filteredGeneralAnnotations],
  );

  if (!visible) {
    return null;
  }

  return (
    <>
      <WalkPanoWithDepth
        pano={pano}
        sheet={sheet}
        isSecondaryView={isSecondaryView}
        {...toolEvents}
      />

      {shouldShowPlaceholders && (
        <WalkPlaceholders
          placeholders={placeholders}
          sheet={sheet}
          hiddenPlaceholders={hiddenPlaceholders}
          onPlaceholderClick={(targetElement, position) => {
            onTargetElementChanged(targetElement);

            onWayPointChanged({
              targetElement,
              position,
              quaternion: camera.quaternion,
            });
          }}
          shouldFadeOff
        />
      )}

      {shouldShowAnnotations && (
        <>
          {/* The annotations which are local to the panorama and they should not be collapsed */}
          <AnnotationsRenderer
            annotations={filteredGeneralAnnotations}
            worldTransform={panoAdjustedPose}
            preventCollapse
            onTargetElementChanged={onTargetElementChanged}
            lookAtId={lookAtId}
          />
          {pano.iElement.isRotationAccurate && (
            // Annotation that are created in the nearby area of the panorama, which needs to be collapsed if they are far away
            // Visible only if the pano has a correct rotation
            <AnnotationsRenderer
              annotations={areaAnnotations}
              worldTransform={panoAdjustedPose}
              onTargetElementChanged={onTargetElementChanged}
              lookAtId={lookAtId}
            />
          )}
        </>
      )}

      <MeasurementsRenderer
        measurements={measurements}
        isToolActive={isToolActive}
      />
    </>
  );
}
