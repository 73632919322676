import { useAppSelector } from "@/store/store-hooks";
import { selectProjectName } from "@faro-lotv/app-component-toolbox";
import { FaroText, useCheckForOverflow } from "@faro-lotv/flat-ui";
import { Breadcrumbs, Link, Tooltip } from "@mui/material";
import { useState } from "react";

type ToolBreadcrumbsProps = {
  /** The name of the tool to display in the breadcrumbs. */
  toolName: string;

  /** A callback that is called when the user tries to redirect back to the viewer */
  onRedirectToViewerClicked?(): void;
};

/**
 * @returns The breadcrumbs for a tool in the Sphere Viewer.
 * Displays the project name and the name of the tool.
 * Clicking on the project name executes the callback to redicrect the user back to the main page.
 */
export function ToolBreadcrumbs({
  toolName,
  onRedirectToViewerClicked,
}: ToolBreadcrumbsProps): JSX.Element {
  const projectName = useAppSelector(selectProjectName);

  const { hasOverflown, checkForOverflow } = useCheckForOverflow();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  return (
    <Breadcrumbs
      color="inherit"
      aria-label="breadcrumb"
      sx={{ textTransform: "uppercase" }}
    >
      <Tooltip
        title={projectName}
        open={isTooltipOpen && hasOverflown}
        onClose={() => setIsTooltipOpen(false)}
      >
        <Link
          underline="none"
          color="text.secondary"
          href="/"
          onClick={(e) => {
            // we have an href to cause a url to show in the browser, but we want to prevent default
            // so that we can ask for confirmation before actually redirecting.
            e.preventDefault();
            onRedirectToViewerClicked?.();
          }}
          sx={{
            maxWidth: 330,
            display: "flex",
          }}
        >
          <FaroText
            variant="heading12"
            sx={{ color: "gray600" }}
            noWrap
            onMouseEnter={(ev) => {
              checkForOverflow(ev.currentTarget);
              setIsTooltipOpen(true);
            }}
          >
            {projectName}
          </FaroText>
        </Link>
      </Tooltip>
      <FaroText variant="heading12" sx={{ display: "flex" }}>
        {toolName}
      </FaroText>
    </Breadcrumbs>
  );
}
