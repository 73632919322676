import { useThreeContext } from "@/components/common/three-context/three-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import {
  Features,
  selectGetFeatureValueIfEnabled,
} from "@/store/features/features-slice";
import { RootState, store } from "@/store/store";
import {
  loadLodFloorPlan,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { removeExtension } from "@faro-lotv/foundation";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import {
  CadModelTreeDescription,
  GUID,
  IElement,
  IElementBase,
  IElementGenericImgSheet,
  IElementGenericModel3d,
  IElementGenericPointCloudStream,
  IElementImg360,
  IElementImgSheet,
  IElementImgSheetTiled,
  IElementModel3dStream,
  IElementType,
  Model3dStreamDescription,
  firstVersionSubMeshesInProd,
  isCadModelTreeDescription,
  isIElementImgSheetTiled,
  isIElementModel3dStream,
  isIElementPointCloudStreamWebShare,
  isModel3dStreamURLs,
  isPotreeURLs,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  AdaptivePointsMaterial,
  CadModel,
  Disposable,
  ICadModel,
  LodCachingStrategyMaxChunks,
  LodFloorPlan,
  LodPano,
  LodPointCloud,
  LodTree,
  ModelAboutToLoad,
  TextureLoader,
  TypedEvent,
  WSInstance,
  createCadModel,
  createPotree,
  createStreamCadModel,
  loadGltf,
  safeDispose,
} from "@faro-lotv/lotv";
import { createPanoLodTree, getPanoOverviewUrl } from "@faro-lotv/spatial-ui";
import { useEffect, useMemo, useState } from "react";
import {
  Color,
  Group,
  LinearMipMapLinearFilter,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
} from "three";
import { useModelAboutToLoad } from "./hooks/use-model-about-to-load";
import { runtimeConfig } from "./runtime-config";
import { selectDefaultCadMaxByteToLoad } from "./store/cad/cad-slice";

/**
 * An entry in an object cache
 */
export class CacheEntry<Type extends Object3D> {
  /** The keys to identify this entry in the cache */
  key: GUID;
  /** The promise loading the data */
  promise: Promise<Type>;
  /** RefCount of the components using this cached object */
  refCount = 0;
  /** How many milliseconds to keep this object in cache when refCount goes to 0 */
  lifespan = 0;
  /** The loading error if the loading failed */
  error?: unknown;
  /** The loaded object when the promise resolve */
  response?: Type;
  /** The timeout id for the disposal that start when refCount goes to 0 */
  disposeTimeout?: number;
  /** Signal this entry has been disposed */
  disposed = new TypedEvent<void>();
  /** Whether the promise was settled or not, regardless of fulfillment or rejection */
  #promiseSettled = false;

  /**
   * Create a new cache entry
   *
   * @param promise The promise loading the data
   * @param key The key to identify this entry in the cache
   * @param lifespan How many milliseconds to keep this alive when refcount goes to 0
   */
  constructor(promise: Promise<Type>, key: GUID, lifespan: number) {
    this.key = key;
    this.promise = promise;
    this.lifespan = lifespan;
    this.promise
      .then((value) => {
        this.response = value;
      })
      .catch((error) => {
        // It should be sufficient to remember only one error
        if (this.error === undefined) {
          this.error = error;
        }
      })
      .finally(() => {
        this.#promiseSettled = true;
        this.scheduleDisposeIfNeeded();
      });
  }

  /**
   * Get this object from the cache or suspend execution while it's loaded
   *
   * @returns The object or suspend
   */
  getOrSuspend(): Type {
    if (this.response) return this.response;
    if (this.error) throw this.error;
    throw this.promise;
  }

  /**
   * Add a reference to this object to keep it alive in the cache
   */
  addRef(): void {
    this.refCount++;
    if (this.refCount > 0 && this.disposeTimeout) {
      this.dismissDispose();
    }
  }

  /** Remove a reference to this object, will start dispose timer if refCount is 0 */
  decRef(): void {
    this.refCount--;
    this.scheduleDisposeIfNeeded();
  }

  /** Dispose of the resources of this cache entry */
  dispose(): void {
    if (this.response) safeDispose(this.response);
    this.disposed.emit();
  }

  /** Schedule the disposal of this element after the lifespan expired */
  scheduleDisposeIfNeeded(): void {
    if (this.refCount > 0 || !this.promiseSettled) return;

    this.disposeTimeout = window.setTimeout(() => {
      this.dispose();
    }, this.lifespan);
  }

  /** Clear the dispose timeout if it's running */
  dismissDispose(): void {
    window.clearTimeout(this.disposeTimeout);
    this.disposeTimeout = undefined;
  }

  /** @returns whether the promise was settled */
  get promiseSettled(): boolean {
    return this.#promiseSettled;
  }
}

/** The object this cache is holding */
export type CachedObject<Cache> =
  Cache extends ObjectCache<IElementBase, infer T> ? T : never;

/** Function to get a token to authenticate for API calls */
type TokenProvider = () => Promise<string>;

type LoaderFunctionParams = {
  /** A function to get a token to talk to the api */
  tokenProvider?: TokenProvider;
  /** A function to report that GPU is about to freeze to load 3D model */
  modelLoadingCallback?: ModelAboutToLoad;
  /**
   * Function returning the maximum number of bytes to load in memory for 3D model.
   * We are passing a function because actual value is not yet known when starting the download.
   */
  getModelMaxByteToLoad(): number;
};

/** Function to load an iElement in the cache */
type LoaderFunction<Element, ObjectType> = (
  iElement: Element,
  params: LoaderFunctionParams,
) => Promise<ObjectType>;

/** Short lifespan of 1 minute (ms before disposing) to use to cache short lived objects (Eg. PanoImages) */
const SHORT_LIFESPAN = 60000;

/** Long lifespan of 10 minutes (ms before disposing) to use for long lived objects (Eg. PointClouds, CadModels) */
const LONG_LIFESPAN = 600000;

/**
 * A cache for 3d objects
 */
export class ObjectCache<
  CachedElement extends IElementBase = IElementBase,
  Type extends Object3D = Object3D,
> {
  /** The cache entries */
  #cache = new Map<GUID, CacheEntry<Type>>();
  /** The function used to load an entry in this cache */
  #loaderFunction: LoaderFunction<CachedElement, Type>;
  /** The lifespan for the object in this cache */
  lifespan: number;

  /**
   * Create a new object cache
   *
   * @param loaderFunction The function used to load objects in this cache
   * @param lifespan The lifespan of objects in this cache
   */
  constructor(
    loaderFunction: LoaderFunction<CachedElement, Type>,
    lifespan = SHORT_LIFESPAN,
  ) {
    this.#loaderFunction = loaderFunction;
    this.lifespan = lifespan;
  }

  /**
   * Peek for an item in the cache without suspending
   *
   * @param key The key for the item
   * @returns The item or undefined if not available
   */
  peek(key: GUID): Type | undefined {
    return this.#cache.get(key)?.response;
  }

  /**
   * Access a cache entry if it exists in the cache
   *
   * @param key to search in the cache
   * @returns the cache entry with all item metadata and state
   */
  entry(key: GUID): Readonly<CacheEntry<Type>> | undefined {
    return this.#cache.get(key);
  }

  /**
   * Start loading an object in the cache
   *
   * @param iElement The descriptor of the object to load
   * @param params Optional parameters for the loading
   * @returns The cache entry
   */
  preload(
    iElement: CachedElement,
    params: LoaderFunctionParams,
  ): CacheEntry<Type> {
    const key = iElement.id;
    let entry = this.#cache.get(key);
    if (entry) {
      return entry;
    }
    entry = new CacheEntry(
      this.#loaderFunction(iElement, params),
      key,
      this.lifespan,
    );
    entry.disposed.on(() => this.#cache.delete(key));
    this.#cache.set(key, entry);
    return entry;
  }

  /**
   * Get an object or start loading it and suspend
   *
   * @param iElement The descriptor of the object to query
   * @param params Optional parameters for the loading
   * @returns The object or suspend
   */
  getOrLoad(iElement: CachedElement, params: LoaderFunctionParams): Type {
    const entry = this.#cache.get(iElement.id);
    if (entry) {
      return entry.getOrSuspend();
    }
    return this.preload(iElement, params).getOrSuspend();
  }

  /**
   *
   * @param id ID of iElement being loaded
   * @returns Whether the loading promise has been settled, regardless of fulfillment of rejection.
   */
  isPromiseSettled(id: string): boolean {
    const entry = this.#cache.get(id);
    if (!entry) return true;
    return entry.promiseSettled;
  }

  /**
   * Add a reference to an object to keep it alive
   *
   * @param iElement The descriptor of the object to add a reference to
   */
  addRef(iElement: CachedElement): void {
    const cacheElement = this.#cache.get(iElement.id);
    if (!cacheElement) {
      throw Error(
        `Requesting to increment the refCount of an ${iElement.id} that is not in cache`,
      );
    }
    cacheElement.addRef();
  }

  /**
   * Remove a reference from an object to keep it alive
   *
   * @param iElement The descriptor of the object to add a reference to
   */
  decRef(iElement: CachedElement): void {
    const cacheElement = this.#cache.get(iElement.id);
    if (!cacheElement) {
      throw Error(
        `Requesting to decrement the refCount of an ${iElement.id} that is not in cache`,
      );
    }
    cacheElement.decRef();
  }
}

/**
 * In the app all object 3d are paired with their project descriptor
 */
export type IElementObject<
  ThreeObject extends Object3D,
  Desc extends IElement,
> = ThreeObject & { iElement: Desc };

/** The type of a Panorama image 3d object */
export type PanoObject = IElementObject<LodPano, IElementImg360>;

/**
 * An async function to load a pano object
 *
 * @param iElement The descriptor for the pano to load
 * @returns A promise that will resolve when the PanoObject is ready
 */
async function loadPano(iElement: IElementImg360): Promise<PanoObject> {
  const imageTree = createPanoLodTree(iElement);
  const url = await getPanoOverviewUrl(iElement);
  const texture = await new TextureLoader().load(url).promise;
  return Object.assign(new LodPano(imageTree, texture), { iElement });
}

/** The type of a sheet image object */
export type SheetObject =
  | IElementObject<LodFloorPlan, IElementImgSheetTiled>
  | IElementObject<Mesh<PlaneGeometry, MeshBasicMaterial>, IElementImgSheet>;

/**
 * Async function to load a SheetObject
 *
 * @param iElement The descriptor of the sheet to load
 * @returns a promise that will resolve to the loaded SheetObject
 */
async function loadSheet(
  iElement: IElementGenericImgSheet,
): Promise<SheetObject> {
  if (isIElementImgSheetTiled(iElement)) {
    const floor = await loadLodFloorPlan(iElement);
    return Object.assign(floor, { iElement });
  }

  const texture = await new TextureLoader().load(iElement.uri).promise;
  // If the sheet is not LOD, mipmaps are needed to have good rendering of images with
  // crisp lines at any zoom level.
  texture.generateMipmaps = true;
  texture.minFilter = LinearMipMapLinearFilter;
  const sizeX = iElement.size?.x ?? 1;
  const sizeZ = iElement.size?.z ?? 1;
  const mesh = new Mesh(
    new PlaneGeometry(sizeX, sizeZ),
    new MeshBasicMaterial({ map: texture }),
  );
  return Object.assign(mesh, { iElement });
}

/** The type of a mesh object */
export type MeshObject = IElementObject<Group | Mesh, IElementGenericModel3d>;

/** The type of a CAD model */
export type CadModelObject = IElementObject<ICadModel, IElementGenericModel3d>;

/**
 * Async function to get the Model3dStreamDescription associated with a given IElementModel3dStream.
 *
 * @param iElement The descriptor of the IElementModel3dStream associated with the Model3dStreamDescription
 * @returns The Model3dStreamDescription (with throw exception in case of failure)
 */
export async function loadModel3dStreamDescription(
  iElement: IElementModel3dStream,
): Promise<Model3dStreamDescription> {
  const res = await fetch(iElement.uri);
  if (!res.ok) {
    throw new Error("Error while fetching Model 3D description");
  }
  const urls = await res.json();
  if (!isModel3dStreamURLs(urls)) {
    throw new Error("Invalid Model3dStream url");
  }
  return urls;
}

/**
 * Async function to get the CAD model tree for a given IElementModel3dStream.
 *
 * @param iElement The descriptor of the IElementModel3dStream associated with the CAD
 * @returns The CAD Model tree if any, or undefined if there is no model tree.
 */
export async function loadCadModelTree(
  iElement: IElementModel3dStream,
): Promise<CadModelTreeDescription | undefined> {
  const model3dStreamDescription = await loadModel3dStreamDescription(iElement);

  if (!model3dStreamDescription.modelTreeUrl) {
    return;
  }

  const res = await fetch(model3dStreamDescription.modelTreeUrl);
  if (!res.ok) {
    throw new Error("Invalid modelTreeUrl uri");
  }
  const modelTree = await res.json();
  if (!isCadModelTreeDescription(modelTree)) {
    throw new Error("Invalid ModelTreeDescription url");
  }
  return modelTree;
}

/**
 * Async function to fetch the legacy IElementGenericModel3d's monolithic GLTF file from a remote URL and parse it
 * into a ThreeJS scene.
 *
 * @param iElement The descriptor of the model to load
 * @param modelLoadingCallback callback to report loading state
 * @returns An object with a parsed GLTF scene.
 */
async function loadMonolithicGLTF(
  iElement: IElementGenericModel3d,
  modelLoadingCallback?: ModelAboutToLoad,
): Promise<Group> {
  // Validate source URL for GLTF.
  let url = "";

  if (isIElementModel3dStream(iElement)) {
    const urls = await loadModel3dStreamDescription(iElement);
    if (!urls.meshGlbUrl) {
      // special case for missing GLB. We create an empty Group on purpose
      // and set userData.emptyMesh to indicate that it is not an error
      const ret = new Group();
      ret.userData.emptyMesh = true;
      return ret;
    }
    url = urls.meshGlbUrl;
  } else {
    url = iElement.uri;
  }

  try {
    return await loadGltf(url, modelLoadingCallback);
  } catch (err) {
    // If an error is caught here, an empty Group() is returned and the error is
    // stored inside the group's user data. Then, the caller function
    // can check whether result.userData.error is defined, and throw it in case.
    // Otherwise, errors throw here are lost.
    // After a lot of experiments, this is the only solution I could find
    // to report these errors.
    const error = new Error(
      `3D Model "${removeExtension(
        iElement.fileName ?? undefined,
      )}" is too big to render.`,
    );
    const ret = new Group();
    ret.userData.error = error;
    return ret;
  }
}

/**
 * Possible types for CadError.
 */
export enum CadErrorType {
  /** Missing mesh file, the conversion has failed */
  NoMeshFile = "NoMeshFile",
  /** Model has no geometry */
  EmptyModel = "EmptyModel",
  /** Model is too big to render */
  ModelTooBigToRender = "ModelTooBigToRender",
}

/**
 * Specialized Error type for loadCadModel.
 */
export class CadError extends Error {
  /**
   *
   * @param type type of CadErrorType
   * @param message error message stored in parent class Error
   */
  constructor(type: CadErrorType, message: string) {
    super(message);
    this.CadErrorType = type;
  }

  CadErrorType: CadErrorType;
}

/**
 * @returns A promise of the requested StreamCadModel object, or undefined if the model is not streamable
 * @param iElement Descriptor of the Cad model to load
 * @param getMaxByteLength function returning the maximum number of bytes to load in memory
 */
async function loadStreamCadModel(
  iElement: IElementModel3dStream,
  getMaxByteLength: () => number,
): Promise<CadModelObject | undefined> {
  const urls = await loadModel3dStreamDescription(iElement);
  if (!urls.subMeshesUrl) return;

  const streamCadModel = await createStreamCadModel(
    urls.subMeshesUrl,
    getMaxByteLength,
  );
  // Always load the first chunk, so the CAD model won't appear empty.
  await streamCadModel.loadOneChunk();
  return Object.assign(streamCadModel, { iElement });
}

/**
 * Async function to fetch and load a Cad model
 *
 * @param iElement Descriptor of the Cad model to load
 * @param params Optional parameters for the loading
 * @returns A promise of the requested Cad model object
 */
async function loadCadModel(
  iElement: IElementModel3dStream,
  params: LoaderFunctionParams,
): Promise<CadModelObject> {
  const streamCadModel = await loadStreamCadModel(
    iElement,
    params.getModelMaxByteToLoad,
  );
  if (streamCadModel) return streamCadModel;

  // Fallback to the single CAD model GLB file
  const model = await loadMonolithicGLTF(iElement, params.modelLoadingCallback);
  if (model.userData.error) {
    throw model.userData.error;
  }

  const cadName = removeExtension(iElement.fileName ?? undefined);

  let ret: CadModel;

  try {
    // Creating the Cad Model with custom rendering data structures and materials.
    ret = createCadModel(model);
  } catch (err) {
    // date when submeshes started to be generated in PROD environment
    const dateDeployment = new Date(firstVersionSubMeshesInProd);
    // date this model was converted
    const dateConversion = new Date(iElement.createdAt);

    // check if the mode was converted prior support for large streamed model
    const message =
      dateConversion < dateDeployment
        ? "Try importing the 3D Model again"
        : `3D Model "${cadName}" is too big to render.`;

    // Handling errors
    throw new CadError(CadErrorType.ModelTooBigToRender, message);
  }

  // If the loaded CAD model is empty (e.g. contains only lines) the app should inform the user
  if (model.userData.emptyMesh) {
    throw new CadError(
      CadErrorType.NoMeshFile,
      `The 3D Model "${cadName}" has failed to convert`,
    );
  } else if (ret.nodesCount() === 0) {
    throw new CadError(
      CadErrorType.EmptyModel,
      `The 3D Model "${cadName}" does not contain any surface geometry`,
    );
  }

  return Object.assign(ret, { iElement });
}

/** The type of a point cloud object */
export type PointCloudObject = IElementObject<
  LodPointCloud,
  IElementGenericPointCloudStream
>;

/**
 * An async function to load a PointCloudObject
 *
 * @param iElement The descriptor of the lod pointcloud to load
 * @param params Optional parameters for the loading
 * @returns A promise that will resolve when the PointCloudObject is ready
 */
async function loadPointCloud(
  iElement: IElementGenericPointCloudStream,
  params: LoaderFunctionParams,
): Promise<PointCloudObject> {
  const { pointCloudApiUrl } = runtimeConfig.backendEndpoints;

  let tree: LodTree;
  /**
   * The material parameters have been chosen from testing multiple datasets.
   * They have been found as a good compromise between covering 'holes' when few nodes are loaded
   * and avoid exaggerated point splats for outliers
   */
  const material = new AdaptivePointsMaterial({ minSize: 2, maxSize: 6 });
  if (isIElementPointCloudStreamWebShare(iElement)) {
    const ws = new WSInstance(iElement.uri, params.tokenProvider);
    tree = await ws.getKDTree(
      iElement.webShareProjectName,
      iElement.webShareCloudId,
      iElement.webShareEntityId,
      true,
    );
    material.size = 2;
    material.sizeAttenuation = false;
  } else {
    const res = await fetch(iElement.uri);
    if (!res.ok) {
      throw new Error("Invalid point cloud stream uri");
    }
    const urls = await res.json();
    if (!isPotreeURLs(urls)) {
      throw new Error("Invalid point clouds stream urls");
    }
    // Assigning directly to the tree object make it impossible to access
    // PoTree specific metadata as it will decay the type to LodTree
    const potree = await createPotree(
      {
        metadata: new URL(urls.metaDataUrl),
        hierarchy: new URL(urls.hierarchyUrl),
        octree: new URL(urls.octreeURL),
      },
      pointCloudApiUrl,
    );
    if (potree.monochrome) {
      material.vertexColors = false;
      material.color = new Color("white");
    }

    material.size = 0.8;
    material.sizeAttenuation = true;

    tree = potree;
  }

  // Setting convenient point cloud rendering parameters
  tree.visibleNodesStrategy.targetPixelsPerPoint = 0.5;
  tree.visibleNodesStrategy.maxPointsInGpu = 8500000;

  // Safe value tested on multiple devices to be able to keep in memory
  // at least an overview of a medium sized point cloud
  const MaxNodesInCache = 1000;
  const pointCloud = new LodPointCloud(tree, material, {
    lodCachingStrategy: new LodCachingStrategyMaxChunks(MaxNodesInCache),
  });
  // Setting raycasting parameters optimized for speed and precision
  pointCloud.raycasting = {
    ...pointCloud.raycasting,
    enabled: true,
    // A small threshold make sure the picked points are closer to the cursor while raycasting
    // but could create problem with sparse point cloud
    threshold: 0.01,
    pickingTree: {
      enabled: true,
      autoUpdate: true,
      maxDepth: 3,
    },
    // This option will force the raycasting to visit all loaded nodes of the LodPointCloud
    maxDepth: Number.MAX_SAFE_INTEGER,
    shouldReturnOnlyClosest: true,
    maxPickingTreesPerRaycast: 1,
  };
  // A single node worker can easily handle more than 1 download at a time
  // but starting too many download can overload the queue
  // 6 downloads with the default 4 workers tested as a nice compromise on multiple devices/networks
  pointCloud.lodTreeFetcher.maxNodesToDownloadAtOnce = 6;

  pointCloud.preloadRootNode();
  return Object.assign(pointCloud, { iElement });
}

/** All the supported iElements in our cache */
type SupportedElements =
  | IElementImg360
  | IElementImgSheet
  | IElementImgSheetTiled
  | IElementModel3dStream
  | IElementGenericPointCloudStream;

/** Map with metadata for each object type supported by the cache */
const OBJECT_CACHES = {
  [IElementType.img360]: new ObjectCache(loadPano),
  [IElementType.imgSheet]: new ObjectCache(loadSheet),
  [IElementType.imgSheetTiled]: new ObjectCache(loadSheet),
  [IElementType.model3dStream]: new ObjectCache(loadCadModel, LONG_LIFESPAN),
  [IElementType.pointCloudStreamWebShare]: new ObjectCache(
    loadPointCloud,
    LONG_LIFESPAN,
  ),
  [IElementType.pointCloudStream]: new ObjectCache(
    loadPointCloud,
    LONG_LIFESPAN,
  ),
};

type IElementObjects = {
  [IElementType.img360]: PanoObject;
  [IElementType.imgSheet]: SheetObject;
  [IElementType.imgSheetTiled]: SheetObject;
  [IElementType.model3dStream]: CadModelObject;
  [IElementType.pointCloudStreamWebShare]: PointCloudObject;
  [IElementType.pointCloudStream]: PointCloudObject;
};

/**
 * @param iElement we want the cache for
 * @returns the cache instance that manages the iElement types passed as argument
 */
function getCache<T extends SupportedElements>(
  iElement: T | undefined,
): ObjectCache<T, IElementObjects[T["type"]]> | undefined {
  // Query object type metadata
  const cache = iElement ? OBJECT_CACHES[iElement.type] : undefined;
  if (!cache) {
    return;
  }

  // We know here the cache is the correct one, but typescript can't infer that
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return cache as unknown as ObjectCache<T, IElementObjects[T["type"]]>;
}

/** Helper type for the promise returned by getPromise() */
type MaybeCachePromiseOf<T extends SupportedElements> =
  | Promise<IElementObjects[T["type"]]>
  | undefined;

/**
 * @returns the Promise of an IElement in the cache if an entry exists
 * @param iElement the IElement to get the promise for
 */
function getPromise<T extends SupportedElements>(
  iElement: T | undefined,
): MaybeCachePromiseOf<T> {
  if (!iElement?.id) return;
  return getCache(iElement)?.entry(iElement.id)?.promise;
}

/**
 * Increment ref on mount, decrement on unmount
 *
 * @param cache The cache holding the element
 * @param iElement An element for which to handle the reference counter increments
 */
function useCacheRef<T extends IElementBase>(
  cache: ObjectCache<T> | undefined,
  iElement: T | undefined,
): void {
  useEffect(() => {
    if (cache && iElement) {
      cache.addRef(iElement);
      return cache.decRef(iElement);
    }
  }, [cache, iElement]);
}

/**
 * Increment ref on mount, decrement on unmount
 *
 * @param caches The caches holding the elements
 * @param iElements An array of elements for which to handle the reference counter increments
 */
function useCacheRefs<T extends IElementBase>(
  caches: Array<ObjectCache<T> | undefined> | undefined,
  iElements: Array<T | undefined> | undefined,
): void {
  useEffect(() => {
    if (!iElements || !caches) return;
    if (iElements.length !== caches.length) return;

    iElements.forEach((el, index) => el && caches[index]?.addRef(el));
    return () => {
      iElements.map((el, index) => el && caches[index]?.decRef(el));
    };
  }, [iElements, caches]);
}

/**
 * Get an object from the cache, suspend if the object does not exist
 *
 * @param iElement The descriptor of the element
 * @returns The loaded object or suspend execution
 */
export function useCached3DObject<T extends SupportedElements>(
  iElement: T,
): IElementObjects[T["type"]] {
  const { tokenProvider } = useAuthContext();

  const cache = getCache(iElement);
  if (!cache) {
    throw new Error(
      `${iElement.type} type is not supported by the object-cache`,
    );
  }

  useCacheRef(cache, iElement);
  return cache.getOrLoad(iElement, {
    tokenProvider,
    getModelMaxByteToLoad: () => getModelMaxByteToLoad(store.getState()),
  });
}

/**
 * Use a cached 3d object if the iElement exists, null if the ielement is not valid or failed to load
 *
 * @param iElement The iElement we want to get the 3d object of
 * @param abortAndReportOnLoadingError true to report loading error with handleErrorWithDialog; this will probably kill the app
 * @returns The correct 3d object, or null if the iElement is invalid
 */
export function useCached3DObjectIfExists<T extends SupportedElements>(
  iElement: T | undefined | null,
  abortAndReportOnLoadingError = true,
): IElementObjects[T["type"]] | null {
  const { tokenProvider } = useAuthContext();

  const { handleErrorWithDialog } = useErrorHandlers();

  // Query object type metadata
  const cache = getCache(iElement ?? undefined);

  // @ts-expect-error We know here the cache is the correct one, but typescript can't infer that
  useCacheRef(cache, iElement);
  if (!iElement) return null;
  if (!cache) return null;

  try {
    return cache.getOrLoad(iElement, {
      tokenProvider,
      getModelMaxByteToLoad: () => getModelMaxByteToLoad(store.getState()),
    });
  } catch (reason) {
    // Throwing a promise is not an error but it's needed to suspend the application
    if (reason instanceof Promise && !cache.isPromiseSettled(iElement.id)) {
      throw reason;
    } else if (reason instanceof Error) {
      if (abortAndReportOnLoadingError) {
        handleErrorWithDialog({
          title: "Error loading 3D Model",
          error: reason.message,
        });
      }
    }
    return null;
  }
}

/**
 * @returns the 3d object of an ielement if it's already in the cache
 * @param iElement to query the relative 3d object for
 *
 * it will not increase the cache reference or extend the lifetime of the object in the cache
 */
export function getWeakRefToCachedObject<T extends SupportedElements>(
  iElement: T | undefined | null,
): IElementObjects[T["type"]] | undefined {
  if (!iElement) return;

  const cache = getCache(iElement);
  return cache?.peek(iElement.id);
}

/**
 * Get an array of objects from the cache, if any of them are not there return null and start preloading
 *
 * @param iElements The descriptor of the elements
 * @returns The array of objects, entries are null if object is not loaded yet
 */
export function useCached3DObjectsIfReady<T extends SupportedElements>(
  iElements: T[] | undefined,
): Array<IElementObjects[T["type"]]> {
  const { tokenProvider } = useAuthContext();
  const modelLoadingCallback = useModelAboutToLoad();

  const { iElementsWithCache, caches } = useMemo(() => {
    const iElementsWithCache: T[] = [];
    const caches: Array<ObjectCache<T, IElementObjects[T["type"]]>> = [];
    if (!iElements) return { iElementsWithCache, caches };

    for (const iElement of iElements) {
      const cache = getCache(iElement);
      if (!cache) continue;
      iElementsWithCache.push(iElement);
      caches.push(cache);
    }
    return { iElementsWithCache, caches };
  }, [iElements]);

  useCacheRefs(caches, iElementsWithCache);

  // Try to load the elements from the cache
  const cacheEntries = useMemo(
    () =>
      iElementsWithCache.map((iElement, index) =>
        caches[index]?.preload(iElement, {
          tokenProvider,
          modelLoadingCallback,
          getModelMaxByteToLoad: () => getModelMaxByteToLoad(store.getState()),
        }),
      ),
    // We only want to initiate the loading when the input changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [iElementsWithCache],
  );

  // The promises for objects that are still being loaded
  const [loadPromises, setLoadPromises] = useState(() =>
    getLoadPromises(cacheEntries),
  );
  // The objects that have already finished loading
  const [loadedObjects, setLoadedObjects] = useState(() =>
    getLoadedObjects(cacheEntries),
  );

  // Update when the cache entries change
  useMemo(() => {
    setLoadPromises(getLoadPromises(cacheEntries));
    setLoadedObjects(getLoadedObjects(cacheEntries));
  }, [cacheEntries]);

  // Update when a new object has been loaded
  useMemo(() => {
    if (loadPromises.length) {
      Promise.race(loadPromises).then(() => {
        setLoadPromises(getLoadPromises(cacheEntries));
        setLoadedObjects(getLoadedObjects(cacheEntries));
      });
    }
  }, [cacheEntries, loadPromises]);

  return loadedObjects;
}

/**
 * @param cacheEntries The cache entries to get the load promises for.
 * @returns The promises for the entries that still need to be loaded.
 */
function getLoadPromises<T extends SupportedElements>(
  cacheEntries: Array<CacheEntry<IElementObjects[T["type"]]>>,
): Array<Promise<IElementObjects[T["type"]]>> {
  return cacheEntries
    .map((entry) => (entry.response ? undefined : entry.promise))
    .filter(isValid);
}

/**
 * @param cacheEntries The cache entries to get the loaded objects for.
 * @returns The objects which are already loaded.
 */
function getLoadedObjects<T extends SupportedElements>(
  cacheEntries: Array<CacheEntry<IElementObjects[T["type"]]>>,
): Array<IElementObjects[T["type"]]> {
  return cacheEntries.map((entry) => entry.response).filter(isValid);
}

/**
 * Get an object from the cache, if not there return null and start preloading
 *
 * @param iElement The descriptor of the element
 * @returns The object or null if the object is not ready
 */
export function useCached3DObjectIfReady<T extends SupportedElements>(
  iElement: T | undefined,
): IElementObjects[T["type"]] | Error | null {
  const { tokenProvider } = useAuthContext();
  const { renderer } = useThreeContext();
  const modelLoadingCallback = useModelAboutToLoad();

  // Query object type metadata
  const cache = getCache(iElement);

  // Wrap the object to load in a state so we can re-draw when the object is ready
  const [object, setObject] = useState<
    IElementObjects[T["type"]] | null | Error
  >(null);

  useEffect(() => {
    setObject(null);

    if (!iElement) {
      return;
    }

    // Get or load the object
    const res = cache?.preload(iElement, {
      tokenProvider,
      modelLoadingCallback,
      getModelMaxByteToLoad: () =>
        // using a callback here because defaultMaximumModelSize will change AFTER cache?.preload has been
        getModelMaxByteToLoad(store.getState()),
    });
    if (res?.response) {
      // If the object is ready notify to the component
      setObject(res.response);
    } else if (res?.error) {
      // If the object is not ready return null
      setObject(
        res.error instanceof Error
          ? res.error
          : new Error(JSON.stringify(res.error)),
      );
    } else {
      // And attach setObject to the loading promise
      res?.promise.then(setObject).catch(setObject);
    }
  }, [cache, tokenProvider, iElement, renderer, modelLoadingCallback]);

  useCacheRef(cache, iElement);

  return object;
}

/**
 * Preload an object in the cache for later use
 *
 * @param iElement The descriptor of the element
 * @param params Optional parameters for the loading
 */
function preload3DObject<T extends SupportedElements>(
  iElement: T | undefined,
  params: LoaderFunctionParams,
): void {
  if (!iElement) return;
  // Get the cache for this object, inside this function the cache for typescript can be any of the available cache
  // and there's no way to have this code generic
  const cache = getCache(iElement);

  // @ts-expect-error We know here the cache is the correct one, but typescript can't infer that
  cache.preload(iElement, params);
}

/**
 * Hook to preload a 3d object as soon as a component is mounted and keep it in cache while the component is used
 *
 * @param iElement The element we want to preload
 */
export function usePreload3DObject<T extends SupportedElements>(
  iElement: T | undefined,
): void {
  const { tokenProvider } = useAuthContext();
  const { renderer } = useThreeContext();
  const modelLoadingCallback = useModelAboutToLoad();

  // Query object type metadata
  const cache = getCache(iElement);

  // Preload on mount, use layout effect to make sure this happen before the
  // hook in useCacheRef
  useEffect(() => {
    preload3DObject(iElement, {
      tokenProvider,
      modelLoadingCallback,
      getModelMaxByteToLoad: () => getModelMaxByteToLoad(store.getState()),
    });
  }, [tokenProvider, iElement, renderer, modelLoadingCallback]);

  useCacheRef(cache, iElement);
}

/**
 * Hook to preload a list of 3d objects as soon as a component is mounted and keep it in cache while the component is used
 *
 * @param toPreload The list of elements we want to preload
 * @returns a list of promises that load the objects.
 */
export function usePreload3DObjects<T extends SupportedElements>(
  toPreload: Array<T | undefined>,
): Array<MaybeCachePromiseOf<T>> {
  const { tokenProvider } = useAuthContext();
  const modelLoadingCallback = useModelAboutToLoad();

  // The actual preload needs to be triggered in the render function, so it
  // executes before suspenses that block the effect below
  for (const element of toPreload) {
    if (!element) continue;
    preload3DObject(element, {
      tokenProvider,
      modelLoadingCallback,
      getModelMaxByteToLoad: () => getModelMaxByteToLoad(store.getState()),
    });
  }

  // Register a reference to keep the objects loaded while the component is mounted
  useEffect(() => {
    const disposals: Array<() => void> = [];
    for (const element of toPreload) {
      if (!element) continue;
      const cache = getCache(element);
      if (cache) {
        cache.addRef(element);
        disposals.push(() => cache.decRef(element));
      }
    }
    return () => {
      for (const dispose of disposals) {
        dispose();
      }
    };
  }, [tokenProvider, toPreload]);

  return useMemo(() => toPreload.map(getPromise), [toPreload]);
}

/**
 * Preloads the provided objects and suspends while they are loading.
 * Use when preloaded objects are nice to have, but not required.
 *
 * TODO: Add a fallback/timeout for slower internet connections
 * see https://faro01.atlassian.net/browse/SWEB-2043
 *
 * @param toPreload the objects to preload
 */
export function useSuspendWhilePreload3DObjects(
  toPreload: SupportedElements[],
): void {
  const loadingPromises = usePreload3DObjects(toPreload);
  const states = useCached3DObjectsLoadingStates(toPreload);
  const isLoading = states.some(
    (state) => state.state === LoadingStates.loading,
  );

  if (isLoading) {
    throw Promise.all(loadingPromises);
  }
}

/**
 * Possible loading states for a cached object
 */
export enum LoadingStates {
  /** Object is not in the cache, loading not even started */
  missing = "missing",

  /** Object is in the cache and is currently loading, data is not yet available */
  loading = "loading",

  /** Object is in the cache and loaded, data is available */
  ready = "ready",

  /** Object is in the cache but failed to load */
  error = "error",
}

/**
 * Type returned by useCached3DObjectLoadingState when the object is not in an error state
 */
export type ValidState = {
  /** Current loading state of the object */
  state: LoadingStates.missing | LoadingStates.loading | LoadingStates.ready;
};

/**
 * Type returned by useCached3DObjectLoadingState when the object is in an error state
 * with the error reason attached
 */
export type ErrorState = {
  /** Object error state  */
  state: LoadingStates.error;

  /** Reason of the error state */
  reason: unknown;
};

/** All possible states returned by useCached3DObjectLoadingState */
export type ObjectLoadingState = ValidState | ErrorState;

/**
 * Check the loading state for an element in a cache
 *
 * @param entry in the cache we want to check the loading state
 * @returns the loading state for the element in the cache
 */
function getObjectLoadingState(
  entry: Readonly<CacheEntry<Object3D>> | undefined,
): ObjectLoadingState {
  // A missing entry means the object was never requested and is not in cache
  if (!entry) {
    return { state: LoadingStates.missing };
  }

  // An existing entry error mark a failed load
  if (entry.error) {
    return {
      state: LoadingStates.error,
      reason: entry.error,
    };
  }

  // An existing response mark a successful load
  if (entry.response) {
    return {
      state: LoadingStates.ready,
    };
  }

  // If both are missing the loading is still pending
  return {
    state: LoadingStates.loading,
  };
}

/**
 * Query the loading state of an object
 *
 * @param iElement for which to query the current loading state
 * @returns the current loading state (missing, loading, ready or error)
 */
export function useCached3DObjectLoadingState<T extends SupportedElements>(
  iElement: T | undefined,
): ObjectLoadingState {
  return useCached3DObjectsLoadingStates<T>([iElement])[0];
}

/**
 * @returns the loading states for a set of iElements
 * @param iElements the iElements to check
 */
function getLoadingStates<T extends SupportedElements>(
  iElements: Array<T | undefined>,
): ObjectLoadingState[] {
  const states = [];

  for (const iElement of iElements) {
    const cache = getCache(iElement);
    const entry = iElement ? cache?.entry(iElement.id) : undefined;

    states.push(getObjectLoadingState(entry));
  }

  return states;
}

/**
 * Query the loading state of an array of objects
 *
 * @param iElements for which to query the current loading state
 * @returns the current loading state (missing, loading, ready or error)
 */
export function useCached3DObjectsLoadingStates<T extends SupportedElements>(
  iElements: Array<T | undefined>,
): ObjectLoadingState[] {
  // Set the initial loading state
  const [loadingStates, setLoadingStates] = useState<ObjectLoadingState[]>(() =>
    getLoadingStates(iElements),
  );

  // Use the elementIds to detect changes in IElements
  const elementIds = iElements.map((iElement) => iElement?.id);

  // React to a change of the tracked iElement
  // Should only update when an iElement's id changes
  useNonExhaustiveEffect(() => {
    function updateLoadingStates(): void {
      return setLoadingStates(getLoadingStates(iElements));
    }

    const disposables: Disposable[] = [];

    for (const iElement of iElements) {
      const cache = getCache(iElement);
      const entry = iElement ? cache?.entry(iElement.id) : undefined;

      if (!entry) {
        continue;
      }

      // Update state when promise finishes
      entry.promise.finally(() => {
        updateLoadingStates();
      });

      // Update state when the object is disposed
      disposables.push(
        entry.disposed.on(() => {
          updateLoadingStates();
        }),
      );
    }

    return () => {
      for (const disposable of disposables) {
        disposable.dispose();
      }
    };
  }, [...elementIds]);

  return loadingStates;
}

/**
 * @returns the value to be used for modelMaxByteToLoad
 * @param storeState state of the store
 */
function getModelMaxByteToLoad(storeState: RootState): number {
  const featureValue = selectGetFeatureValueIfEnabled(Features.LargeCadModel)(
    storeState,
  );
  return featureValue !== undefined && featureValue !== null
    ? +featureValue * 1024 * 1024
    : selectDefaultCadMaxByteToLoad()(storeState);
}
