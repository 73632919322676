import { PositionedHtml } from "@/components/r3f/renderers/measurements/positioned-html";
import { PointCloudObject } from "@/object-cache";
import { useAppStore } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { Map2DControls } from "@faro-lotv/app-component-toolbox";
import { FaroSvgIcon } from "@faro-lotv/flat-ui";
import { TransformOverrides } from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box3, Matrix4, Vector3 } from "three";
import { ReactComponent as LocationSvg } from "../../icons/location.svg";
import { computePointCloudBoundingBox } from "../../utils/camera-views";
import { SinglePinInteraction } from "./single-pin-interaction";

/** The size of the pin graphic. */
const PIN_SIZE = 40;

type PointCloudPinControlsProps = {
  /**
   * The point cloud object that can be manipulated by the user.
   * If `undefined`, the controls are disabled.
   */
  manipulatedPointCloud?: PointCloudObject;
  /** The active overrides for the transforms, to correctly calculate the world transforms. */
  transformOverrides: TransformOverrides;
  /** The callback to execute when the object is manipulated by the user. */
  onTransform(worldTransform: Matrix4): void;
};

/**
 * @returns A set of controls for the user to manipulate the transform of a point cloud.
 *
 * The user can left-click on a point cloud to place a pin.
 * - When dragging *on* the pin, the point cloud is translated
 * - When dragging *around* the pin, the point cloud is rotated.
 * - When right-clicking on the pin, the pin is removed
 */
export function PointCloudPinControls({
  manipulatedPointCloud,
  transformOverrides,
  onTransform,
}: PointCloudPinControlsProps): JSX.Element {
  const camera = useThree((three) => three.camera);
  const canvasElement = useThree((three) => three.gl.domElement);
  const store = useAppStore();
  const domElement = canvasElement.parentElement ?? canvasElement;
  // A ref wrapper is needed for the portal
  const domElementRef = useRef(domElement);
  domElementRef.current = domElement;

  const isManipulationEnabled = !!manipulatedPointCloud;
  const pointCloudId = manipulatedPointCloud?.iElement.id;

  // Accessor for the origin of the point cloud, to calculate the updated transform
  const getPointCloudTransform = useCallback(
    () =>
      selectIElementWorldMatrix4(
        pointCloudId,
        transformOverrides,
      )(store.getState()),
    [store, pointCloudId, transformOverrides],
  );

  // Accessor for the center of the point cloud, to improve pin positioning
  const bboxCache = useRef(new Box3());
  const centerCache = useRef(new Vector3());
  const getPointCloudCenter = useCallback(() => {
    if (!manipulatedPointCloud) return centerCache.current;

    computePointCloudBoundingBox(manipulatedPointCloud, bboxCache.current);
    return bboxCache.current.getCenter(centerCache.current);
  }, [manipulatedPointCloud]);

  const [pinInteraction] = useState(
    () =>
      new SinglePinInteraction(
        camera,
        getPointCloudTransform,
        getPointCloudCenter,
      ),
  );
  pinInteraction.isManipulationEnabled = isManipulationEnabled;
  pinInteraction.getObjectTransform = getPointCloudTransform;
  pinInteraction.getObjectCenter = getPointCloudCenter;

  const [pinPosition, setPinPosition] = useState(new Vector3());
  const [isPinVisible, setIsPinVisible] = useState(false);

  // Reset the pin when the point cloud changes
  useEffect(() => {
    const center = pinInteraction.centerPin();
    // For some reason, the `setPinEvent` is not received here, so the position needs to be updated manually
    setPinPosition(center);
  }, [pointCloudId, pinInteraction]);

  // Connect the callbacks to the interaction logic
  useEffect(() => {
    const disposeSetPin = pinInteraction.setPinEvent.on((newPinPosition) => {
      setIsPinVisible(true);
      setPinPosition((oldPosition) => oldPosition.copy(newPinPosition));
    });
    const disposeRemovePin = pinInteraction.removePinEvent.on(() => {
      setIsPinVisible(false);
    });
    const disposeTransform = pinInteraction.transformEvent.on(onTransform);

    return () => {
      disposeSetPin.dispose();
      disposeRemovePin.dispose();
      disposeTransform.dispose();
    };
  }, [pinInteraction, camera, canvasElement, onTransform]);

  // Attach event listeners to the correct dom Element
  useEffect(() => {
    pinInteraction.attach(domElement);

    return () => {
      pinInteraction.detach();
    };
  }, [domElement, pinInteraction]);

  return (
    <>
      <PositionedHtml center portal={domElementRef} position={pinPosition}>
        {isPinVisible && (
          <FaroSvgIcon
            source={LocationSvg}
            sx={{ width: PIN_SIZE, height: PIN_SIZE }}
          />
        )}
      </PositionedHtml>
      <Map2DControls isPrimaryControl={!isManipulationEnabled} />
    </>
  );
}
