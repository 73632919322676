import { logError } from "@/logging/logging";
import { useToast } from "@faro-lotv/flat-ui";
import {
  ApiResponseError,
  ProjectApiError,
  getMutationErrorProblemsMessages,
  isMergeConflictError,
} from "@faro-lotv/service-wires";
import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  ErrorBoundary,
  useErrorHandler as reactErrorBoundaryHandler,
} from "react-error-boundary";
import { AppErrorDialog } from "./app-error-dialog";
import { AppErrorPage } from "./app-error-page";

/** The error information to display inside the dialog */
export type ErrorDialogData = {
  /** The error dialog title */
  title: string;
  /** The error raised */
  error: unknown;
};

/** A type that allows to handle errors in different ways. */
export type ErrorHandlers = {
  /** Error handler that displays a page with error information. */
  handleErrorWithPage(error: Error | unknown): void;

  /** Error handler that displays an error toast with given title and a message. */
  handleErrorWithToast(data: ErrorDialogData): void;

  /** Error handler that displays a modal dialog with given title, message and error button. */
  handleErrorWithDialog(data: ErrorDialogData): void;
};

/**
 * This type is needed because we cannot put the 'handleErrorWithPage' callback in the
 * context, it must be created anew for each call to 'useErrorHandlers()'
 */
type ErrorHandlersInContext = {
  /** Error handler that displays an error toast with given title and a message. */
  handleErrorWithToast(data: ErrorDialogData): void;

  /** Error handler that displays a modal dialog with given title, message and error button. */
  handleErrorWithDialog(data: ErrorDialogData): void;
};

/**
 * A context to expose the required error handling functions to be used inside components
 */
export const ErrorHandlersContext = createContext<
  ErrorHandlersInContext | undefined
>(undefined);

/**
 * @returns The error handlers
 */
export function useErrorHandlers(): ErrorHandlers {
  // The callback below cannot stay in the context, it must be created fresh
  // every time calling 'reactErrorBoundaryHandler()'.
  const ebHandler = reactErrorBoundaryHandler();
  const ctx = useContext(ErrorHandlersContext);
  if (ctx) {
    return {
      handleErrorWithPage: ebHandler,
      ...ctx,
    };
  }
  // While running tests, this context may not exist.
  return {
    handleErrorWithPage: ebHandler,
    handleErrorWithToast: (data: ErrorDialogData) => {
      logError(data.error);
      console.error(parseErrorMessage(data.error));
    },
    handleErrorWithDialog: (data: ErrorDialogData) => {
      logError(data.error);
      console.error(parseErrorMessage(data.error));
    },
  };
}

/**
 * @param error Error of unknown type
 * @returns An error message for an unknown error object
 */
function parseErrorMessage(error: unknown): ReactNode {
  if (error instanceof ProjectApiError) {
    let message: string | undefined = undefined;
    if (typeof error.body === "object") {
      message = error.body?.data?.title;

      switch (error.body.error) {
        case "mutation_failed":
        case "merge_conflict": {
          const mutationResults = error.body.data;

          if (Array.isArray(mutationResults)) {
            // Only the failed mutations contain useful information
            const messages = mutationResults
              .filter((mutationResult) => mutationResult.status === "failure")
              .map((mutationResult) => mutationResult.message)
              .filter((message) => !!message);

            if (messages.length <= 1) {
              message = messages[0];
            } else if (messages.length > 1) {
              return (
                <ErrorList
                  message={`${error.status}: Multiple errors occurred`}
                  details={messages}
                />
              );
            }
          }
          break;
        }
      }
    }
    return `${error.status}: ${message ?? "An unknown error occurred"}`;
  } else if (error instanceof ApiResponseError) {
    if (isMergeConflictError(error)) {
      return "A merge conflict occurred. Please restart the workflow.";
    }

    return getMutationErrorProblemsMessages(error).join("\n");
  } else if (error instanceof Error) {
    return `${error.name}: ${error.message}`;
  } else if (typeof error === "string") {
    return error;
  }
  return "An unknown error occurred";
}

interface ErrorListProps {
  /** The main message to display above the list */
  message: string;

  /** The individual messages to display in the list */
  details: string[];
}

/**
 * @returns a formatted list of errors
 */
function ErrorList({ message, details }: ErrorListProps): JSX.Element {
  return (
    <>
      {message}
      <ul>
        {details.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </>
  );
}

/**
 * @returns A context providing to all its children an error handling framework
 * such that any exceptions thrown can be handled via an ErrorBoundary component
 * (breaking the rendering of children components), or via a message in a modal
 * dialog (keeping rendering and state of children components) or via other means.
 */
export function ErrorHandlingContext({
  children,
}: PropsWithChildren): JSX.Element {
  const [dialogData, setDialogData] = useState<ErrorDialogData | undefined>(
    undefined,
  );

  const { openToast } = useToast();

  const handleErrorWithToast = useCallback(
    (data: ErrorDialogData) =>
      openToast({
        variant: "error",
        title: data.title,
        message: parseErrorMessage(data.error),
      }),
    [openToast],
  );

  const handleErrorWithDialog = useCallback(
    (data: ErrorDialogData) => setDialogData(data),
    [setDialogData],
  );

  const closeErrorDialog = useCallback(
    () => setDialogData(undefined),
    [setDialogData],
  );

  return (
    <ErrorHandlersContext.Provider
      value={{
        handleErrorWithToast,
        handleErrorWithDialog,
      }}
    >
      <ErrorBoundary FallbackComponent={AppErrorPage} onError={logError}>
        {children}
      </ErrorBoundary>
      {dialogData && (
        <AppErrorDialog
          title={dialogData.title}
          message={parseErrorMessage(dialogData.error)}
          onClose={closeErrorDialog}
        />
      )}
    </ErrorHandlersContext.Provider>
  );
}
