import {
  EventType,
  OpenQuickHelpEventProperties,
} from "@/alignment-tool/analytics/analytics-events";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { ExclusiveModeProps } from "@/modes/mode";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  selectExclusiveModeCompletionAction,
  selectIsQuickHelpOpen,
} from "@/store/ui/ui-selectors";
import {
  setExclusiveModeCompletionAction,
  setIsQuickHelpOpen,
} from "@/store/ui/ui-slice";
import {
  FaroButton,
  FaroButtonProps,
  FaroIconButton,
  FontWeights,
  QuestionMarkInCircleIcon,
  useToast,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Stack, StackProps, SvgIcon, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useCallback, useState } from "react";
import { ProgressOverviewMenu } from "./progress-overview/progress-overview-menu";

export type ExclusiveHeaderBarProps = ExclusiveModeProps & {
  /** true to show the QuickHelp toggle button */
  hasQuickHelp: boolean;
};

/** @returns the ExclusiveMode specific HeaderBar with the back and apply buttons */
export function ExclusiveHeaderBar({
  onBack,
  onApply,
  title,
  hasQuickHelp,
}: ExclusiveHeaderBarProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const projectApi = useCurrentProjectApiClient();
  const { handleErrorWithDialog } = useErrorHandlers();
  const { openToast } = useToast();

  const currentAction = useAppSelector(selectExclusiveModeCompletionAction);

  const onBackClick = useCallback(() => {
    if (!onBack) return;
    dispatch(setExclusiveModeCompletionAction("back"));

    onBack({ store, dispatch, projectApi })
      .catch((error) => {
        handleErrorWithDialog({ title, error });
      })
      .finally(() => {
        dispatch(setExclusiveModeCompletionAction(undefined));
      });
  }, [dispatch, handleErrorWithDialog, onBack, projectApi, store, title]);

  const onApplyClick = useCallback(() => {
    if (!onApply) return;
    dispatch(setExclusiveModeCompletionAction("apply"));

    onApply({ store, dispatch, projectApi })
      .then((successMessage) =>
        successMessage
          ? openToast({ title: successMessage, variant: "success" })
          : undefined,
      )
      .catch((error) => {
        handleErrorWithDialog({ title, error });
      })
      .finally(() => {
        dispatch(setExclusiveModeCompletionAction(undefined));
      });
  }, [
    dispatch,
    handleErrorWithDialog,
    onApply,
    openToast,
    projectApi,
    store,
    title,
  ]);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "gray50",
        height: "60px",
      }}
    >
      <Toolbar disableGutters>
        <Stack
          direction="row"
          width="100%"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          {onBack && (
            <BackButton onClick={onBackClick} isEnabled={!currentAction} />
          )}

          <Typography color="gray850" fontSize="0.875em">
            {title}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ minWidth: "120px", pr: 1 }}
          >
            <ProgressOverviewMenu />
            {onApply && (
              <ApplyButton
                onClick={onApplyClick}
                isProcessing={currentAction === "apply"}
                isEnabled={!currentAction}
              />
            )}
            {hasQuickHelp && <QuickHelpToggle isEnabled={!currentAction} />}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

type BackButtonProps = Pick<StackProps, "onClick"> & {
  /** True if the back button should be enabled */
  isEnabled: boolean;
};

// TODO: SWEB-2121 Update style when disabled https://faro01.atlassian.net/browse/SWEB-2121
/** @returns the properly styled back button for the ExclusiveHeaderBar */
function BackButton({ onClick, isEnabled }: BackButtonProps): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  const mainColor = isEnabled && isHovered ? "primary.main" : "gray850";

  return (
    <Stack
      aria-label="back"
      direction="row"
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
      p={2}
      gap={1}
      sx={{
        height: "100%",
        borderRight: ({ palette }) => `1px solid ${palette.gray200}`,
        backgroundColor: ({ palette }) =>
          isEnabled && isHovered ? `${palette.gray500}1A` : undefined,
        cursor: isEnabled ? "pointer" : undefined,
        minWidth: "120px",
      }}
      alignItems="center"
      onClick={isEnabled ? onClick : undefined}
    >
      <SvgIcon
        sx={{
          color: mainColor,
          width: "24px",
          height: "24px",
          opacity: isEnabled ? 1 : 0.5,
        }}
        component={ArrowBack}
      />

      <Typography
        color={mainColor}
        fontWeight={FontWeights.SemiBold}
        sx={{ userSelect: "none", opacity: isEnabled ? 1 : 0.5 }}
      >
        Back
      </Typography>
    </Stack>
  );
}

type ApplyButtonProps = {
  /** Callback called when the ApplyButton is clicked */
  onClick: FaroButtonProps["onClick"];

  /** True to show a spinner inside the button*/
  isProcessing: boolean;

  /** True if the button should be enabled */
  isEnabled: boolean;
};

/** @returns the button to close the exclusive mode and apply the required changes */
function ApplyButton({
  onClick,
  isProcessing,
  isEnabled,
}: ApplyButtonProps): JSX.Element {
  return (
    <FaroButton
      onClick={onClick}
      disabled={isProcessing && !isEnabled}
      aria-label="apply"
      isLoading={isProcessing}
    >
      Apply Changes
    </FaroButton>
  );
}

type QuickHelpToggleProps = {
  /** True to enable this button */
  isEnabled: boolean;
};

/** @returns a toggle button to show/hide the quick help sidebar */
function QuickHelpToggle({ isEnabled }: QuickHelpToggleProps): JSX.Element {
  const dispatch = useAppDispatch();

  const isQuickHelpOpen = useAppSelector(selectIsQuickHelpOpen);
  const onToggleQuickHelp = useCallback(() => {
    Analytics.track<OpenQuickHelpEventProperties>(EventType.openQuickHelp, {
      via: "header bar",
    });

    dispatch(setIsQuickHelpOpen(!isQuickHelpOpen));
  }, [dispatch, isQuickHelpOpen]);

  return (
    <FaroIconButton
      onClick={onToggleQuickHelp}
      disabled={!isEnabled}
      color={isQuickHelpOpen ? "primary.main" : "secondary.main"}
    >
      <QuestionMarkInCircleIcon />
    </FaroIconButton>
  );
}
