import { useAppSelector } from "@/store/store-hooks";
import { FaroText, neutral } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { selectRevisionScans } from "../store/revision-selectors";
import { ScanList } from "./scan-list";

type DataPreparationSidebarProps = {
  /** The title of the current step. */
  title: ReactNode;

  /** The description of the current step. */
  description: ReactNode;

  /** The actions that can be used in the data preparation step. */
  buttons?: ReactNode;
};

/** @returns Sidebar to use throughout the data preparation tool. */
export function DataPreparationSidebar({
  title,
  description,
  buttons,
}: DataPreparationSidebarProps): JSX.Element {
  const scanEntities = useAppSelector(selectRevisionScans);

  return (
    <Stack
      justifyContent="space-between"
      flexShrink={0}
      sx={{
        width: 290,
        height: "100%",
        px: 1.5,
        py: 3,
        borderRight: `1px solid ${neutral[200]}`,
      }}
    >
      <Stack gap={2} height="100%">
        <Stack gap={1.5}>
          <FaroText variant="heading16">{title}</FaroText>
          <FaroText variant="bodyM">{description}</FaroText>
        </Stack>

        <ScanList scanEntities={scanEntities} />
      </Stack>

      {/* Buttons */}
      <Stack gap={1}>{buttons}</Stack>
    </Stack>
  );
}
