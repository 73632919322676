import { FaroButton } from "@faro-lotv/flat-ui";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactNode } from "react";
type ErrorDialogProps = {
  /** The title of the dialog */
  title: string;

  /** The text appearing in the body of the dialog  */
  message: ReactNode;

  /** Callback notified when the dialog closes */
  onClose(): void;
};

/**
 * @returns A modal error dialog with custom title and message.
 */
export function AppErrorDialog({
  title,
  message,
  onClose,
}: ErrorDialogProps): JSX.Element {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <DialogTitle
        id="error-dialog-title"
        sx={{
          color: "yellow600",
          paddingTop: 3,
          paddingBottom: 4,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        id="error-dialog-description"
        sx={{ paddingBottom: 1, color: ({ palette }) => palette.darkGrey }}
      >
        {message}
      </DialogContent>
      <DialogActions
        sx={{
          paddingBottom: 3,
        }}
      >
        <FaroButton onClick={onClose}>Ok</FaroButton>
      </DialogActions>
    </Dialog>
  );
}
