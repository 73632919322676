import {
  SnapshotRenderer,
  showEverything,
} from "@/components/r3f/renderers/snapshot-renderer";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectAllPanosInOdometryPath } from "@/store/selections-selectors";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { selectIElementWorldPosition } from "@faro-lotv/app-component-toolbox";
import { isIElementPanoInOdometryPath } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useLayoutEffect, useState } from "react";
import { Vector3 } from "three";
import { PanoToPanoDirect } from "./pano-to-pano-direct";
import { PanoToPanoSeries } from "./pano-to-pano-series";
import { PanoToPanoProps } from "./pano-to-pano-types";

/** @returns an animation from pano-to-pano. Selects the best animation based on the pano type. */
export function PanoToPano({
  currentElement,
  targetElement,
  sheet,
  isSecondaryView,
  onCameraMoved,
  onAnimationFinished,
  targetQuaternion,
}: PanoToPanoProps): JSX.Element {
  const panos = useAppSelector((state) => {
    // Returning an empty array here ensure the simple blend animation is used
    // reducing all the extra computations needed only by the sequence animation
    // if the WalkAnimation feature is not enabled
    if (
      isIElementPanoInOdometryPath(currentElement) &&
      selectHasFeature(Features.WalkAnimation)(state)
    ) {
      return selectAllPanosInOdometryPath(currentElement)(state);
    }
    return [];
  });

  const store = useAppStore();
  const cameraPosition = useThree((s) => s.camera.position);

  const [startingIndex] = useState(() => {
    const positions = panos.map(
      (p) =>
        new Vector3(...selectIElementWorldPosition(p.id)(store.getState())),
    );
    const idx = positions.reduce<number | undefined>(
      (prev, curr, currentIndex) => {
        if (!prev) {
          return currentIndex;
        }
        if (
          curr.distanceTo(cameraPosition) <
          positions[prev].distanceTo(cameraPosition)
        ) {
          return currentIndex;
        }
        return prev;
      },
      undefined,
    );
    return idx ?? panos.findIndex((pano) => pano.id === currentElement.id);
  });

  const targetIndex = panos.findIndex((pano) => pano.id === targetElement.id);

  if (currentElement.id === targetElement.id) {
    return (
      <EmptyAnimation
        targetElement={targetElement}
        onAnimationFinished={onAnimationFinished}
      />
    );
  } else if (startingIndex === -1 || targetIndex === -1) {
    return (
      <PanoToPanoDirect
        currentElement={currentElement}
        targetElement={targetElement}
        targetQuaternion={targetQuaternion}
        sheet={sheet}
        isSecondaryView={isSecondaryView}
        onCameraMoved={onCameraMoved}
        onAnimationFinished={onAnimationFinished}
      />
    );
  }
  return (
    <PanoToPanoSeries
      currentElement={currentElement}
      targetElement={targetElement}
      sheet={sheet}
      isSecondaryView={isSecondaryView}
      onCameraMoved={onCameraMoved}
      onAnimationFinished={onAnimationFinished}
      currentIndex={startingIndex}
      targetIndex={targetIndex}
      panos={panos}
    />
  );
}

type EmptyAnimationProps = Pick<
  PanoToPanoProps,
  "targetElement" | "onAnimationFinished"
>;

/** @returns an animation that immediately finishes */
function EmptyAnimation({
  targetElement,
  onAnimationFinished,
}: EmptyAnimationProps): JSX.Element {
  useLayoutEffect(
    () => onAnimationFinished(targetElement),
    [onAnimationFinished, targetElement],
  );

  return <SnapshotRenderer filter={showEverything} />;
}
