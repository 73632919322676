import { useProjectUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import { useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  humanReadableArea,
  humanReadableDistance,
} from "@faro-lotv/app-component-toolbox";
import { FaroText, FontWeights, neutral } from "@faro-lotv/flat-ui";
import { Optional } from "@faro-lotv/foundation";
import { IElementMeasurePolygon } from "@faro-lotv/ielement-types";
import { areaOfClosedPolygon, polygonPerimeter } from "@faro-lotv/lotv";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { Vector3 } from "three";

type MeasurementValuesFieldProps = {
  /** The measurement whose values we want to show to the user */
  measurement: Pick<IElementMeasurePolygon, "points" | "isClosed"> &
    Optional<Pick<IElementMeasurePolygon, "id">, "id">;
};

/** @returns A stack containing the info about the input measurement */
export function MeasurementValuesField({
  measurement,
}: MeasurementValuesFieldProps): JSX.Element {
  const { unitOfMeasure } = useProjectUnitOfMeasure();

  const worldMatrix = useAppSelector(
    selectIElementWorldMatrix4(measurement.id),
  );
  const points = useMemo(
    () =>
      measurement.points.map((p) =>
        new Vector3(p.x, p.y, p.z).applyMatrix4(worldMatrix),
      ),
    [measurement.points, worldMatrix],
  );
  const { perimeter, area } = useMemo(
    () => ({
      perimeter: polygonPerimeter(points, measurement.isClosed),
      area: measurement.isClosed ? areaOfClosedPolygon(points).area : undefined,
    }),
    [measurement.isClosed, points],
  );

  return (
    <Stack>
      <FaroText
        variant="labelL"
        sx={{ color: neutral[300], pb: 1, fontWeight: FontWeights.Bold }}
      >
        Measurement values
      </FaroText>
      <Stack direction="row" gap="80px">
        {area && (
          <Stack direction="row">
            <FaroText variant="heading12" color={neutral[0]}>
              Area
            </FaroText>
            <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
              {humanReadableArea(area, unitOfMeasure)}
            </FaroText>
          </Stack>
        )}
        <Stack direction="row">
          <FaroText variant="heading12" color={neutral[0]}>
            {measurement.isClosed ? "Perimeter" : "Length"}
          </FaroText>
          <FaroText variant="bodyS" sx={{ pl: 1 }} color={neutral[0]}>
            {humanReadableDistance(perimeter, unitOfMeasure)}
          </FaroText>
        </Stack>
      </Stack>
    </Stack>
  );
}
