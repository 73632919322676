import {
  AnnotationVisibility,
  computeAnnotationVisibility,
} from "@/components/r3f/renderers/annotations/annotation-utils";
import { useAppSelector } from "@/store/store-hooks";
import { selectVisibilityDistance } from "@/store/view-options/view-options-selectors";
import { VisibilityDistance } from "@/store/view-options/view-options-slice";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { GUID, TypedEvent } from "@faro-lotv/foundation";
import {
  IElementGenericAnnotation,
  IElementType,
} from "@faro-lotv/ielement-types";
import { useFrame } from "@react-three/fiber";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Matrix4 } from "three";

interface AnnotationSorterData {
  /** List of sortedAnnotations. This should only be accessed through the exported hooks. */
  sortedAnnotations: SortedAnnotation[];
}

/** A generic callback to implement performant animations of faded animations (bypassing reactivity) */
type FadeCallback = (opacity: number) => void;

/** Information needed to sort an annotation */
interface SortedAnnotation {
  /** Id of the sorted annotation */
  id: GUID;

  /** The points in world space that is used to determine the distance metric from. */
  annotation: IElementGenericAnnotation;

  /** The world offset of an annotation */
  worldMatrix: Matrix4;

  /** Whether an annotation should always be visible. */
  forceVisible?: boolean;

  /** Cached importance metric used to sort annotations */
  importance: number;

  /** The visibility state that the annotation wants to be in */
  desiredVisibility: AnnotationVisibility;

  /** The last visibility state the annotation was in */
  lastVisibility?: AnnotationVisibility;

  /** The visibility state that the annotation wants to be in */
  desiredFade: number;

  /** The last visibility state the annotation was in */
  lastFade?: number;

  /** Callback called when the visibility state changed */
  visibilityChanged: TypedEvent<AnnotationVisibility>;

  /** Event executed when the fade changes */
  fadeChanged: TypedEvent<number>;
}

export const AnnotationSorterContext = createContext<
  AnnotationSorterData | undefined
>(undefined);

function useAnnotationSorter(): AnnotationSorterData {
  const ctx = useContext(AnnotationSorterContext);
  if (!ctx) {
    throw Error(
      "useAnnotationSorter called outside an AnnotationSorterContext",
    );
  }
  return ctx;
}

interface AnnotationsSorterProviderProps {
  /** The annotations to sort */
  annotations: IElementGenericAnnotation[];

  /** If true, annotations are never collapsed or hidden */
  preventCollapse?: boolean;

  /** The distance threshold after which annotations start fading (in meters) */
  fadeThreshold?: number;

  /** The distance threshold over which annotations are hidden (in meters) */
  hideThreshold?: number;
}

/** The maximum number of annotations visible */
const NUM_MAX_ANNOTATIONS_VISIBLE = 100;

/**
 * @returns a context provider that provides visibility sorting logic for all annotation children.
 */
export function AnnotationSorterProvider({
  children,
  annotations,
  preventCollapse,
  fadeThreshold: inputFadeThreshold,
  hideThreshold: inputHideThreshold,
}: PropsWithChildren<AnnotationsSorterProviderProps>): JSX.Element {
  /** Mutable state is used for performance of the sorting algorithm */
  const [sortedAnnotations, setSortedAnnotations] = useState<
    SortedAnnotation[]
  >([]);

  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  const fadeThreshold =
    visibilityDistance === VisibilityDistance.nearby
      ? inputFadeThreshold
      : Number.MAX_VALUE;
  const hideThreshold =
    visibilityDistance === VisibilityDistance.nearby
      ? inputHideThreshold
      : Number.MAX_VALUE;

  // If the list of annotation changed, then search for annotations that were present in the previous state,
  // so that they are not re-initialized.
  // This is done to avoid the annotation flickering after changing the tag filter.
  // This hook does not depend on sortedAnnotation because they are recomputed at every frame and
  // executing this hook that many times, would hinder performances.
  useNonExhaustiveEffect(() => {
    const initialAnnotationList: SortedAnnotation[] = [];

    for (const annotation of annotations) {
      const sortedAnnotation = sortedAnnotations.find(
        (sorted) => sorted.id === annotation.id,
      );
      if (sortedAnnotation) {
        initialAnnotationList.push(sortedAnnotation);
      } else {
        initialAnnotationList.push(initSortedAnnotation(annotation));
      }
    }
    setSortedAnnotations(initialAnnotationList);
  }, [annotations]);

  useFrame(({ camera }) => {
    for (const sortedAnnotation of sortedAnnotations) {
      const { annotation, worldMatrix } = sortedAnnotation;

      if (preventCollapse) {
        sortedAnnotation.desiredVisibility = AnnotationVisibility.Visible;
        sortedAnnotation.desiredFade = 1;
        continue;
      }

      if (
        annotation.type !== IElementType.markupPolygon &&
        annotation.type !== IElementType.measurePolygon
      ) {
        // Other annotation types are always visible
        continue;
      }

      const { visibility, fade, importance } = computeAnnotationVisibility(
        camera,
        annotation,
        worldMatrix,
        fadeThreshold,
        hideThreshold,
      );
      sortedAnnotation.desiredVisibility = visibility;
      sortedAnnotation.desiredFade = fade;
      sortedAnnotation.importance = importance;
    }

    if (sortedAnnotations.length > NUM_MAX_ANNOTATIONS_VISIBLE) {
      sortedAnnotations.sort((a, b) => b.importance - a.importance);
    }

    let numVisibleAnnotations = 0;

    for (const annotation of sortedAnnotations) {
      if (annotation.forceVisible) {
        annotation.desiredVisibility = AnnotationVisibility.Visible;
        annotation.desiredFade = 1;
      } else if (
        annotation.desiredVisibility !== AnnotationVisibility.NotVisible
      ) {
        if (numVisibleAnnotations < NUM_MAX_ANNOTATIONS_VISIBLE) {
          numVisibleAnnotations++;
        } else {
          annotation.desiredVisibility = AnnotationVisibility.NotVisible;
        }
      }

      if (annotation.desiredVisibility !== annotation.lastVisibility) {
        annotation.lastVisibility = annotation.desiredVisibility;
        annotation.visibilityChanged.emit(annotation.desiredVisibility);
      }

      if (annotation.desiredFade !== annotation.lastFade) {
        annotation.lastFade = annotation.desiredFade;
        annotation.fadeChanged.emit(annotation.desiredFade);
      }
    }
  });

  return (
    <AnnotationSorterContext.Provider
      value={{
        sortedAnnotations,
      }}
    >
      {children}
    </AnnotationSorterContext.Provider>
  );
}

function initSortedAnnotation(
  annotation: IElementGenericAnnotation,
): SortedAnnotation {
  return {
    id: annotation.id,
    annotation,
    worldMatrix: new Matrix4(),
    lastVisibility: AnnotationVisibility.NotVisible,
    desiredVisibility: AnnotationVisibility.NotVisible,
    desiredFade: -1,
    importance: 0,
    visibilityChanged: new TypedEvent(),
    fadeChanged: new TypedEvent(),
  };
}

function useSortedAnnotation(id: GUID): SortedAnnotation | undefined {
  const { sortedAnnotations } = useAnnotationSorter();

  return useMemo(
    () => sortedAnnotations.find((a) => a.id === id),
    [sortedAnnotations, id],
  );
}

/**
 * @returns the visibility state of an annotation as determined by a distance-based algorithm.
 * @param id the id of the annotation
 */
export function useAnnotationVisibility(id: GUID): AnnotationVisibility {
  const [visibility, setVisibility] = useState(AnnotationVisibility.NotVisible);

  const sortedAnnotation = useSortedAnnotation(id);

  useEffect(() => {
    if (sortedAnnotation) {
      // Initialize the state here, to avoid missing events
      setVisibility(
        sortedAnnotation.lastVisibility ?? AnnotationVisibility.NotVisible,
      );

      sortedAnnotation.visibilityChanged.on(setVisibility);

      return () => sortedAnnotation.visibilityChanged.off(setVisibility);
    }
  }, [sortedAnnotation]);

  return visibility;
}

/**
 * Supplies a world offset of an annotation to the Annotation Sorter
 *
 * @param id the id of the annotation
 * @param worldMatrix The world matrix of the annotation
 */
export function useAnnotationSorterWorldMatrix(
  id: GUID,
  worldMatrix: Matrix4,
): void {
  const sortedAnnotation = useSortedAnnotation(id);

  useEffect(() => {
    if (sortedAnnotation) {
      sortedAnnotation.worldMatrix = worldMatrix;
    }
  }, [sortedAnnotation, worldMatrix]);
}

/**
 * Updates the forceVisible flag for an annotation
 *
 * @param id The id of the annotation
 * @param forceVisible whether to force the visibility of an annotation
 */
export function useAnnotationSorterForceVisible(
  id: GUID,
  forceVisible: boolean,
): void {
  const sortedAnnotation = useSortedAnnotation(id);

  useEffect(() => {
    if (sortedAnnotation) {
      sortedAnnotation.forceVisible = forceVisible;
    }
  }, [id, forceVisible, sortedAnnotation]);
}

/**
 * Attaches a callback to the fadeChanged event of a sorted annotation. Use this to high-frequency properties.
 *
 * @param id The id of the annotation
 * @param onFadeChanged the callback to use
 */
export function useAnnotationSorterFadeChanged(
  id: GUID,
  onFadeChanged: FadeCallback,
): void {
  const sortedAnnotation = useSortedAnnotation(id);

  useEffect(() => {
    if (sortedAnnotation) {
      // Call it once to make sure users are initialized correctly
      onFadeChanged(sortedAnnotation.lastFade ?? 0);

      sortedAnnotation.fadeChanged.on(onFadeChanged);

      return () => {
        sortedAnnotation.fadeChanged.off(onFadeChanged);
      };
    }
  }, [id, onFadeChanged, sortedAnnotation]);
}
