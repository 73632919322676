import {
  EventType,
  ToggleViewSettingsMenuProperties,
} from "@/analytics/analytics-events";
import { ToolButton, ViewOptionsIcon } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { PopoverProps, Tooltip } from "@mui/material";
import { useState } from "react";
import { ViewOptionsMenu } from "./view-options-menu";

export type ViewOptionsToolProps = {
  /** Reference to the element to anchor the popover to */
  anchorEl: PopoverProps["anchorEl"];
};

/** @returns a tool button for the ViewSettingsToolbar to manage the main ViewOptions */
export function ViewOptionsTool({
  anchorEl,
}: ViewOptionsToolProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  function toggleMenu(opening: boolean): void {
    Analytics.track<ToggleViewSettingsMenuProperties>(
      EventType.toggleViewSettingsMenu,
      { opening },
    );

    setIsOpen(opening);
  }

  return (
    <>
      <Tooltip placement="left" title="View settings">
        <ToolButton onClick={() => toggleMenu(true)} selected={isOpen}>
          <ViewOptionsIcon />
        </ToolButton>
      </Tooltip>

      <ViewOptionsMenu
        open={isOpen}
        onClose={() => toggleMenu(false)}
        anchorEl={anchorEl}
      />
    </>
  );
}
