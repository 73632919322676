import { PointCloudObject } from "@/object-cache";
import {
  ColorString,
  dataComparisonColorsSorted,
  neutral,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { useEffect } from "react";
import { Material, PointsMaterial } from "three";

/**
 * @param index The index to get the color for.
 * @returns The comparison color for the given index.
 */
export function colorForIndex(index: number): ColorString {
  return dataComparisonColorsSorted[index % dataComparisonColorsSorted.length];
}

/**
 * Sets materials with distinct colors and convenient transparency to render multiple point clouds.
 *
 * @param pointClouds The point clouds to adapt the materials for.
 * @param selectedPointCloudId The ID of the selected point cloud to highlight.
 */
export function usePointCloudMaterials(
  pointClouds: PointCloudObject[],
  selectedPointCloudId?: GUID,
): void {
  useEffect(() => {
    const oldMaterials: Material[] = [];
    for (const [index, pointCloud] of pointClouds.entries()) {
      oldMaterials.push(pointCloud.material);

      const isSelected =
        !selectedPointCloudId ||
        selectedPointCloudId === pointCloud.iElement.id;

      const color = isSelected ? colorForIndex(index) : neutral[400];

      pointCloud.material = new PointsMaterial({
        color,
        transparent: true,
        opacity: 0.1,
        depthTest: false,
        depthWrite: false,
        sizeAttenuation: false,
        size: 2,
      });
    }
    return () => {
      for (const [index, pointCloud] of pointClouds.entries()) {
        pointCloud.material.dispose();
        pointCloud.material = oldMaterials[index];
      }
    };
  }, [pointClouds, selectedPointCloudId]);
}
