import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/** Types of view objects to control the visibility for */
export enum ViewObjectTypes {
  waypoints = "waypoints",
  annotations = "annotations",
  measurements = "measurements",
  trajectories = "trajectories",
}

export enum VisibilityDistance {
  nearby = "nearby",
  all = "all",
}

type ViewOptionsState = {
  /** Visibility of objects in the scene */
  visibility: Record<ViewObjectTypes, boolean>;
  /** Visibility distance for rendering the objects */
  visibilityDistance: VisibilityDistance;
  /** True to color the waypoints based on their altitude */
  shouldColorWaypoints: boolean;
  /** True to render the waypoints on the floor. False to render them at the scan height */
  shouldShowWaypointsOnFloors: boolean;
};

/** Initial values for the ViewOptionsSlice */
export const VIEW_OPTIONS_INITIAL_VALUES: ViewOptionsState = {
  visibility: {
    waypoints: true,
    annotations: true,
    measurements: true,
    trajectories: true,
  },
  visibilityDistance: VisibilityDistance.nearby,
  shouldColorWaypoints: true,
  shouldShowWaypointsOnFloors: true,
};

export const viewOptionsSlice = createSlice({
  initialState: VIEW_OPTIONS_INITIAL_VALUES,
  name: "viewOptions",
  reducers: {
    /**
     * Update the waypoints visibility
     *
     * @param state current state
     * @param action new visibility for waypoints
     */
    setObjectVisibility(
      state,
      action: PayloadAction<{ type: ViewObjectTypes; visibility: boolean }>,
    ) {
      state.visibility[action.payload.type] = action.payload.visibility;
    },

    /**
     * Update the visibility distance when rendering objects in the 3D scene
     *
     * @param state current state
     * @param action the new visibility distance
     */
    setVisibilityDistance(state, action: PayloadAction<VisibilityDistance>) {
      state.visibilityDistance = action.payload;
    },
    /**
     * Color the waypoints in the 3D scene, based on their altitude
     *
     * @param state current state
     * @param action flag to decide if the waypoints should be colored
     */
    setShouldColorWaypoints(state, action: PayloadAction<boolean>) {
      state.shouldColorWaypoints = action.payload;
    },
    /**
     * Show waypoints on the floor or at the scan height
     *
     * @param state current state
     * @param action flag to decide if the waypoints should be shown on the floor
     */
    setShouldShowWaypointsOnFloors(state, action: PayloadAction<boolean>) {
      state.shouldShowWaypointsOnFloors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => VIEW_OPTIONS_INITIAL_VALUES);
  },
});

export const {
  setObjectVisibility,
  setVisibilityDistance,
  setShouldColorWaypoints,
  setShouldShowWaypointsOnFloors,
} = viewOptionsSlice.actions;

export const viewOptionsReducer = viewOptionsSlice.reducer;
