import { PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import {
  EyeDomePass,
  GapFillPass,
  LodPointCloudRendererBase,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { LodPointCloud } from "@faro-lotv/lotv";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { useEffect } from "react";
import { Plane } from "three";

export type PointCloudProps = EventHandlers & {
  /** Object to render */
  pointCloud: PointCloudObject;

  /** Point cloud opacity */
  opacity?: number;

  /**
   * Whether the point cloud should answser to pointer events via raycasting
   *
   * @default true By default the point cloud handles pointer events via efficient raycasting on the loaded LOD nodes
   */
  raycastEnabled?: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];
};

/**
 * @returns the PointCloud in the correct world position
 */
export function PointCloudRenderer({
  pointCloud,
  opacity = 1,
  raycastEnabled = true,
  clippingPlanes = [],
  ...rest
}: PointCloudProps): JSX.Element {
  const transform = useAppSelector(
    selectIElementWorldTransform(pointCloud.iElement.id),
  );

  useEffect(() => {
    const oldIntersection = pointCloud.material.clipIntersection;

    pointCloud.material.clippingPlanes = clippingPlanes;
    pointCloud.material.clipIntersection = false;

    return () => {
      pointCloud.material.clippingPlanes = [];
      pointCloud.material.clipIntersection = oldIntersection;
    };
  }, [clippingPlanes, pointCloud]);

  return (
    <group {...transform}>
      <LodPointCloudRendererBase
        pointCloud={pointCloud}
        opacity={opacity}
        raycastEnabled={raycastEnabled}
        {...rest}
      />
    </group>
  );
}

type PointCloudDefaultEffectsProps = {
  /** The PointCloud that will be rendered by these Effects */
  pointCloud: LodPointCloud | null;
};

/** @returns the set of default effects for PointClouds to use in an EffectPipeline */
export function PointCloudDefaultEffects({
  pointCloud,
}: PointCloudDefaultEffectsProps): JSX.Element {
  return (
    <>
      <GapFillPass />
      <EyeDomePass enabled={pointCloud?.monochrome} strength={0.5} />
    </>
  );
}
