import { EventType } from "@/analytics/analytics-events";
import { SendToPointRenderer } from "@/components/r3f/renderers/send-to-point-renderer";
import { selectSendToPoints } from "@/store/send-to-tool-selector";
import { addSendToPoint } from "@/store/send-to-tool-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName, deactivateTool } from "@/store/ui/ui-slice";
import { ToolControlsRef } from "@/tools/tool-controls-interface";
import {
  selectIElement,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { generateGUID } from "@faro-lotv/foundation";
import { GUID, isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { forwardRef, useCallback, useEffect, useMemo } from "react";
import { Matrix4, Vector3Tuple } from "three";
import { SendToControls } from "./send-to-controls";
import { useSendToRevitContext } from "./send-to-revit-context";

type SendToPointProps = {
  /** The UUID of the project element that is being used for SendTo. */
  iElementId?: GUID;
};

// This constant defines a rotation matrix which rotates around the x-axis by +90 degrees around x-axis
const MATRIX_ROTATION_X_90 = new Matrix4().makeRotationX(Math.PI / 2);

/**
 * @returns A component that enables the creation and storage of SendTo points on a given model.
 */
export const SendTo = forwardRef<ToolControlsRef, SendToPointProps>(
  function SendTo({ iElementId }: SendToPointProps, ref): JSX.Element {
    const dispatch = useAppDispatch();

    const { sendPoint, sendCommand, connect, disconnect, isConnected } =
      useSendToRevitContext();

    const sendToActive = useAppSelector(selectActiveTool) === ToolName.sendTo;

    useEffect(() => {
      // establish connection to revit if tool is activated but shared revit connection is not
      if (sendToActive && !isConnected) {
        connect();
      }
      // close connection to revit if tool is not activate
      if (!sendToActive && isConnected) {
        disconnect();
      }
    }, [connect, disconnect, sendToActive, isConnected]);

    // select the point cloud element which is being used for picking
    const pointCloud = useAppSelector((root: RootState) => {
      const iElement = iElementId
        ? selectIElement(iElementId)(root)
        : undefined;
      return iElement && isIElementPointCloudStream(iElement)
        ? iElement
        : undefined;
    });

    // select the transformation of the point cloud element which is being used for picking
    const { worldMatrix } = useAppSelector(
      selectIElementWorldTransform(iElementId),
    );
    const pcTransform = useMemo(
      () =>
        new Matrix4().fromArray(worldMatrix).premultiply(MATRIX_ROTATION_X_90),
      [worldMatrix],
    );

    useEffect(() => {
      if (!sendCommand || !isConnected || !iElementId) return;

      const pcStreamingCommand = {
        command: "streamPointCloud",
        parameters: {
          // point cloud uri, delivers the metadata json
          pointCloudUri: pointCloud?.uri,
          // column-major, transforms from local pc coordinates to world coordinates
          transformation: pcTransform.toArray(),
        },
      };
      sendCommand(JSON.stringify(pcStreamingCommand));
    }, [iElementId, isConnected, pcTransform, pointCloud?.uri, sendCommand]);

    const { position: cameraPos } = useThree((s) => s.camera);

    const sendPickedPoint = useCallback(
      (point: Vector3Tuple) => {
        if (!iElementId || !sendPoint || !isConnected) return;

        // Id of the newly created SendTo point.
        const newId = generateGUID();

        // convert gl coordinates of picked point and camera position to revit coordinate system
        const revitPos = { x: point[0], y: -point[2], z: point[1] };
        const revitCamPos = { x: cameraPos.x, y: -cameraPos.z, z: cameraPos.y };

        // send converted point and camera position to revit
        sendPoint(newId, revitPos, revitCamPos);

        Analytics.track(EventType.pickSendToPoints);

        // add point into scene
        dispatch(
          addSendToPoint({
            elementID: iElementId,
            sendToPoint: { id: newId, point, camPos: cameraPos.toArray() },
          }),
        );
      },
      [iElementId, sendPoint, isConnected, cameraPos, dispatch],
    );

    const sendToPoints = useAppSelector(selectSendToPoints(iElementId));

    return (
      <>
        <SendToControls
          onPointPick={sendPickedPoint}
          onEscPressed={() => {
            dispatch(deactivateTool());
          }}
          ref={ref}
        />
        {sendToPoints.map((sendToPoint) => (
          <SendToPointRenderer key={sendToPoint.id} point={sendToPoint.point} />
        ))}
      </>
    );
  },
);
