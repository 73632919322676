import { createSet360ImageRotationMutation } from "@/alignment-tool/project-alignment-mutations";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useViewRuntimeContext } from "@/components/common/view-runtime-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { setSyncCamerasRotation } from "@/store/modes/walk-mode-slice";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { useToast } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  IElementImg360,
  IElementType,
  IElementTypeHint,
  IPose,
  isIElementImg360,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  changePosition,
  computeReferenceSystemTransform,
  fetchProjectIElements,
  isPoseLeftHanded,
  LEFT_TO_RIGHT,
  selectAncestor,
  selectIElementWorldPosition,
} from "@faro-lotv/project-source";
import { useCallback, useState } from "react";
import { Matrix4, Quaternion, Vector3, Vector4Tuple } from "three";
import { WalkSceneActiveElement } from "../walk-mode/walk-types";

export type ImageAlignmentRet = {
  /** Callback to apply the pano image alignment */
  applyImageAlignment(): Promise<void>;
  /** Whether the pano pose adjustment is in progress or not */
  alignmentInProgress: boolean;
};

/**
 * This hook returns a function that applies the pano image alignment, computing the new
 * pano transform and issuing the correct mutation to the Project API.
 *
 * @param imageElement The 360 image element to align
 * @param mainWalkElement The active element in the walk scene
 * @returns a function that applies the pano image alignment.
 */
export function useApplyImageAlignment(
  imageElement: IElementImg360 | undefined,
  mainWalkElement: WalkSceneActiveElement,
): ImageAlignmentRet {
  const store = useAppStore();

  const dispatch = useAppDispatch();

  const { cameras } = useViewRuntimeContext();
  const { openToast } = useToast();
  const client = useCurrentProjectApiClient();

  const { handleErrorWithToast } = useErrorHandlers();

  const [alignmentInProgress, setAlignmentInProgress] = useState(false);

  const applyImageAlignment = useCallback(async () => {
    const imageParentElement = imageElement?.parentId
      ? selectAncestor(imageElement, (element) =>
          isIElementWithTypeAndHint(
            element,
            IElementType.section,
            IElementTypeHint.area,
          ),
        )(store.getState())
      : undefined;
    assert(
      imageElement !== undefined &&
        isIElementImg360(imageElement) &&
        imageParentElement,
      "Missing 360 image element",
    );

    setAlignmentInProgress(true);

    const indexPanoCamera = isIElementImg360(mainWalkElement) ? 0 : 1;
    const index3DCamera = 1 - indexPanoCamera;

    // transformation from 3d view to the edited pano view
    const relativeFullTransformation = cameras[indexPanoCamera].matrixWorld
      .clone()
      .multiply(cameras[index3DCamera].matrixWorldInverse);

    // projecting relativeFullTransformation's X axis on horizontal plane
    const vec1 = new Vector3(
      relativeFullTransformation.elements[0],
      0,
      relativeFullTransformation.elements[2],
    ).normalize();

    const relativeTransformation = new Matrix4().set(
      vec1.x,
      0,
      vec1.z,
      0,
      0,
      1,
      0,
      0,
      -vec1.z,
      0,
      vec1.x,
      0,
      0,
      0,
      0,
      1,
    );

    // Get the world matrices of the pano and the parent
    const panoWorld = selectIElementWorldMatrix4(imageElement.id)(
      store.getState(),
    );
    const parentWorld = selectIElementWorldMatrix4(
      imageElement.parentId ?? undefined,
    )(store.getState());

    // Get the world position of the pano
    const position = selectIElementWorldPosition(imageElement.id)(
      store.getState(),
    );

    const isInsideCaptureTree = !!selectAncestor(
      imageElement,
      (e) => e.typeHint === IElementTypeHint.captureTree,
    )(store.getState());

    // Compute the world transformation that should be applied to the pano, i.e
    // (translation to the origin -> rotation -> translation to the world position)
    const transform = new Matrix4()
      .makeTranslation(new Vector3(...position))
      .multiply(relativeTransformation)
      .multiply(
        new Matrix4().makeTranslation(
          new Vector3(...position).multiplyScalar(-1),
        ),
      );

    // Compute the local quaternion by extracting the new local position from the new world matrix
    // We also need to remove the contribution of the refCoordSystemMatrix, so that can be safely applied
    // when reading the back the transform from the project
    const refCS = computeReferenceSystemTransform(
      imageElement,
      isInsideCaptureTree,
    );

    // ParentWorld and panoWorld are right-handed for sure. However,
    // refCS.rightMatrix may be either left-handed or right-handed.
    // That is why we need to check for a left-handed refCS.rightMatrix
    // and compensate for that.
    const isRMleftHanded = isPoseLeftHanded(refCS.rightMatrix);
    const fixHandedness = isRMleftHanded ? LEFT_TO_RIGHT : new Matrix4();

    const quaternion = new Quaternion().setFromRotationMatrix(
      parentWorld
        .invert()
        .multiply(transform)
        .multiply(panoWorld)
        .multiply(refCS.rightMatrix.invert())
        .multiply(fixHandedness),
    );

    const mirrorQuaternion: Vector4Tuple = [
      quaternion.x,
      quaternion.y,
      -quaternion.z,
      -quaternion.w,
    ];

    // apply the mutation updating the image orientation
    try {
      await client.applyMutations([
        createSet360ImageRotationMutation(imageElement, mirrorQuaternion),
      ]);
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new image orientation",
        error,
      });
      setAlignmentInProgress(false);
      return;
    }

    // update the local copy of the image before fetching it in order to avoid a glitch in the displayed image
    const originalImagePose = imageElement.pose;
    const newPose: IPose = {
      isWorldPose: false,
      pos: originalImagePose?.pos ?? null,
      isWorldScale: originalImagePose?.isWorldScale,
      scale: originalImagePose?.scale ?? null,
      isWorldRot: false,
      rot: {
        x: mirrorQuaternion[0],
        y: mirrorQuaternion[1],
        z: mirrorQuaternion[2],
        w: mirrorQuaternion[3],
      },
      gps: originalImagePose?.gps ?? null,
    };
    dispatch(changePosition({ id: imageElement.id, pose: newPose }));

    // move the panos camera to the same direction (but not azimuth) as the 3D one
    cameras[indexPanoCamera].setRotationFromMatrix(
      relativeTransformation
        .clone()
        .multiply(cameras[indexPanoCamera].matrixWorld),
    );

    // update the local copy of the imageElement
    // Fetch the changed area sub-tree and update the local copy of the project
    // that new alignment will be used without reloading whole project
    await dispatch(
      fetchProjectIElements({
        fetcher: async () => {
          // Refresh the area node to get new transform
          return await client.getAllIElements({
            ancestorIds: [imageElement.id],
          });
        },
      }),
    );

    // lock both screen cameras
    dispatch(setSyncCamerasRotation(true));

    // command completed
    setAlignmentInProgress(false);

    openToast({
      title: "New image orientation saved",
      variant: "success",
    });
  }, [
    client,
    openToast,
    cameras,
    mainWalkElement,
    store,
    dispatch,
    handleErrorWithToast,
    imageElement,
  ]);

  return {
    applyImageAlignment,
    alignmentInProgress,
  };
}
