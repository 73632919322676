import {
  IElementGenericImgSheet,
  isIElementGenericStream,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { Quaternion } from "@react-three/fiber";
import { Vector3 } from "three";
import { ModelToModel } from "./animations/model-to-model";
import { ModelToPano } from "./animations/model-to-pano";
import { PanoToModel } from "./animations/pano-to-model";
import { PanoToPano } from "./animations/pano-to-pano/pano-to-pano";
import { ViewType, WalkSceneActiveElement } from "./walk-types";

type WalkSceneAnimationProp = {
  /** The element from which we start the animation */
  currentElement: WalkSceneActiveElement;

  /** The target element of the animation */
  targetElement: WalkSceneActiveElement;

  /** if provided quaternion should be applied while performing pano animation */
  targetQuaternion?: Quaternion;

  /** Current active img sheet */
  sheet: IElementGenericImgSheet;

  /** Which view is this: main view in walk mode, left view, or right view in splitscreen mode */
  viewType: ViewType;

  /** Callback executed when the animation finishes */
  onAnimationFinished(target: WalkSceneActiveElement): void;

  /** Callback executed when the camera moves in a different position */
  onCameraMoved?(position: Vector3): void;
};

/** @returns The correct animation for the walk scene */
export function WalkSceneAnimation({
  currentElement,
  targetElement,
  targetQuaternion,
  sheet,
  viewType,
  onAnimationFinished,
  onCameraMoved,
}: WalkSceneAnimationProp): JSX.Element | null {
  // TODO: Ensure onCameraMoved is called, in the animation components, when the camera position changes https://faro01.atlassian.net/browse/SWEB-3349

  if (isIElementImg360(currentElement)) {
    if (isIElementImg360(targetElement)) {
      /** Current: Pano, Target: Pano */
      return (
        <PanoToPano
          // The key property is necessary so that the previous animation is
          // unmounted completely before mounting the new one
          key={targetElement.id}
          currentElement={currentElement}
          targetElement={targetElement}
          targetQuaternion={targetQuaternion}
          sheet={sheet}
          isSecondaryView={viewType === ViewType.RightView}
          onCameraMoved={onCameraMoved}
          onAnimationFinished={onAnimationFinished}
        />
      );
    }
    /** Current: Pano, Target: PointCloud/CAD */
    return (
      <PanoToModel
        currentElement={currentElement}
        targetElement={targetElement}
        sheet={sheet}
        onAnimationFinished={onAnimationFinished}
        onCameraMoved={onCameraMoved}
      />
    );
  } else if (isIElementGenericStream(currentElement)) {
    if (isIElementImg360(targetElement)) {
      /** Current: PointCloud, Target: Pano */
      return (
        <ModelToPano
          currentElement={currentElement}
          targetElement={targetElement}
          sheet={sheet}
          onCameraMoved={onCameraMoved}
          onAnimationFinished={onAnimationFinished}
          isSecondaryView={viewType === ViewType.RightView}
        />
      );
    }
    /** Current: PointCloud/CAD, Target: PointCloud */
    return (
      <ModelToModel
        targetElement={targetElement}
        onAnimationFinished={onAnimationFinished}
      />
    );
  }

  return null;
}
