import { selectSheetToCadAlignmentSplitDirection } from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import {
  setSheetToCadAlignmentSplitDirection,
  SplitDirection,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { neutral, ViewDiv } from "@faro-lotv/app-component-toolbox";
import { Stack } from "@mui/material";
import { useCallback } from "react";
import { useSplitDirection } from "../align-to-cad-commons/align-to-cad-utils";
import { ScreenSplitDirectionToggle } from "../align-to-cad-commons/screen-split-direction-toggle";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { SheetToCadCrossSectionToggle } from "./sheet-to-cad-alignment-cross-section-toggle";

/** @returns The sheet to cad alignment split screen */
export function SheetToCadAlignmentSplitScreen(): JSX.Element {
  const { setFirstScreen, setSecondScreen } = useOverlayElements();
  const firstScreenRef = useOverlayRef(setFirstScreen);
  const secondScreenRef = useOverlayRef(setSecondScreen);

  const dispatch = useAppDispatch();
  const splitDirection = useAppSelector(
    selectSheetToCadAlignmentSplitDirection,
  );

  // callback function for changing split-screen direction
  const changeSplitDirection = useCallback(() => {
    const newDirection =
      splitDirection === SplitDirection.horizontalSplit
        ? SplitDirection.verticalSplit
        : SplitDirection.horizontalSplit;
    dispatch(setSheetToCadAlignmentSplitDirection(newDirection));
  }, [dispatch, splitDirection]);

  const { width, height } = useSplitDirection(splitDirection);

  return (
    <Stack
      direction={
        splitDirection === SplitDirection.horizontalSplit ? "row" : "column"
      }
      justifyItems="stretch"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <ViewDiv
        eventDivRef={firstScreenRef}
        sx={{
          height: { height },
          width: { width },
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      />
      <SheetToCadCrossSectionToggle />
      <ViewDiv
        eventDivRef={secondScreenRef}
        sx={{
          height: { height },
          width: { width },
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      />
      <ScreenSplitDirectionToggle
        splitDirection={splitDirection}
        onClick={changeSplitDirection}
      />
    </Stack>
  );
}
