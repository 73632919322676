import {
  SceneContextMenuEvent,
  useSceneEvents,
} from "@/components/common/scene-events-context";
import { ElementIcon, ElementIconType } from "@/components/ui/icons";
import { WalkDepthLoadingToast } from "@/modes/walk-mode/walk-depth-loading-toast";
import { useToast, useTypedEvent } from "@faro-lotv/app-component-toolbox";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { PointerEvent, useCallback, useRef, useState } from "react";
import { MOUSE, Vector3Tuple } from "three";
import { PanoNoDepthInfoToast } from "./pano-no-depth-info-toast";

/**
 * Get the coordinates string "x, y, z" in Z-up for a given point in Y-up
 *
 * @param point the given coordinate in Y-up
 * @returns a text string of the coordinates in z-up.
 */
function getCoordinateStringZUp(point: Vector3Tuple): string {
  return `${point[0].toFixed(3)}, ${-point[2].toFixed(3)}, ${point[1].toFixed(3)}`;
}

/**
 * @returns a context menu for the 3D Scene
 */
export function SceneContextMenu(): JSX.Element {
  const [contextMenu, setContextMenu] = useState<SceneContextMenuEvent>();

  const [coordinates, setCoordinates] = useState<string | undefined>();
  const [objectId, setObjectId] = useState<number | undefined>();

  const cadSceneEvents = useSceneEvents();

  const { openToast } = useToast();

  useTypedEvent<SceneContextMenuEvent>(
    cadSceneEvents?.openSceneContextMenuFrom3DView,
    (event) => {
      cadSceneEvents?.locateCadObjectInTree.emit(event.objectId);
      setContextMenu({ ...event });
      setCoordinates(
        event.point ? getCoordinateStringZUp(event.point) : undefined,
      );
      setObjectId(event.objectId);
    },
  );

  const closeContextMenu = useCallback(() => {
    setContextMenu(undefined);
    pointerOverMenuItem.current = false;
  }, []);

  const hideCadObject = useCallback(() => {
    if (!contextMenu || contextMenu.objectId === undefined) return;
    cadSceneEvents?.setCadModelVisibility.emit({
      objectId: contextMenu.objectId,
      visibility: false,
    });

    closeContextMenu();
  }, [cadSceneEvents, contextMenu, closeContextMenu]);

  const selectParent = useCallback(() => {
    if (!contextMenu || contextMenu.objectId === undefined) return;
    cadSceneEvents?.selectParentCadObjectInTree.emit(contextMenu.objectId);
    closeContextMenu();
  }, [cadSceneEvents, contextMenu, closeContextMenu]);

  // Keep track of whether the pointer is over a menu item, used to prevent
  // right click opening context menu when over a menu item.
  const pointerOverMenuItem = useRef<boolean>(false);
  const pointerUp = useCallback(
    (event: PointerEvent): void => {
      if (!pointerOverMenuItem.current && event.button === MOUSE.RIGHT) {
        closeContextMenu();
        cadSceneEvents?.openSceneContextMenuFromOverlay.emit({
          x: event.clientX,
          y: event.clientY,
        });
      }
    },
    [cadSceneEvents, closeContextMenu],
  );

  const menuItemEvents = {
    onPointerEnter() {
      pointerOverMenuItem.current = true;
    },
    onPointerLeave() {
      pointerOverMenuItem.current = false;
    },
  };

  const copyCoordinates = useCallback((): void => {
    if (coordinates) {
      // Copy to the clipboard.
      navigator.clipboard.writeText(coordinates);
      openToast({ title: "Coordinates copied to clipboard" });
      closeContextMenu();
    }
  }, [closeContextMenu, coordinates, openToast]);

  return (
    <>
      {contextMenu?.panoDepthInfo &&
        !contextMenu.panoDepthInfo.isPanoWithDepth && <PanoNoDepthInfoToast />}
      {contextMenu?.panoDepthInfo?.isPanoWithDepth && (
        <WalkDepthLoadingToast
          isDepthReady={contextMenu.panoDepthInfo.isDepthReady}
        />
      )}
      {contextMenu && !contextMenu.panoDepthInfo && (
        <Menu
          // Prevent default browser context menu.
          // Note: Without following line, on Windows Chrome/Edge always popup the default browser
          // context menu at the same time and often block this menu. There seems be no such issue
          // for Chrome/Edge/Firefox on Mac.
          onContextMenu={(e) => e.preventDefault()}
          open
          onClose={closeContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={{ top: contextMenu.y, left: contextMenu.x }}
          onPointerUp={pointerUp}
        >
          {objectId !== undefined && (
            <MenuItem onClick={hideCadObject} {...menuItemEvents}>
              <ListItemIcon>
                <ElementIcon icon={ElementIconType.NonVisible} />
              </ListItemIcon>

              <Typography>Hide</Typography>
            </MenuItem>
          )}
          {objectId !== undefined && (
            <MenuItem onClick={selectParent} {...menuItemEvents}>
              <ListItemIcon>
                <ElementIcon icon={ElementIconType.FolderIcon} />
              </ListItemIcon>

              <Typography>Select parent (Tab)</Typography>
            </MenuItem>
          )}
          {coordinates && (
            <MenuItem onClick={copyCoordinates}>
              <ListItemIcon>
                <ElementIcon icon={ElementIconType.WorldCoordinateIcon} />
              </ListItemIcon>

              <Typography>{coordinates}</Typography>
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
}
