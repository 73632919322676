import { TypedEvent } from "@faro-lotv/foundation";
import { PropsWithChildren, createContext, useContext, useState } from "react";
import { Vector3Tuple } from "three";

/** Event data for context menu anchor position */
export type SceneContextMenuAnchorPosition = {
  /** The X coordinate of the mouse pointer in viewport coordinates. */
  x: number;

  /** The Y coordinate of the mouse pointer in viewport coordinates. */
  y: number;
};

/** Data for pano depth info availability */
export type PanoDepthAvailabilityInfo = {
  /** The pano has depth information or not */
  isPanoWithDepth: boolean;

  /** The depth is loaded and ready for use*/
  isDepthReady: boolean;
};

/** Event data for context menu */
export type SceneContextMenuEvent = SceneContextMenuAnchorPosition & {
  // The ID of the CAD object associated with the context menu;
  // It can be undefined in case if event is not associated with CAD
  objectId?: number;

  // clicked point on the CAD/PointCloud/Pano object (in WCS and meters)
  point?: Vector3Tuple;

  // pano depth info availability
  panoDepthInfo?: PanoDepthAvailabilityInfo;
};

/** Event data for set visiblity of CAD model */
export type CadModelSetVisibilityEvent = {
  /** The ID of the CAD object */
  objectId: number;

  /** The visiblity to be set to the CAD object */
  visibility: boolean;
};

export type SceneEvents = {
  /** Event emitted when invalidate 3D scene */
  invalidate: TypedEvent<void>;

  /** Event emitted when user right clicks on the 3D view to popup the context menu on a object */
  openSceneContextMenuFrom3DView: TypedEvent<SceneContextMenuEvent>;

  /**
   * Event emitted from 3D view overlay component when user right click to popup the context menu.
   * Because there is no threejs context from overlay components, this event passes the click position to
   * 3D renderer component in which raycast on mesh can be called to find the relative object.
   */
  openSceneContextMenuFromOverlay: TypedEvent<SceneContextMenuAnchorPosition>;

  /** Event emitted when change CAD model visibility from scene context menu */
  setCadModelVisibility: TypedEvent<CadModelSetVisibilityEvent>;

  /**
   Event emitted when locate CAD object in the model tree from scene context menu
   When the event type is a number it's the id of the cad object to select.
   When it is undefined, it means that we want to deselect all the cad objects
   */
  locateCadObjectInTree: TypedEvent<number | undefined>;

  /** Event emitted when select parent from scene context menu */
  selectParentCadObjectInTree: TypedEvent<number>;
};

/** A context for the 3D scene events */
const SceneEventsContext = createContext<SceneEvents | undefined>(undefined);

/**
 * @returns The registered CAD 3D scene events context
 */
export function useSceneEvents(): SceneEvents | undefined {
  return useContext(SceneEventsContext);
}

class SceneEventsContextData {
  invalidate = new TypedEvent<void>();
  openSceneContextMenuFrom3DView = new TypedEvent<SceneContextMenuEvent>();
  openSceneContextMenuFromOverlay =
    new TypedEvent<SceneContextMenuAnchorPosition>();
  setCadModelVisibility = new TypedEvent<CadModelSetVisibilityEvent>();
  locateCadObjectInTree = new TypedEvent<number | undefined>();
  selectParentCadObjectInTree = new TypedEvent<number>();
}

/**
 * @returns A component to register the invalidate 3D scene event context
 */
export function SceneEventsContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [contextData] = useState(() => new SceneEventsContextData());

  return (
    <SceneEventsContext.Provider value={contextData}>
      {children}
    </SceneEventsContext.Provider>
  );
}
