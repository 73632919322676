import { useAppSelector } from "@/store/store-hooks";
import { selectCanUploadPointCloud } from "@/store/subscriptions/subscriptions-selectors";
import {
  DatePicker,
  Dropdown,
  FaroDialog,
  FaroIconButton,
  QuestionMarkInCircleIcon,
  TextField,
  WarningIcon,
} from "@faro-lotv/flat-ui";
import { removeExtension } from "@faro-lotv/foundation";
import { GUID, IElementSection } from "@faro-lotv/ielement-types";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

type PointCloudMetaDialogProps = {
  /** Initial value to use for the name */
  initialName?: string;

  /** Initial value to use for the date */
  initialDate?: Date;

  /** Initial value to use for the floorplan */
  initialFloorplanId?: GUID;

  /** List of floorplans the user will be able to choose from */
  floorplans: IElementSection[];

  /**
   * Function that will be called once the user clicks on the confirm button
   *
   * @param pointCloudName Name of the point cloud the user has provided
   * @param pointCloudDate Date of the point cloud the user has provided
   * @param floorplanId ID of the floorplan the user has selected
   * @param isGeoReferenced true if the user asked the cloud to be geo-referenced
   */
  onConfirm(
    pointCloudName: string,
    pointCloudDate: Date,
    floorplanId: GUID,
    isGeoReferenced: boolean,
  ): void;

  /** Function that will be called once the user clicks on the cancel button */
  onCancel(): void;
};

/**
 * @returns UI allowing to set different attributes of the Point Cloud
 */
export function PointCloudMetaDialog({
  initialName,
  initialDate,
  initialFloorplanId,
  floorplans,
  onConfirm,
  onCancel,
}: PointCloudMetaDialogProps): JSX.Element {
  const [pointCloudName, setPointCloudName] = useState<string | undefined>(
    removeExtension(initialName),
  );
  const [pointCloudDate, setPointCloudDate] = useState<Date | undefined>(
    initialDate,
  );
  const [floorplanId, setFloorplanId] = useState<GUID | undefined>(
    initialFloorplanId,
  );

  const disableConfirm = !pointCloudName || !pointCloudDate || !floorplanId;
  const canUploadPointCloud = useAppSelector(selectCanUploadPointCloud);

  const [isGeoReferenced, setIsGeoReferenced] = useState(false);

  return (
    <FaroDialog
      open
      title="Import Point Cloud"
      confirmText="Import"
      onConfirm={() => {
        if (!pointCloudName) {
          throw new Error("No valid name for the Point Cloud provided");
        }
        if (!pointCloudDate) {
          throw new Error("No valid date for the Point Cloud provided");
        }
        if (!floorplanId) {
          throw new Error("No valid floorplan selected");
        }

        onConfirm(pointCloudName, pointCloudDate, floorplanId, isGeoReferenced);
      }}
      onCancel={onCancel}
      isConfirmDisabled={disableConfirm}
    >
      <Stack
        spacing={2}
        sx={{
          paddingBottom: 3,
        }}
      >
        <Typography variant="body2" color="gray850">
          The process might take some time: we need to upload first your Point
          Cloud and then process it. You can continue using the Viewer, the
          importing will continue in the background.
        </Typography>

        <Stack spacing={2} maxWidth="320px">
          <TextField
            label="Point Cloud Name"
            text={pointCloudName}
            onTextChanged={setPointCloudName}
            error={pointCloudName ? undefined : "This field is required"}
            helpText={initialName ? `Original Name: ${initialName}` : undefined}
          />

          <DatePicker
            label="Time Point"
            initialDate={initialDate}
            date={pointCloudDate}
            onChange={setPointCloudDate}
          />

          <Dropdown
            label="Associated Sheet"
            value={floorplanId}
            options={floorplans.map((floorplan) => ({
              key: floorplan.id,
              value: floorplan.id,
              label: floorplan.name,
            }))}
            onChange={(ev) => {
              setFloorplanId(ev.target.value);
            }}
          />

          {canUploadPointCloud && (
            <Stack direction="row" paddingTop="1rem">
              <FormControlLabel
                label="Point cloud is georeferenced"
                control={
                  <Checkbox
                    checked={isGeoReferenced}
                    onChange={(ev) => setIsGeoReferenced(ev.target.checked)}
                  />
                }
              />
              <Tooltip title="Georeferenced point clouds are expressed in the coordinates system of the 3D Model. Point clouds can be aligned to survey data using FARO® SCENE.">
                <FaroIconButton>
                  <QuestionMarkInCircleIcon />
                </FaroIconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>

        <Stack direction="row" paddingTop="1rem">
          <WarningIcon
            sx={{
              color: "yellow600",
              paddingRight: 0.8,
              alignSelf: "flex-start",
            }}
          />

          <Typography variant="body2" color="black40">
            Please don't close the browser during the upload to not lose your
            progress.
          </Typography>
        </Stack>
      </Stack>
    </FaroDialog>
  );
}
