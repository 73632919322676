import { useCached3DObjectsIfReady } from "@/object-cache";
import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { Canvas, CircularProgress } from "@faro-lotv/app-component-toolbox";
import { TypedEvent } from "@faro-lotv/foundation";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/material";
import { MutableRefObject, useState } from "react";
import { useLoadRevisionPointCloudStreams } from "../loading/use-load-revision-point-cloud-streams";
import { PerspectiveSwitch } from "../ui/perspective-switch";
import { Projection, ProjectionSwitch } from "../ui/projection-switch";
import { RegistrationConnections } from "./registration-connections";
import { RevisionScansControls } from "./revision-scans-controls";
import { RevisionScansRenderer } from "./revision-scans-renderer";

type RevisionScansProps = {
  /** The scans to render in the scene. */
  scanEntities: RevisionScanEntity[];

  /** The report showing the quality of the registration. */
  registrationReport?: MultiRegistrationReport;

  /** A UI overlay to display on top of the canvas. */
  overlay?: React.ReactNode;
};

/** @returns A 3D scene displaying the scans included in the revision. */
export function RevisionScansScene({
  scanEntities,
  registrationReport,
  overlay,
}: RevisionScansProps): JSX.Element | null {
  const [portal, setPortal] = useState<MutableRefObject<HTMLElement>>();
  const pointCloudStreams = useLoadRevisionPointCloudStreams(scanEntities);
  const pointCloudObjects = useCached3DObjectsIfReady(pointCloudStreams);

  const [centerCameraEvent] = useState(new TypedEvent<Perspective>());
  const [perspective, setPerspective] = useState(Perspective.topView);
  const [projection, setProjection] = useState(Projection.orthographic);

  // Display a loading spinner if the point cloud streams are still loading
  if (
    !pointCloudStreams ||
    pointCloudObjects.length !== pointCloudStreams.length
  ) {
    return (
      <Box
        component="div"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{ display: "flex", height: "100%" }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box
      ref={setPortal}
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Canvas style={{ position: "absolute" }} orthographic>
        <RevisionScansRenderer
          scanEntities={scanEntities}
          pointCloudObjects={pointCloudObjects}
          onInitialPositioning={() => centerCameraEvent.emit(perspective)}
        />
        <RevisionScansControls
          centerCameraEvent={centerCameraEvent}
          pointCloudObjects={pointCloudObjects}
          projection={projection}
        />

        <RegistrationConnections
          scanEntities={scanEntities}
          registrationReport={registrationReport}
          portal={portal}
        />
      </Canvas>

      <Stack
        sx={{ p: 2, width: "100%", height: "100%" }}
        justifyContent="end"
        alignItems="start"
        gap={1}
      >
        {projection === Projection.orthographic && (
          <PerspectiveSwitch
            perspective={perspective}
            onPerspectiveChange={(value) => {
              setPerspective(value);
              centerCameraEvent.emit(value);
            }}
          />
        )}

        <ProjectionSwitch
          projection={projection}
          onProjectionChange={setProjection}
        />
      </Stack>

      {overlay}
    </Box>
  );
}
