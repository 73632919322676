import { useThreeContext } from "@/components/common/three-context/three-context";
import { Canvas, CanvasOverlay } from "@faro-lotv/app-component-toolbox";
import { Box } from "@mui/material";
import { PerformanceMonitor, useContextBridge } from "@react-three/drei";
import { PropsWithChildren, useRef } from "react";
import { ReactReduxContext } from "react-redux";
import { useAppSelector } from "@/store/store-hooks";
import {
  AlignmentOverlayElementsContext,
  useAlignmentOverlayElementsInitialState,
} from "./alignment-overlay-context";
import { getStep } from "./alignment-steps";
import { StepTransitionManager } from "./alignment-steps/step-transition-manager";
import { selectActiveStep } from "./store/alignment-selectors";

/** @returns a wrapper to bridge context from React to R3F */
function ContextBridge({ children }: PropsWithChildren<unknown>): JSX.Element {
  const Bridge = useContextBridge(
    ReactReduxContext,
    AlignmentOverlayElementsContext,
  );
  return <Bridge>{children}</Bridge>;
}

/**
 *
 * @returns The main 3D view of the sheet-cloud alignment tool
 */
export function Alignment3DView(): JSX.Element | null {
  document.title = "FARO Sphere Viewer - Align Point Cloud";
  const canvas = useRef<HTMLCanvasElement>(null);
  const overlayElements = useAlignmentOverlayElementsInitialState();
  const stepName = useAppSelector(selectActiveStep);

  const { setRenderer } = useThreeContext();

  if (!stepName) {
    return null;
  }
  const step = getStep(stepName);

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Box
        component="div"
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <AlignmentOverlayElementsContext.Provider value={overlayElements}>
          <Canvas
            style={{ position: "absolute" }}
            ref={canvas}
            onRendererChanged={setRenderer}
          >
            <PerformanceMonitor>
              <ContextBridge>
                <StepTransitionManager step={step} />
              </ContextBridge>
            </PerformanceMonitor>
          </Canvas>
          {step.Overlay && (
            <CanvasOverlay canvas={canvas}>
              <step.Overlay />
            </CanvasOverlay>
          )}
        </AlignmentOverlayElementsContext.Provider>
      </Box>
    </Box>
  );
}
