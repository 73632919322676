import { useCached3DObjectIfExists } from "@/object-cache";
import {
  PositionedPointCloud,
  PositionedPointCloudProps,
} from "@/registration-tools/common/rendering/positioned-point-cloud";
import { useAppSelector } from "@/store/store-hooks";
import { TransformOverrides } from "@faro-lotv/project-source";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { useMemo } from "react";
import {
  selectPointCloudStreamForScanEntity,
  selectRevisionEntityWorldTransformCache,
} from "../store/revision-selectors";

type CaptureTreePointCloudProps = Pick<
  PositionedPointCloudProps,
  "onInitialPositioning"
> & {
  /** The scan entity to render. */
  scanEntity: RevisionScanEntity;
};

/** @returns The capture tree point cloud rendered at the position as determined in the revision. */
export function RevisionScanRenderer({
  scanEntity,
  onInitialPositioning,
}: CaptureTreePointCloudProps): JSX.Element | null {
  const pointCloudStream = useAppSelector(
    selectPointCloudStreamForScanEntity(scanEntity),
  );
  const pointCloudObject = useCached3DObjectIfExists(pointCloudStream);
  const cachedWorldTransform = useAppSelector(
    selectRevisionEntityWorldTransformCache(scanEntity),
  );

  // Apply the position from the revision to the point cloud
  const transformOverrides = useMemo(() => {
    if (!pointCloudObject) return;

    return {
      local: {},
      global: {
        [pointCloudObject.iElement.id]: cachedWorldTransform,
      },
    } satisfies TransformOverrides;
  }, [pointCloudObject, cachedWorldTransform]);

  if (!pointCloudObject) {
    console.warn("No point cloud object found for", scanEntity.id);
    return null;
  }

  return (
    <PositionedPointCloud
      pointCloudObject={pointCloudObject}
      transformOverrides={transformOverrides}
      onInitialPositioning={onInitialPositioning}
    />
  );
}
