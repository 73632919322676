import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { FaroTooltip, useDialog } from "@faro-lotv/flat-ui";
import { TreeData } from "@faro-lotv/project-source";
import { useCallback } from "react";
import { ProjectTreeActionButton } from "./project-tree-action-button";
import {
  compatibilityMessage,
  useDisableCaptureTreeAlignment,
} from "./tree/cad-model-tree/use-disable-capture-tree-alignment";
import { redirectToFloorScalingTool } from "./tree/tree-context-menu/utils";

/**
 * @returns a button used in the project tree, to start the exclusive mode floor scale
 */
export function SetFloorScaleButton({
  id,
}: Pick<TreeData, "id">): JSX.Element | null {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const { createDialog } = useDialog();

  const disableScaling = useDisableCaptureTreeAlignment();

  const onClick = useCallback(() => {
    redirectToFloorScalingTool({
      elementID: id,
      state: store.getState(),
      createDialog,
      dispatch,
    });
  }, [createDialog, dispatch, id, store]);

  return (
    <FaroTooltip title={disableScaling && compatibilityMessage}>
      <ProjectTreeActionButton
        disabled={disableScaling}
        name="Set scale"
        onClick={onClick}
      />
    </FaroTooltip>
  );
}
