import {
  MultiRegistrationReport,
  validateMultiRegistrationReport,
} from "@/registration-tools/utils/multi-registration-report";
import { assert } from "@faro-lotv/foundation";
import { useEffect, useState } from "react";

/**
 * @param reportUrl The URL of the report.
 * @returns The loaded registration report, or `undefined` if it is still being loaded.
 */
export function useLoadRegistrationReport(
  reportUrl: string | null | undefined,
): MultiRegistrationReport | undefined {
  const [registrationReport, setRegistrationReport] =
    useState<MultiRegistrationReport>();

  // Load the registration report
  useEffect(() => {
    if (!reportUrl) return;

    const abortController = new AbortController();

    fetch(reportUrl, {
      signal: abortController.signal,
    }).then(async (response) => {
      const registrationReport = await response.json();

      assert(
        validateMultiRegistrationReport(registrationReport),
        "Invalid registration report",
      );

      setRegistrationReport(registrationReport);
    });

    return () => {
      abortController.abort("Cancel registration report fetching");
    };
  }, [reportUrl]);

  return registrationReport;
}
