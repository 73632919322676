import {
  ButtonProps,
  Button as MUIButton,
  ToggleButton as MUIToggleButton,
  SxProps,
  Theme,
  ToggleButtonProps,
} from "@mui/material";
import { forwardRef } from "react";

/** A custom ToggleButton that can be disabled while preserving the ToolTip */
export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  function ToggleButton(
    { children, value, disabled, onClick, sx, ...props }: ToggleButtonProps,
    ref,
  ) {
    const extraSx: SxProps<Theme> = disabled
      ? {
          ":hover": {
            backgroundColor: "transparent",
            cursor: "default",
          },
          opacity: 0.5,
          backgroundColor: "transparent",
        }
      : {};
    return (
      <MUIToggleButton
        ref={ref}
        value={value}
        sx={{ ...extraSx, ...sx }}
        disableRipple={disabled}
        onClick={disabled ? undefined : onClick}
        {...props}
      >
        {children}
      </MUIToggleButton>
    );
  },
);

/** A custom Button that preserves the ToolTip even when the button is disabled */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    { children, disabled, onClick, sx, ...props }: ButtonProps,
    ref,
  ) {
    const extraSx: SxProps<Theme> = disabled
      ? {
          ":hover": {
            backgroundColor: "transparent",
            cursor: "default",
          },
          opacity: 0.5,
          backgroundColor: "transparent",
        }
      : {};
    return (
      <MUIButton
        ref={ref}
        sx={{ ...extraSx, ...sx }}
        disableRipple={disabled}
        onClick={disabled ? undefined : onClick}
        {...props}
      >
        {children}
      </MUIButton>
    );
  },
);
