import { useSvg } from "@faro-lotv/app-component-toolbox";
import { useFrame, useThree } from "@react-three/fiber";
import { OrthographicCamera, Sprite, Vector3 } from "three";
import LocationTransparent from "../../icons/location.svg";

type DragHandleProps = {
  /** A reference to the underlying Sprite object */
  handle: React.RefObject<Sprite>;
  /** Whether the drag handle should be visible or not */
  visible: boolean;
};

/**
 * @returns Returns the Handle that allows users to move the point cloud and acts as the rotation point
 */
export function DragHandle({ handle, visible }: DragHandleProps): JSX.Element {
  const camera = useThree((state) => state.camera);

  const pinTexture = useSvg(LocationTransparent, 512, 512);

  // Making the handle scale-invariant when the camera zooms
  useFrame(() => {
    if (!handle.current) return;

    if (camera instanceof OrthographicCamera) {
      const initialCamRight = 96;
      handle.current.scale.copy(
        new Vector3(8, 8, 0.1).multiplyScalar(camera.right / initialCamRight),
      );
    }
  });

  return (
    <sprite ref={handle} visible={visible} renderOrder={1}>
      <spriteMaterial
        map={pinTexture}
        transparent={true}
        alphaTest={0.1}
        depthTest={false}
      />
    </sprite>
  );
}
