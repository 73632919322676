import { GUID, assert } from "@faro-lotv/foundation";
import {
  RegistrationRevision,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";

/**
 * @param revisionId The ID of the revision to load.
 * @returns The revision with the given ID.
 *  While the revision is loading, `undefined` is returned.
 *  If the revision does not exist, an error is thrown.
 */
export function useLoadRevision(
  revisionId: GUID,
): RegistrationRevision | undefined {
  const { projectApiClient } = useApiClientContext();

  const [revisions, setRevisions] = useState<RegistrationRevision[]>();

  useEffect(() => {
    const abortController = new AbortController();

    projectApiClient
      .getAllRegistrationRevisions(abortController.signal)
      .then(setRevisions);

    return () => {
      abortController.abort("useRevision hook unmounted");
    };
  }, [projectApiClient]);

  if (!revisions) return;

  // There is currently no endpoint to query for a revision by ID, so we search it manually
  const revision = revisions.find((rev) => rev.id === revisionId);
  assert(revision, `Revision with ID ${revisionId} not found`);

  return revision;
}
