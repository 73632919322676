import {
  EventType,
  OpenTagManagementProperties,
} from "@/analytics/analytics-events";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIsProjectOverviewExpanded } from "@/store/ui/ui-selectors";
import { setIsProjectOverviewExpanded } from "@/store/ui/ui-slice";
import { selectHasWritePermission } from "@/store/user-selectors";
import { APP_VERSION } from "@/utils/env-constants";
import {
  selectProjectName,
  useHorizontalResize,
} from "@faro-lotv/app-component-toolbox";
import {
  ArrowDownIcon,
  FaroIconButton,
  FaroTooltip,
  TagsIcon,
  TruncatedFaroText,
  useBreakpointMdUp,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Box, Divider, GlobalStyles, Stack, Typography } from "@mui/material";
import { RefObject, useState } from "react";
import { DevToolsPanel } from "../devtools/dev-tools-panel";
import { AreaNavigation } from "./area-navigation/area-navigation";
import { ProjectStatusWarningIcon } from "./project-status-warning-icon";
import { ProjectView } from "./project-view";
import { TagsManagementDialog } from "./tags/tags-management-dialog";

/**
 * @returns A wrapper around the IElementTreeView component, which allows to collapse it to the side
 */
export function ProjectOverview(): JSX.Element {
  const dispatch = useAppDispatch();
  const isProjectViewExpanded = useAppSelector(selectIsProjectOverviewExpanded);

  const leftBarWidth = 40;

  const { width, isResizing, handleRef } = useHorizontalResize({
    initialWidth: 320,
    minWidth: 200,
    leftOffset: leftBarWidth,
    maxWidth: 500,
  });

  return (
    <>
      <Box
        component="div"
        sx={{
          backgroundColor: "gray50",
          width: leftBarWidth,
        }}
      >
        <FaroIconButton
          onClick={() => {
            dispatch(setIsProjectOverviewExpanded(!isProjectViewExpanded));
          }}
          title={
            isProjectViewExpanded
              ? "Collapse project overview"
              : "Expand project overview"
          }
        >
          <ArrowDownIcon
            sx={{
              transform: isProjectViewExpanded
                ? "rotate(90deg)"
                : "rotate(-90deg)",
              transition: "transform 0.1s linear",
            }}
          />
        </FaroIconButton>
      </Box>

      <Stack
        direction="row"
        sx={{
          width,
          flexShrink: 0,
          zIndex: 1,
          borderRight: ({ palette }) => `1px solid ${palette.gray200}`,
          display: isProjectViewExpanded ? undefined : "none",
        }}
      >
        <ProjectOverviewBase />
        <ResizeHandle shouldShowHandle={isResizing} handleRef={handleRef} />
      </Stack>
    </>
  );
}

/**
 *  @returns The base project overview component without the resize handle
 */
export function ProjectOverviewBase(): JSX.Element {
  const shouldUseAreaNavigation = useAppSelector(
    selectHasFeature(Features.AreaNavigation),
  );

  let navigation: JSX.Element;
  if (shouldUseAreaNavigation) {
    navigation = <AreaNavigation />;
  } else {
    navigation = (
      <>
        <SidebarTitle />
        <ProjectView />
      </>
    );
  }

  return (
    <Stack sx={{ width: "100%", flex: 1, overflow: "auto" }}>
      {navigation}

      <DevToolsPanel />
      <Typography
        variant="caption"
        sx={{
          color: "black40",
          ml: 0.8,

          // Make sure that long dev-only version strings don't break design
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        ©FARO v{APP_VERSION}
      </Typography>
    </Stack>
  );
}

/**
 * @returns Title for the sidebar showing the project name and potential warning indicators.
 */
function SidebarTitle(): JSX.Element {
  const projectName = useAppSelector(selectProjectName);
  const isMdUp = useBreakpointMdUp();

  const hasWritePermission = useAppSelector(selectHasWritePermission);

  return (
    <Stack
      alignItems="stretch"
      sx={{
        maxWidth: "100%",
        p: 2,
        pl: isMdUp ? 2 : 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={0.5}
      >
        <Stack direction="row" alignItems="center" gap={1.5} minWidth={0}>
          <TruncatedFaroText variant="heading14" sx={{ py: 1 }}>
            {projectName ?? "Loading..."}
          </TruncatedFaroText>
          <ProjectStatusWarningIcon
            sx={{
              fontSize: "14px",
            }}
          />
        </Stack>

        {hasWritePermission && <TagManagementButton />}
      </Stack>

      <Divider />
    </Stack>
  );
}

/** @returns an icon button that shows the tag management dialog when clicked */
function TagManagementButton(): JSX.Element {
  const [isTagsManagementOpen, setIsTagsManagementOpen] = useState(false);

  return (
    <>
      <FaroTooltip title="Tag Management">
        <FaroIconButton
          aria-label="open tag management"
          size="s"
          onClick={() => {
            Analytics.track<OpenTagManagementProperties>(
              EventType.openTagManagement,
              {
                via: "project overview",
              },
            );
            setIsTagsManagementOpen(true);
          }}
        >
          <TagsIcon />
        </FaroIconButton>
      </FaroTooltip>

      <TagsManagementDialog
        open={isTagsManagementOpen}
        onClose={() => setIsTagsManagementOpen(false)}
      />
    </>
  );
}

interface IResizeHandleProps {
  /** Indicator if the handle should be visible */
  shouldShowHandle: boolean;

  /** Ref to the handler */
  handleRef: RefObject<HTMLElement>;
}

/**
 * While being rendered, this will globally override the cursor to be a col-resize
 *
 * Putting into dedicated variable in order to avoid unnecessary re-rendering
 * As suggested on https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override
 */
const GlobalResizeCursor = (
  <GlobalStyles
    styles={{
      body: {
        cursor: "col-resize",
      },
    }}
  />
);

/**
 * @returns Handle bar that will become visible while it is being hovered and `shouldShowHandle` is `true`
 * Will invoke the onMouseDown when mouse button is pressed on it
 */
function ResizeHandle({
  shouldShowHandle,
  handleRef,
}: IResizeHandleProps): JSX.Element {
  const handleStyle = {
    backgroundColor: "black",
    opacity: 0.3,
  };

  return (
    <Box
      component="div"
      ref={handleRef}
      sx={[
        {
          minWidth: 3,
          cursor: "col-resize",

          "&:hover": handleStyle,
        },
        // Keep handle visible while dragging as the mouse can leave the handle during that action
        shouldShowHandle && handleStyle,
      ]}
    >
      {/*
        Keep the resize cursor visible while dragging as hover affect will not take affect
        while cursor moves of the element
      */}
      {shouldShowHandle && GlobalResizeCursor}
    </Box>
  );
}
