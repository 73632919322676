import { BackgroundTask } from "@/utils/background-tasks";
import { FaroTooltip, useBreakpointMdUp } from "@faro-lotv/flat-ui";
import { Typography } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip";
import { MutableRefObject, useEffect, useState } from "react";
import { PointCloudAddOnRequiredMessage } from "../permission-messages";
import { TreeTaskPopup } from "./tree-task-popup";
import { TreeNodeDisabledReason } from "./tree-types";

type TreePopUpProps = {
  /** Text to display in the tree pop up */
  title: string;

  /** True to enable the tooltip */
  isTooltipEnabled: boolean;

  /** Reason to why a node is disabled. A fitting message is shown in the tooltip. */
  disabledReason?: TreeNodeDisabledReason;

  /** The task associated to the current element */
  task?: BackgroundTask;

  /** The rest of the tree element node */
  children: TooltipProps["children"];

  /** Flag to open/close the popup */
  open: boolean;

  /**
   * Ref of the direct parent.
   * Used for making sure that the popup is still hidden after the parent is not hovered anymore and the corresponding
   * event has not been triggered after a timeout.
   */
  parentRef: MutableRefObject<HTMLDivElement | undefined>;
};

/** Timeout to use to force hiding a frozen popup */
const HIDE_FROZEN_POPUP_TIMEOUT = 200;

/** @returns The PopUp for a Tree node */
export function TreePopUp({
  title,
  isTooltipEnabled,
  disabledReason,
  task,
  children,
  open,
  parentRef,
}: TreePopUpProps): JSX.Element | null {
  const isMdUp = useBreakpointMdUp();

  const [isHovering, setIsHovering] = useState(open);

  // Resetting state after the popup has been opened again
  useEffect(() => setIsHovering(open), [open]);

  /**
   * Workaround to make sure that popups are still removed after the mouse has left the parent
   *
   * When the user moves the mouse to fast, it can happen that mouseleave (and mouseout) event is not triggered
   * on the parent. In order to fix that issue, this effect will create an interval for open popups and hide them
   * if the parent is not hovered anymore.
   */
  useEffect(() => {
    let intervalId: number;

    // Only apply this workaround for non-task tooltips as tasks have to be interactable
    if (!task && isHovering) {
      intervalId = window.setInterval(() => {
        const isParentHovered = !!parentRef.current?.matches(":hover");

        if (!isParentHovered) {
          setIsHovering(false);
          clearInterval(intervalId);
        }
      }, HIDE_FROZEN_POPUP_TIMEOUT);
    }

    return () => clearInterval(intervalId);
  }, [parentRef, isHovering, task]);

  const isTooltipVisible = isMdUp && isTooltipEnabled && isHovering && open;

  if (task) {
    // Having a different component structure here is not ideal, because it will completely re-mount
    // the children and lose their internal state (e.g. it closes open context-menus)
    return (
      <TreeTaskPopup
        title={task.type}
        elementName={title}
        task={task}
        placement="right"
        sx={{ height: "inherit" }}
        open={isTooltipVisible}
      >
        {children}
      </TreeTaskPopup>
    );
  }

  const tooltipTitle = disabledReason ? (
    <TreeNodeDisabledMessage disabledReason={disabledReason} />
  ) : (
    title
  );

  return (
    <FaroTooltip title={tooltipTitle} placement="right" open={isTooltipVisible}>
      {children}
    </FaroTooltip>
  );
}

/**
 * @returns a fitting message for the disabledReason
 */
function TreeNodeDisabledMessage({
  disabledReason,
}: Required<Pick<TreePopUpProps, "disabledReason">>): JSX.Element {
  switch (disabledReason) {
    case TreeNodeDisabledReason.notSupported:
      return (
        <Typography>
          This item currently can't be shown in the Sphere Viewer
        </Typography>
      );
    case TreeNodeDisabledReason.missingPointCloudPermission:
      return <PointCloudAddOnRequiredMessage />;
  }
}
