import { SheetModeRenderOrders } from "@/modes/sheet-mode/sheet-mode-render-orders";
import {
  MapWaypointsRenderer,
  PrecisePoints,
  SinglePointGeometry,
  useSvg,
} from "@faro-lotv/app-component-toolbox";
import { isAncestor } from "@faro-lotv/lotv";
import { useCursor } from "@react-three/drei";
import { ThreeEvent } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { useCallback, useMemo, useRef } from "react";
import { Group, Intersection, Points, Raycaster, Vector3 } from "three";
import EndPointSvg from "./VM_EndPoint.svg?url";
import StartPointSvg from "./VM_StartPoint.svg?url";
import PointSvg from "./VM_WaypointDefault.svg?url";
import HoverSvg from "./VM_WaypointHover.svg?url";
import LockedSvg from "./VM_WaypointLocked.svg?url";
import { useSubSamplePlaceholders } from "./use-subsample-placeholders";

/** Size parameters of the placeholder */
const BASE_SIZE = 16;

/** The multiplication factor for the size of an hovered placeholder */
const PLACEHOLDER_HOVER_FACTOR = 1.5;

interface CommonProps {
  /** Which placeholder should currently be hovered */
  hoveredId?: number;

  /** Callback executed when a placeholder is clicked */
  onPlaceholderClick?(id: number): void;
}

interface OdometryPathPlaceholdersProps extends CommonProps {
  /** The set of placeholders to render */
  positions: Vector3[];

  /** Whether to show all placeholders, instead of just the start and end ones.*/
  showAll: boolean;

  /** A list containing for each placeholder a flag specifying if it should be considered "locked" */
  locked: boolean[];

  /** Callback executed when the hovered placeholder changes */
  onHoveredPlaceholderChanged(id: number | undefined): void;
}

/**
 * @param intersection the intersected object
 * @returns the placeholder index of the intersected object
 */
export function computeRaycastedIndex(
  intersection?: Intersection,
): number | undefined {
  // We explicitly defined the object name of the first and last placeholders
  // to be their index
  if (intersection?.object.name) {
    return Number.parseInt(intersection.object.name, 10);
  }
  // If we reach here we hit one of the other placeholders, the proper index is the
  // point index in the buffer + 1 for the starting point
  if (intersection?.index !== undefined) {
    return intersection.index + 1;
  }
}

/**
 * @param raycaster to cast a ray on the placeholders
 * @param group that contains the OdometryPathPlaceholders
 * @returns the index of the hit placeholder or undefined
 */
export function raycastOnPlaceholdersGroup(
  raycaster: Raycaster,
  group: Group,
): number | undefined {
  const points: Points[] = [];
  group.traverse((o) => {
    if (o instanceof Points) {
      points.push(o);
    }
  });
  const hit = raycaster.intersectObjects(points)[0];
  return computeRaycastedIndex(hit);
}

/**
 * @returns the placeholders for an odometry path
 */
export function OdometryPathPlaceholders({
  positions,
  showAll,
  locked,
  hoveredId,
  onHoveredPlaceholderChanged,
  onPlaceholderClick,
}: OdometryPathPlaceholdersProps): JSX.Element | null {
  const [start, end, rest] = useMemo(() => {
    return [positions.at(0), positions.at(-1), positions.slice(1, -1)];
  }, [positions]);

  const restLocked = useMemo(() => locked.slice(1, -1), [locked]);

  const groupRef = useRef<Group>(null);

  const updateHovered = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      ev.stopPropagation();
      const point = ev.intersections.find(
        (i) =>
          i.object instanceof Points &&
          groupRef.current &&
          isAncestor(i.object, groupRef.current),
      );
      onHoveredPlaceholderChanged(computeRaycastedIndex(point));
    },
    [onHoveredPlaceholderChanged],
  );

  if (!start || !end) {
    return null;
  }

  return (
    <group
      ref={groupRef}
      position-y={0.1}
      onPointerEnter={updateHovered}
      onPointerMove={updateHovered}
      onPointerLeave={updateHovered}
    >
      {rest.length > 0 && showAll && (
        <OtherPlaceholders
          positions={rest}
          references={[start, end]}
          locked={restLocked}
          hoveredId={hoveredId}
          onPlaceholderClick={onPlaceholderClick}
        />
      )}

      <StartEndPlaceholders
        position={start}
        index={0}
        hoveredId={hoveredId}
        onPlaceholderClick={onPlaceholderClick}
      />

      <StartEndPlaceholders
        position={end}
        index={positions.length - 1}
        hoveredId={hoveredId}
        onPlaceholderClick={onPlaceholderClick}
      />
    </group>
  );
}

interface StartEndPlaceholdersProps extends CommonProps {
  /** The position of the placeholder */
  position: Vector3;

  /** The index of this placeholder in the list */
  index: number;
}

/**
 * @returns the placeholders to be used at the start or end of a path
 */
function StartEndPlaceholders({
  position,
  index,
  hoveredId,
  onPlaceholderClick,
}: StartEndPlaceholdersProps): JSX.Element | null {
  const texture = useSvg(index === 0 ? StartPointSvg : EndPointSvg, 256, 256);

  const onClick = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      if (onPlaceholderClick) {
        ev.stopPropagation();
        onPlaceholderClick(index);
      }
    },
    [onPlaceholderClick, index],
  );

  const isHovered = hoveredId === index;

  useCursor(isHovered);

  return (
    <PrecisePoints
      position={position}
      material-size={
        isHovered ? BASE_SIZE * PLACEHOLDER_HOVER_FACTOR : BASE_SIZE
      }
      onClick={onClick}
      material-map={texture}
      material-transparent={true}
      material-sizeAttenuation={false}
      material-depthTest={false}
      material-depthWrite={true}
      name={`${index}`}
      renderOrder={SheetModeRenderOrders.Placeholders}
    >
      <SinglePointGeometry />
    </PrecisePoints>
  );
}

interface OtherPlaceholdersProps extends CommonProps {
  /** The set of placeholders to render */
  positions: Vector3[];

  /** Additional placeholders that are rendered in the path and should be accounted for in the hiding logic. */
  references: Vector3[];

  /** The flags specifying which placeholders are locked */
  locked: boolean[];
}

/**
 * @returns placeholders to be used in the middle of a path. Automatically hides cluttering waypoints.
 */
function OtherPlaceholders({
  positions,
  references,
  locked,
  onPlaceholderClick,
  hoveredId,
}: OtherPlaceholdersProps): JSX.Element | null {
  const defaultTexture = useSvg(PointSvg, 256, 256);
  const hoveredTexture = useSvg(HoverSvg, 256, 256);
  const lockedTexture = useSvg(LockedSvg, 256, 256);

  const { groupRef, computeHiddenPlaceholders } = useSubSamplePlaceholders(
    references,
    BASE_SIZE,
    locked,
  );

  const localHovered = useMemo(() => {
    if (hoveredId !== undefined && hoveredId - 1 < positions.length) {
      return hoveredId - 1;
    }
  }, [hoveredId, positions.length]);

  const clickOnPlaceholder = useCallback(
    (index: number | undefined) => {
      if (!onPlaceholderClick || index === undefined) return;
      onPlaceholderClick(index + 1);
    },
    [onPlaceholderClick],
  );

  const customPlaceholders = useMemo(() => {
    const v = [];
    for (let i = 0; i < locked.length; ++i) {
      if (locked[i]) {
        v.push(i);
      }
    }
    return v;
  }, [locked]);

  return (
    <group ref={groupRef}>
      <MapWaypointsRenderer
        waypoints={positions}
        baseSize={BASE_SIZE}
        hoveredSizeFactor={PLACEHOLDER_HOVER_FACTOR}
        hoveredId={localHovered}
        onPlaceholderClick={clickOnPlaceholder}
        computeHidden={computeHiddenPlaceholders}
        customWaypoints={customPlaceholders}
        textures={{
          defaultTexture,
          hoveredTexture,
          customTexture: lockedTexture,
        }}
      />
    </group>
  );
}
