import {
  CompleteMeasurementEventProperties,
  DeleteMeasurementEventProperties,
  EventType,
  ToggleUnitOfMeasureEventProperties,
} from "@/analytics/analytics-events";
import { AnnotationVisibility } from "@/components/r3f/renderers/annotations/annotation-utils";
import { ExpandedMeasureRenderer } from "@/components/r3f/renderers/measurements/expanded-measure-renderer";
import { MultiPointMeasureRenderer } from "@/components/r3f/renderers/measurements/multi-point-measure-renderer";
import { TwoPointMeasureSegment } from "@/components/r3f/renderers/measurements/two-point-segment-renderer";
import { useProjectUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import {
  selectActiveMeasurement,
  selectIsMeasurementBeingTaken,
  selectMeasurements,
} from "@/store/measurement-tool-selector";
import {
  addMeasurement,
  removeMeasurement,
  setActiveMeasurement,
  setIsMeasurementBeingTaken,
} from "@/store/measurement-tool-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName, deactivateTool } from "@/store/ui/ui-slice";
import { useThreeEventTarget } from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert, generateGUID } from "@faro-lotv/foundation";
import { usePerformanceMonitor } from "@react-three/drei";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { isEqual } from "lodash";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MOUSE, Vector3 } from "three";
import { ToolControlsRef } from "../tool-controls-interface";
import { MeasuresSorter } from "./measures-sorter";
import {
  MultiPointMeasureControls,
  MultiPointMeasureControlsActions,
} from "./multi-point-measures-controls";

type MultiPointMeasuresProps = {
  /** The UUID of the project element that is being measured. */
  iElementIds: string[] | undefined;

  /**
   * Enable/disable controls
   *
   * @default true
   */
  enableControls?: boolean;
};

/**
 * @returns A component that enables the creation, editing, storage and deletion of two-point measures on a given model.
 */
export const MultiPointMeasures = forwardRef<
  ToolControlsRef,
  MultiPointMeasuresProps
>(function MultiPointMeasures(
  { iElementIds, enableControls = true }: MultiPointMeasuresProps,
  ref,
): JSX.Element {
  const dispatch = useAppDispatch();

  const { unitOfMeasure, setUnitOfMeasure } = useProjectUnitOfMeasure();

  const measurements = useAppSelector(selectMeasurements(iElementIds), isEqual);

  const [points, setPoints] = useState<Vector3[]>();
  const onPointsChanged = useCallback((points: Vector3[] | undefined) => {
    setPoints(points);
  }, []);

  const [currentPoint, setCurrentPoint] = useState<Vector3>();
  const onCurrentPointChanged = useCallback((point: Vector3 | undefined) => {
    setCurrentPoint(point ? point.clone() : undefined);
  }, []);

  const onMeasurementCompleted = useCallback(
    (isClosed: boolean, iElementId: string) => {
      if (!iElementIds?.includes(iElementId)) return;
      if (!points) return;

      Analytics.track<CompleteMeasurementEventProperties>(
        EventType.completeMeasurement,
        {
          isClosed,
          numberOfPoints: points.length,
        },
      );

      // Id of the new created measurement.
      const measurement = {
        id: generateGUID(),
        points: points.map((p) => p.toArray()),
        parentId: iElementId,
        metadata: {
          name: `measurement - ${measurements.length}`,
          isLoop: isClosed,
        },
      };
      dispatch(
        addMeasurement({
          elementID: iElementId,
          measurement,
        }),
      );

      setPoints(undefined);

      // Set the new measurement as the active one.
      dispatch(setActiveMeasurement(measurement));

      dispatch(setIsMeasurementBeingTaken(false));

      // Deactivate the tool after the measurement is completed.
      dispatch(deactivateTool());
    },
    [dispatch, iElementIds, measurements.length, points],
  );

  const activeMeasurement = useAppSelector(selectActiveMeasurement);

  const isMeasurementBeingTaken = useAppSelector(selectIsMeasurementBeingTaken);

  const deleteActiveMeasurement = useCallback(() => {
    if (activeMeasurement) {
      Analytics.track<DeleteMeasurementEventProperties>(
        EventType.deleteMeasurement,
        {
          via: "delete key",
        },
      );

      dispatch(
        removeMeasurement({
          elementID: activeMeasurement.parentId,
          measurementID: activeMeasurement.id,
        }),
      );
    }
  }, [activeMeasurement, dispatch]);

  const camera = useThree((s) => s.camera);

  const sorter = useMemo(() => new MeasuresSorter(camera), [camera]);
  // Make sorter reactive to performance, with faster updates if possible
  const onIncline = useCallback(() => {
    sorter.secsBeforeUpdate = 2;
  }, [sorter]);
  const onDecline = useCallback(() => {
    sorter.secsBeforeUpdate = 0.1;
  }, [sorter]);
  usePerformanceMonitor({ onIncline, onDecline });

  // While picking the other measurements should not be interactable
  const disablePointerEvents = !!points;

  const onToggleUnitOfMeasure = useCallback(() => {
    const newUnitOfMeasure = unitOfMeasure === "metric" ? "us" : "metric";

    Analytics.track<ToggleUnitOfMeasureEventProperties>(
      EventType.toggleUnitOfMeasure,
      {
        newValue: newUnitOfMeasure === "metric" ? newUnitOfMeasure : "imperial",
      },
    );

    setUnitOfMeasure(newUnitOfMeasure);
  }, [setUnitOfMeasure, unitOfMeasure]);

  const unselectActiveMeasurement = useCallback(() => {
    dispatch(setActiveMeasurement(undefined));
  }, [dispatch]);

  const deselectMeasureTool = useCallback(() => {
    if (!isMeasurementBeingTaken) dispatch(deactivateTool());
  }, [dispatch, isMeasurementBeingTaken]);

  // When the active tool changes, we need to unselect the active measurement
  const measureActive =
    useAppSelector(selectActiveTool) === ToolName.measurement;
  useEffect(() => {
    if (!measureActive) {
      unselectActiveMeasurement();
    }
  }, [measureActive, unselectActiveMeasurement]);

  const onMeasurementStarted = useCallback(() => {
    unselectActiveMeasurement();

    dispatch(setIsMeasurementBeingTaken(true));
  }, [dispatch, unselectActiveMeasurement]);

  const controlActions = useRef<MultiPointMeasureControlsActions>(null);
  const onHandlerClicked = useCallback(
    (ev: ThreeEvent<MouseEvent>, index: number) => {
      if (!points) return;
      if (index < 0 || index >= points.length) return;
      if (points.length <= 1) return;
      if (ev.button !== MOUSE.LEFT) return;

      if (index === 0) {
        controlActions.current?.completeMeasurement(true);
        ev.stopPropagation();
      } else if (index === points.length - 1) {
        controlActions.current?.completeMeasurement(false);
        ev.stopPropagation();
      }
    },
    [points],
  );

  const onHandlerContextMenu = useCallback(
    (ev: ThreeEvent<MouseEvent>, index: number) => {
      controlActions.current?.deletePoint(index);
      ev.nativeEvent.preventDefault();
      ev.stopPropagation();
    },
    [],
  );

  const [isCurrentLabelVisible, setIsCurrentLabelVisible] = useState(true);
  const [isLastLabelVisible, setIsLastLabelVisible] = useState(true);
  const onHandlerHovered = useCallback(
    (index: number) => {
      if (points?.length === undefined) return;
      setIsCurrentLabelVisible(index !== points.length - 1);
      setIsLastLabelVisible(index !== 0);
    },
    [points],
  );

  const currentSegmentPosition = useMemo(() => {
    if (!points || points.length < 1 || !currentPoint) return;
    return Object.freeze(
      new Vector3()
        .addVectors(points[points.length - 1], currentPoint)
        .multiplyScalar(0.5),
    );
  }, [currentPoint, points]);

  const lastSegmentPosition = useMemo(() => {
    if (!points || points.length < 2 || !currentPoint) return;
    return Object.freeze(
      new Vector3().addVectors(points[0], currentPoint).multiplyScalar(0.5),
    );
  }, [currentPoint, points]);

  const viewOverlay = useThreeEventTarget();
  assert(
    // Return of useThreeEventTarget is not correctly typed (see https://faro01.atlassian.net/browse/SWEB-3461)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    viewOverlay !== null,
    "Cannot create a AnnotationRenderer component because R3F canvas has no parent HTML element.",
  );

  const labelContainer = useRef(viewOverlay);

  return (
    <>
      {points &&
        points.length >= 1 &&
        currentPoint &&
        currentSegmentPosition && (
          <ExpandedMeasureRenderer
            position={currentSegmentPosition}
            firstPoint={points[points.length - 1]}
            secondPoint={currentPoint}
            live
            disablePointerEvents={disablePointerEvents}
            unitOfMeasure={unitOfMeasure}
            visible={true}
            isToolActive={true}
            isMainLabelVisible={isCurrentLabelVisible}
            areXYZLabelsVisible={false}
            labelContainer={labelContainer}
            dashed={true}
            onToggleUnitOfMeasure={onToggleUnitOfMeasure}
          />
        )}
      {points &&
        points.length >= 2 &&
        currentPoint &&
        lastSegmentPosition &&
        isCurrentLabelVisible && (
          <TwoPointMeasureSegment
            visible={true}
            start={currentPoint}
            end={points[0]}
            labelPosition={lastSegmentPosition}
            length={currentPoint.distanceTo(points[0])}
            index={0}
            main={false}
            live={false}
            isMeasurementActive={true}
            isLabelActive={true}
            labelContainer={labelContainer}
            unitOfMeasure={unitOfMeasure}
            onClick={() => {}}
            dashed={true}
            labelsPointerEvents={disablePointerEvents ? "none" : "auto"}
            isLabelVisible={isLastLabelVisible}
          />
        )}
      {points && (
        <MultiPointMeasureRenderer
          points={points}
          live
          isClosed={false}
          active={true}
          disablePointerEvents={disablePointerEvents}
          visibility={AnnotationVisibility.Visible}
          unitOfMeasure={unitOfMeasure}
          onToggleUnitOfMeasure={onToggleUnitOfMeasure}
          onHandlerClicked={onHandlerClicked}
          onHandlerContextMenu={onHandlerContextMenu}
          onHandlerHovered={onHandlerHovered}
        />
      )}
      {enableControls && (
        <MultiPointMeasureControls
          onPointsChanged={onPointsChanged}
          onCurrentPointChanged={onCurrentPointChanged}
          onMeasurementCompleted={onMeasurementCompleted}
          onDeleteActiveMeasurement={deleteActiveMeasurement}
          onMeasurementStarted={onMeasurementStarted}
          onEscPressed={() => {
            unselectActiveMeasurement();
            deselectMeasureTool();
          }}
          onMeasurementCanceled={() => {
            setPoints(undefined);
          }}
          ref={ref}
          actions={controlActions}
        />
      )}
    </>
  );
});
