import { Measurement } from "@/store/measurement-tool-slice";
import { selectAnnotationSection } from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { selectPanoAnnotationsAdjustedPose } from "@/utils/camera-transform";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  GUID,
  IElement,
  IElementSection,
  IElementTypeHint,
  IPose,
  PolygonPoint,
  isAnnotationGroup,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { DEFAULT_NODES_GROUP_SCALE_UNIFORM } from "@faro-lotv/service-wires";
import { Matrix4, Vector3, Vector3Tuple } from "three";

type MutationCreationResult = {
  /** The ID of the root of the project */
  rootId: GUID;
  /** The parent section of the Measurement/Markup Polygon */
  sectionId: GUID;
  /** The id of the group containing all the measurements/annotations for this sub-tree */
  groupId: GUID | undefined;
  /** The list of points describing the polygon */
  points: PolygonPoint[];
  /** The pose of the polygon element in the project */
  pose: IPose;
  /** The IElement on which the Markup/Measurement has been defined */
  targetElement: IElement;
};

/** The descriptor of a point annotation */
export type PointAnnotationData = {
  /** The clicked 3d point */
  points: Vector3Tuple[];

  /** The iElement on which the annotation was picked */
  parentId: GUID;

  /** The annotation type (space or map) */
  type: IElementTypeHint.mapAnnotation | IElementTypeHint.spaceAnnotation;
};

/**
 * Compute all the necessary parameters to add a markup/measurement polygon to the project
 *
 * @param annotationToCreate The annotation to store in the project
 * @param area The current area of the project
 * @returns The parameters to use to create the mutation
 */
export function selectAddPolygonMutationData(
  annotationToCreate: Measurement | PointAnnotationData,
  area: IElementSection,
) {
  return (appState: RootState): MutationCreationResult | undefined => {
    // Get the 3D element to which the annotation is associated (point cloud, pano, etc..)
    const targetElement = selectIElement(annotationToCreate.parentId)(appState);
    if (!targetElement) return;

    const annotationParent = selectAnnotationSection(
      targetElement,
      area,
    )(appState);
    if (!annotationParent) return;

    // Check if the section already contains an annotation group
    const annotationGroup = selectChildDepthFirst(
      annotationParent,
      isAnnotationGroup,
      1,
    )(appState);

    // If the element is a 360, take care of the manually set position of the pano
    const panoOffset = new Vector3();
    if (isIElementImg360(targetElement)) {
      const adjustedPose = new Matrix4().fromArray(
        selectPanoAnnotationsAdjustedPose(targetElement)(appState),
      );
      panoOffset.setFromMatrixPosition(adjustedPose);
    }

    // Compute the offset that will be put in the annotation pose
    const offset = new Vector3()
      .fromArray(annotationToCreate.points[0])
      .sub(panoOffset);
    const worldTransform = selectIElementWorldMatrix4(
      annotationGroup?.id ?? annotationParent.id,
    )(appState).clone();
    if (!annotationGroup) {
      worldTransform.scale(
        new Vector3(
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
        ),
      );
    }

    offset.applyMatrix4(worldTransform.clone().invert());
    const pose = new Matrix4().makeTranslation(offset.x, offset.y, offset.z);

    // Compute the coordinates of the annotation points relative to the poses
    // defined in the project
    worldTransform.multiplyMatrices(worldTransform, pose).invert();
    const TEMP_VEC = new Vector3();
    const points: PolygonPoint[] = annotationToCreate.points.map((point) => {
      const p = TEMP_VEC.fromArray(point)
        .sub(panoOffset)
        .applyMatrix4(worldTransform);
      return {
        // TODO: Remove usage of null from the polygon point states - https://faro01.atlassian.net/browse/SWEB-4163
        state: null,
        x: p.x,
        y: p.y,
        // Negate the z for the right-handed to left-handed conversion
        z: -p.z,
      };
    });

    // Return the necessary data for the mutation
    return {
      rootId: annotationParent.rootId,
      sectionId: annotationParent.id,
      groupId: annotationGroup?.id,
      points,
      pose: {
        pos: {
          x: offset.x,
          y: offset.y,
          // Negate the z for the right-handed to left-handed conversion
          z: -offset.z,
        },
        scale: null,
        rot: null,
        gps: null,
        isWorldRot: false,
      },
      targetElement,
    };
  };
}
