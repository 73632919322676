import { useAppSelector } from "@/store/store-hooks";
import { BreadcrumbItem, FaroBreadcrumbs } from "@faro-lotv/flat-ui";
import {
  getActiveProjectsPage,
  getProjectDetailsPage,
  selectDashboardUrl,
  selectProjectId,
  selectProjectName,
} from "@faro-lotv/project-source";

/** @returns the breadcrumbs navigation for the project main ui */
export function ProjectBreadcrumbs(): JSX.Element | null {
  const projectName = useAppSelector(selectProjectName);
  const projectId = useAppSelector(selectProjectId);
  const dashboardUrl = useAppSelector(selectDashboardUrl);

  if (!projectName) return null;

  const projectsUrl = dashboardUrl
    ? getActiveProjectsPage(dashboardUrl)
    : undefined;
  const projectUrl =
    dashboardUrl && projectId
      ? getProjectDetailsPage(dashboardUrl, projectId)
      : undefined;

  return (
    <FaroBreadcrumbs>
      <BreadcrumbItem label="projects" link={projectsUrl} />
      <BreadcrumbItem label={projectName} link={projectUrl} />
      <BreadcrumbItem label="viewer" />
    </FaroBreadcrumbs>
  );
}
