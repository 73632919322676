import { Dispatch } from "@reduxjs/toolkit";
// This is the first time importing useDispatch and useSelector, hence disabling eslint
import {
  TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports
  useDispatch,
  // eslint-disable-next-line no-restricted-imports
  useSelector,
  useStore,
} from "react-redux";
import type { RootState, AppDispatch } from "./store";

/**
 * @returns Custom useDispatch hook that is typed with the app's store
 *
 * Use throughout your app instead of plain `useDispatch`
 */
export function useAppDispatch(): Dispatch {
  return useDispatch<AppDispatch>();
}

/**
 * Custom useSelector hook that is typed with the app's store
 *
 * Use throughout your app instead of plain `useSelector`
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Custom useStore hook that is typed with our RootSTate
 *
 * Use instead of useStore, all caution about useStore reactivity still applies
 */
export const useAppStore = useStore<RootState>;
