import {
  EventType,
  ResetPairwiseRegistrationTransformationEventProperties,
} from "@/analytics/analytics-events";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { useCached3DObject, useCached3DObjectIfExists } from "@/object-cache";
import { useAlignmentOverlay } from "@/registration-tools/common/alignment-overlay-context";
import { useRegistrationContext } from "@/registration-tools/common/registration-context";
import {
  selectCurrentPerspective,
  selectPointCloudTransform,
} from "@/registration-tools/common/store/registration-selectors";
import { setPointCloudTransform } from "@/registration-tools/common/store/registration-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  LodPointCloudRendererBase,
  Map2DControls,
  selectIElementWorldTransform,
  useCustomCanvasCursor,
  useNonExhaustiveEffect,
  useReproportionCamera,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { CursorStyle } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { TypedEvent } from "@faro-lotv/foundation";
import {
  IElementGenericImgSheet,
  IElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import { Map2DControls as Map2DControlsImpl } from "@faro-lotv/lotv";
import { useFrame, useThree } from "@react-three/fiber";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Group,
  Matrix4,
  Matrix4Tuple,
  OrthographicCamera,
  Raycaster,
  Sprite,
  Vector3,
} from "three";
import { DragHandle } from "../common/interaction/drag-handle";
import {
  C2CMode,
  PointCloudManipulatorLogic,
} from "../common/interaction/point-cloud-manipulator-logic";
import { useEventsListener } from "../common/interaction/use-events-listener";
import { useMultiCloudPointBudgetManager } from "../common/rendering/use-multi-cloud-point-budget-manager";
import { usePointCloudMaterials } from "../common/rendering/use-point-cloud-materials";
import { Perspective } from "../common/store/registration-datatypes";
import { centerCameraOnPointClouds } from "../utils/camera-views";

/**
 * The maximum number of points for both point clouds combined.
 *
 * Value determined experimentally.
 */
const MAX_TOTAL_POINTS = 1_000_000;

/**
 * The maximum number of points to download at the same time for both point clouds combined.
 *
 * Value determined experimentally.
 */
const MAX_NODES_TO_DOWNLOAD_AT_ONCE = 6;

interface PairwiseRegistrationSceneProps {
  /** The reference point cloud */
  activeRefPointCloud: IElementGenericPointCloudStream;
  /** The point cloud to be registered */
  activeModelPointCloud: IElementGenericPointCloudStream;
  /** The sheet both pointclouds are related to */
  activeSheet?: IElementGenericImgSheet;
  /** Callback to execute when the user manually manipulates the point cloud positions. */
  onVisualRegistration(): void;
}

/**
 * @returns The components involved in translating an rotating the point cloud
 * The Point Cloud Manipulator
 */
export function PairwiseRegistrationScene({
  activeRefPointCloud,
  activeModelPointCloud,
  activeSheet,
  onVisualRegistration,
}: PairwiseRegistrationSceneProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const modelPointCloudGroupRef = useRef<Group>(null);
  const refPointCloudGroupRef = useRef<Group>(null);
  const pinRef: React.RefObject<Sprite> = useRef<Sprite>(null);

  const camera = useThree((state) => state.camera);
  useReproportionCamera(camera);
  const pointer = useThree((state) => state.pointer);

  // The current view direction: top, front, or side
  const perspective = useAppSelector(selectCurrentPerspective);

  // The current cloud-to-cloud interaction mode
  const [mode, setMode] = useState<C2CMode>(C2CMode.default);
  // Which cursor to show according to the tool's state
  const [cursorToShow, setCursorToShow] = useState(CursorStyle.default);
  // Whether the map controls are active or whether the user is translating/rotating the point cloud
  const [controlsActive, setControlsActive] = useState(true);

  const modelPointCloud = useCached3DObject(activeModelPointCloud);
  const refPointCloud = useCached3DObject(activeRefPointCloud);
  const sheet = useCached3DObjectIfExists(activeSheet);
  const {
    initialModelTransform,
    setModelCloudInitialTransform,
    modelCloudTransformReset,
    centerCameraOnPerspective,
  } = useAlignmentOverlay();

  const { registrationCompleted } = useRegistrationContext();

  // Initialize the transform of the ref and model point cloud to what we have in the project API
  useNonExhaustiveEffect(() => {
    // Reference point cloud:
    // Not edited by the user, so we can apply it directly
    const initialRefTransform = selectIElementWorldTransform(
      activeRefPointCloud.id,
    )(store.getState());
    applyTransformToGroup(
      refPointCloudGroupRef,
      new Matrix4().fromArray(initialRefTransform.worldMatrix),
    );

    // Model point cloud:
    // We update the transform in the store, which is then applied to the point cloud
    const initialModelTransformIELement = selectIElementWorldTransform(
      activeModelPointCloud.id,
    )(store.getState());
    setModelCloudInitialTransform(initialModelTransformIELement.worldMatrix);
    applyTransformToGroup(
      modelPointCloudGroupRef,
      new Matrix4().fromArray(initialModelTransformIELement.worldMatrix),
    );
    dispatch(setPointCloudTransform(initialModelTransformIELement.worldMatrix));

    recenterCameraOnPointClouds(perspective ?? Perspective.topView);
  }, [refPointCloudGroupRef]);

  const pcTransformArray = useAppSelector(selectPointCloudTransform);

  // The transformation matrix for the model point cloud
  const pcTransform = useMemo(() => {
    return pcTransformArray
      ? new Matrix4().fromArray(pcTransformArray)
      : new Matrix4();
  }, [pcTransformArray]);

  // When the manipulator logic changes the point cloud pose,
  // it is directly updated to the point cloud threejs object.
  const cloudPoseChanged = useCallback(
    (WM: Matrix4) => {
      applyTransformToGroup(modelPointCloudGroupRef, WM);
    },
    [modelPointCloudGroupRef],
  );

  // The manipulator logic object takes care of handling all pointer events and
  // translating and rotating the moving point cloud according to these events.
  // Logic for cursor style changes is also handled by the manipulator logic.
  const [manipulatorLogic, setManipulatorLogic] = useState(
    new PointCloudManipulatorLogic(pcTransform),
  );

  // Keep the transform of the manipulator logic up-to-date
  useEffect(() => {
    manipulatorLogic.updateTransform(pcTransform);
  }, [manipulatorLogic, pcTransform]);

  // Connect the actions of the manipulator logic
  useEffect(() => {
    manipulatorLogic.cursorToShowChanged.on(
      (newCursor: React.SetStateAction<CursorStyle>) => {
        setCursorToShow(newCursor);
      },
    );
    manipulatorLogic.controlsActiveChanged.on(
      (controlsState: boolean | ((prevState: boolean) => boolean)) => {
        setControlsActive(controlsState);
        onVisualRegistration();
      },
    );
    manipulatorLogic.modeChanged.on((newMode: React.SetStateAction<C2CMode>) =>
      setMode(newMode),
    );
    manipulatorLogic.cloudPoseChanged.on(cloudPoseChanged);
    manipulatorLogic.pinPosChanged.on((newPinPos: Vector3) =>
      pinRef.current?.position.copy(newPinPos),
    );
    manipulatorLogic.cloudPoseChangeEnded.on(
      (matrix: { toArray(): Matrix4Tuple | undefined }) => {
        dispatch(setPointCloudTransform(matrix.toArray()));
        onVisualRegistration();
      },
    );
  }, [manipulatorLogic, cloudPoseChanged, dispatch, onVisualRegistration]);

  // Resets model transform to initial transform when workflow was entered,
  // also resets the pin because it is not moved together with the point cloud
  const resetModelTransform = useCallback(() => {
    if (initialModelTransform) {
      Analytics.track<ResetPairwiseRegistrationTransformationEventProperties>(
        EventType.resetPairwiseRegistrationTransformation,
        {
          movedBefore:
            modelPointCloudGroupRef.current?.matrixWorld
              .toArray()
              .toString() !== initialModelTransform.toString(),
        },
      );
      dispatch(setPointCloudTransform(initialModelTransform));
      const initialModelTransformMat = new Matrix4().fromArray(
        initialModelTransform,
      );
      // hide pin
      setMode(C2CMode.default);
      // emit event to change model pc pose and remove the pin as the workflow is entered
      cloudPoseChanged(initialModelTransformMat);
      // reset manipulator
      setManipulatorLogic(
        new PointCloudManipulatorLogic(initialModelTransformMat),
      );
    }
  }, [cloudPoseChanged, dispatch, initialModelTransform]);

  // Sets callback function in the ManualAlignmentOverlay context.
  useTypedEvent<void, TypedEvent<void>>(
    modelCloudTransformReset,
    resetModelTransform,
  );

  // When the view type changes, the camera is repositioned to visualize the model
  // point cloud from the view's camera focal axis
  const pointClouds = useMemo(
    () => [refPointCloud, modelPointCloud],
    [refPointCloud, modelPointCloud],
  );

  // This hook sets convenient materials to the reference and moving point clouds.
  usePointCloudMaterials(pointClouds);
  // Improve performance
  useMultiCloudPointBudgetManager(
    pointClouds,
    MAX_TOTAL_POINTS,
    MAX_NODES_TO_DOWNLOAD_AT_ONCE,
  );

  const recenterCameraOnPointClouds = useCallback(
    (newPerspective: Perspective | null) => {
      if (camera instanceof OrthographicCamera && newPerspective) {
        centerCameraOnPointClouds(
          [refPointCloud, modelPointCloud],
          camera,
          newPerspective,
        );
        manipulatorLogic.perspective = newPerspective;

        if (controlsRef.current) {
          // Whenever the view type (perspective) changes,
          // the camera is re-assigned to the controls so its
          // pose is not changed by the controls.
          controlsRef.current.camera = camera;
        }
      }
    },
    [camera, manipulatorLogic, modelPointCloud, refPointCloud],
  );

  // Sets callback function in the ManualAlignmentOverlay context.
  useTypedEvent<Perspective | null, TypedEvent<Perspective | null>>(
    centerCameraOnPerspective,
    recenterCameraOnPointClouds,
  );

  // This hook sets different cursor styles on hover and drag operations on pin and point cloud
  useCustomCanvasCursor({
    enable: mode !== C2CMode.default,
    cursorToShow,
  });

  const controlsRef = useRef<Map2DControlsImpl>(null);

  const raycaster = useMemo(() => new Raycaster(), []);

  // calculate all intersects used once per frame to feed into the logic below
  // this way it is easier to reason about the current interaction because it does not span across multiple frames
  useFrame(() => {
    raycaster.setFromCamera(pointer, camera);

    manipulatorLogic.ptCloudIntersects =
      raycaster.intersectObject(modelPointCloud);

    if (pinRef.current) {
      manipulatorLogic.pinIntersects = raycaster.intersectObject(
        pinRef.current,
      );
    }
  });

  // When the registration completes, reset the alingment pin
  useTypedEvent<void, TypedEvent<void>>(
    registrationCompleted,
    useCallback(() => {
      setMode(C2CMode.default);
    }, [setMode]),
  );

  // this hook connects the current canvas's pointer events to the manipulator logic
  useEventsListener(manipulatorLogic);

  return (
    <>
      {sheet && <SheetRenderer sheet={sheet} />}
      <Map2DControls ref={controlsRef} enabled={controlsActive} />
      <DragHandle handle={pinRef} visible={mode !== C2CMode.default} />
      <group ref={refPointCloudGroupRef}>
        <LodPointCloudRendererBase pointCloud={refPointCloud} />
      </group>
      <group ref={modelPointCloudGroupRef}>
        <LodPointCloudRendererBase pointCloud={modelPointCloud} />
      </group>
    </>
  );
}

/**
 * Apply the given transform to the group and update its world matrix.
 *
 * Does nothing if the current ref is null.
 *
 * @param groupRef A reference to the group to apply the transform on.
 * @param transform The new transform of the point cloud.
 */
function applyTransformToGroup(
  groupRef: React.RefObject<Group>,
  transform: Matrix4,
): void {
  if (!groupRef.current) return;

  transform.decompose(
    groupRef.current.position,
    groupRef.current.quaternion,
    groupRef.current.scale,
  );
  groupRef.current.updateWorldMatrix(true, true);
}
