import { useLoadIElements } from "@/registration-tools/common/use-load-ielements";
import { useAppSelector } from "@/store/store-hooks";
import {
  IElementGenericPointCloudStream,
  IElementType,
  isValid,
} from "@faro-lotv/ielement-types";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { isEqual } from "lodash";
import { selectPointCloudStreamForScanEntity } from "../store/revision-selectors";

/**
 *
 * @param scanEntities The scans in the revision to load the point cloud streams for.
 * @returns The point cloud streams corresponding to the given revision entities.
 *   If the data is still loading, `undefined` is returned.
 *   Note that some scans may not yet have a processed point cloud stream available.
 */
export function useLoadRevisionPointCloudStreams(
  scanEntities: RevisionScanEntity[],
): IElementGenericPointCloudStream[] | undefined {
  const isLoading = useLoadIElements([
    {
      // Load ancestors for world pos calculation
      descendantIds: scanEntities.map((entity) => entity.id),
    },
    {
      // Load descendants for scan rendering
      ancestorIds: scanEntities.map((entity) => entity.id),
      types: [IElementType.pointCloudStream],
    },
  ]);

  const pointCloudStreams = useAppSelector(
    (state) =>
      scanEntities
        .map((entity) => selectPointCloudStreamForScanEntity(entity)(state))
        .filter(isValid),
    isEqual,
  );

  if (isLoading) return;

  return pointCloudStreams;
}
