import { IDENTITY } from "@/alignment-tool/store/alignment-slice";
import {
  alignmentTransformToMatrix4,
  matrix4ToAlignmentTransform,
} from "@/alignment-tool/utils/alignment-transform";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import { changeMode } from "@/store/mode-slice";
import {
  selectCloudForCadAlignment,
  selectCloudToCadAlignmentCloudElevation,
  selectCloudToCadAlignmentLayout,
  selectCloudToCadAlignmentModelElevation,
  selectCloudToCadAlignmentStep,
  selectIncrementalCloudTransform,
} from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import {
  CloudToCadAlignmentStep,
  resetCloudToCadAlignment,
  setCloudToCadAlignmentLayout,
} from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { AlignmentViewLayout } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { setShowSpinner } from "@/store/ui/ui-slice";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { useToast } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementSection,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  fetchProjectIElements,
  selectAncestor,
  selectIElement,
} from "@faro-lotv/project-source";
import {
  createMutationSetDataSessionWorldPose,
  createMutationSetElementMetaData,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";
import { AlignmentViewLayoutToggle } from "../align-to-cad-commons/alignment-view-layout-toggle";
import { CloudToCadAlignmentProgressBar } from "./cloud-to-cad-alignment-progress-bar";
import { CloudToCadAlignmentSplitScreen } from "./cloud-to-cad-alignment-split-screen";

/** @returns The overlay for the cloud to CAD alignment mode */
export function CloudToCadAlignmentModeOverlay(): JSX.Element {
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const client = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();

  const activeCloudID = useAppSelector(selectCloudForCadAlignment);
  assert(activeCloudID, "point cloud for alignment not defined");

  const activeCad = useAppSelector(selectActiveCadModel);
  assert(activeCad, "Cad model for alignment not defined");

  const applyCloudMutation = useCallback(async () => {
    dispatch(setShowSpinner(true));

    const pointCloudSection = selectAncestor(
      selectIElement(activeCloudID)(store.getState()),
      isIElementSectionDataSession,
    )(store.getState());

    assert(
      pointCloudSection && isIElementSection(pointCloudSection),
      "point cloud for alignment not defined",
    );
    const sectionWorldMatrix = selectIElementWorldMatrix4(pointCloudSection.id)(
      store.getState(),
    );

    const initialCloudWorldMatrix = selectIElementWorldMatrix4(activeCloudID)(
      store.getState(),
    );

    const initialCloudWorldTransform = matrix4ToAlignmentTransform(
      initialCloudWorldMatrix,
    );

    const incrementalTransform = selectIncrementalCloudTransform(
      store.getState(),
    );

    const cloudElevation = selectCloudToCadAlignmentCloudElevation(
      store.getState(),
    );
    const modelElevation = selectCloudToCadAlignmentModelElevation(
      store.getState(),
    );

    const resultElevation =
      initialCloudWorldTransform.position[1] + modelElevation - cloudElevation;

    const transformToApply = incrementalTransform ?? IDENTITY;

    const cloudToCadMatrix = alignmentTransformToMatrix4(transformToApply);

    const mutationTransform = matrix4ToAlignmentTransform(
      cloudToCadMatrix.multiply(sectionWorldMatrix),
    );

    // apply new alignment and set cloud position and rotation to be in World CS.
    // taking into account that it will be stored in left-handed CS.
    const alignMutation = createMutationSetDataSessionWorldPose(
      pointCloudSection.id,
      1,
      {
        x: mutationTransform.quaternion[0],
        y: mutationTransform.quaternion[1],
        z: -mutationTransform.quaternion[2],
        w: -mutationTransform.quaternion[3],
      },
      {
        x: mutationTransform.position[0],
        y: resultElevation,
        z: -mutationTransform.position[2],
      },
    );

    // Save the elevation value in point cloud stream metadata.
    // After the alignment, the elevation for cad model and for the cloud is the same.
    const metaDataMutation = createMutationSetElementMetaData(activeCloudID, [
      {
        key: "alignToCadModelId",
        value: activeCad.id,
        skipIfPresent: false,
      },
      {
        key: "cloudToCadElevation",
        value: modelElevation,
        skipIfPresent: false,
      },
    ]);

    try {
      await client.applyMutations([alignMutation, metaDataMutation]);

      // Fetch the changed section area sub-tree and update the local copy of the project
      // that new alignment will be used without reloading whole project
      dispatch(
        fetchProjectIElements({
          fetcher: async () => {
            // Refresh the area node to get new transform
            return await client.getAllIElements({
              ancestorIds: [pointCloudSection.id],
            });
          },
        }),
      );

      // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
      dispatch(resetCloudToCadAlignment());

      dispatch(setShowSpinner(false));
      openToast({
        title: "Alignment Completed",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new alignment",
        error,
      });

      dispatch(setShowSpinner(false));

      return;
    }

    // after alignment force switch to overview mode as most convenient to validate alignment result in main 3D view
    dispatch(changeMode("overview"));
  }, [
    dispatch,
    activeCloudID,
    activeCad,
    client,
    openToast,
    handleErrorWithToast,
  ]);

  const step = useAppSelector(selectCloudToCadAlignmentStep);
  const alignmentLayout = useAppSelector(selectCloudToCadAlignmentLayout);

  const changeAlignmentScreenLayout = useCallback(
    (value: AlignmentViewLayout) =>
      dispatch(setCloudToCadAlignmentLayout(value)),
    [dispatch],
  );

  return (
    <>
      <CloudToCadAlignmentProgressBar apply={applyCloudMutation} />
      {(step === CloudToCadAlignmentStep.setElevations ||
        alignmentLayout === AlignmentViewLayout.splitScreen) && (
        <CloudToCadAlignmentSplitScreen />
      )}
      {step === CloudToCadAlignmentStep.alignIn2d && (
        <AlignmentViewLayoutToggle
          alignmentLayout={alignmentLayout}
          changeAlignmentScreenLayout={changeAlignmentScreenLayout}
        />
      )}
    </>
  );
}
