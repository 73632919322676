import { compareDateTimes } from "@faro-lotv/foundation";
import {
  IElementType,
  IElementTypeHint,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  BackgroundTaskState,
  isBackgroundTaskActive,
  isBackgroundTaskInfoProgress,
  ProgressApiSupportedTaskTypes,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";

const DEFAULT_INTERVAL = 5000;

export enum SceneConversionState {
  Failed = "failed",
  Running = "running",
  Ok = "ok",
}

/**
 * @returns The current state of a scene conversion, polled every [DEFAULT_INTERVAL] milliseconds
 */
export function useSceneConversionState(): SceneConversionState | undefined {
  const [sceneConversionState, setSceneConversionState] = useState<
    SceneConversionState | undefined
  >(undefined);
  const { progressApiClient: progressApi, projectApiClient: projectApi } =
    useApiClientContext();

  useEffect(() => {
    const controller = new AbortController();
    let timeoutId: number | undefined;
    async function checkConversionProgress(): Promise<void> {
      const root = await projectApi.getRootIElement().catch(() => undefined);
      if (controller.signal.aborted) {
        return;
      }

      const isUploading = !!root?.labels?.find(
        (label) =>
          label.name === "SceneOperation" && label.labelType === "initializing",
      );

      if (isUploading) {
        setSceneConversionState(SceneConversionState.Running);
        return;
      }

      const data = await progressApi
        .requestAllProgress({
          signal: controller.signal,
          direction: "lastToFirst",
        })
        .catch(() => []);

      // The signal could have been aborted while waiting for the progress API response
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (controller.signal.aborted) {
        return;
      }

      // Sort the tasks from the latest to the newest
      data.sort((a, b) => compareDateTimes(b.createdAt, a.createdAt));
      // Find the last scene conversion task
      const sceneConversionTask = data.find(
        (t) => t.taskType === ProgressApiSupportedTaskTypes.sceneConversion,
      );
      // If the conversion failed, return early
      const hasFailed =
        sceneConversionTask?.status.state === BackgroundTaskState.failed;
      if (hasFailed) {
        setSceneConversionState(SceneConversionState.Failed);
        return;
      }

      // Check if there are runing tasks
      const runningTasks = data.filter(
        (t) =>
          (t.status.state && isBackgroundTaskActive(t.status.state)) ??
          isBackgroundTaskInfoProgress(t.status),
      );

      // Check if there is a running Scene conversion task
      if (
        runningTasks.find(
          (t) => t.taskType === ProgressApiSupportedTaskTypes.sceneConversion,
        )
      ) {
        setSceneConversionState(SceneConversionState.Running);
        return;
      }

      const descendantIds = runningTasks
        .map((data) => data.context.elementId ?? data.context.correlationId)
        .filter(isValid);

      const focusDatasets =
        descendantIds.length === 0
          ? []
          : await projectApi
              .getAllIElements({
                typeHints: [IElementTypeHint.dataSetFocus],
                types: [IElementType.section],
                descendantIds,
              })
              .catch(() => []);

      setSceneConversionState(
        focusDatasets.length
          ? SceneConversionState.Running
          : SceneConversionState.Ok,
      );
    }

    // Check for the conversion state every [DEFAULT_INTERVAL] milliseconds
    async function pollConversionProgress(): Promise<void> {
      await checkConversionProgress();
      if (!controller.signal.aborted) {
        timeoutId = window.setTimeout(pollConversionProgress, DEFAULT_INTERVAL);
      }
    }

    pollConversionProgress();

    return () => {
      if (timeoutId !== undefined) {
        window.clearTimeout(timeoutId);
      }
      controller.abort();
    };
  }, [progressApi, projectApi]);

  return sceneConversionState;
}
